import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

const InputText = (props: any) => {
    return (
      <>
        <FloatingLabel
          className="mb-3"
          controlId={props?.name}
          label={
            <>
              {props?.label}
              {props?.required && (
                <span className="text-danger ms-1">*</span>
              )}
            </>
          }
        >
          <Form.Control
            type={props?.type}
            name={props?.name}
            placeholder={props?.placeholder}
            {...props?.register}
            disabled={props?.disabled}
            onChange={props?.onChange}
            // value={props?.value}
            maxlength={props?.maxlength}
            readOnly={props?.readOnly}
            min={0}
          />
          {props?.errors[props?.name] && (
            <p className="errorMsg">{props?.errors[props?.name].message}</p>
          )}
        </FloatingLabel>
        {/* <Form.Group className="mb-3" controlId={props?.name}>
                <Form.Label>{props?.label}</Form.Label>
                <Form.Control
                    type={props?.type}
                    name={props?.name}
                    placeholder={props?.placeholder}
                    {...props?.register}
                />
                {props?.errors[props?.name] && <p className="errorMsg">{props?.errors[props?.name].message}</p>}
            </Form.Group> */}
        {/* <Form.Group className="mb-3" controlId={props?.name}>
                <Form.Label>{props?.label}</Form.Label>
                <Form.Control
                    type={props?.type}
                    name={props?.name}
                    placeholder={props?.placeholder}
                    {...props?.personalinfo}
                />
                {props?.errors[props?.name] && <p className="errorMsg">{props?.errors[props?.name].message}</p>}
            </Form.Group> */}
        {/* <input type={props?.type} name={props?.name} {...props?.register} className='form-control'/>
            {props?.errors[props?.name] && props?.errors[props?.name]?.type === "required" && (
                <p className="errorMsg">{props?.name} is required.</p>
            )}
            {props?.errors[props?.name] && props?.errors[props?.name]?.type === "pattern" && (
                <p className="errorMsg">{props?.name} is not valid.</p>
            )}
            {props?.errors[props?.name] && props?.errors[props?.name].type === "minLength" && (
                <p className="errorMsg">{props?.name} should be at-least 6 characters.</p>
            )} */}
      </>
    );

}
export default InputText