import React, { useEffect, useState } from "react";
import "./mainPage.css";
import PublicHeader from "./PublicHeader";
import PublicFooter from "./PublicFooter";
import PublicLogoHeader from "./PublicLogoHeader";
import PublicBanner from "./PublicBanner";
import PublicDepartments from "./PublicDepartments";
import MegaMenu from "./MegaMenu";
// import { Chart } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import PortalAnalyticView from "./PortalAnalyticView";
import NewsFeed from "./NewsFeed";
import Feedback from "./Feedback";
// import PublicServices from "./PublicServices";
// import ContactUsComponent from "../../components/portalComponents/ContactUs.component";
// import FooterComponent from "../../components/portalComponents/Footer.component";
// import HeaderComponent from "../../components/header/Header.component";
// import MapInfoComponent from "../../components/portalComponents/MapSection.component";
// import MiddleBarComponent from "../../components/portalComponents/MiddleBar.component";
// import SliderComponent from "../../components/portalComponents/Slider.component";
// import SocialFeedsComponent from "../../components/portalComponents/SocialFeeds.component";
// import { Image } from "react-bootstrap";
// import ScrollToTop from "../../components/ScrollToTop";

export default function MainPage() {
  const [showAlert, setShowAlert] = useState(false);

  function handleclick() {
    setShowAlert(false);
  }

  //TO DO

  // useEffect(() => {
  //     const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
  //     let setTiming: any;
  //     if (!hasVisitedBefore) {
  //         setTiming = setTimeout(() => {
  //             setShowAlert(true);
  //             localStorage.setItem('hasVisitedBefore', 'true');
  //         }, 1000);
  //     }
  //     return () => clearTimeout(setTiming);
  // }, []);
  const data = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        data: [65, 59, 80, 81, 56, 55, 40],
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      {/* <GenericPopupModal title="" display="d-none" isVisible={showAlert} secondaryAction={handleclick} isCentered={true} class='advertise-modal' primaryAction={() => { }} children={<img src={advertisementPopup} width='100%' height='100%' alt="" />} /> */}
      <div className="position-relative">
        <PublicHeader />
        {/* <div>
                <PublicLogoHeader />
            </div>
            <div>
                <MegaMenu />
            </div> */}
        <div
          style={{
            paddingTop: "85px",
            paddingBottom: "85px",
            background: "white",
          }}
        >
          <PublicBanner />
        </div>
        <div style={{ padding: "20px 0" }}>
          <PortalAnalyticView />
        </div>
        <div>
          <NewsFeed />
        </div>
        <div>
          <Feedback />
        </div>
        <div>
          <PublicDepartments />
        </div>
        <div>
          <PublicFooter />
        </div>

        {/* <HeaderComponent />
            <SliderComponent />
            <MiddleBarComponent />
            <MapInfoComponent />
            <SocialFeedsComponent />
            <ContactUsComponent />
            <FooterComponent />
            <ScrollToTop type='public' /> */}
      </div>
    </>
  );
}
