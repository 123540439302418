export const personalInfo = [
  "applicationNumber",
  "applicantNameAsPerAadhar",
  "firstName",
  "middleName",
  "lastName",
  "fatherName",
  "husbandName",
  "dob",
  "age",
  "yearsMonthTotal",
  "aadharNumber",
  "mobileNumber",
  "voterIdNumber",
  "panNumber",
  "drivingLicenceNumber",
];

export const addressDetails = [
  "currentState",
  "currentDistrict",
  "currentBlock",
  "currentPanchayat",
  "currentVillageTown",
  "currentHousePlotNumber",
  "currentCity",
  "currentPostoffice",
  "currentPinCode",
  "permanentState",
  "permanentDistrict",
  "permanentBlock",
  "permanentPanchayat",
  "permanentVillageTown",
  "permanentHousePlotNumber",
  "permanentCity",
  "permanentPostoffice",
  "permanentPinCode",
  "nationality",
];

export const membershipDetails = [
  "membershipQualifications",
  "individualType",
  "shgId",
  "nameGplf",
  "nameBlf",
  "nameDlf",
  "designationDuration",
];

export const loanDetails = [
  "bankName",
  "branchName",
  "accountNumber",
  "bankIfscCode",
  "customerId",
  "ageOfBanking",
  "existingLoanDetailsBank",
  "existingLoanDetailsLoanType",
  "existingLoanDetailsLoanAmount",
  "existingLoanDetailsEmiAmount",
  "bankIncomeDetailsoutstandingLoanAmount",
  "incomeFromRemuneration",
  "incomeFromOtherSource",
  "totalIncome",
];

export const vehicleInformation = [
  "manufacturer",
  "vehicleModel",
  "classOfVehicle",
  "yearManufacture",
  "priceOfVehicle",
  "insuranceCost",
  "accessoriesCost",
  "registrationCost",
  "lessMarginMoney",
  "lessDealerDiscount",
  "amountOfLoanRequired",
  "nameAndAddressOfDealer",
  "tinOfShowroom",
  "quotationNumber",
  "quotationDate",
  "modeOfRepayment",
  "proposedRepaymentPeriod",
  "proposedMonthlyInstallment",
];


export const vehicleAuditInformation = [
  { key: "manufacturer", title: "Manufacturer Name" },
  { key: "vehicleModel", title: "Vehicle Model" },
  { key: "classOfVehicle", title: "Type/Class of Two Wheeler" },
  { key: "yearManufacture", title: "Year of manufacture" },
  { key: "priceOfVehicle", title: "Vehicle Price" },
  { key: "insuranceCost", title: "Insurance Cost" },
  { key: "accessoriesCost", title: "Accessories Cost" },
  { key: "registrationCost", title: "Registration Cost" },
  { key: "lessMarginMoney", title: "Less Margin" },
  { key: "lessDealerDiscount", title: "Less Dealer" },
  { key: "amountOfLoanRequired", title: "Loan Amount" },
  { key: "nameAndAddressOfDealer", title: "Name & Address of the Showroom/Dealer/Seller" },
  { key: "tinOfShowroom", title: "TIN of Showroom/Dealer/Seller" },
  { key: "quotationNumber", title: "Quotation No" },
  { key: "quotationDate", title: "Quotation Date" },
  { key: "modeOfRepayment", title: "Proposed Mode of Repayment" },
  { key: "proposedRepaymentPeriod", title: "Proposed Repayment Period" },
  { key: "proposedMonthlyInstallment", title: "Proposed Equated Monthly Installment(in Rs)" },
];


export const audiDetails= [
  { key: "createdBy", title: "Created By" },
  { key: "createdTime", title: "Created Time" },
  { key: "lastModifiedBy", title: "Last Modified By" },
  { key: "lastModifiedTime", title: "Last Modified Time" },
];