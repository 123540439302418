import { useTranslation } from 'react-i18next';
import PublicFooter from './PublicFooter';
import PublicHeader from './PublicHeader';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
const MissionVissionPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };
    return (
        <>
            <PublicHeader />
            <div style={{paddingTop: "110px"}}> 
                <div className='container' >
                    <h2><IoArrowBack onClick={handleBackClick}/> {t("Mission & Vission")} </h2>
                    <p>
                        <div className='ft-b'>{t("MissionStatement")}</div>
                    “{t("MissonText")}”
                    </p>
                    <div>
                        <p>
                            <div className='ft-b'>{t("VisionStatement")}</div>
                            {t("ActivitiesGrowth")}</p>
                    </div>
                </div>
            </div>
            <PublicFooter />
        </>
        
    );
};

export default MissionVissionPage;
