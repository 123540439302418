import { useTranslation } from 'react-i18next';
import PublicFooter from './PublicFooter';
import PublicHeader from './PublicHeader';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
const ProgremeeOverviewPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };
    return (
        <>
            <PublicHeader />
            <div style={{paddingTop: "110px"}}> 
                <div className='container' >
                    <h2><IoArrowBack onClick={handleBackClick}/> {t('Programme Overview')} </h2>
                    <p>
                        {t("WomenEmpowerment")}
                    </p>
                    <div>
                        <p>
                           {t("DownPayment")} 
                        </p>
                        <p>
                            {t("LoanDocument")}
                        </p>
                        <p>
                            {t("ViewAbove")}
                        </p>
                    </div>
                </div>
            </div>
            <PublicFooter />
        </>
        
    );
};

export default ProgremeeOverviewPage;
