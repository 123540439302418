
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const FaqPage = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
  
    return (
        <>
            <div className="faq-page">
                <ul>
                    <li>
                        <div className="faq-q">Q. How do I know if I am eligible for the Mission Shakti Scooter Loan?</div>
                        <div>A. You can check your eligibility from <b>Scooter Loan Process</b> section</div>
                    </li>
                    <li>
                        <div className="faq-q">Q. What are the mandatory documents required to apply for the Mission Shakti Scooter Loan?</div>
                        <div>A. You can check the mandatory documents required to apply for the Mission Shakti Scooter Loan from <b>Scooter Loan Process</b> section</div>
                    </li>
                    <li>
                        <div className="faq-q">Q. Where can I apply for the Mission Shakti Scooter Loan?</div>
                        <div>A. To apply for the Mission Shakti Scooter Loan, you need to login first.</div>
                    </li>
                    <li>
                        <div className="faq-q">Q. Where can I get the information about the banks I can approach for availing the Mission Shakti Scooter Loan?</div>
                        <div>A. You can get the list of department empaneled list of banks from <b>Scooter Loan Process</b> section.</div>
                    </li>
                    <li>
                        <div className="faq-q">Q. Can I re-apply if my loan application is rejected?</div>
                        <div>A. Yes, you can re-apply subject to condition that you fulfill the eligibility criteria.</div>
                    </li>
                    <li>
                        <div className="faq-q">Q. Can I give my Feedback on the Mission Scooter Loan Application?</div>
                        <div>A. We shall always like to get the opportunity to serve you better. You can share your feedback and comments by clicking the <b>Feedback</b>, from the footer section of the home page.</div>
                    </li>
                    <li>
                        <div className="faq-q">Q. Where can I contact in case of any issue on the Mission Scooter Loan Application?</div>
                        <div>A. You can check the <b>Contact Us</b>, from the footer sction of the home page.</div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default FaqPage