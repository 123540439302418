import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import InputText from "../../common/InputText";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { CONSTANT_REGEX_SAMPLE } from "../../../utils/constants"
import { changePassword } from '../../../services/http.service';
import { useTranslation } from 'react-i18next';

const ChangePassword = ({ formData, setFormData }) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const initialFormData = formData || {
        current_password: "",
        new_password: "",
        confirm_new_pass: "",
    };
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues: initialFormData
    });

    useEffect(() => {
        reset(formData);
    }, [formData, reset]);

    useEffect(() => {
        const userId = localStorage.getItem('UUID');
        if (userId) {
            // console.log("User ID from localStorage:", userId);
            setValue('userId', userId);
        }
    }, [setValue]);
    const userInfo = JSON.parse(localStorage.getItem('UserDetails') || '{}');
    const onSubmit = async (data) => {
        const payload = {
            phoneNumber: userInfo.mobileNumber, // Replace with your phone number logic
            userId: userInfo.userName,
            newPassword: data.new_password,
            oldPassword: data.current_password,
        };

        try {
            const response = await changePassword(payload);
            console.log("Password reset response:", response);
            toast.success("Password changed successfully!", {
                position: "top-right",
                autoClose: 3000
            });
            setTimeout(() => {
                navigate('/mission-shakti/home');
            }, 3000);
        } catch (error) {
            console.error("Detailed error resetting password:", error);

            if (error.status === 400 && error.message.includes('Incorrect password')) {
                toast.error("Current password is incorrect. Please try again.", {
                    position: "top-right"
                });
            } else if (error.status === 400) {
                toast.error(`Failed to change password. ${error.message}`, {
                    position: "top-right"
                });
            } else {
                toast.error("An unknown error occurred. Please try again later.", {
                    position: "top-right"
                });
            }
        }
    };
    const handleCancel = () => {
        navigate('/mission-shakti/home');
    };
    const newPassword = watch("new_password");

    return (
      <div className="LoanDetails">
        <ToastContainer />
        {/* Applicant's Primary Account Details Card */}
        <div
          className="card mb-4 shadow mt-3"
          style={{ margin: "3 28px", padding: "1rem" }}
        >
          <div className="card-body">
            {/* <h6 className='text-left mb-3 m-3'><strong>{t('ChangePassword.Heading')}</strong></h6> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={4}>
                  <InputText
                    type="password"
                    name="current_password"
                    label={t("ChangePassword.Current Password")}
                    placeholder="Enter Your Current Password"
                    register={register("current_password", {
                      required: "Please enter your Current Password",
                      minLength: {
                        value: 6,
                        message: "Password should be at-least 6 characters.",
                      },
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.PASSWORD,
                        message:
                          "Password should contain at least one uppercase letter, lowercase letter, digit, and special symbol",
                      },
                    })}
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="password"
                    name="new_password"
                    label={t("ChangePassword.New Password")}
                    placeholder="Enter Your New Password"
                    register={register("new_password", {
                      required: "Please enter your New Password",
                      minLength: {
                        value: 6,
                        message: "Password should be at-least 6 characters.",
                      },
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.PASSWORD,
                        message:
                          "Password should contain at least one uppercase letter, lowercase letter, digit, and special symbol",
                      },
                    })}
                    errors={errors}
                    // onInput={handleIFSCInput}
                    // onChange={handleIfscChange}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="password"
                    name="confirm_new_pass"
                    label={t("ChangePassword.Confirm New Password")}
                    placeholder="Enter Your Confirm New Password"
                    register={register("confirm_new_pass", {
                      required: "Please enter your Confirm New Password",
                      minLength: {
                        value: 6,
                        message: "Password should be at-least 6 characters.",
                      },
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.PASSWORD,
                        message:
                          "Password should contain at least one uppercase letter, lowercase letter, digit, and special symbol",
                      },
                      validate: (value) =>
                        value === newPassword ||
                        "Confirm Passwords do not match",
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    variant="secondary"
                    onClick={handleCancel}
                    style={{
                      width: "max-content",
                    }}
                    className="cancelBtn"
                  >
                    {t("ChangePassword.Cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    className="primaryBtn"
                    style={{ width: "max-content" }}
                  >
                    {t("ChangePassword.Update Password")}
                  </Button>
                </div>
            </form>
          </div>
        </div>

        {/* Existing Loan Details and Income Details Card */}

        {/* Navigation Buttons Card */}
        {/* <div className="card mb-4 shadow">
                <div className="card-body">
                    <Row className="mt-2 twobtn mb-3">
                        <Col className="text-left">
                            <Button variant="secondary" style={{ width: 'max-content', padding: '0.375rem 0.75rem', marginTop: '15px' }} onClick={handleCancel}>
                            {t('ChangePassword.Cancel')}
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button variant="info" style={{ width: 'max-content', padding: '0.375rem 0.75rem', marginTop: '15px' }} type="button" onClick={handleSubmit(onSubmit)}>
                            {t('ChangePassword.Update Password')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div> */}
      </div>
    );
};

export default ChangePassword;
