import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import InputText from "../common/InputText";
import { Button, Row, Col } from "react-bootstrap";
// import { CONSTANT_REGEX_SAMPLE } from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CustomCaptcha from "../common/CustomCaptcha";
import { useTranslation } from "react-i18next";
import InputTextArea from "../common/InputTextArea";
import { CONSTANT_REGEX_SAMPLE } from "../../utils/constants";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";


interface inputProps {
    feedBackForm: any;
    onFormSubmit: (params: any) => any;
    action:any
}
const FeedbackPage = ({ feedBackForm = {}, onFormSubmit, action }: inputProps) => {
    const { t } = useTranslation();
  let navigate = useNavigate();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [formData, setFormData] = useState(feedBackForm);
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: formData
  });

  const onSubmit = async (data: any) => {
    if (!isCaptchaValid) {
      toast.error("Please complete the CAPTCHA.", {
        position: "top-right",
      });
      return;
    }
    onFormSubmit(data);
  };
    return (
      <>
        <div>
          <div className="">
            <h2 className="mb-4 mt-2">
              {t("feedback.feedback")}{" "}
              <FaInfoCircle data-tooltip-id="my-tooltip-2" />{" "}
            </h2>
            <ReactTooltip
              id="my-tooltip-2"
              place="bottom"
              variant="info"
              style={{ zIndex: 11, width: "500px", background: "black" }}
              content="This website is brought to you by the Department of Mission Shakti while surfing through this site you will come across directories and links to Government and Private Organizations. The contents of these sites are not to be construed as a responsibility of or endorsement by the Department of Mission Shakti and are owned by the respective organizations which may be contacted for any further information or suggestion."
            />
            {/* {showInfo && <div className="callout">
                    <p>
                    This website is brought to you by the Department of Mission Shakti while surfing through this site you will come across directories and links to Government and Private Organizations. The contents of these sites are not to be construed as a responsibility of or endorsement by the Department of Mission Shakti and are owned by the respective organizations which may be contacted for any further information or suggestion.
                    </p>
                </div>} */}
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ padding: "0px 20px 0px 10px", marginTop: "10px" }}
            >
              <Row>
                <Col md={6}>
                  <InputText
                    type="text"
                    name="firstName"
                    label={t("feedback.firstName")}
                    placeholder={t("feedback.firstName")}
                    register={{
                      ...register("firstName", {
                        required: t("feedback.firstNameRequired"),
                      }),
                    }}
                    errors={errors}
                  />
                </Col>
                <Col md={6}>
                  <InputText
                    type="text"
                    name="lastName"
                    label={t("feedback.lastName")}
                    placeholder={t("feedback.lastName")}
                    register={{
                      ...register("lastName", {
                        required: t("feedback.lastNameRequired"),
                      }),
                    }}
                    errors={errors}
                  />
                </Col>
                <Col md={6}>
                  <InputText
                    type="text"
                    name="email"
                    label={t("feedback.email")}
                    placeholder={t("feedback.email")}
                    register={{
                      ...register("email", {
                        required: t("feedback.emailRequired"),
                        pattern: {
                          value: CONSTANT_REGEX_SAMPLE.EMAIL,
                          message: "Please enter a correct email",
                        },
                      }),
                    }}
                    errors={errors}
                  />
                </Col>
                <Col md={6}>
                  <InputText
                    type="text"
                    name="phone"
                    maxlength={10}
                    label={t("feedback.phone")}
                    placeholder={t("feedback.phone")}
                    register={{
                      ...register("phone", {
                        required: t("feedback.phoneRequired"),
                        minLength: {
                          value: 10,
                          message:
                            "Phone Number should not less than 10 digits.",
                        },
                        maxLength: {
                          value: 10,
                          message:
                            "Phone Number should not more than 10 digits.",
                        },
                        pattern: {
                          value: CONSTANT_REGEX_SAMPLE.NUMERIC,
                          message: "Phone Number should contain numbers only",
                        },
                      }),
                    }}
                    errors={errors}
                  />
                </Col>
                <Col md={12}>
                  <InputTextArea
                    type="text"
                    name="subject"
                    label={t("feedback.subject")}
                    placeholder={t("feedback.Subject")}
                    register={{
                      ...register("subject", {
                        required: t("feedback.subjectRequired"),
                      }),
                    }}
                    errors={errors}
                  />
                </Col>
                <Col md={12}>
                  <InputTextArea
                    type="text"
                    name="feedback"
                    maxlength={250}
                    label={t("feedback.feedback")}
                    placeholder={t("feedback.feedback")}
                    register={{
                      ...register("feedback", {
                        required: t("feedback.feedbackRequired"),
                        minLength: {
                          value: 10,
                          message: "Please enter atleast 10 character",
                        },
                        maxLength: {
                          value: 250,
                          message: "Maximum character size is 250",
                        },
                      }),
                    }}
                    errors={errors}
                  />
                </Col>
                <CustomCaptcha onCaptchaValid={setIsCaptchaValid} />
              </Row>
              {action && action === "create" && (
                <div style={{ float: "right" }}>
                  <Button
                    className="btn background-saffron"
                    type="submit"
                    style={{ width: "max-content" }}
                  >
                    {t("Submit")}
                  </Button>
                </div>
              )}
            </form>
          </div>
        </div>
      </>
    );
}

export default FeedbackPage