import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "content-type": "application/json",
  },
  responseType: "json",
});

function createAxiosResponseInterceptor(axiosInstance: any) {
  // const interceptor = axiosInstance.interceptors.response.use(
  //     (response: any) => response,
  //     (error: any )=> {
  //         // Reject promise if usual error
  //         // if (errorResponse.status !== 401) {
  //         //     return Promise.reject(error);
  //         // }

  //         /*
  //          * When response code is 401, try to refresh the token.
  //          * Eject the interceptor so it doesn't loop in case
  //          * token refresh causes the 401 response
  //          */
  //         axiosInstance.interceptors.response.eject(interceptor);

  //         // return axiosInstance.post('/api/refresh_token', {
  //         //     'refresh_token': this._getToken('refresh_token')
  //         // }).then(response => {
  //         //     saveToken();
  //         //     error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
  //         //     return axiosInstance(error.response.config);
  //         // }).catch(error => {
  //         //     destroyToken();
  //         //     this.router.push('/login');
  //         //     return Promise.reject(error);
  //         // }).finally(createAxiosResponseInterceptor);

  //       }
  // );

  axiosInstance.interceptors.response.use(
    (response: any) => {
      // Do something with response data
      return response;
    },
    (error: any) => {
      // Handle response error
      if (error?.response && error?.response?.status === 401) {
        // Unauthorized access, redirect or handle accordingly
      }
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.request.use(
    (config: any) => {
      const uData = localStorage.getItem("UserDetails");
      if (uData && (config.method === "post" || config.method === "put")) {
        const userInfo = JSON.parse(uData);
        config.data = {
          ...config.data,
          userInfo,
        };
      }
      // Add authentication token if available
      const token = localStorage.getItem("token");
      //   if (!token) {
      //     throw new Error('Token is required');
      // }

      if (token) {
        const parts = token.split(".");
        if (parts.length !== 3) {
          throw new Error("Invalid JWT");
        }
        const payload = JSON.parse(atob(parts[1]));

        const exp = payload.exp * 1000;
        // if (Date.now() <= exp) {
        //   config.headers["Authorization"] = `Bearer ${token}`;
        // }
      }
      return config;
    },
    (error: any) => {
      // Handle request error
      return Promise.reject(error);
    }
  );
}

createAxiosResponseInterceptor(axiosInstance);

export default axiosInstance;

// const userinfo = window.localStorage.getItem('userData')

//  export const requestBody = {
//   RequestInfo: {
//     apiId: "1",
//     ver: "1",
//     ts: null,
//     action: "create",
//     did: "jh",
//     key: "",
//     msgId: "gfcfc",
//     correlationId: "wefiuweiuff897",
//     authToken: window.localStorage.getItem('authToken'),
//     userInfo: userinfo !== null ? JSON.parse(userinfo) : null,
//   }
// };
