import React from 'react';
import { file_csv, file_excel, file_pdf } from '../../../utils/Logo';
import "../export/exportbtn.css";

function ExportMenu(props: any) {
    const { onCSVExport, onExcelExport, onExportPdf } = props;

    return (
        <>
            <ul className="dropdown-menu" aria-labelledby="dropdownExportButton" style={{padding: 0, minWidth: "90px"}}>
                <li onClick={onExcelExport} className='drop-down-panel-li'>
                    <a className="dropdown-item" href="jascript:void(0)" style={{padding: "5px"}}><img src={file_excel} alt='' style={{ height: 20, width: 20, marginLeft: 5 }} className='me-2' />
                    {'Excel'}</a>
                </li>
                <li onClick={onExportPdf} className='drop-down-panel-li'>
                    <a className="dropdown-item" href="jascript:void(0)" style={{padding: "5px"}}>
                        <img src={file_pdf} alt='' style={{ height: 20, width: 20, marginLeft: 5 }} className='me-2' />
                        {'Pdf'}
                    </a>
                </li>
                <li onClick={onCSVExport} className='drop-down-panel-li'>
                    <a className="dropdown-item" href="jascript:void(0)" style={{padding: "5px"}}>
                        <img src={file_csv} alt='' style={{ height: 20, width: 20, marginLeft: 5 }} className='me-2' />
                        {'CSV'}
                    </a>
                </li>
            </ul>
            {/* <div className='dropdown-content userprofile-content' style={{ top: '50px', width: 150, borderRadius: 8 }}>
                <div>
                    <button onClick={onExcelExport} className='dropdown-item'>
                        <img src={file_excel} alt='' style={{ height: 20, width: 20, marginLeft: 8 }} className='me-3' />
                        {'Excel'}
                    </button>
                </div>

                <div>
                    <button onClick={onExportPdf} className='dropdown-item'>
                        <img src={file_pdf} alt='' style={{ height: 20, width: 20, marginLeft: 10 }} className='me-3' />
                        {'Pdf'}
                    </button>
                </div>

                <div>
                    <button onClick={onCSVExport} className='dropdown-item'>
                        <img src={file_csv} alt='' style={{ height: 20, width: 20, marginLeft: 10 }} className='me-3' />
                        {'CSV'}
                    </button>
                </div>
            </div> */}
        </>
    );
}

export default ExportMenu;
