import { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";

import InputText from "../../../common/InputText";
import InputMultiSelect from "../../../common/InputMultiSelect";
import {
  APP_MESSAGES,
  CONSTANT_REGEX_SAMPLE,
} from "../../../../utils/constants";
import { postData } from "../../../../services/http.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../../../LanguageContext";
import Collapsible from "../../../common/Collapsible";
import { userRoleName } from "../../../../utils/constants";
const AddressInfo = ({
  formData,
  addressDetails,
  setAddressDetails,
  userRole,
  control,
  register,
  errors,
}) => {
  const applicationStatus =
    formData?.applicationBasicDetails?.applicationStatus;
  let editable =
    userRole === userRoleName &&
    (applicationStatus === "Saved as Draft" || applicationStatus === undefined);
  const { t } = useTranslation();
  const { changeLanguage } = useLanguageContext();
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState({ p_blocks: [], c_blocks: [] });
  const [panchayats, setPanchayats] = useState({
    p_panchayats: [],
    c_panchayats: [],
  });
  const [villages, setVillages] = useState({ p_villages: [], c_villages: [] });
  const [postOffices, setPostOffices] = useState({
    p_postoffices: [],
    c_postoffices: [],
  });
  const [sameAsPermanent, setSameAsPermanent] = useState(false);

  useEffect(() => {
    async function loadDistricts() {
      try {
        const result = await postData(
          process.env.REACT_APP_ADDRESS_DROPDOWN_SERVICE
        );
        const { state, districts } = {
          state: result?.data?.addressDetails?.state,
          districts: result?.data?.addressDetails?.districts || [],
        };
        setDistricts(districts);
      } catch (error) {
        toast.error(APP_MESSAGES.API_ERR, {
          position: "top-right",
        });
      }
    }

    async function loadBlocks() {
      if (addressDetails.permanentDistrict) {
        const districtId = districts.find((d) => {
          return d.districtName === addressDetails.permanentDistrict;
        });
        if (districtId) {
          try {
            const blockRes = await postData(
              `${process.env.REACT_APP_ADDRESS_DROPDOWN_SERVICE}?districtId=${districtId.uuid}`
            );
            const blocks = blockRes?.data?.addressDetails?.blocks || [];
            setBlocks(blocks);
          } catch (error) {
            toast.error(APP_MESSAGES.API_ERR, {
              position: "top-right",
            });
          }
        }
      }
    }
    loadDistricts();
    loadBlocks();
  }, []);

  const handleDistrictChange = async (selectedOption) => {
    const districtId = selectedOption.value;
    const districtName = selectedOption.label;
    try {
      const blockRes = await postData(
        `${process.env.REACT_APP_ADDRESS_DROPDOWN_SERVICE}?districtId=${districtId}`
      );
      const blocks = blockRes?.data?.addressDetails?.blocks || [];
      setBlocks((prevstate) => ({ ...prevstate, p_blocks: blocks }));
    } catch (error) {
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    }
    setAddressDetails((prevstate) => ({
      ...prevstate,
      permanentDistrict: districtName,
      permanentBlock: "",
      permanentPanchayat: "",
      permanentVillageTown: "",
    }));
  };

  const handleBlockChange = async (selectedOption) => {
    const blockId = selectedOption?.value;
    const blockName = selectedOption?.label;
    try {
      const response = await postData(
        `${process.env.REACT_APP_ADDRESS_DROPDOWN_SERVICE}?blockId=${blockId}`
      );
      const panchData = response?.data?.addressDetails?.panchayats || [];
      setPanchayats((prevstate) => ({ ...prevstate, p_panchayats: panchData }));
      setAddressDetails((prevstate) => ({
        ...prevstate,
        permanentBlock: blockName,
        permanentPanchayat: "",
        permanentVillageTown: "",
      }));
    } catch (error) {
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    }
  };

  const handlePanchayatChange = async (selectedOption) => {
    const panchayatId = selectedOption.value;
    const panchayatName = selectedOption.label;
    try {
      const response = await postData(
        `${process.env.REACT_APP_ADDRESS_DROPDOWN_SERVICE}?panchayatId=${panchayatId}`
      );
      const data = response?.data?.addressDetails?.villages || [];
      setVillages((prevstate) => ({ ...prevstate, p_villages: data }));
      setAddressDetails((prevstate) => ({
        ...prevstate,
        permanentPanchayat: panchayatName,
        permanentVillageTown: "",
      }));
    } catch (error) {
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    }
  };
  const handleVillageChange = (selectedOption) => {
    const villageName = selectedOption.label;
    setAddressDetails((prevstate) => ({
      ...prevstate,
      permanentVillageTown: villageName,
    }));
  };
  const handleCDistrictChange = async (selectedOption) => {
    const districtId = selectedOption.value;
    const CdistrictName = selectedOption.label;
    const blockRes = await postData(
      `${process.env.REACT_APP_ADDRESS_DROPDOWN_SERVICE}?districtId=${districtId}`
    );
    const blocks = blockRes?.data?.addressDetails?.blocks || [];
    setBlocks((prevstate) => ({ ...prevstate, c_blocks: blocks }));
    setAddressDetails((prevstate) => ({
      ...prevstate,
      currentDistrict: CdistrictName,
      currentBlock: "",
      currentPanchayat: "",
      currentVillageTown: "",
    }));
  };

  const handleCBlockChange = async (selectedOption) => {
    const blockId = selectedOption.value;
    const CblockName = selectedOption.label;
    try {
      const response = await postData(
        `${process.env.REACT_APP_ADDRESS_DROPDOWN_SERVICE}?blockId=${blockId}`
      );
      const data = response?.data?.addressDetails?.panchayats || [];
      setPanchayats((prevstate) => ({ ...prevstate, c_panchayats: data }));
      setAddressDetails((prevstate) => ({
        ...prevstate,
        currentBlock: CblockName,
      }));
    } catch (error) {
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    }
  };

  const handleCPanchayatChange = async (selectedOption) => {
    const panchayatId = selectedOption.value;
    const CpanchayatName = selectedOption.label;
    try {
      const response = await postData(
        `${process.env.REACT_APP_ADDRESS_DROPDOWN_SERVICE}?panchayatId=${panchayatId}`
      );
      const data = response?.data?.addressDetails?.villages || [];
      setVillages((prevstate) => ({ ...prevstate, c_villages: data }));
      setAddressDetails((prevstate) => ({
        ...prevstate,
        currentPanchayat: CpanchayatName,
      }));
    } catch (error) {
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    }
  };

  const handleCVillageChange = (selectedOption) => {
    const CvillageName = selectedOption.label;
    setAddressDetails((prevstate) => ({
      ...prevstate,
      currentPanchayat: CvillageName,
    }));
  };
  const handlePincodeChange = async (e) => {
    const pincode = e.target.value;
    setAddressDetails((prevstate) => ({
      ...prevstate,
      permanentPinCode: pincode,
    }));
    if (pincode.length === 6) {
      try {
        const response = await postData(
          `${process.env.REACT_APP_UTILS_PINCODE_SERVICE}?pincode=${pincode}`
        );
        const data = response?.data?.PostOffice;
        // const data = await fetchPostOfficesByPincode(pincode);
        if (data) {
          setPostOffices((prevstate) => ({
            ...prevstate,
            p_postoffices: data,
          }));
        } else {
          setPostOffices([]);
        }
      } catch (error) {
        toast.error(APP_MESSAGES.API_ERR + " for Post Offices..", {
          position: "top-right",
        });
      }
    }
  };

  const handleCPincodeChange = async (e) => {
    setAddressDetails((prevstate) => ({
      ...prevstate,
      currentPinCode: pincode,
    }));
    const pincode = e.target.value;
    if (pincode.length === 6) {
      try {
        const response = await postData(
          `${process.env.REACT_APP_UTILS_PINCODE_SERVICE}?pincode=${pincode}`
        );
        const data = response?.data?.PostOffice;
        if (response?.data?.PostOffice) {
          setPostOffices((prevstate) => ({
            ...prevstate,
            c_postoffices: data,
          }));
        } else {
          setPostOffices([]);
        }
      } catch (error) {
        toast.error(APP_MESSAGES.API_ERR + " for Post Offices..", {
          position: "top-right",
        });
      }
      // const data = await fetchPostOfficesByPincode(pincode);
    }
  };
  const handlePostOfficeChange = (selectedOption) => {
    const postOffice = selectedOption.value;
    setAddressDetails((prevstate) => ({
      ...prevstate,
      permanentPostoffice: postOffice,
    }));
  };

  const handleCurrentPostOfficeChange = (selectedOption) => {
    const postOffice = selectedOption.value;
    setAddressDetails((prevstate) => ({
      ...prevstate,
      currentPostoffice: postOffice,
    }));
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setSameAsPermanent(isChecked);
    if (isChecked) {
      setAddressDetails((prevstate) => ({
        ...prevstate,
        currentHousePlotNumber: addressDetails.permanentHousePlotNumber || null,
        currentVillageTown: addressDetails.permanentVillageTown || null,
        currentPostoffice: addressDetails.permanentPostoffice || null,
        currentPanchayat: addressDetails.permanentPanchayat || null,
        currentBlock: addressDetails.permanentBlock || null,
        currentCity: addressDetails.permanentCity || null,
        currentDistrict: addressDetails.permanentDistrict || null,
        currentState: addressDetails.permanentState || null,
        currentPinCode: addressDetails.permanentPinCode || null,
        isSameAsPermanentAddress: true,
      }));
    } else {
      setAddressDetails((prevstate) => ({
        ...prevstate,
        currentHousePlotNumber: "",
        currentVillageTown: "",
        currentPostoffice: "",
        currentPanchayat: "",
        currentBlock: "",
        currentCity: "",
        currentDistrict: "",
        currentState: "Odisha",
        currentPinCode: "",
        isSameAsPermanentAddress: false,
      }));
    }
  };
  console.log(sameAsPermanent, "same");
  return (
    <div className="AddressInfo">
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("AddressInfo.Permanent Address")}>
            <Row>
              <Col md={4}>
                <InputText
                  type="text"
                  name="permanentState"
                  label={t("AddressInfo.State")}
                  placeholder="Enter your State"
                  disabled
                  value={addressDetails.permanentState}
                  register={{
                    ...register("permanentState", {
                      required: "Please enter your State",
                    }),
                  }}
                  errors={errors}
                />
              </Col>
              <Col md={4}>
                <InputMultiSelect
                  key={addressDetails.permanentDistrict}
                  isMulti={false}
                  name="permanentDistrict"
                  label={t("AddressInfo.District")}
                  placeholder="Select your District"
                  register={control}
                  options={districts?.map((dis) => {
                    return {
                      value: dis.uuid,
                      label: dis.districtName,
                    };
                  })}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={handleDistrictChange}
                  defaultValue={
                    addressDetails.permanentDistrict
                      ? {
                          value: addressDetails.permanentDistrict,
                          label: addressDetails.permanentDistrict,
                        }
                      : null
                  }
                  disabled={!editable}
                />
              </Col>

              <Col md={4}>
                <InputMultiSelect
                  key={addressDetails.permanentBlock}
                  isMulti={false}
                  name="permanentBlock"
                  label={t("AddressInfo.Block")}
                  placeholder="Select your Block"
                  register={control}
                  options={blocks?.p_blocks?.map((bl) => {
                    return { value: bl.uuid, label: bl.blockName };
                  })}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={handleBlockChange}
                  defaultValue={
                    addressDetails.permanentBlock
                      ? {
                          value: addressDetails.permanentBlock,
                          label: addressDetails.permanentBlock,
                        }
                      : null
                  }
                  disabled={!editable}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <InputMultiSelect
                  key={addressDetails.permanentPanchayat}
                  isMulti={false}
                  name="permanentPanchayat"
                  label={t("AddressInfo.Panchayat")}
                  placeholder="Select your Panchayat"
                  register={control}
                  options={panchayats?.p_panchayats?.map((p) => {
                    return { value: p.uuid, label: p.panchayatName };
                  })}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={handlePanchayatChange}
                  defaultValue={
                    addressDetails.permanentPanchayat
                      ? {
                          value: addressDetails.permanentPanchayat,
                          label: addressDetails.permanentPanchayat,
                        }
                      : null
                  }
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputMultiSelect
                  key={addressDetails.permanentVillageTown}
                  isMulti={false}
                  name="permanentVillageTown"
                  label={t("AddressInfo.VillageTown")}
                  placeholder="Select your Village/Town"
                  register={control}
                  options={villages?.p_villages?.map((v) => {
                    return { value: v.uuid, label: v.villageName };
                  })}
                  // rules={{
                  //   required: true,
                  //   message: "This field is required",
                  // }}
                  errors={errors}
                  onChange={handleVillageChange}
                  defaultValue={
                    addressDetails.permanentVillageTown
                      ? {
                          value: addressDetails.permanentVillageTown,
                          label: addressDetails.permanentVillageTown,
                        }
                      : null
                  }
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputText
                  type="text"
                  name="permanentHousePlotNumber"
                  label={t("AddressInfo.HousePlot No")}
                  placeholder="Enter Your House/Plot No"
                  register={{
                    ...register("permanentHousePlotNumber", {
                      // required: "Please enter your House/Plot No",
                    }),
                  }}
                  errors={errors}
                  value={addressDetails?.permanentHousePlotNumber}
                  onChange={(e) => {
                    setAddressDetails((prevstate) => ({
                      ...prevstate,
                      permanentHousePlotNumber: e.target.value,
                    }));
                  }}
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputText
                  type="text"
                  name="permanentPinCode"
                  label={t("AddressInfo.Pin Code")}
                  placeholder="Enter your Pin Code"
                  register={{
                    ...register("permanentPinCode", {
                      required: "Please enter your Pin Code",
                    }),
                  }}
                  errors={errors}
                  onChange={handlePincodeChange}
                  value={addressDetails.permanentPinCode}
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputMultiSelect
                  key={addressDetails.permanentPostoffice}
                  isMulti={false}
                  name="permanentPostoffice"
                  label={t("AddressInfo.PostOffice")}
                  placeholder="Select your Post office"
                  register={control}
                  options={postOffices?.p_postoffices?.map((po) => {
                    return { value: po.Name, label: po.Name };
                  })}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={handlePostOfficeChange}
                  defaultValue={
                    addressDetails.permanentPostoffice
                      ? {
                          value: addressDetails.permanentPostoffice,
                          label: addressDetails.permanentPostoffice,
                        }
                      : null
                  }
                  disabled={!editable}
                />
              </Col>

              <Col md={4}>
                <InputText
                  type="text"
                  name="permanentCity"
                  label={t("AddressInfo.City")}
                  placeholder="Enter your City"
                  register={{
                    ...register("permanentCity", {
                      required: "Please enter your City",
                    }),
                  }}
                  errors={errors}
                  value={addressDetails?.permanentCity}
                  onChange={(e) => {
                    setAddressDetails((prevstate) => ({
                      ...prevstate,
                      permanentCity: e.target.value,
                    }));
                  }}
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputText
                  type="text"
                  name="nationality"
                  label={t("AddressInfo.Nationality")}
                  placeholder="Enter your nationality"
                  register={{
                    ...register("nationality", {
                      required: "Please enter your Nationality",
                    }),
                  }}
                  value={addressDetails?.nationality}
                  errors={errors}
                  onChange={(e) => {
                    setAddressDetails((prevstate) => ({
                      ...prevstate,
                      nationality: e.target.value,
                    }));
                  }}
                  disabled={!editable}
                />
              </Col>
            </Row>
          </Collapsible>
        </div>
      </div>
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("AddressInfo.Current Address")}>
            {userRole === "FL/CSS" && (
              <Form.Check
                className="m-2"
                type="checkbox"
                label={t("AddressInfo.SameAddress")}
                onChange={handleCheckboxChange}
                checked={sameAsPermanent}
              />
            )}
            <Row>
              <Col md={4}>
                <InputText
                  type="text"
                  name="currentState"
                  label={t("AddressInfo.State")}
                  placeholder="Enter your State"
                  register={{
                    ...register("currentState", {
                      required: "Please enter your State",
                    }),
                  }}
                  value={addressDetails?.currentState}
                  disabled
                  errors={errors}
                />
              </Col>
              <Col md={4}>
                <InputMultiSelect
                  key={addressDetails.currentDistrict}
                  isMulti={false}
                  name="currentDistrict"
                  label={t("AddressInfo.District")}
                  placeholder="Select your District"
                  register={control}
                  // options={district}
                  options={districts?.map((d) => {
                    return { value: d.uuid, label: d.districtName };
                  })}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={handleCDistrictChange}
                  defaultValue={
                    addressDetails.currentDistrict
                      ? {
                          value: addressDetails.currentDistrict,
                          label: addressDetails.currentDistrict,
                        }
                      : null
                  }
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputMultiSelect
                  key={addressDetails.currentBlock}
                  isMulti={false}
                  name="currentBlock"
                  label={t("AddressInfo.Block")}
                  placeholder="Select your Block"
                  register={control}
                  // options={block}
                  options={blocks?.c_blocks?.map((b) => {
                    return { value: b.uuid, label: b.blockName };
                  })}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={handleCBlockChange}
                  defaultValue={
                    addressDetails.currentBlock
                      ? {
                          value: addressDetails.currentBlock,
                          label: addressDetails.currentBlock,
                        }
                      : null
                  }
                  disabled={!editable}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <InputMultiSelect
                  key={addressDetails.currentPanchayat}
                  isMulti={false}
                  name="currentPanchayat"
                  label={t("AddressInfo.Panchayat")}
                  placeholder="Select your Panchayat"
                  register={control}
                  options={panchayats?.c_panchayats?.map((p) => {
                    return { value: p.uuid, label: p.panchayatName };
                  })}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={handleCPanchayatChange}
                  defaultValue={
                    addressDetails.currentPanchayat
                      ? {
                          value: addressDetails.currentPanchayat,
                          label: addressDetails.currentPanchayat,
                        }
                      : null
                  }
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputMultiSelect
                  key={addressDetails.currentVillageTown}
                  isMulti={false}
                  name="currentVillageTown"
                  label={t("AddressInfo.VillageTown")}
                  placeholder="Select your Village/Town"
                  register={control}
                  options={villages?.c_villages?.map((v) => {
                    return { value: v.uuid, label: v.villageName };
                  })}
                  // rules={{
                  //   required: true,
                  //   message: "This field is required",
                  // }}
                  errors={errors}
                  onChange={handleCVillageChange}
                  defaultValue={
                    addressDetails.currentVillageTown
                      ? {
                          value: addressDetails.currentVillageTown,
                          label: addressDetails.currentVillageTown,
                        }
                      : null
                  }
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputText
                  type="text"
                  name="currentHousePlotNumber"
                  label={t("AddressInfo.HousePlot No")}
                  placeholder="Enter Your House/Plot No"
                  register={{
                    ...register("currentHousePlotNumber", {
                      // required: "Please enter your House/Plot No",
                    }),
                  }}
                  errors={errors}
                  onChange={(e) => {
                    setAddressDetails((prevstate) => ({
                      ...prevstate,
                      currentHousePlotNumber: e.target.value,
                    }));
                  }}
                  value={addressDetails.currentHousePlotNumber}
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputText
                  type="text"
                  name="currentPinCode"
                  label={t("AddressInfo.Pin Code")}
                  placeholder="Enter your Pin Code"
                  register={{
                    ...register("currentPinCode", {
                      required: "Please enter your Pin Code",
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.NUMERIC,
                        message: "Invalid Pincode.",
                      },
                      minLength: {
                        value: 6,
                        message: "Pincode should not less than 6 digits.",
                      },
                      maxLength: {
                        value: 6,
                        message: "Pincode should not more than 6 digits.",
                      },
                    }),
                  }}
                  errors={errors}
                  onChange={handleCPincodeChange}
                  value={addressDetails?.currentPinCode}
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputMultiSelect
                  key={addressDetails?.currentPostoffice}
                  isMulti={false}
                  name="currentPostoffice"
                  label={t("AddressInfo.PostOffice")}
                  placeholder="Select your Post office"
                  register={control}
                  options={postOffices?.c_postoffices?.map((po) => {
                    return { value: po.Name, label: po.Name };
                  })}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={handleCurrentPostOfficeChange}
                  defaultValue={
                    addressDetails?.currentPostoffice
                      ? {
                          value: addressDetails.currentPostoffice,
                          label: addressDetails.currentPostoffice,
                        }
                      : null
                  }
                  disabled={!editable}
                />
              </Col>
              <Col md={4}>
                <InputText
                  type="text"
                  name="currentCity"
                  label={t("AddressInfo.City")}
                  placeholder="Enter your City"
                  register={{
                    ...register("currentCity", {
                      required: "Please enter your City",
                    }),
                  }}
                  errors={errors}
                  onChange={(e) => {
                    setAddressDetails((prevstate) => ({
                      ...prevstate,
                      currentCity: e.target.value,
                    }));
                  }}
                  value={addressDetails?.currentCity}
                  disabled={!editable}
                />
              </Col>
            </Row>
          </Collapsible>
        </div>
      </div>
    </div>
  );
};

export default AddressInfo;
