export const CONSTANT_REGEX_SAMPLE = {
  PASSWORD: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[!@#$*])/,
  ALPHANUMERIC: /^[a-zA-Z0-9]*$/,
  NUMERIC: /^[0-9]*$/,
  ALPHABATE: /^[A-Za-z]+$/,
  ALPHANUMERIC_SPECIAL: /^[a-zA-Z0-9\s,'.-]+$/,
  EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  ALPHABATEWITHSPACE: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
  GSTNO: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  PAN: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
  DL: / ^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
  NAME: /^(?!.*\s$)[A-Za-z.'-]+( [A-Za-z.'-]+)*$/,
};

export const APP_MESSAGES = {
  CMN_ERR: "Something went wrong..",
  API_ERR: "Failed to fetch the data",
  UPLOAD_LETTER_ERR: "Failed to upload the letter",
  UPLOAD_LETTER_SUCCESS: "Uploaded the letter successfully",
  FILE_UPLOAD_ERR: "Please select a file to upload",
  LOGOUT_MSG: "Logged out successfully!",
  LOGOUT_ERR: "Logout failed. Please try again.",
  USER_ERR: "User not Authenticated",
  NO_DATA_MSG: "No Data Available",
};

export const extractValuesfromObject = (dataObj: any, mappingArr: any[]) => {
  const result = Object.entries(dataObj)
    .map(([key, value]) => {
      const titleObj = mappingArr?.find((item: any) => item.key === key);
      if (titleObj) {
        return {
          key: key,
          title: titleObj?.title,
          value: value,
        };
      }
    })
    .filter((item) => item !== undefined); // Filter out undefined entries
  return result || [];
};

export const MONTH_LIST: any[] = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const FIN_YEAR_LIST: any[] = [
  { value: "2022-23", label: "2022-2023" },
  { value: "2023-24", label: "2023-2024" },
  { value: "2024-25", label: "2024-2025" },
];

export const salutations = [
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Ms.", value: "Ms." },
  { label: "Other", value: "Other" },
];

export const genderTypeList = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" },
];

export const BLOCK_LEVEL_ROLES = ["CSS", "BPC", "CDPO", "BDO"];
export const BANK_LEVEL_ROLES = ["BRANCH_BANK", "CONTROLLING_BANK"];
export const userRoleName = "FL/CSS";
export const editableUsers = ["FL/CSS", "BPC"];

export const strToCamelCase = (str:any) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (match:any, index:number) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
};

export const toTitleCase = (str: string) => {
  const string = str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  const spacedString = string.replace(/([a-z])([A-Z])/g, '$1 $2');
  return spacedString;
};


export const FIN_YEAR_MONTHS = [
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
  "JANUARY",
  "FEBRUARY",
  "MARCH"
]