import { useTranslation } from 'react-i18next';
import PublicFooter from './PublicFooter';
import PublicHeader from './PublicHeader';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
const Overview = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const keyActivities: any = [t("FormationandStrengthening"),
   
        t("SkillDevelopment"),
        
        t("FinancialResources"),
        
        t("LivelihoodSupport"),
        
        t("LeadershipandDecisionMakingEmpowerment"),
        
        t("DigitalLiteracy")]
    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };
    return (
        <>
            <PublicHeader />
            <div style={{paddingTop: "110px"}}> 
                <div className='container' >
                    <h2><IoArrowBack onClick={handleBackClick}/> {t("OverviewDepartment")} </h2>
                    <p>
                    {t("DeptText")}
                    </p>
                    <div>
                        <p style={{fontWeight: 600}}>{t("DeptHeading")}</p>
                        <ul>
                            {keyActivities && keyActivities.map((e:any)=>(<li style={{listStyle: "auto", padding: "5px 0px 5px 0px"}}>
                                    {e}
                            </li>))}
                        </ul>
                        <p>{t("DeptFooter")}</p>
                    </div>
                </div>
            </div>
            <PublicFooter />
        </>
        
    );
};

export default Overview;
