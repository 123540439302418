import React, { createContext, useState } from 'react';

// Create the UserContext
const ApplicationContext = createContext();

const ApplicationContextProvider = ({ children }) => {
  // Initialize state with an object
  const [application, setApplication] = useState({
    id: "",
    applicationNumber:"",
    applicationStatus:""
  });

  const [userInfo,setUserInfo]=useState({

  })
  // Function to update user information
  const updateApplicationContext = (application) => {
    setApplication(prevUser => ({ ...prevUser, ...application }));
  };

  const updateUser=(newUser)=>{
    setUserInfo({...newUser})
  }
  return (
    <ApplicationContext.Provider value={{ application, updateApplicationContext,updateUser,userInfo }}>
      {children}
    </ApplicationContext.Provider>
  );
};

export { ApplicationContext, ApplicationContextProvider };
