import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import './InterestSubvention.css';
import ActionTable from "../../common/ActionTable";
import { t } from "i18next";
import { postData } from "../../../services/http.service";
import { URLS_ENDPOINT } from "../../../utils/urls.const";
import InputMultiSelect from "../../common/InputMultiSelect";
import { APP_MESSAGES, FIN_YEAR_LIST, MONTH_LIST } from "../../../utils/constants";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import ActionModal from "../../common/ActionModal";
import InputFile from "../../common/InputFile";
import CustomCaptcha from "../../common/CustomCaptcha";

const defFormData: any = {
  financialYear: "",
  month: "",
  bankId: "",
};

const defModalFormData: any = {
  financialYear: "",
  month: "",
  bankId: "",
  exlFile: "",
};

const InterestSubvention: React.FC = () => {
    const [rowData, setRowData] = useState<any[]>([]);
    const [bankList, setBankList] = useState<any[]>([]);
    const [finYearList, setFinYearList] = useState<any[]>([]);
    const [formData, setFormData] = useState(defFormData);

    const [modal, setModal] = useState(false);
    const [modalAction, setModalAction] = useState("");
    const [modalTitle, setModalTitle] = useState(t("CalScooterYojana"));
    const handleModalSubmit = (e:any) => {};
    const Toggle = () => {
      setModalFormData(defModalFormData)
      setModal(!modal);
    };
    const [modalFormData, setModalFormData] = useState(defModalFormData);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
        const {
          register,
          handleSubmit,
          control,           
          formState: { errors },
      } = useForm({
          defaultValues: defFormData
      });

      const onYearChangeInCalcForm = (selectedOption: any) =>
        setModalFormData((prevstate: any) => ({
          ...prevstate,
          financialYear: selectedOption,
        }));
      const onMonthChangeInCalcForm = (selectedOption: any) =>
        setModalFormData((prevstate: any) => ({
          ...prevstate,
          month: selectedOption,
        }));

      const onBankChangeInCalcForm = (selectedOption: any) =>
        setModalFormData((prevstate: any) => ({
          ...prevstate,
          bankId: selectedOption,
        }));

    const onFileChangeInCalcForm = (selectedOption:any) => setModalFormData((prevstate:any) => ({
      ...prevstate,
      exlFile: selectedOption?.target?.files[0] || "",
    }));  


    const onCalFormSubmit = async () => {
      try {
        const userInfo = JSON.parse(localStorage?.getItem("UserDetails") || "");
        const fd = new FormData();
        fd.append("financialYear", modalFormData?.financialYear?.label);
        fd.append("month", modalFormData?.month?.value);
        fd.append("bankId", modalFormData?.bankId?.value);
        fd.append("file", modalFormData?.exlFile);
        fd.append("userId",  userInfo?.uuid || "");
        fd.append("userName", userInfo?.userName || "");
        fd.append("bankName", modalFormData?.bankId?.label);
        const URL = process.env.REACT_APP_MIS_SUBVENTION_SERVICE + URLS_ENDPOINT.SUBVENTION.SUBMIT;
        const response: any = await axios.post(URL, fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          });
          console.log(response);
          if(response?.status === 200){
            toast.success(response?.data?.message || "Operation Success..." , {
              position: "top-right",
            });
            Toggle();
          }else{
            toast.error(APP_MESSAGES.CMN_ERR, {
              position: "top-right",
            });
          }
         
      } catch (error) {
        toast.error(APP_MESSAGES.API_ERR, {
          position: "top-right",
        });
      }
    };

    const monthList :any[] = [...MONTH_LIST];
    const TABLE_COLUMNS: any = [
      {
        colName: "Loan ID",
        fieldName: "uuid",
        clsName: "column-text-start",
       
      },
      {
        colName: "Application TokenID",
        fieldName: "mssyApplicationTokenId",
        clsName: "column-text-start",
       
      },
      {
        colName: "District",
        fieldName: "district",
        clsName: "column-text-start",
       
      },
      {
        colName: "Branch Name",
        fieldName: "branchName",
        clsName: "column-text-start",
       
      },
      {
        colName: "Branch_ifsc",
        fieldName: "branchIfsc",
        clsName: "column-text-start",
       
      },
      {
        colName: "beneficiary_name",
        fieldName: "beneficiaryName",
        clsName: "column-text-start",
       
      },
      {
        colName: "address",
        fieldName: "address",
        clsName: "column-text-start",
       
      },
      {
        colName: "loan_account",
        fieldName: "loanAccount",
        clsName: "column-text-start",
       
      },
      {
        colName: "disbursed_loan_amount",
        fieldName: "disbursedLoanAmount",
        clsName: "column-text-start",
       
      },
      {
        colName: "date_of_disbursement (YYYY-MM-DD)",
        fieldName: "dateOfDisbursement",
        clsName: "column-text-start",
       
      },
      {
        colName: "annual_interest_charged %",
        fieldName: "annualInterestCharged",
        clsName: "column-text-start",
       
      },
      {
        colName: "repayment_schedule",
        fieldName: "repaymentSchedule",
        clsName: "column-text-start",
       
      },
      {
        colName: "vehicle_registration_no_details",
        fieldName: "vehicleRegistrationNoDetails",
        clsName: "column-text-start",
       
      },
      {
        colName: "loan_outstanding",
        fieldName: "loanOutstanding",
        clsName: "column-text-start",
       
      },
      {
        colName: "overdue_amount",
        fieldName: "overdueAmount",
        clsName: "column-text-start",
       
      },
      {
        colName: "total_interest_adjusted",
        fieldName: "totalInterestAdjusted",
        clsName: "column-text-start",
       
      },
      {
        colName: "no_of_loan_accounts_closed",
        fieldName: "noOfLoanAccountsClosed",
        clsName: "column-text-start",
       
      },
      {
        colName: "IS_Status",
        fieldName: "isCalculationStatus",
        clsName: "column-text-start",
       
      },
    ];

    const handleYearSelect = (selectedOption:any) => setFormData((prevstate:any) => ({
        ...prevstate,
        financialYear: selectedOption,
      }));
    const handleOnMonthChange = (selectedOption:any) => setFormData((prevstate:any) => ({
        ...prevstate,
        month: selectedOption,
      }));

    const handleBankSelect = (selectedOption:any) => setFormData((prevstate:any) => ({
        ...prevstate,
        bankId: selectedOption,
      }));


        // Handle search button click
    const onSubmitForm = async () => {
      try {
        const fd = new FormData();
        fd.append("financialYear", formData?.financialYear?.label);
        fd.append("month", formData?.month?.value);
        fd.append("bankId", formData?.bankId?.value);
        const URL =
          process.env.REACT_APP_MIS_SUBVENTION_SERVICE +
          URLS_ENDPOINT.SUBVENTION.SEARCH;
          const response:any = await axios.post(URL, fd);
          console.log(response);
          if (response?.data?.loanDetails?.length > 0) {
            const loanDetails:any[] = [...response?.data?.loanDetails];
            setRowData(loanDetails);
         }else{
            toast.info(APP_MESSAGES.NO_DATA_MSG, {
                position: "top-right",
             });
         }
      } catch (error) {
        toast.error(APP_MESSAGES.API_ERR, {
          position: "top-right",
        });
        console.error("Error fetching bank list:", error);
      }
    };

    useEffect(() => {
      async function getBanksList() {
        try {
          const response = await postData(
            process.env.REACT_APP_BANKS_DROPDOWN_SERVICE,
            {
              bankFilterDetails: {
                bankCode: "",
                branchCode: "",
              },
            }
          );
          const bankList: any[] = response?.data?.masterBankDetails || []; // Ensure banks array is extracted from the response
          if (bankList.length > 0) {
            let tempBankList: any[] = [];
            for (const key in bankList) {
              tempBankList.push({
                value: bankList[key].code,
                label: bankList[key].bankName,
              });
            }
            setBankList(tempBankList);
          }
        } catch (error) {
          console.error("Error fetching bank list:", error);
        }
      }

      async function getFinYearList() {
        try {
          let finURL  = process.env.REACT_APP_MASTER_SERVICE+URLS_ENDPOINT.MASTER_SERVICES.FINYEAR_DROPDOWN;
          const response = await postData(finURL);
          if (response?.data?.financialYears?.length > 0) {
            let tempList: any[] = [];
            for (const key in response?.data?.financialYears) {
              tempList.push({
                value: response?.data?.financialYears[key].uuid,
                label: response?.data?.financialYears[key].financialYear,
              });
            }
            setFinYearList(tempList);
          }
        } catch (error) {
          console.error("Error fetching bank list:", error);
        }
      }
      
      getBanksList();
      getFinYearList();
    }, []);

    return (
      <div className="container mt-4">
        <ToastContainer />
    
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={3}>
                  <InputMultiSelect
                    key="Financial Year"
                    isMulti={false}
                    id="financialYear"
                    name="financialYear"
                    label={t("FinancialYear")}
                    placeholder="Select Financial Year"
                    register={control}
                    options={finYearList}
                    rules={{
                      required: true,
                      message: "Financial Year is required",
                    }}
                    errors={errors}
                    onChange={handleYearSelect}
                    defaultValue={
                      formData?.financialYear
                        ? {
                            ...formData?.financialYear,
                          }
                        : null
                    }
                  />
                </Col>

                <Col md={3}>
                  <InputMultiSelect
                    key="Month"
                    isMulti={false}
                    id="month"
                    name="month"
                    label={t("Month")}
                    placeholder="Select Month"
                    register={control}
                    options={monthList}
                    rules={{
                      required: true,
                      message: "Month is required",
                    }}
                    errors={errors}
                    onChange={handleOnMonthChange}
                    defaultValue={
                      formData?.month
                        ? {
                            ...formData?.month,
                          }
                        : null
                    }
                  />
                </Col>

                <Col md={5}>
                  <InputMultiSelect
                    key="Ban Year"
                    isMulti={false}
                    id="bankId"
                    name="bankId"
                    label={t("Bank")}
                    placeholder="Select Bank"
                    register={control}
                    options={bankList}
                    rules={{
                      required: true,
                      message: "Bank is required",
                    }}
                    errors={errors}
                    onChange={handleBankSelect}
                    defaultValue={
                      formData?.bankId
                        ? {
                            ...formData?.bankId,
                          }
                        : null
                    }
                  />
                </Col>

                <Col md={1}>
                  <Button
                    className="btn background-saffron"
                    type="submit"
                    style={{ marginTop: "1rem" }}
                  >
                    {t("Table.Search")}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>

        <div className="w-full">
              <ActionTable
                tableWidth={"285%"}
                columns={TABLE_COLUMNS}
                rowData={rowData}
                tableHeader={t("Table.SUBVENTION_TABLE_HEADER")}
                hideExportBtn={false}
                searchBarPlaceholder={t("Table.SEARCH_BY")} />
        </div>

        <div className="d-flex justify-content-end mt-4">
          <Button
            onClick={Toggle}
            className="btn-custom-green mx-2 pr-8"
            variant="contained"
          >
           {t("Calculate IS")}
          </Button>
        </div>

        {modal && (
          <ActionModal
            show={modal}
            close={Toggle}
            handleModalSubmit={handleModalSubmit}
            title={modalTitle}
            action={modalAction}
            isFooterHide={true}
            size={"lg"}
          >
            <form onSubmit={handleSubmit(onCalFormSubmit)}>
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col md={3}>
                      <InputMultiSelect
                        key="Financial Year"
                        isMulti={false}
                        id="financialYear"
                        name="financialYear"
                        label={t("FinancialYear")}
                        placeholder="Select Financial Year"
                        register={control}
                        options={finYearList}
                        rules={{
                          required: true,
                          message: "Financial Year is required",
                        }}
                        errors={errors}
                        onChange={onYearChangeInCalcForm}
                        defaultValue={
                          modalFormData?.financialYear
                            ? {
                                ...modalFormData?.financialYear,
                              }
                            : null
                        }
                      />
                    </Col>

                    <Col md={4}>
                      <InputMultiSelect
                        key="Month"
                        isMulti={false}
                        id="month"
                        name="month"
                        label={t("Month")}
                        placeholder="Select Month"
                        register={control}
                        options={monthList}
                        rules={{
                          required: true,
                          message: "Month is required",
                        }}
                        errors={errors}
                        onChange={onMonthChangeInCalcForm}
                        defaultValue={
                          modalFormData?.month
                            ? {
                                ...modalFormData?.month,
                              }
                            : null
                        }
                      />
                    </Col>

                    <Col md={5}>
                      <InputMultiSelect
                        key="Bank"
                        isMulti={false}
                        id="bankId"
                        name="bankId"
                        label={t("Bank")}
                        placeholder="Select Bank"
                        register={control}
                        options={bankList}
                        rules={{
                          required: true,
                          message: "Bank is required",
                        }}
                        errors={errors}
                        onChange={onBankChangeInCalcForm}
                        defaultValue={
                          modalFormData?.bankId
                            ? {
                                ...modalFormData?.bankId,
                              }
                            : null
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={11}>
                      <InputFile
                        type="file"
                        name="exlFile"
                        label={t("UploadFile")}
                        placeholder="Upload your file (.xlsx / CSV only)"
                        register={{
                          ...register("exlFile", {
                            required: "Please upload a (xlsx / csv) file",
                          }),
                        }}
                        errors={errors}
                        disabled={false}
                        required={true}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e: any) => onFileChangeInCalcForm(e)}
                      />
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col md={11}>
                      <CustomCaptcha onCaptchaValid={setIsCaptchaValid} />
                    </Col>
                  </Row> */}

                  {/* <br /> */}

                  <Row
                    style={{ textAlign: "center", justifyContent: "center" }}
                  >
                    <Col md={3}>
                      <Button className="btn background-saffron" type="submit">
                        {t("Calculate")}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </form>
          </ActionModal>
        )}
      </div>
    );
};

export default InterestSubvention;
