import React from 'react';
import './MissonSaktiForm.css'; // Assuming you're using an external CSS file

const VerificationCertificate = (props:any) => {
    const{formData}=props;
    console.log(formData,"data")
    const boldText=(text:string)=>{
        return <strong style={{fontWeight:'800'}}>{text}</strong>
    }
    function convertMonthsToYears(months:number) {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;  
        return `${years}-${remainingMonths} months` ;
    }
    return (
        <div className="certificate-container">
            <div className="certificate-title">
                Block/Project Level Verification-cum-Recommendation Certificate from <br/>
                Department of Mission Shakti
            </div>
            <br/>
            <div className="certificate-content">
                <p className="paragraph-spacing" style={{ textIndent: '40px' }}>
                    I/We hereby certify that Miss/Mrs.  {boldText(formData?.applicationBasicDetails?.applicantNameAsPerAadhar)}  W/o or D/o  
                    {boldText(formData?.applicationBasicDetails?.fatherName)}presently residing in village/town {boldText(formData?.addressDetails?.permanentVillageTown)} is working as a {boldText(formData?.applicationBasicDetails?.fatherName)} presently residing in village/town {boldText(formData?.addressDetails?.permanentVillageTown)} is working as a {boldText(formData?.membershipQualifications?.individualType)} 
                    since the year {convertMonthsToYears(formData?.membershipQualifications?.designationDuration)} (Name of the GPLF/BLF/DLF)  under Department of Mission Shakti.
                </p>
                
                <p className="paragraph-spacing" style={{ textIndent: '40px' }}>
                    Further, it is to certify that the statements/particulars in this application and the documents submitted 
                    (as per list attached) are true, complete and correct to the best of my knowledge and belief.
                </p>
                
                <p className="paragraph-spacing" style={{ textIndent: '40px' }}>
                    Considering the request for two-wheeler of the applicant, I/We recommend a loan amount of Rs. 
                    ${boldText(formData?.vehicleProposalDetails?.amountOfLoanRequired)} only in favor of Mrs. ${boldText(formData?.applicationBasicDetails?.applicantNameAsPerAadhar)}  towards 
                    purchase of Scooter (${boldText(formData?.vehicleProposalDetails?.vehicleModel)}) under Mission Shakti Scooter Yojana - 
                    "Interest Free Loan for purchase of Scooter by Mission Shakti Federation Leaders and Community Support Staff."
                </p>
            </div>

            <div className="signature">
                <div>
                <p >Signature</p>
                <p>{boldText(formData?.applicationBasicDetails?.applicantNameAsPerAadhar)}</p>
                {/* <p>{boldText(formData?.membershipQualifications?.educationalQualification)}</p> */}
                <p>{boldText(formData?.addressDetails?.permanentBlock)}</p>
                <p>{boldText(formData?.addressDetails?.permanentDistrict)}</p>
                </div>
                
                <p>Seal & Signature</p>
            </div>
        </div>
    );
}

export default VerificationCertificate;
