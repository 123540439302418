// components/DynamicHeader.js

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { routes } from '../../routes/routeConfig';
import './DynamicHeader.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from 'react-i18next';

const DynamicHeader = ({ isLoggedIn }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };

    // const findCurrentRouteName = () => {
    //     const currentRoute = routes.find(route => route.path === location.pathname);
    //     return currentRoute ? currentRoute.name : '';
    // };
    const findCurrentRouteName = () => {
        const currentRoute = routes.find(route => {
            // Split the current route path and the location pathname into segments
            const routeSegments = route.path.split('/').filter(Boolean);
            const locationSegments = location.pathname.split('/').filter(Boolean);
    
            // If the number of segments doesn't match, skip this route
            if (routeSegments.length !== locationSegments.length) return false;
    
            // Compare each segment
            return routeSegments.every((segment, index) => {
                // If the segment starts with ':', it's a dynamic segment, so we accept any value in the corresponding location segment
                if (segment.startsWith(':')) return true;
                // Otherwise, the segments must match exactly
                return segment === locationSegments[index];
            });
        });
    
        return currentRoute ? currentRoute.name : '';
    };
    
    
    

    const currentRouteName = findCurrentRouteName();


    return (
        <div className="Preview">
            <header className="dynamic-header">
                {isLoggedIn && currentRouteName !=='Home' && (
                    <div className="header-content">
                        <i className="fa fa-arrow-left" style={{ marginTop: '4px', cursor: "pointer" }} onClick={handleBackClick}></i>
                        <span className="route-name"> {t(`routeNames.${currentRouteName}`)}</span>
                    </div>
                )}
                {isLoggedIn && currentRouteName ==='Home' && (
                    <div className="header-content">
                        <i className="fa fa-home" style={{ marginTop: '4px' }}></i>
                        <span className="route-name"> {t(`routeNames.${currentRouteName}`)}</span>
                    </div>
                )}
            </header>
        </div>
    );
};

export default DynamicHeader;
