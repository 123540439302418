import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MShakti from '../../assets/images/MShakti.png'
import OCAC from '../../assets/images/OCAC.png'
import Gov from '../../assets/images/Gov.png'
import MShakti1 from '../../assets/images/MShakti.png'
import MShakti2 from '../../assets/images/MShakti.png'
import DigitalIndiaImg from '../../assets/images/digitalIndia.png';
import JanaSunaniImg from '../../assets/images/janasunani.jpg'
import "./PublicDepartments.css"

const PublicDepartments = () => {
  
    return (
        <div style={{ background: 'white', padding: '60px 0px 40px 0px' }}>
            {/* <Container className='image-scroll-container'> */}
                <Row className="justify-content-center align-items-center image-scroll-wrapper">
                    <Col xs={6} sm={4} md={2}>
                        <a href='https://odisha.gov.in' target='_blank' rel={"noreferrer"}><img src={Gov} alt="Company Logo 3" className='img-scroll' style={{ width: '100%', height: 'auto' }} /></a>
                    </Col>
                    <Col xs={6} sm={4} md={2}>
                        <a href='https://missionshakti.odisha.gov.in/' target='_blank' rel={"noreferrer"}><img src={MShakti} alt="Company Logo 1" className='img-scroll' style={{ width: '100%', height: 'auto' }} /></a>
                    </Col>
                    <Col xs={6} sm={4} md={2}>
                        <a href='https://www.ocac.in/or' target='_blank' rel={"noreferrer"}><img src={OCAC} alt="Company Logo 2" className='img-scroll' style={{ width: '100%', height: 'auto' }} /></a>
                    </Col>
                    
                    <Col xs={6} sm={4} md={2}>
                        <a href='https://www.digitalindia.gov.in/' target='_blank' rel={"noreferrer"}><img src={DigitalIndiaImg} alt="Company Logo 4" className='img-scroll' style={{ width: '100%', height: 'auto' }} /></a>
                    </Col>
                    <Col xs={6} sm={4} md={2}>
                        <a href='https://janasunani.odisha.gov.in/grievance-details/' target='_blank' className='img-scroll janasunani-cls' rel={"noreferrer"}><img src={JanaSunaniImg} alt="Company Logo 5"  style={{ width: '37%', height: 'auto',padding: "3px" }} /><span>Janasunani</span></a>
                    </Col>
                </Row>
            {/* </Container> */}
        </div>
    );
};

export default PublicDepartments;
