// src/i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      governmentOfOdisha: "Government of Odisha",
      pradhanPrustha: "Main Page",
      Login: "Login",
      register: "Register",
      language: "Language",
      english: "English",
      odia: "Odia",
      missionShaktiScooterYojana: "MISSION SHAKTI SCOOTER YOJANA",
      checkSchemeEligibility: "Check Scheme Eligibility",
      applyScooterLoan: "Apply Scooter loan?",
      Home: "Home",
      "About Us": "About Us",
      Notification: "Notification",
      Publication: "Publication",
      Programme: "Programme",
      Convergence: "Convergence",
      "District Pages": "District Pages",
      RTI: "RTI",
      "Media Gallery": "Media Gallery",
      "Contact Us": "Contact Us",
      "Important Links": "Important Links",
      More: "More",
      Overview: "Overview",
      "Mission & Vission": "Mission & Vission",
      "Programme Overview": "Programme Overview",
      "Community Structure": "Community Structure",
      "Organizational Structure": "Organizational Structure",
      WhosWho: "Who's Who",
      Administration: "Administration",
      "State Project Monitoring Unit": "State Project Monitoring Unit",
      "Rules & Guidelines": "Rules & Guidelines",
      "Letters & Circular": "Letters & Circular",
      Report: "Report",
      Magazines: "Magazines",
      Newsletters: "Newsletters",
      Posters: "Posters",
      Leaflets: "Leaflets",
      Books: "Books",
      "Institution Building": "Institution Building",
      Partnership: "Partnership",
      ORMAS: "ORMAS",
      RSETI: "RSETI",
      UNFPA: "UNFPA",
      UNICEF: "UNICEF",
      UNCDF: "UNCDF",
      "Capacity Building": "Capacity Building",
      "Financial Inclusion": "Financial Inclusion",
      "Mission Shakti Loan – State Interest Subvention":
        "Mission SHAKTI Loan – State Interest Subvention",
      "Livelihoods, Skill Development & Market Linkages":
        "Livelihoods, Skill Development & Market Linkages",
      "Food Supplies & Consumer Welfare Department":
        "Food Supplies & Consumer Welfare Department",
      "Department of Energy": "Department of Energy",
      "School & Mass Education Department":
        "School & Mass Education Department",
      "Department of W & CD and Mission Shakti":
        "Department of W & CD and Mission Shakti",
      "Directorate of Fisheries": "Directorate of Fisheries",
      "Directorate of Animal Husbandry & Veterinary Services":
        "Directorate of Animal Husbandry & Veterinary Services",
      "Directorate of Horticulture": "Directorate of Horticulture",
      "Directorate of Extension Education (OUAT)":
        "Directorate of Extension Education (OUAT)",
      "Photo Gallery": "Photo Gallery",
      "Video Gallery": "Video Gallery",
      "Audio Gallery": "Audio Gallery",
      "Plan Progress": "Plan Progress",
      "Data & Statistics": "Data & Statistics",
      "Best Practice": "Best Practice",
      Achievements: "Achievements",
      Infrastructure: "Infrastructure",
      Messages: "Messages",
      FAQs: "FAQs",
      "Success Stories": "Success stories",
      "Loan Eligiblity": "Loan Eligiblity",
      "Empanelled Banks": "Empanelled Banks",
      "Documents Required": "Documents Required",
      Next: "Next",
      Previous: "Previous",
      Language: "Language",
      "Save & Next": "Save & Next",
      "Save & Continue": "Save & Continue",
      Cancel: "Cancel",
      Submit: "Submit",
      Forward: "Forward",
      SendBack: "Send Back to Citizen",
      Preview: "Preview",
      "Basic Details": "Basic Details",
      year: "Year",
      Month: "Month",
      FinancialYear: "Financial Year",
      Filter: "Filter",
      Reset: "Reset",
      Bank: "Bank",
      "Application No.": "Application No.",
      "Calculate IS": "Calculate IS",
      "Mobile No": "Mobile Number",
      EnterpriseUserInformation: "Enterprise User Information",
      EnterpriseUserProjectDetails: "Enterprise User Project Details",
      UserList: "User List",
      Add: "Add",
      AddUser: "Add User",
      EditUser: "Edit User Details",
      AddSHG: "Add SHG Details",
      Designation: "Designation",
      EditSHG: "Edit SHG Details",
      CalScooterYojana: "Scooter Yojana - Calculate Interest Subvention",
      UploadFile: "Upload a File",
      Calculate: "Calculate",
      AddNews: "Add News",
      EditNews: "Edit News",
      "Basic&AddressDetails": "Basic & Address Details",
      "Membership&Qualifications": "Membership & Qualifications",
      "BankIncomeDetails": "Bank & Income Details",
      TwoWheelerProposed: "Two Wheeler Proposed",
      SupportingDocuments: "Supporting Documents",
      Declarationn: "Declaration",
      PreviewApplication: "Preview Application",
      Note: "Note:",
      "Attachment Upto 500kb Format": "Attachment Upto 500kb Format",
      "Attachment Upto 2Mb Format":
        "Attachment upto 2Mb Format(jpg/jpeg/png/pdf)",
      Approve: "Approve",
      Reject: "Reject",
      Forward: "Forward",
      PreviewLetter: "PreviewLetter",
      ForwardtoControllingBank: "Forward to Controlling Bank",
      GoBackToHome: "Go Back To Home",
      Mobility: "Mobility is Empowerment",
      ApplyScooterLoan: "Apply Scooter Loan",
      AboutScooterYojana: "About Scooter Yojana",
      AboutScheme:
        "In recognition of the contribution of the Community Leaders and Support, the Department of Mission Shakti will facilitate full interest subvention on bank loans up to Rs 1 lakh to eligible women to purchase scooters. While 40,000+ women have already been handed over keys to scooters of their choice, 35,000+ are gearing up for the same.",
      ScooterLoanProcess: "Scooter Loan Process",
      "Login/Register": "Login / Register",
      "UploadBackLogData" : "Upload Backlog Data",
  MobilityandIndependence:
        "Take the first step towards mobility and independence.",
      BankLoan:
        "department enabled banks as trusted partners to facilitate the beneficiaries to avail hassle-free Scooter loan.",
      LoanEligibility: "Loan Eligibility",
      loaneligibility:
        "Check your loan eligibility before you rush to get your scooter.",
      DocumentRequired: "Document Required",
      Documents:
        "Keep these documents handy as you move a step closer to get your scooter.",
      HAPPYWOMEN: "NO OF HAPPY WOMEN😊",
      HAPPYMOMENTS: "HAPPY MOMENTS",
      WhatWomensay: "What Women say",
      AboutMissionShakti: "about Mission Shakti.",
      Feedback: "Feedback",
      OverviewDepartment: "Overview of the Department",
      DeptText:
        "The Department of Mission Shakti, Government of Odisha, was established with the primary goal of empowering women through the promotion of Self-Help Groups (SHGs). It focuses on socio-economic upliftment by providing women with opportunities for skill development, financial inclusion, and entrepreneurship. The department leads various initiatives aimed at fostering gender equality, increasing women’s participation in decision-making, and enhancing livelihoods. Through Mission Shakti, the government has created a robust platform for over 70 lakh women in Odisha, contributing significantly to the state's development.",
      DeptHeading:
        "The Department of Mission Shakti is driving numerous initiatives for women's empowerment in Odisha, focusing on economic independence and leadership. Key activities include:",
      DeptFooter:
        "These activities aim to build a robust framework for women’s socio-economic development, ensuring they have the skills, resources, and confidence to actively participate in the state’s growth.",
      MissionStatement: "Mission Statement-",
      MissonText:
        "Help construct a society which is self-reliant, conscious of socioeconomic issues, where there is a spirit of cooperation, where women are appropriately skilled to undertake their choice of activities without hindrances or dependence, where there is leadership development while maintaining gender equity & above all each having a respect for the values of others and each striving for the good of the greater society.",
      VisionStatement: "Vision Statement-",
      ActivitiesGrowth:
        "These activities aim to build a robust framework for women’s socio-economic development, ensuring they have the skills, resources, and confidence to actively participate in the state’s growth.",
      WomenEmpowerment:
        "The Mission Shakti Scooter Yojana is a visionary initiative by the Government of Odisha aimed at empowering women, particularly those involved in Self-Help Groups (SHGs) under Mission Shakti. In recognition of the Federation Leaders and Community Support Staff (CSS) towards women empowerment, State government had announced “Interest Free Loan for purchase of scooter by Mission Shakti Federation Leaders and Community Support Staff",
      DownPayment:
        "Under this scheme, the beneficiaries will receive interest free bank loan up to Rs.1 lakh to purchase scooter (electric/ petrol/hybrid) of their own choice with a maximum repayment period of 4 years (48 months). The beneficiary does not need to pay any margin money or down payment for availing the scooter loan up to Rs. 1 lakh.",
      LoanDocument:
        "It is of paramount importance to submit adequate number of loan applications to bank branches by the eligible beneficiaries in time. In this connection, a common loan application form has been developed in consultation with the banks. The field functionaries of Mission Shakti shall verify the loan documents and the same shall be recommended by Block Development Officer (BDO)/ Child Development Project Officer (CDPO).",
      ViewAbove:
        "In view of the above, the field functionaries are impressed upon to act swiftly and ensure that all eligible beneficiaries are covered under this new scheme",
      FormationandStrengthening:
        "Self-Help Group (SHG) Formation and Strengthening: Over 6 lakh SHGs involving more than 70 lakh women, enabling collective savings and financial empowerment.",
      SkillDevelopment:
        "Skill Development: Offering tailored training programs to boost women’s employability in diverse sectors like agriculture, textiles, and handicrafts.",
      FinancialResources:
        "Access to Financial Resources: Facilitating microfinance and credit linkages through Mission Shakti Loans, which provide low-interest loans for small businesses and initiatives like the Scooter Yojana, supporting mobility and independence.",
      LivelihoodSupport:
        "Entrepreneurship and Livelihood Support: Enabling women to establish micro-enterprises by providing financial assistance, market linkages, and business development services.",
      LeadershipandDecisionMakingEmpowerment:
        "Leadership and Decision-Making Empowerment: Conducting training programs to promote women’s participation in community governance, local decision-making, and public service.",
      DigitalLiteracy:
        "Digital Literacy and Financial Inclusion: Enhancing women’s access to digital banking, digital payments, and online financial services, promoting their involvement in the formal financial sector.",

      login: {
        "Sign In": "Sign In",
        "Forgot Password?": "Forgot Password?",
        Username: "Mobile Number or Username",
        Password: "Password",
        "UserName Place": "Enter your Mobile Number or Username",
        "Password Place": "Enter your Password",
        Submit: "Submit",
        Update: "Update",
        Cancel: "Cancel",
        NameRequired: "Please enter your Mobile Number or Username",
        PasswordRequired: "Please enter your password",
        newUser: "New user?  ",
        registerHere: "ପଂଜୀକରଣ କରନ୍ତୁ",
      },
      Register: {
        Register1: "Register",
        AadharName: "Name as per Aadhaar",
        AadharNo: "Aadhaar Number",
        MobileNo: "Mobile Number",
        Uname: "Username",
        "Uname Place": "Enter your Username",
        "UserName Place": "Enter your Mobile Number or Username",
        "Password Place": "Enter your Password",
        Submit: "Submit",
        Cancel: "Cancel",
        AlreadyAccount: "Already have an account? Login",
        SHG_Id: "SHG ID",
      },
      Forgot: {
        "Forgot Password": "Forgot Password",
        MobileNo: "Mobile Number",
        "UserName Place": "Enter your Mobile Number or Username",
        Submit: "Submit",
        Cancel: "Cancel",
      },
      feedback: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        phone: "Phone No.",
        subject: "Subject",
        feedback: "Feedback",
        firstNameRequired: "First Name is required",
        lastNameRequired: "Last Name is required",
        emailRequired: "Email is required",
        phoneRequired: "Phone No. is required",
        subjectRequired: "Subject is required",
        feedbackRequired: "Feedback is required",
        "Feedback List": "Feedback List",
      },
      Table: {
        "Token ID": "Token ID",
        "Date of Application": "Date of Application",
        "Name of Beneficiary": "Name of Beneficiary",
        "Panchayat/Jurisdiction": "Panchayat/Jurisdiction",
        Status: "Status",
        "Entries found": "Entries found",
        Action: "Action",
        SEARCH_BY: "SEARCH_BY",
        SUBVENTION_TABLE_HEADER: "Interest Subvention",
        Sorry: "Sorry, we couldn't find any results",
        Search: "Search",
        "WorkList Applications": "WorkList Applications",
        MyWorkList: "My Work List",
        "SHG ID": "SHG ID",
        "SHG Name": "SHG Name",
        "Beneficiary Name": "Beneficiary Name",
        "Contact No.": "Contact No.",
        "Aadhaar No.": "Aadhaar No.",
        District: "District",
        Block: "Block",
        Panchayat: "Panchayat",
        "ICDS Project Name": "ICDS Project Name",
        "Loan ID": "Loan ID",
        "Issue Date": "Issue Date",
        "Loan Amount": "Loan Amount",
        EMI: "EMI",
        ROI: "ROI",
        Interest: "Interest",
        Principal: "Principal",
        Outstanding: "Outstanding",
        Period: "Period",
        "Current Status": "Current Status",
        "Branch Name": "Branch Name",
        Branch_ifsc: "Branch IFSC",
        beneficiary_name: "Beneficiary Name",
        address: "Address",
        loan_account: "Loan Account",
        disbursed_loan_amount: "disbursed Loan Amount",
        "date_of_disbursement (YYYY-MM-DD)":
          "Date of disbursement (YYYY-MM-DD)",
        "annual_interest_charged %": "Annual interest charged %",
        repayment_schedule: "Repayment Schedule",
        vehicle_registration_no_details: "Vehicle RegistrationNo Details",
        loan_outstanding: "Loan Outstanding",
        overdue_amount: "Overdue Amount",
        total_interest_adjusted: "Total Interest Adjusted",
        no_of_loan_accounts_closed: "No.of loan Acc. closed",
        IS_Status: "IS Status",
        "Loan ID": "Loan ID",
        "Application TokenID": "Application TokenID",
        District: "District",
        SHGList: "SHG List",
        "Pending Since": "Pending Since (Days)",
        "User Id": "User Id",
        "User Name": "User Name",
        "Name as per Aadhar": "Name as per Aadhar",
        Role: "Role",
        Address: "Address",
        Others: "Others",
        News: "News & Updates",
        Feedback: "Feedback",
        "Sl#": "Sl#",
        Subject: "Subject",
        "Feedback Messsage": "Feedback Messsage",
        "Sent By": "Sent By",
        "Sent On": "Sent On",
        Email: "Email Id",
        Phone: "Phone No.",
        "Interest Amount": "Interest Amount",
        "Outstanding Amount": "Outstanding Amount",
        "OverDue Amount": "OverDue Amount",
        "NPA Count": "NPA Count",
        newsDetails: "News Details / Content",
        pubDate: "Published Date",
        Feedback: "Feedback",
        Id: "Id",
        Date: "Date",
        FeedbackList: "Feedback List",
        feedback: "Feedback",
        "News & Updates": "News & Updates",
        UserName: "User Name",
        NameAadhar: "Name as per Aadhar",
        Role: "Role",
        "ContactNo.": "Contact No.",
        Address: "Address",
        "Mobile No.": "Mobile No",
        IdentificationMark: "Identification Mark",
      },
      PrivateHeader: {
        "Mission Shakti": "Mission Shakti",
        "Goverment of Odisha": "Goverment of Odisha",
        "Scooter Yojana": "Scooter Yojana",
        Home: "Home",
        "My Application": "My Application",
        ApplyforMSSYLoan: "Apply for Scooter Loan",
        ViewApplication: "View Application",
        DownloadApplicationForm: "Download Application Form",
        Profile: "Profile",
        "My Profile": "My Profile",
        "View Profile": "View Profile",
        "Edit Profile": "Edit Profile",
        "Change Password": "Change Password",
        Logout: "Logout",
        "Application Management": "Application Management",
        "Beneficiary Applications": "Beneficiary Applications",
        "Check Eligibility": "Check Eligibility",
        "Issue Recommendation Certificates":
          "Issue Recommendation Certificates",
        "Forward Applications": "Forward Applications",
        "Track Applications": "Track Applications",
        Master: "Master",
        "User Management": "User Management",
        Calculations: "Calculations",
        Reports: "Reports",
        Others: "Others",
      },
      routeNames: {
        Home: "Home",
        "Profile Details": "View Profile",
        "Update Profile": "Edit Profile",
        "Change Password": "Change Password",
        "Block Official Home": "Block Official Home",
        "Beneficiary Applications": "Beneficiary Applications",
        "CDPO Home": "CDPO Home",
        "BDO Home": "BDO Home",
        "Application Form": "Application Form",
        "Loan Bank Home": "Loan Bank Home",
        "Controlling Bank Home": "Controlling Bank Home",
        "Track My Application": "Track My Application",
        "SHG Details": "SHG Details",
        "User Management": "User Management",
        "SHG Details": "SHG Details",
        "Interest Subvention": "Interest Subvention for Scooter Yojana",
        "Interest Subvention Reports":
          "Interest Subvention for Scooter Yojana - Report",
        " SHG Details": "SHG Details",
        "View Feedback": "View Feedback",
        "News & Updates": "News & Updates",
        "User Management": "User Management",
        "UploadBackLogData" : "Upload Backlog Data",
        // Add more routes as needed
      },
      ViewProfile: {
        Heading: "Profile Information",
        DOB: "Date of Birth",
        Gender: "Gender",
        "Aadhar Number": "Aadhar Number",
        "Pan Number": "PAN Number",
        Email: "Email Address",
        "Mobile No": "Mobile No",
        "Edit Profile": "Edit Profile",
        Cancel: "Cancel",
        Nationality: "Nationality",
        VoterId: "Voter Id",
        DrivingLicenceNo: "Driving Licence No",
        "Profile Photo": "Profile Photo",
        "Educational Qualification": "Educationational Qualification",
        Address: "Address",
        "Guardian Relation": "Guardian Relation",
        "Guardian Name": "Guardian Name",
        Name: "Applicant Name As Per Aadhaar",
        UName: "User Name",
        Age: "Age",
        "No Photo": "No Photo",
      },
      EditProfile: {
        Heading: "Update Profile",
        DOB: "Date of Birth",
        Gender: "Gender",
        "Aadhaar Number": "Aadhaar Number",
        "Pan Number": "PAN Number",
        Email: "Email Address",
        "Mobile No": "Mobile No",
        "Update Profile": "Update Profile",
        "Update Password": "Update Password",
        Cancel: "Cancel",
        Nationality: "Nationality",
        VoterId: "Voter Id",
        DrivingLicenceNo: "Driving Licence No",
        "Profile Photo": "Profile Photo(upto 300kb)(jpg/png/jpeg)",
        "Educational Qualification": "Educationational Qualification",
        Address: "Address",
        "Guardian Relation": "Guardian Relation",
        "Guardian Name": "Guardian Name",
        Name: "Applicant Name As Per Aadhaar",
        UName: "User Name",
        Age: "Age",
        "Alternate Number": "Alternate Mobile Number",
        "Blood Group": "Blood Group",
      },
      ChangePassword: {
        Heading: "Change Password",
        "Current Password": "Current Password",
        "New Password": "New Password",
        "Confirm New Password": "Confirm New Password",
        "Update Password": "Update Password",
        Cancel: "Cancel",
      },
      PersonalInfo: {
        Salution: "Salution",
        "First Name": "First Name",
        "Last Name": "Last Name",
        "Middle Name": "Middle Name",
        DOB: "DOB(mm/dd/yyyy)",
        userType: "User Type",
        Gender: "Gender",
        "Fathers Name": "Father's Name",
        "Husbands Name": "Husband's Name",
        Age: "Age",
        "YearsMonths Total": "Years & Months Total",
        "Aadhar Number": "Aadhar Number",
        "Pan Number": "PAN Number",
        Email: "Email",
        VoterId: "Voter Id",
        DrivingLicenceNo: "Driving Licence No (if Any)",
        "Profile Photo": "Profile Photo (upto 300kb)(jpg/png/jpeg)",
        "Mobile No": "Mobile Number",
        AadhaarName: "Name as per Aadhaar",
        "Basic Details": "Basic Details",
      },
      AddressInfo: {
        "Current Address": "Current Address",
        "Permanent Address": "Permanent Address",
        "HousePlot No": "House/Plot No",
        VillageTown: "Village/Town",
        PostOffice: "Post Office",
        Panchayat: "Panchayat",
        Block: "Block",
        City: "City",
        District: "District",
        "Pin Code": "Pin Code",
        State: "State",
        Nationality: "Nationality",
        SameAddress: "Same as Permanent Address",
      },
      MemberShip: {
        "Educational Qualification": "Educational Qualification",
        "Individual Type": "Individual Type",
        "Qualification Details": "Qualification Details",
        "Membership Details": "Membership Details",
        "SHG ID": "SHG ID",
        "Name of GPLF": "Name of GPLF",
        "Name of BLF": "Name of BLF",
        "Name of DLF": "Name of DLF",
        no_of_pd: "No of Months in Present Designation",
      },
      LoanDetails: {
        Heading1: "Applicant's Primary Account Details",
        "Account Number": "Account Number",
        "Account Placeholder": "Enter Your Account Number",
        "IFSC Code": "IFSC Code",
        "IFSC Placeholder": "Enter Your IFSC Code",
        "Bank Name": "Bank Name",
        "Bank Placeholder": "Enter Your Bank Name",
        "Branch Name": "Branch Name",
        "Branch Placeholder": "Enter Your Branch Name",
        "Customer ID": "Customer ID",
        "ID Placeholder": "Enter Your Customer ID",
        "Age of Bank": "Age of Banking Relationship With Bank(Months)",
        "BankAge Placeholder":
          "Enter Your Age of Banking Relationship With Bank",
        Heading2: "Existing Loan Details (If Any)",
        "Loan Type": "Loan Type",
        "LoanType Placeholder": "Select your Loan Type",
        "Loan Amount": "Loan Amount(in Rs)",
        "Loan Amount Placeholder": "Enter Your Loan Amount(in Rs)",
        EMI: "EMI(in Rs)",
        "EMI Placeholder": "Enter Your EMI(in Rs)",
        "Outstanding Loan": "Outstanding Loan Amount(in Rs)",
        "Outstanding Loan Placeholder":
          "Enter Your Outstanding Loan Amount(in Rs)",
        Heading3: "Income Details",
        "Income Source":
          "Income From Remuneration/Setting Fees/Incentive(in Rs)",
        "Income Source Placeholder": "Enter your Income Source",
        "Income From": "Income From (Livelihood/other resources)(in Rs)",
        "Income From Placeholder": "Enter Your Income From (in Rs)",
        "Total Income": "Total Income(in Rs)",
        "Total Income Placeholder": "Enter Your Total Income(in Rs)",
      },
      VehicleDetails: {
        Manufacturer: "Manufacturer Name",
        "Manufacturer Placeholder": "Select Your Name of Manufacturer",
        "Vehicle Model": "Vehicle Model",
        "Vehicle Placeholder": "Enter Your Vehicle Model",
        "Type/Class": "Type/Class of Two Wheeler",
        "Type/Class Placeholder": "Enter Your Type/Class of Two Wheeler",
        "Manufacture Year": "Year of manufacture",
        "MY Placeholder": "Enter Your Year of manufacture",
        "Vehicle Price": "Price of the Vehicle(Ex-show price)(in Rs)",
        "Price Placeholder": "Enter Your Price of the Vehicle",
        "Insurance Cost": "Add Insurance Cost(in Rs)",
        "Insurance Placeholder": "Enter your Add Insurance Cost",
        "Registration Cost": "Add Registration Cost(in Rs)",
        "Registration Placeholder": "Enter Your Add Registration Cost",
        "Less Margin": "Less Margin Money(in Rs)",
        "LMM Placeholder": "Enter Less Margin Money",
        "Less Dealer": "Less Dealer/Manufacturer Discount(in Rs)",
        "Less Dealer Placeholder":
          "Enter your Less Dealer/Manufacturer Discount",
        "Accessories Cost": "Add Accessories Cost(in Rs)",
        "Accessories Cost Placeholder": "Enter your Add Accessories Cost",
        "Loan Amount": "Amount of Loan Required(in Rs)",
        "Loan Amount Placeholder": "Enter Your Amount of Loan Required",
        "Name&Address": "Name & Address of the Showroom/Dealer/Seller",
        "Name&Address Placeholder":
          "Enter your Name & Address of the Showroom/Dealer/Seller",
        TIN: "TIN of Showroom/Dealer/Seller",
        "TIN Placeholder": "Enter your TIN of Showroom/Dealer/Seller",
        "Quotation no": "Quotation No",
        "Quotation Placeholder": "Enter your Quotation No",
        "Quotation Date": "Quotation Date",
        "Quotation Date Placeholder": "Select date",
        "Proposed Repayment": "Proposed Repayment Period",
        "Proposed Repayment Placeholder":
          "Select your Proposed Mode of Repayment",
        "Monthly Installment": "Proposed Equated Monthly Installment(in Rs)",
        "Monthly Installment Placeholder":
          "Enter your Proposed Equated Monthly Installment",
        "Repayment Mode": "Proposed Mode of Repayment",
        "Repayment Mode Placeholder": "Select your Proposed Mode of Repayment",
      },
      SupportingDocs: {
        Heading1: "Identity Proof",
        Heading2: "Address/Resident Proof",
        Heading3: "Additional Documents",
        Aadhaar: "Aadhaar",
        PAN: "PAN",
        Passport: "Passport",
        VoterId: "VoterId",
        ElectricityBill: "Electricity Bill",
        Telephone: "Telephone",
        PropertyTax: "Property Tax",
        AnyOtherDocument: "Any Other Document(Specify)(pdf/docx)",
        OtherDocumentLabel: "Upload Any Other Document",
        QuotationDetailsLabel:
          "Quotation Details(from Dealer/Seller)(pdf/docx)",
        RecentPhotoLabel: "Recent Photo(Two Copies)(jpg/jpeg/png)",
        PropertyTaxLabel: "Upload Property Tax",
        AadhaarLabel: "Upload Aadhaar",
        PANLabel: "Upload PAN",
        TelephoneLabel: "Upload Telephone Bill",
        ElectricityBillLabel: "Upload Electricity bill",
        PassportLabel: "Upload Passport",
        VoterIdLabel: "Upload Voter ID",
      },
      Declaration: {
        SignatureUploadLabel: "Signature Upload(jpg/png/jpeg)",
        Declaration1:
          "I hereby declare that the information given in this application is true and correct to the best of my knowledge and belief.",
        Declaration2: "I agree to the terms and conditions.",
        Declaration3:
          "I understand that any false information may result in my disqualification.",
        Declaration4:
          "I authorize the verification of the information provided.",
        Declaration5:
          "I agree to receive communications regarding my application.",
        Date: "Date",
        Place: "Place",
      },
      Preview: {
        SignatureUploadLabel: "Signature Upload(jpg/png/jpeg)",
        Declaration1:
          "I hereby declare that the information given in this application is true and correct to the best of my knowledge and belief.",
        Declaration2: "I agree to the terms and conditions.",
        Declaration3:
          "I understand that any false information may result in my disqualification.",
        Declaration4:
          "I authorize the verification of the information provided.",
        Declaration5:
          "I agree to receive communications regarding my application.",
        Date: "Date",
        Place: "Place",
        Declaration: "Declaration",
        Preview: "Preview",
        Yes: "Yes",
        No: "No",

        Heading1: "Identity Proof",
        Heading2: "Address/Resident Proof",
        Heading3: "Additional Documents",
        Aadhaar: "Aadhaar",
        PAN: "PAN",
        Passport: "Passport",
        VoterId: "VoterId",
        ElectricityBill: "Electricity Bill",
        Telephone: "Telephone",
        PropertyTax: "Property Tax",
        AnyOtherDocument: "Any Other Document(Specify)(pdf/docx)",
        OtherDocumentLabel: "Upload Any Other Document",
        QuotationDetailsLabel:
          "Quotation Details(from Dealer/Seller)(pdf/docx)",
        RecentPhotoLabel: "Recent Photo(Two Copies)(jpg/jpeg/png)",
        PropertyTaxLabel: "Upload Property Tax",
        AadhaarLabel: "Upload Aadhaar",
        PANLabel: "Upload PAN",
        TelephoneLabel: "Upload Telephone Bill",
        ElectricityBillLabel: "Upload Electricity bill",
        PassportLabel: "Upload Passport",
        VoterIdLabel: "Upload Voter ID",
        "Supporting Documents": "Supporting Documents",

        Manufacturer: "Manufacturer",
        "Vehicle Model": "Vehicle Model",
        "Type/Class": "Type/Class",
        "Manufacture Year": "Year of Manufacture",
        "Vehicle Price": "Ex-Showroom Price",
        "Insurance Cost": "Insurance Cost",
        "Registration Cost": "Registration Cost",
        "Less Margin": "Less Margin Money",
        "Less Dealer": "Less Discount",
        "Accessories Cost": "Accessories Cost",
        "Loan Amount": "Loan Amount",
        "Name&Address": "Showroom Name & Address ",
        TIN: "TIN Number",
        "Quotation no": "Quotation No",
        "Quotation Date": "Quotation Date",
        "Proposed Repayment": "Proposed Repayment Period",
        "Monthly Installment": "Proposed Equated Monthly Installment",
        "Repayment Mode": "Proposed Mode of Repayment",
        "Vehicle Information": "Vehicle Information",

        Heading1: "Applicant's Primary Account Details",
        "Account Number": "Account Number",
        "IFSC Code": "IFSC Code",
        "Bank Name": "Bank Name",
        "Branch Name": "Branch Name",
        "Customer ID": "Customer ID",
        "Age of Bank": "Age Of Banking",
        Heading2: "Existing Loan Details",
        "Loan Type": "Loan Type",
        "Loan Amount": "Loan Amount",
        EMI: "EMI Amount",
        "Outstanding Loan": "Outstanding Loan Amount",
        Heading3: "Income Details",
        "Income Source": "Income From Remuneration",
        "Income From": "Income From",
        "Total Income": "Total Income",
        "Loan Details": "Loan Details",
        "Other Income": "Other Income",

        "Educational Qualification": "Educational Qualification",
        "Individual Type": "Individual Type",
        "Qualification Details": "Qualification Details",
        "Membership Details": "Membership Details",
        "SHG ID": "SHG ID",
        "Name of GPLF": "Name of GPLF",
        "Name of BLF": "Name of BLF",
        "Name of DLF": "Name of DLF",
        no_of_pd: "No of Months in Present Designation",

        "First Name": "First Name",
        "Last Name": "Last Name",
        "Middle Name": "Middle Name",
        DOB: "Date of Birth",
        Gender: "Gender",
        "Fathers Name": "Father's Name",
        "Husbands Name": "Husband's Name",
        Age: "Age",
        "YearsMonths Total": "Years & Months Total",
        "Aadhar Number": "Aadhar Number",
        "Pan Number": "PAN Number",
        Email: "Email",
        VoterId: "Voter Id",
        DrivingLicenceNo: "Driving Licence No",
        "Profile Photo": "Profile Photo (upto 300kb)(jpg/png/jpeg)",
        "Mobile No": "Mobile Number",
        AadhaarName: "Name as per Aadhaar",

        "Current Address": "Current Address",
        "Permanent Address": "Permanent Address",
        "HousePlot No": "House/Plot No",
        VillageTown: "Village/Town",
        PostOffice: "Post Office",
        Panchayat: "Panchayat",
        Block: "Block",
        City: "City",
        District: "District",
        "Pin Code": "Pin Code",
        State: "State",
        Nationality: "Nationality",
        SameAddress: "Same as Permanent Address",
        "Personal Information": "Personal Information",
        ApplicationNumber: "Application Number",
        "Address Information": "Address Information",
      },

      TrackApplication: {
        ApplicationStatusTracking: "Application Status Tracking",
        ApplicationNumber: "Application Number",
        "Application Submitted": "Application Submitted",
        BPCVerified: "BPC Verified",
        CDPOVerified: "CDPO Verified",
        BDOApproved: "BDO Approved",
        ControllingBankApproved: "Controlling Bank Approved",
        "Loan Approved": "Loan Approved",
      },
    },
  },
  or: {
    translation: {
      governmentOfOdisha: "ଓଡ଼ିଶା ସରକାର",
      pradhanPrustha: "ପ୍ରଧାନ ପୃଷ୍ଠା",
      Login: "ଲଗଇନ୍",
      register: "ପଂଜୀକରଣ କରନ୍ତୁ",
      language: "ଭାଷା",
      english: "ଇଂରାଜୀ",
      odia: "ଓଡ଼ିଆ",
      missionShaktiScooterYojana: "ମିଶନ୍ ଶକ୍ତି ସ୍କୁଟର୍ ଯୋଜନା",
      checkSchemeEligibility: "ଯୋଜନା ଯୋଗ୍ୟତା ଯାଞ୍ଚ",
      applyScooterLoan: "ସ୍କୁଟର୍ ରିଣ ପାଇଁ ଆବେଦନ କରନ୍ତୁ?",
      Home: "ହୋମ୍",
      "Save & Continue": "ସେଭ୍ କରନ୍ତୁ ଓ ଜାରୀ ରଖନ୍ତୁ",
      // "About Us": "ଆମ ବିଷୟରେ",
      "About Us": "ଆବାଉଟ୍  ଅସ",
      // Notification: "ବିଜ୍ଞପ୍ତି",
      Notification: "ନୋଟିଫିକେସନ୍",
      Publication: "ପ୍ରକାଶନ",
      // Programme: "କାର୍ଯ୍ୟକ୍ରମ",
      Programme: "ପ୍ରୋଗ୍ରାମ୍",
      Convergence: "ସମ୍ମିଶ୍ରଣ",
      "District Pages": "ଜିଲ୍ଲା ପୃଷ୍ଠା",
      RTI: "ସୂଚନା ଅଧିକାର",
      "Media Gallery": "ମିଡିଆ ଗ୍ୟାଲେରୀ",
      "Contact Us": "ଯୋଗାଯୋଗ କରନ୍ତୁ",
      "Important Links": "ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ସଂଯୋଗ",
      More: "ଅଧିକ",
      // Overview: "ଅବଲୋକନ",
      Overview: "ଓଭରଭୀୟୁ",
      // "Mission & Vission": "ଲକ୍ଷ୍ୟ ଓ ଉଦ୍ଦେଶ୍ୟ",
      "Mission & Vission": "ମିଶନ୍ ଏଣ୍ଡ ଭିଜନ୍",
      // "Community Structure": "ଅନୁଷ୍ଠାନ ଅଧିରଚନା",
      "Community Structure": "କମୁନିଟି ଷ୍ଟ୍ରକଚର",
      // "Organizational Structure": "ସାମ୍ପ୍ରଦାୟିକ ସଂରଚନା ",
      "Organizational Structure": "ସାଂଗଠନିକ ଗଠନ",
      WhosWho: "ବିଶେଷ ବ୍ୟକ୍ତିମାନଙ୍କର ତାଲିକା",
      Administration: "ପ୍ରଶାସନ",
      "State Project Monitoring Unit": "ରାଜ୍ୟ ପ୍ରକଳ୍ପ ମନିଟରିଂ ୟୁନିଟ୍",
      "Rules & Guidelines": "ନିୟମ ଓ ନିର୍ଦ୍ଦେଶାବଳୀ",
      // "Letters & Circular": "ପତ୍ର ଓ ପରିପତ୍ର",
      "Letters & Circular": "ଲେଟର୍ ଏଣ୍ଡ ସର୍କୁଲାର",
      Report: "ବିବରଣୀ",
      Magazines: "ପତ୍ରିକା",
      Newsletters: "ସମ୍ବାଦ",
      Posters: "ପୋଷ୍ଟର",
      Leaflets: "ପ୍ରଚାର ପତ୍ରିକା",
      Books: "ପୁସ୍ତକ",
      "Institution Building": "ଅନୁଷ୍ଠାନ ନିର୍ମାଣ",
      Partnership: "ସହଭାଗୀତା",
      ORMAS: "ଓରମାସ",
      RSETI: "ଆରଏସଇଟିଆଇ",
      UNFPA: "ୟୁଏନଏଫପିଏ",
      UNICEF: "ୟୁନିସେଫ୍",
      UNCDF: "ୟୁଏନସିଡିଏଫ",
      "Capacity Building": "ଦକ୍ଷତା ବୃଦ୍ଧି",
      "Financial Inclusion": "ଆର୍ଥିକ ଅନ୍ତର୍ଭୁକ୍ତୀକରଣ",
      "Mission Shakti Loan – State Interest Subvention":
        "ମିଶନ୍ ଶକ୍ତି ଋଣ - ରାଜ୍ୟ ସୁଧ ଆର୍ଥିକ ସାହାଯ୍ୟ",
      "Livelihoods, Skill Development & Market Linkages":
        "ଜୀବିକା, ଦକ୍ଷତା ବିକାଶ ଓ ବଜାର ସଂଯୁକ୍ତ କରିବା ପଦ୍ଧତି",
      "Food Supplies & Consumer Welfare Department":
        "ଖାଦ୍ୟ ଯୋଗାଣ ଏବଂ ଖାଉଟି କଲ୍ୟାଣ ବିଭାଗ",
      "Department of Energy": "ଶକ୍ତି ବିଭାଗ",
      "School & Mass Education Department": "ବିଦ୍ୟାଳୟ ଓ ଗଣଶିକ୍ଷା ବିଭାଗ",
      "Department of W & CD and Mission Shakti": "ମହିଳା ଓ ଶିଶୁ ବିକାଶ ବିଭାଗ",
      "Directorate of Fisheries": "ମତ୍ସ୍ୟ ବିଭାଗର ନିର୍ଦ୍ଦେଶାଳୟ",
      "Directorate of Animal Husbandry & Veterinary Services":
        "ପଶୁପାଳନ ଓ ପ୍ରାଣୀ ଚିକିତ୍ସା ସେବା ନିର୍ଦ୍ଦେଶାଳୟ",
      "Directorate of Horticulture": "ଉଦ୍ୟାନ କୃଷି ନିର୍ଦ୍ଦେଶାଳୟ",
      "Directorate of Extension Education (OUAT)":
        "ସମ୍ପ୍ରସାରଣ ଶିକ୍ଷା ନିର୍ଦ୍ଦେଶାଳୟ (ଓୟୁଏଟି)",
      "Photo Gallery": "ଫଟୋ ଗ୍ୟାଲେରୀ",
      "Video Gallery": "ଭିଡିଓ ଗ୍ୟାଲେରୀ",
      "Audio Gallery": "ଅଡିଓ ଗ୍ୟାଲେରୀ",
      "Plan Progress": "ପ୍ରଗତି ଯୋଜନା",
      "Data & Statistics": "ତଥ୍ୟ ଓ ପରିସଂଖ୍ୟାନ",
      "Best Practice": "ସର୍ବୋତ୍ତମ ଅଭ୍ୟାସ",
      Achievements: "ସଫଳତା",
      Infrastructure: "ଭିତ୍ତିଭୂମି",
      Messages: "ବାର୍ତ୍ତା",
      FAQs: "ପ୍ରାୟତଃ ପଚରାଯାଇଥିବା ପ୍ରଶ୍ନ",
      "Success Stories": "ସଫଳତା କାହାଣୀ",
      "Loan Eligiblity": "ଋଣ ଯୋଗ୍ୟତା",
      "Empanelled Banks": "ସୂଚିଭୁକ୍ତ ବ୍ୟାଙ୍କଗୁଡ଼ିକ",
      "Documents Required": "ଆବଶ୍ୟକ ତଥ୍ୟପତ୍ର",
      Next: "ପରବର୍ତ୍ତୀ",
      Previous: "ପୂର୍ବ",
      Language: "ଭାଷା ପରିବର୍ତ୍ତନ",
      "Save & Next": "ସେଭ୍ ଏବଂ ପରବର୍ତ୍ତୀ",
      Cancel: "ବାତିଲ୍ କରନ୍ତୁ",
      Submit: "ଦାଖଲ କରନ୍ତୁ",
      Forward: "ଅଗ୍ରଗାମୀ",
      SendBack: "ନାଗରିକଙ୍କ ନିକଟକୁ ପଠାଗଲା",
      Preview: "ପୂର୍ବାବଲୋକନ",
      "Basic Details": "ମୌଳିକ ବିବରଣୀ",
      "SHG Details": "SHG ବିବରଣୀଗୁଡିକ",
      "Application No.": "ଆବେଦନ ସଂଖ୍ୟା",
      FinancialYear: "ଆର୍ଥିକ ବର୍ଷ",
      Month: "ମାସ",
      Filter: "ଫିଲ୍ଟର୍ କରନ୍ତୁ",
      Reset: "ପୁନରୁଦ୍ଧାର କରନ୍ତୁ",
      Bank: "ବ୍ୟାଙ୍କ",
      "Calculate IS": "ଗଣନା କର IS",
      EnterpriseUserInformation: "ଏଣ୍ଟରପ୍ରାଇଜ୍ ବ୍ୟବହାରକାରୀ ସୂଚନା",
      EnterpriseUserProjectDetails: "ଏଣ୍ଟରପ୍ରାଇଜ୍ ୟୁଜର୍ ପ୍ରୋଜେକ୍ଟ ବିବରଣୀ",
      UserList: "ବ୍ୟବହାରକାରୀ ସୂଚୀ",
      Add: "ଯୋଡନ୍ତୁ",
      AddUser: "ବ୍ୟବହାରକାରୀ ଯୋଡନ୍ତୁ",
      EditUser: "ବ୍ୟବହାରକାରୀ ବିବରଣୀ ସମ୍ପାଦନା କରନ୍ତୁ",
      AddSHG: "SHG ତଥ୍ୟ ଯୋଗ କରନ୍ତୁ",
      Designation: "ପଦବୀ",
      EditSHG: "SHG ବିବରଣୀ ସମ୍ପାଦନା କରନ୍ତୁ",
      CalScooterYojana: "ସ୍କୁଟର ଯୋଜନା - ସୁଧ ସବଭେନ୍ସନ୍ ଗଣନା କରନ୍ତୁ",
      UploadFile: "ଏକ ଫାଇଲ୍ ଅପଲୋଡ୍ କରନ୍ତୁ",
      Calculate: "ଗଣନା କରନ୍ତୁ",
      AddNews: "ସମ୍ବାଦ ଯୋଡନ୍ତୁ",
      EditNews: "ସମ୍ବାଦ ସମ୍ପାଦନା କରନ୍ତୁ",
      "Basic&AddressDetails": "ମୌଳିକ ଓ ଠିକଣା ବିବରଣୀ",
      "Membership&Qualifications": "ସଦସ୍ୟତା ଓ ଯୋଗ୍ୟତା",
      "BankIncomeDetails": "ବ୍ୟାଙ୍କ ଓ ଆୟ ବିବରଣୀ",
      TwoWheelerProposed: "ଦୁଇ ଚକ୍ର ଯାନ ପ୍ରସ୍ତାବ",
      SupportingDocuments: "ସମର୍ଥନ ଦସ୍ତାବିଜ୍",
      Declarationn: "ଘୋଷଣା",
      PreviewApplication: "ଆବେଦନ ପୂର୍ବଦୃଷ୍ଟି",
      Note: "ନୋଟ:",
  "UploadBackLogData" : "ବ୍ୟାକ୍ଲଗ୍ ଡାଟା ଅପଲୋଡ୍ କରନ୍ତୁ",
      "Attachment Upto 500kb Format":
        "ଏକ 500kb ଫର୍ମାଟର ଏଂଜେନ୍ତ (jpg/jpeg) ଅଟ୍ୟାଚ୍‌ମେଣ୍ଟ",
      "Attachment Upto 2Mb Format":
        "2Mb ଫର୍ମାଟର ଅଟ୍ୟାଚ୍‌ମେଣ୍ଟ (jpg/jpeg/png/pdf)",
      Approve: "ମଞ୍ଜୁର କରନ୍ତୁ",
      Reject: "ଖାରିଜ କରନ୍ତୁ",
      Forward: "ଅଗ୍ରସର କରନ୍ତୁ",
      PreviewLetter: "ଚିଠିର ପୂର୍ବାବଲୋକନ",
      ForwardtoControllingBank: "ନିୟନ୍ତ୍ରକ ବ୍ୟାଙ୍କକୁ ଅଗ୍ରସର କରନ୍ତୁ",
      GoBackToHome: "ଗୃହକୁ ପୁନର୍ବାସନ କରନ୍ତୁ",
      "Programme Overview": "ପ୍ରୋଗ୍ରାମ୍ ସମୀକ୍ଷା",
      // "Programme Overview": "କାର୍ଯ୍ୟକ୍ରମ ସାରାଂଶ",
      year: "ବର୍ଷ",
      Mobility: "ଗତିଶୀଳତା ହେଉଛି ସଶକ୍ତିକରଣ",
      ApplyScooterLoan: "ସ୍କୁଟର ଋଣ ଆବେଦନ କରନ୍ତୁ",
      AboutScooterYojana: "ସ୍କୁଟର ଯୋଜନା ବିଷୟରେ",
      AboutScheme:
        "କମ୍ୟୁନିଟି ଲିଡର୍ସ ଏବଂ ସପୋର୍ଟର ଅବଦାନକୁ ସ୍ୱୀକୃତି ଦେଇ ମିଶନ ଶକ୍ତି ବିଭାଗ ସ୍କୁଟର କିଣିବା ପାଇଁ ଯୋଗ୍ୟ ମହିଳାଙ୍କୁ 1 ଲକ୍ଷ ଟଙ୍କା ପର୍ଯ୍ୟନ୍ତ ବ୍ୟାଙ୍କ ଋଣ ଉପରେ ସମ୍ପୂର୍ଣ୍ଣ ସୁଧ ସବଭେନ୍ସନ୍ ପ୍ରଦାନ କରିବ। 40,000+ ମହିଳା ନିଜ ପସନ୍ଦର ସ୍କୁଟରଗୁଡିକର ଚାବି ହସ୍ତାନ୍ତର କରିସାରିଥିବାବେଳେ, 35,000+ ସମାନ ପାଇଁ ପ୍ରସ୍ତୁତ ହେଉଛନ୍ତି",
      ScooterLoanProcess: "ସ୍କୁଟର ଋଣ ପ୍ରକ୍ରିୟା",
      "Login/Register": "ସ୍କୁଟର ଋଣ ପ୍ରକ୍ରିୟା",
      MobilityandIndependence:
        "ଗତିଶୀଳତା ଏବଂ ସ୍ୱାଧୀନତା ଦିଗରେ ପ୍ରଥମ ପଦକ୍ଷେପ ନିଅନ୍ତୁ",
      BankLoan:
        "ବିଭାଗ ବ୍ୟାଙ୍କଗୁଡ଼ିକୁ ବିଶ୍ୱସ୍ତ ସହଭାଗୀ ଭାବରେ ହିତାଧିକାରୀମାନଙ୍କୁ ଅସୁବିଧାମୁକ୍ତ ସ୍କୁଟର ଋଣ ପାଇବାକୁ ସହଜ କରିବାକୁ ସକ୍ଷମ କରିଥିଲା",
      LoanEligibility: "ଋଣ ଯୋଗ୍ୟତା",
      loaneligibility: "ସ୍କୁଟର କିଣିବା ଆଗରୁ ଆପଣଙ୍କ ଋଣ ଯୋଗ୍ୟତା ଯାଞ୍ଚ କରନ୍ତୁ",
      DocumentRequired: "ଡକ୍ୟୁମେଣ୍ଟ୍ ଆବଶ୍ୟକ",
      Documents:
        "ତୁମେ ତୁମର ସ୍କୁଟର ପାଇବାକୁ ଏକ ପାଦ ଆଗକୁ ବଢ଼ୁଥିବା ସମୟରେ ଏହି ଡକ୍ୟୁମେଣ୍ଟଗୁଡ଼ିକୁ ପ୍ରସ୍ତୁତ ରଖନ୍ତୁ",
      HAPPYWOMEN: "ସୁଖୀ ମହିଳାମାନଙ୍କ ସଂଖ୍ୟା😊",
      HAPPYMOMENTS: "ଖୁସିର ମହୁର୍ତ୍ତଗୁଡ଼ିକ",
      WhatWomensay: "ମହିଳାମାନେ କ’ଣ କୁହନ୍ତି",
      AboutMissionShakti: "ମିଶନ୍ ଶକ୍ତି ବିଷୟରେ",
      Feedback: "ମତାମତ",
      OverviewDepartment: "ବିଭାଗର ସମୀକ୍ଷା",
      DeptText:
        "ମିଶନ୍ ଶକ୍ତି ବିଭାଗ, ଓଡିଶା ସରକାର, ସ୍ୱୟଂସାହାୟକ ଗୋଷ୍ଠୀ (SHGs) ର ପ୍ରୋତ୍ସାହନ ମାଧ୍ୟମରେ ମହିଳାମାନଙ୍କୁ ସଶକ୍ତିକରଣର ମୂଳ ଲକ୍ଷ୍ୟ ସହିତ ପ୍ରତିଷ୍ଠା ହୋଇଛି | ଏହା ମହିଳାମାନଙ୍କୁ ଦକ୍ଷତା ବିକାଶ, ଆର୍ଥିକ ଅନ୍ତର୍ଭୁକ୍ତୀକରଣ ଏବଂ ଉଦ୍ୟୋଗୀକରଣ ପାଇଁ ସୁଯୋଗ ଯୋଗାଇ ସାମାଜିକ-ଆର୍ଥିକ ଉନ୍ନତିରେ ଧ୍ୟାନ ଦେଇଥାଏ | ଲିଙ୍ଗଗତ ସମାନତା ବୃଦ୍ଧି, ନିଷ୍ପତ୍ତି ନେବାରେ ମହିଳାଙ୍କ ଅଂଶଗ୍ରହଣ ବୃଦ୍ଧି ଏବଂ ଜୀବିକା ବୃଦ୍ଧି ପାଇଁ ବିଭାଗ ବିଭିନ୍ନ ପଦକ୍ଷେପ ନେଇଥାଏ। ମିଶନ୍ ଶକ୍ତି ମାଧ୍ୟମରେ, ସରକାର ଓଡିଶାର 70 ଲକ୍ଷରୁ ଅଧିକ ମହିଳାଙ୍କ ପାଇଁ ଏକ ଦୃଢ଼ ମଞ୍ଚ ସୃଷ୍ଟି  କରିଛି, ଯାହା ରାଜ୍ୟର ବିକାଶରେ ବିଶେଷ ଅବଦାନ ଦେଇଛନ୍ତି |",
      DeptHeading:
        "ମିଶନ୍ ଶକ୍ତି ବିଭାଗ ଓଡିଶାରେ ମହିଳା ସଶକ୍ତିକରଣ ପାଇଁ ଅନେକ ପଦକ୍ଷେପ ନେଉଛି, ଯାହାର ପ୍ରମୁଖ ଲକ୍ଷ୍ୟ ଆର୍ଥିକ ସ୍ୱାଧୀନତା ଏବଂ ନେତୃତ୍ୱକୁ ବଢ଼ାଇବା. ମୁଖ୍ୟ କାର୍ଯ୍ୟକଳାପଗୁଡ଼ିକ ଅନ୍ତର୍ଭୁକ୍ତ:",
      DeptFooter:
        "ଏହି କାର୍ଯ୍ୟକଳାପଗୁଡ଼ିକ ମହିଳାମାନଙ୍କର ସାମାଜିକ-ଆର୍ଥିକ ଉନ୍ନତି ପାଇଁ ଏକ ଦୃଢ଼ ଢାଞ୍ଚା ଗଠନ କରିବାକୁ ଲକ୍ଷ୍ୟରଖେ, ଯାହା ମହିଳାମାନଙ୍କୁ ଦକ୍ଷତା, ସମ୍ବଳ ଏବଂ ଆତ୍ମବିଶ୍ୱାସ ଦେଇ, ରାଜ୍ୟର ଅଭିବୃଦ୍ଧିରେ ସକ୍ରିୟ ଭାବେ ଅଂଶଗ୍ରହଣ କରିବାକୁ ସହାୟ କରିବ |",
      MissionStatement: "Mission Statement (ମିଶନ୍ ସଂକଳ୍ପ)-",
      MissonText:
        "ଏକ ସମାଜ ଗଠନ କରିବାକୁ ସହାୟତା କରନ୍ତୁ, ଯେଉଁଠାରେ ଆତ୍ମନିର୍ଭରଶୀଳତା ରହିବ, ସାମାଜିକ-ଆର୍ଥିକ ସମସ୍ୟାଗୁଡ଼ିକ ପ୍ରତି ସଚେତନତା ରହିବ, ସହଯୋଗର ଭାବନା ରହିବ, ଯେଉଁଠାରେ ମହିଳାମାନେ ତାଙ୍କର ଚୟନକରା କାର୍ଯ୍ୟକଳାପ ସ୍ୱତନ୍ତ୍ର ଭାବେ କୌଣସି ବାଧା ବା ନିର୍ଭରତା ବିନା ନିର୍ବିଗ୍ନ ଭାବେ କରିବା ପାଇଁ ପର୍ଯ୍ୟାପ୍ତ ଦକ୍ଷତା ରଖିଥାନ୍ତି, ଯେଉଁଠାରେ ନେତୃତ୍ୱ ଉନ୍ନତି ସହ ଲିଙ୍ଗଗତ ସମାନତା ବଜାୟ ରହିବ ଓ ସବୁଠାରୁ ମୁଖ୍ୟ ହେଉଛି ପ୍ରତ୍ୟେକ ଜଣେ ଅନ୍ୟମାନଙ୍କର ମୂଲ୍ୟବୋଧ ପ୍ରତି ସମ୍ମାନ ରଖିବେ ଓ ସମାଜର ମଙ୍ଗଳ ପାଇଁ ପ୍ରୟାସ କରିବେ |",
      VisionStatement: "Vision Statement (ଦୂରଦୃଷ୍ଟି ସଂକଳ୍ପ)-",
      ActivitiesGrowth:
        "ଏହି କାର୍ଯ୍ୟକଳାପଗୁଡ଼ିକ ମହିଳାମାନଙ୍କ ସାମାଜିକ-ଆର୍ଥିକ ଉନ୍ନତି ପାଇଁ ଏକ ଦୃଢ଼ ଢାଞ୍ଚା ଗଠନ କରିବାକୁ ଲକ୍ଷ୍ୟ ରଖିଛି, ଯାହା ମହିଳାମାନଙ୍କୁ ଦକ୍ଷତା, ସମ୍ବଳ ଓ ବିଶ୍ୱାସ ଦେଇ ରାଜ୍ୟର ଅଭିବୃଦ୍ଧିରେ ସକ୍ରିୟ ଭାବେ ଅଂଶଗ୍ରହଣ କରିବାକୁ ସହାୟ କରିବ",
      WomenEmpowerment:
        'ମିଶନ୍ ଶକ୍ତି ସ୍କୁଟର ଯୋଜନା ହେଉଛି ଓଡିଶା ସରକାରଙ୍କ ଦ୍ୱାରା ଏକ ଦୂରଦୃଷ୍ଟିସମ୍ପନ୍ନ ପଦକ୍ଷେପ, ଯାହା ମହିଳାମାନଙ୍କୁ  ସଶକ୍ତିକରଣ କରିବାକୁ ଲକ୍ଷ୍ୟ ରଖିଛି, ବିଶେଷକରି ମିଶନ୍ ଶକ୍ତି ଅଧୀନରେ ସ୍ୱୟଂସାହାୟକ ଗୋଷ୍ଠୀ (SHGs) ସହ ଜଡିତ। ମହିଳା ସଶକ୍ତିକରଣ ପ୍ରତି ଫେଡେରେସନ୍ ଲିଡର୍ସ ଏବଂ କମ୍ୟୁନିଟି ସପୋର୍ଟ ଷ୍ଟାଫ୍ (CSS) ଙ୍କର ଅବଦାନକୁ ଅସ୍ମିତା ଦେଇ, ରାଜ୍ୟ ସରକାର "ମିଶନ୍ ଶକ୍ତି ଫେଡେରେସନ୍ ଲିଡର୍ସ ଏବଂ କମ୍ୟୁନିଟି ସପୋର୍ଟ ଷ୍ଟାଫଙ୍କ ପାଇଁ ସ୍କୁଟର କିଣିବା ପାଇଁ ସୁଧମୁକ୍ତ ଋଣ" ଘୋଷଣା କରିଛନ୍ତି।',
      DownPayment:
        "ଏହି ଯୋଜନାର ଅଧୀନରେ, ହିତାଧିକାରୀମାନେ ନିଜ ପସନ୍ଦର ସ୍କୁଟର (ଇଲେକ୍ଟ୍ରିକ/ପେଟ୍ରୋଲ/ହାଇବ୍ରିଡ) କିଣିବା ପାଇଁ Rs.1 ଲକ୍ଷ ଟଙ୍କା ପର୍ଯ୍ୟନ୍ତ ସୁଧମୁକ୍ତ ବ୍ୟାଙ୍କ ଋଣ ପାଇବେ, ଯାହାର ଅଧିକତମ ପରିଶୋଧ ଅବଧି 4 ବର୍ଷ (48 ମାସ) ହେବ।  ହିତାଧିକାରୀମାନେ Rs.1 ଲକ୍ଷ ପର୍ଯ୍ୟନ୍ତ ସ୍କୁଟର ଋଣ ଉପଭୋଗ କରିବା ପାଇଁ କୌଣସି ମାର୍ଜିନ ଟଙ୍କା କିମ୍ବା ଡାଉନ୍ ପେମେଣ୍ଟ ଦେବାର ଆବଶ୍ୟକତା ନାହିଁ |",
      LoanDocument:
        "ଯୋଗ୍ୟ ହିତାଧିକାରୀଙ୍କ ଦ୍ bank ାରା ବ୍ୟାଙ୍କ ଶାଖାଗୁଡ଼ିକରେ ପର୍ଯ୍ୟାପ୍ତ ସଂଖ୍ୟକ ଋଣ ଆବେଦନ ଦାଖଲ କରିବା ଅତ୍ୟନ୍ତ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ। ଏହି ସଂପର୍କରେ ବ୍ୟାଙ୍କଗୁଡ଼ିକ ସହିତ ପରାମର୍ଶ କରି ଏକ ସାଧାରଣ ଋଣ ଆବେଦନ ଫର୍ମ ପ୍ରସ୍ତୁତ କରାଯାଇଛି। ମିଶନ୍ ଶକ୍ତିର କ୍ଷେତ୍ର ଅଧିକାରୀମାନେ ଲୋଣ ଦଲିଲଗୁଡ଼ିକୁ ଯାଞ୍ଚ କରିବେ ଓ ଏହାକୁ ବ୍ଲକ୍ ଡେଭଲପମେଣ୍ଟ୍ ଅଫିସର (BDO)/ଶିଶୁ ବିକାଶ ପ୍ରକଳ୍ପ ଅଧିକାରୀ (CDPO) ଦ୍ୱାରା ସୁପାରିଶ କରାଯିବ।",
      ViewAbove:
        "ଉପରୋକ୍ତ ବିଷୟକୁ ଦୃଷ୍ଟିରେ ରଖି, କ୍ଷେତ୍ର ଅଧିକାରୀମାନଙ୍କୁ ଶୀଘ୍ର କାର୍ଯ୍ୟ କରିବା ପାଇଁ ଏବଂ ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ସମସ୍ତ ଯୋଗ୍ୟ ହିତାଧିକାରୀ ଏହି ନୂତନ ଯୋଜନା ଅଧୀନରେ ଅନ୍ତର୍ଭୁକ୍ତ |",
      FormationandStrengthening:
        "ସ୍ୱୟଂସାହାୟକ ଗୋଷ୍ଠୀ (SHG) ଗଠନ ଓ ଶକ୍ତିଶାଳୀ କରିବା: 6 ଲକ୍ଷରୁ ଅଧିକ SHG ଗଠିତ ହୋଇଛି, ଯାହାରେ 70 ଲକ୍ଷରୁ ଅଧିକ ମହିଳା ଅଂଶଗ୍ରହଣ କରୁଛନ୍ତି, ଯାହା ସମୁହିକ ସଞ୍ଚୟ ଏବଂ ଆର୍ଥିକ ସଶକ୍ତିକରଣକୁ ସମ୍ବଲ ଦେଇଛି ।",
      SkillDevelopment:
        "ଦକ୍ଷତା ବିକାଶ: କୃଷି, ବୟନଶିଳ୍ପ ଏବଂ ହସ୍ତଶିଳ୍ପ ପରି ବିଭିନ୍ନ କ୍ଷେତ୍ରରେ ମହିଳାମାନଙ୍କର ନିଯୁକ୍ତି ଯୋଗ୍ୟତା ବଢ଼ାଇବା ପାଇଁ ପ୍ରଶିକ୍ଷଣ କାର୍ଯ୍ୟକ୍ରମ ପ୍ରଦାନ କରାଯାଉଛି।",
      FinancialResources:
        "ଆର୍ଥିକ ସମ୍ବଳ ପ୍ରାପ୍ତି: ମିଶନ୍ ଶକ୍ତି ଋଣ ମାଧ୍ୟମରେ ମାଇକ୍ରୋଫାଇନାନ୍ସ ଓ କ୍ରେଡିଟ୍ ଲିଙ୍କେଜ୍କୁ ସୁବିଧା ଦେଇଛି, ଯେଉଁଠାରେ ଛୋଟ ବ୍ୟବସାୟ ତଥା ସ୍କୁଟର ଯୋଜନା ପରି ପଦକ୍ଷେପ ପାଇଁ ସ୍ୱଳ୍ପ ସୁଧ ପ୍ରଦାନ କରିଥାଏ।",
      LivelihoodSupport:
        "ଉଦ୍ୟୋଗୀ ଏବଂ ଜୀବିକା ସହାୟତା: ମହିଳାମାନଙ୍କୁ ଆର୍ଥିକ ସହାୟତା, ବଜାର ସଂଯୋଗ ଓ ବ୍ୟବସାୟ ବିକାଶ ସେବା ଯୋଗାଇ ମାଇକ୍ରୋ ଉଦ୍ୟୋଗ ଏବଂ କାରୋବାର ସ୍ଥାପନ କରିବାକୁ ସମର୍ଥ କରାଯାଉଛି।",
      LeadershipandDecisionMakingEmpowerment:
        "ନେତୃତ୍ୱ ଏବଂ ନିଷ୍ପତ୍ତି-ସଶକ୍ତିକରଣ: ସମ୍ପ୍ରଦାୟର ଶାସନ, ସ୍ଥାନୀୟ ନିଷ୍ପତ୍ତି ନେବା ଓ ସାର୍ବଜନିକ ସେବାରେ ମହିଳାମାନଙ୍କ ଅଂଶଗ୍ରହଣକୁ ବଢ଼ାଇବା ପାଇଁ ପ୍ରଶିକ୍ଷଣ କାର୍ଯ୍ୟକ୍ରମ ଆୟୋଜନ କରାଯାଉଛି।",
      DigitalLiteracy:
        "ଡିଜିଟାଲ୍ ସାକ୍ଷରତା ଏବଂ ଆର୍ଥିକ ସାମାବେଶ: ମହିଳାମାନଙ୍କୁ ଡିଜିଟାଲ୍ ବ୍ୟାଙ୍କିଂ, ଡିଜିଟାଲ୍ ପେମେଣ୍ଟ ଓ ଅନଲାଇନ୍ ଆର୍ଥିକ ସେବାଗୁଡ଼ିକରେ ମହିଳାଙ୍କ ପ୍ରବେଶକୁ ବଢ଼ାଇ ତାଙ୍କୁ ଆନୁଷ୍ଠାନିକ ଆର୍ଥିକ କ୍ଷେତ୍ରରେ ସାମିଲ କରିବାକୁ ପ୍ରୋତ୍ସାହିତ କରାଯାଉଛି।",
      login: {
        "Sign In": "ଆପଣଙ୍କ ଆକାଉଣ୍ଟକୁ ଲଗିନ୍‌ କରନ୍ତୁ",
        "Forgot Password?": "ପାସ୍‌ୱର୍ଡ ଭୁଲିଯାଇଛି?",
        Username: "ମୋବାଇଲ୍ ନମ୍ବର କିମ୍ବା ବ୍ୟବହାରକାରୀ ନାମ",
        Password: "ପାସ୍‌ୱର୍ଡ",
        "UserName Place": "ଆପଣଙ୍କର ମୋବାଇଲ୍ ନମ୍ବର କିମ୍ବା ଚାଳକନାମ ପ୍ରବେଶ କରନ୍ତୁ",
        "Password Place": "ଆପଣଙ୍କର ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ",
        Submit: "ଦାଖଲ କରନ୍ତୁ",
        Update: "ଅପଡେଟ୍",
        Cancel: "ବାତିଲ୍ କରନ୍ତୁ",
        NameRequired: "ମୋବାଇଲ୍ ନମ୍ବର କିମ୍ବା ବ୍ୟବହାରକାରୀ ନାମ ଦରକାର",
        PasswordRequired: "ପାସ୍‌ଓ୍ୱାର୍ଡ ଦରକାର",
        newUser: "ନୂତନ ବ୍ୟବହାରକାରୀ ? ",
        registerHere: "ଏଠାରେ ପଞ୍ଜିକରଣ କର",
      },
      Register: {
        Register1: "ପଂଜୀକରଣ କରନ୍ତୁ",
        AadharName: "ଆଧାର ଅନୁଯାୟୀ ନାମ ଦିଅ",
        AadharNo: "ଆଧାର ସଂଖ୍ୟା",
        MobileNo: "ମୋବାଇଲ୍ ନମ୍ବର",
        Uname: "ଉପଯୋଗକର୍ତ୍ତା ନାମ",
        "Uname Place": "ଆପଣଙ୍କର ଉପଯୋଗକର୍ତ୍ତା ନାମ ପ୍ରବେଶ କରନ୍ତୁ",
        "UserName Place":
          "ଆପଣଙ୍କର  ମୋବାଇଲ୍ ନମ୍ବର / ଉପଯୋଗକର୍ତ୍ତା ନାମ ପ୍ରବେଶ କରନ୍ତୁ",
        "Password Place": "ଆପଣଙ୍କର ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ",
        Submit: "ଦାଖଲ କରନ୍ତୁ",
        Cancel: "ବାତିଲ୍ କରନ୍ତୁ",
        AlreadyAccount: "ପୂର୍ବରୁ ଏକ ଖାତା ଅଛି କି? ଲଗଇନ୍ କରନ୍ତୁ",
        SHG_Id: "ଏସଏଚଜି ପରିଚୟ",
      },
      Forgot: {
        "Forgot Password": "ପାସ୍‌ୱର୍ଡ ଭୁଲିଯାଇଛି?",
        MobileNo: "ମୋବାଇଲ୍ ନମ୍ବର",
        "UserName Place": "Enter your Mobile Number or Username",
        "Password Place": "Enter your Password",
        Submit: "ଦାଖଲ କରନ୍ତୁ",
        Cancel: "ବାତିଲ୍ କରନ୍ତୁ",
      },
      Table: {
        "Token ID": "ଟୋକେନ୍ ID",
        "Date of Application": "ଆବେଦନ ତାରିଖ",
        "Name of Beneficiary": "ହିତାଧିକାରୀଙ୍କ ନାମ",
        "Panchayat/Jurisdiction": "ପଞ୍ଚାୟତ / ଅଧିକାର",
        Status: "ସ୍ଥିତି",
        "Entries found": "ଏଣ୍ଟ୍ରିଗୁଡିକ ମିଳିଲା",
        Action: "କାର୍ଯ୍ୟ",
        SEARCH_BY: "SEARCH_BY",
        Sorry: "ଦୁଖିତ, ଆମେ କଣସି ଫଳାଫଳ ପାଇଲୁ ନାହିଁ",
        Search: "ଖୋଜ",
        MyWorkList: "ମୋର କାର୍ଯ୍ୟ ତାଲିକା",
        "WorkList Applications": "କାମ ତାଲିକା ଆବେଦନ",
        SUBVENTION_TABLE_HEADER: "ସୁଧ ସହାୟତା",
        "Loan ID": "ଋଣ ID",
        "Application TokenID": "ଆବେଦନ ଟୋକେନ ID",
        District: "ଜିଲ୍ଲା",
        "SHG ID": "ଏସଏଚଜି ପରିଚୟ",
        "SHG Name": "SHG ନାମ",
        "Beneficiary Name": "ହିତାଧିକାରୀ ନାମ",
        "Contact No.": "ଯୋଗାଯୋଗ ନମ୍ବର ",
        "Aadhaar No.": "ଆଧାର ନଂ",
        SHGList: "SHG ତାଲିକା",
        Block: "ବ୍ଲକ୍",
        Panchayat: "ପଞ୍ଚାୟତ",
        "ICDS Project Name": "ICDS ପ୍ରକଳ୍ପ ନାମ",

        Others: "ଅନ୍ୟାନ୍ୟ",
        News: "News & Updates",
        Feedback: "ମତାମତ",
        "Sl#": "Sl#",
        Subject: "ବିଷୟ",
        "Feedback Messsage": "ମତାମତ ବାର୍ତ୍ତା",
        "Sent By": "ପ୍ରେରକ",
        "Sent On": "ପ୍ରେରଣ ତାରିଖ",
        Email: "ଇମେଲ୍ ଠିକଣା",
        Phone: "ଫୋନ୍ ନମ୍ବର",
        "Interest Amount": "Interest Amount",
        "Outstanding Amount": "Outstanding Amount",
        "OverDue Amount": "OverDue Amount",
        "NPA Count": "NPA Count",
        News: "ଖବର ଓ ଅଦ୍ୟତନ",
        "News Details": "ସମ୍ବାଦ ବିବରଣୀ",
        "News & Updates": "ସମ୍ବାଦ ଏବଂ ଅଦ୍ୟତନଗୁଡିକ",
        Id: "Id",
        Date: "ତାରିଖ",
        FeedbackList: "ମତାମତ ତାଲିକା",
        feedback: "ମତାମତ",
        newsDetails: "ସମ୍ବାଦ ବିବରଣୀ / ବିଷୟବସ୍ତୁ",
        pubDate: "ପ୍ରକାଶିତ ତାରିଖ",
        UserName: "ବ୍ୟବହାରକାରୀ ନାମ",
        NameAadhar: "ଆଧାର ଅନୁସାରେ ନାମ",
        Role: "ଭୂମିକା",
        "ContactNo.": "ମୋବାଇଲ୍ ନମ୍ବର",
        "AadhaarNo.": "ଆଧାର ନମ୍ବର",
        Address: "ଠିକଣା",
        "Mobile No.": "ମୋବାଇଲ୍ ନମ୍ବର",
        IdentificationMark: "ପରିଚୟ ଚିହ୍ନ",
      },
      routeNames: {
        Home: "ହୋମ୍",
        "Profile Details": "ପ୍ରୋଫାଇଲ୍ ଦେଖନ୍ତୁ",
        "Update Profile": "ପ୍ରୋଫାଇଲ୍ ସଂପାଦନ",
        "Change Password": "ପାସୱାର୍ଡ ବଦଳାନ୍ତୁ",
        "Block Official Home": "ହୋମ୍",
        "Beneficiary Applications": "ହିତାଧିକାରୀ ପ୍ରୟୋଗଗୁଡ଼ିକ",
        "CDPO Home": "ହୋମ୍",
        "BDO Home": "ହୋମ୍",
        "Application Form": "ଆବେଦନ ଫର୍ମ",
        "Loan Bank Home": "ଋଣ ବ୍ୟାଙ୍କ ହୋମ୍",
        "Controlling Bank Home": "ନିୟନ୍ତ୍ରଣ ବ୍ୟାଙ୍କ ହୋମ୍",
        "Track My Application": "ମୋ ଆବେଦନକୁ ଟ୍ରାକ୍ କରନ୍ତୁ",
        "Interest SubVention": "ସ୍କୁଟର ଯୋଜନା ପାଇଁ ସୁଧ ସବଭେନ୍ସନ୍",
        "SHG Details": "SHG ବିବରଣୀଗୁଡିକ",
        "View Feedback": "ମତାମତ ଦେଖନ୍ତୁ",
        "Interest Subvention": "ସ୍କୁଟର ଯୋଜନା ପାଇଁ ସୁଧ ସବଭେନ୍ସନ୍",
        "Interest Subvention Reports":
          "ସ୍କୁଟର ଯୋଜନା ପାଇଁ ସୁଧ ସବଭେନ୍ସନ୍ - ରିପୋର୍ଟ",
        "News & Updates": "ସମ୍ବାଦ ଏବଂ ଅଦ୍ୟତନଗୁଡିକ",
        "User Management": "ବ୍ୟବହାରକାରୀ ପରିଚାଳନା",
       "UploadBackLogData" : "ବ୍ୟାକ୍ଲଗ୍ ଡାଟା ଅପଲୋଡ୍ କରନ୍ତୁ"
        // Add more routes as needed
      },
      PrivateHeader: {
        "Mission Shakti": "ମିଶନ୍ ଶକ୍ତି",
        "Goverment of Odisha": "ଓଡିଶା ସରକାର",
        "Scooter Yojana": "ସ୍କୁଟର ଯୋଜନା",
        Home: "ହୋମ୍",
        "My Application": "ମୋର ଆବେଦନ",
        ApplyforMSSYLoan: "MSSY ଋଣ ପାଇଁ ଆବେଦନ କରନ୍ତୁ",
        ViewApplication: "ଆବେଦନ ଦେଖନ୍ତୁ",
        DownloadApplicationForm: "ଆବେଦନ ଫର୍ମ ଡାଉନଲୋଡ୍ କରନ୍ତୁ",
        Profile: "ପ୍ରୋଫାଇଲ୍",
        "My Profile": "ମୋର ପ୍ରୋଫାଇଲ୍",
        "View Profile": "ପ୍ରୋଫାଇଲ୍ ଦେଖନ୍ତୁ",
        "Edit Profile": "ପ୍ରୋଫାଇଲ୍ ସଂପାଦନ କରନ୍ତୁ",
        "Change Password": "ପାସୱାର୍ଡ ପରିବର୍ତ୍ତନ କରନ୍ତୁ",
        Logout: "ପ୍ରସ୍ଥାନ କର",
        "Application Management": "ପ୍ରୟୋଗ ପରିଚାଳନା",
        "Beneficiary Applications": "ହିତାଧିକାରୀ ପ୍ରୟୋଗଗୁଡ଼ିକ",
        "Check Eligibility": "ଯୋଗ୍ୟତା ଯାଞ୍ଚ",
        "Issue Recommendation Certificates":
          "ସୁପାରିଶ ସାର୍ଟିଫିକେଟ୍ ପ୍ରଦାନ କରନ୍ତୁ",
        "Forward Applications": "ଅଗ୍ରଗାମୀ ପ୍ରୟୋଗଗୁଡ଼ିକ",
        "Track Applications": "ଆବେଦନକୁ ଟ୍ରାକ୍ କରନ୍ତୁ",
        Master: "ମାଷ୍ଟର",
        "User Management": "ବ୍ୟବହାରକାରୀ ପରିଚାଳନା",
        Calculations: "ଗଣନା",
        Reports: "ରିପୋର୍ଟଗୁଡିକ",
        Others: "ଅନ୍ୟାନ୍ୟ",
      },
      ViewProfile: {
        Heading: "ପ୍ରୋଫାଇଲ୍ ସୂଚନା",
        DOB: "ଜନ୍ମ ତାରିଖ",
        Gender: "ଲିଙ୍ଗ",
        "Aadhar Number": "ଆଧାର ସଂଖ୍ୟା",
        "Pan Number": "ପାନ୍ ନଂ",
        Email: "ଇମେଲ୍",
        "Mobile No": "ମୋବାଇଲ୍ ନମ୍ବର",
        "Edit Profile": "ପ୍ରୋଫାଇଲ୍ ଅପଡେଟ୍ କରନ୍ତୁ",
        Cancel: "ବାତିଲ୍ କରନ୍ତୁ",
        Nationality: "ଜାତୀୟତା",
        VoterId: "ଭୋଟର ପରିଚୟ ସଂଖ୍ୟା",
        DrivingLicenceNo: "ଡ୍ରାଇଭିଂ ଲାଇସେନ୍ସ ନଂ",
        "Profile Photo": "ପ୍ରୋଫାଇଲ୍ ଫଟୋ",
        "Educational Qualification": "ଶିକ୍ଷାଗତ ଯୋଗ୍ୟତା",
        Address: "ଠିକଣା",
        "Guardian Relation": "ଅଭିଭାବକ ସମ୍ପର୍କ",
        "Guardian Name": "ଅଭିଭାବକ ନାମ",
        Name: "ଆଧାର ଅନୁଯାୟୀ ଆବେଦନକାରୀଙ୍କ ନାମ",
        UName: "ବ୍ୟବହାରକାରୀ ନାମ",
        Age: "ବୟସ",
        "No Photo": "ଫଟୋ ନାହିଁ",
      },
      EditProfile: {
        Heading: "ପ୍ରୋଫାଇଲ୍ ସଂପାଦନ",
        DOB: "ଜନ୍ମ ତାରିଖ",
        Gender: "ଲିଙ୍ଗ",
        "Aadhaar Number": "ଆଧାର ସଂଖ୍ୟା",
        "Pan Number": "ପାନ୍ ନଂ",
        Email: "ଇମେଲ୍",
        "Mobile No": "ମୋବାଇଲ୍ ନମ୍ବର",
        "Update Profile": "ପ୍ରୋଫାଇଲ୍ ଅପଡେଟ୍ କରନ୍ତୁ",
        "Update Password": "ପାସୱାର୍ଡ ଅଦ୍ୟତନ କରନ୍ତୁ",
        Cancel: "ବାତିଲ୍ କରନ୍ତୁ",
        Nationality: "ଜାତୀୟତା",
        VoterId: "ଭୋଟର ପରିଚୟ",
        DrivingLicenceNo: "ଡ୍ରାଇଭିଂ ଲାଇସେନ୍ସ ନଂ",
        "Profile Photo": "ପ୍ରୋଫାଇଲ୍ ଫଟୋ (upto 300kb)(jpg/png/jpeg)",
        "Educational Qualification": "ଶିକ୍ଷାଗତ ଯୋଗ୍ୟତା",
        Address: "ଠିକଣା",
        "Guardian Relation": "ଅଭିଭାବକ ସମ୍ପର୍କ",
        "Guardian Name": "ଅଭିଭାବକ ନାମ",
        Name: "ଆଧାର ଅନୁଯାୟୀ ଆବେଦନକାରୀଙ୍କ ନାମ",
        UName: "ବ୍ୟବହାରକାରୀ ନାମ",
        Age: "ବୟସ",
        "Alternate Number": "ବିକଳ୍ପ ମୋବାଇଲ୍ ନମ୍ବର",
        "Blood Group": "ବ୍ଲଡ୍ ଗ୍ରୁପ୍",
      },
      ChangePassword: {
        Heading: "ପାସୱାର୍ଡ ବଦଳାନ୍ତୁ",
        "Current Password": "ଏବେକାର ପାସୱାର୍ଡ଼",
        "New Password": "ନୁତନ ପାସ୍ୱାର୍ଡ",
        "Confirm New Password": "ନୂତନ ପାସୱାର୍ଡ ନିଶ୍ଚିତ କରନ୍ତୁ",
        "Update Password": "ପାସୱାର୍ଡ ଅଦ୍ୟତନ କରନ୍ତୁ",
        Cancel: "ବାତିଲ୍ କରନ୍ତୁ",
      },
      PersonalInfo: {
        Salution: "ନମସ୍କାର",
        "First Name": "ପ୍ରଥମ ନାମ",
        "Last Name": "ଶେଷ ନାମ",
        "Middle Name": "ମଧ୍ୟମ ନାମ",
        DOB: "ଜନ୍ମ ତାରିଖ",
        Gender: "ଲିଙ୍ଗ",
        userType: "ବ୍ୟବହାରକାରୀ ପ୍ରକାର",
        "Fathers Name": "ପିତାଙ୍କ ନାମ",
        "Husbands Name": "ସ୍ୱାମୀଙ୍କ ନାମ",
        Age: "ବୟସ",
        "YearsMonths Total": "ବର୍ଷ ଏବଂ ମାସ ସମୁଦାୟ",
        "Aadhar Number": "ଆଧାର ସଂଖ୍ୟା",
        "Pan Number": "ପାନ୍ ନଂ",
        Email: "ଇମେଲ୍",
        VoterId: "ଭୋଟର ପରିଚୟ ସଂଖ୍ୟା",
        DrivingLicenceNo: "ଡ୍ରାଇଭିଂ ଲାଇସେନ୍ସ ନଂ (ଯଦି ଥାଏ)",
        "Profile Photo": "ପ୍ରୋଫାଇଲ୍ ଫଟୋ (upto 300kb)(jpg/png/jpeg)",
        "Mobile No": "ମୋବାଇଲ୍ ନମ୍ବର",
        AadhaarName: "ଆଧାର ଅନୁଯାୟୀ ଆବେଦନକାରୀଙ୍କ ନାମ",
        "Basic Details": "ମୌଳିକ ବିବରଣୀ",
      },
      AddressInfo: {
        "Current Address": "ବର୍ତ୍ତମାନର ଠିକଣା",
        "Permanent Address": "ସ୍ଥାୟୀ ଠିକଣା",
        "HousePlot No": "ଘର/ପ୍ଲଟ୍ ନଂ",
        VillageTown: "ଗାଁ/ଟାଉନ୍",
        PostOffice: "ଡାକ ଘର",
        Panchayat: "ପଞ୍ଚାୟତ",
        Block: "ବ୍ଲକ",
        City: "ସହର",
        District: "ଜିଲ୍ଲା",
        "Pin Code": "ପିନ୍ କୋଡ୍",
        State: "ରାଜ୍ୟ",
        Nationality: "ଜାତୀୟତା",
        SameAddress: "ସ୍ଥାୟୀ ଠିକଣା ପରି ସମାନ",
      },
      MemberShip: {
        "Educational Qualification": "ଶିକ୍ଷାଗତ ଯୋଗ୍ୟତା",
        "Individual Type": "ବ୍ୟକ୍ତିଗତ ପ୍ରକାର",
        "Qualification Details": "ଯୋଗ୍ୟତା ବିବରଣୀ",
        "Membership Details": "ସଦସ୍ୟତା ବିବରଣୀ",
        "SHG ID": "ଏସଏଚଜି ପରିଚୟ",
        "Name of GPLF": "GPLF ର ନାମ",
        "Name of BLF": "BLF ର ନାମ",
        "Name of DLF": "DLF ର ନାମ",
        no_of_pd: "ବର୍ତ୍ତମାନର ପଦବୀରେ ମାସ ସଂଖ୍ୟା",
      },
      LoanDetails: {
        Heading1: "ଆବେଦନକାରୀଙ୍କ ପ୍ରାଥମିକ ଖାତା ବିବରଣୀ",
        "Account Number": "ଆକାଉଣ୍ଟ୍ ନମ୍ବର",
        "Account Placeholder": "ଆପଣଙ୍କର ଆକାଉଣ୍ଟ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ",
        "IFSC Code": "IFSC କୋଡ୍",
        "IFSC Placeholder": "ଆପଣଙ୍କର IFSC କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ",
        "Bank Name": "ବ୍ୟାଙ୍କ ନାମ",
        "Bank Placeholder": "ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ନାମ ପ୍ରବେଶ କରନ୍ତୁ",
        "Branch Name": "ଶାଖା ନାମ",
        "Branch Placeholder": "ଆପଣଙ୍କର ଶାଖା ନାମ ପ୍ରବେଶ କରନ୍ତୁ",
        "Customer ID": "ଗ୍ରାହକ ID",
        "ID Placeholder": "ଆପଣଙ୍କର ଗ୍ରାହକ ID ପ୍ରବେଶ କରନ୍ତୁ",
        "Age of Bank": "ବ୍ୟାଙ୍କ ସହିତ ବ୍ୟାଙ୍କିଙ୍ଗ୍ ସମ୍ପର୍କର ବୟସ",
        "BankAge Placeholder":
          "ବ୍ୟାଙ୍କ ସହିତ ଆପଣଙ୍କର ବ୍ୟାଙ୍କିଙ୍ଗ୍ ସମ୍ପର୍କର ବୟସ ପ୍ରବେଶ କରନ୍ତୁ",
        Heading2: "ବିଦ୍ୟମାନ ଋଣ ବିବରଣୀ (ଯଦି ଥାଏ)",
        "Loan Type": "ଋଣ ପ୍ରକାର",
        "LoanType Placeholder": "ଆପଣଙ୍କର ଋଣ ପ୍ରକାର ଚୟନ କରନ୍ତୁ",
        "Loan Amount": "ଋଣ ପରିମାଣ (ଟଙ୍କା)",
        "Loan Amount Placeholder": "ଆପଣଙ୍କର ଋଣ ପରିମାଣ ପ୍ରବେଶ କରନ୍ତୁ(ଟଙ୍କା)",
        EMI: "ଇଏମଆଇ(ଟଙ୍କା)",
        "EMI Placeholder": "ଆପଣଙ୍କର ଇଏମଆଇ ପରିମାଣ ପ୍ରବେଶ କରନ୍ତୁ(ଟଙ୍କା)",
        "Outstanding Loan": "ଉଲ୍ଲେଖନୀୟ ଋଣ ରାଶି(ଟଙ୍କା)",
        "Outstanding Loan Placeholder": "ଉଲ୍ଲେଖନୀୟ ଋଣ ରାଶି ପ୍ରବେଶ କରନ୍ତୁ",
        Heading3: "ଆୟ ବିବରଣୀ",
        "Income Source": "ଦରମା/ସେଟିଂ ଫି/ପ୍ରୋତ୍ସାହନରୁ ଆୟ (ଟଙ୍କା)",
        "Income Source Placeholder": "ଆପଣଙ୍କର ଆୟ ଉତ୍ସ ପ୍ରବେଶ କରନ୍ତୁ",
        "Income From": "ଆୟ (ଜୀବିକା / ଅନ୍ୟାନ୍ୟ ସମ୍ବଳ)(ଟଙ୍କା)",
        "Income From Placeholder":
          "ଆପଣଙ୍କର ଆୟ (ଜୀବିକା / ଅନ୍ୟାନ୍ୟ ସମ୍ବଳ) ପ୍ରବେଶ କରନ୍ତୁ",
        "Total Income": "ମୋଟ ଆୟ (ଟଙ୍କା)",
        "Total Income Placeholder": "ଆପଣଙ୍କର ମୋଟ ଆୟ ପ୍ରବେଶ କରନ୍ତୁ",
      },
      VehicleDetails: {
        Manufacturer: "ଉତ୍ପାଦକ",
        "Manufacturer Placeholder": "ଉତ୍ପାଦକଙ୍କ ନାମ ଚୟନ କରନ୍ତୁ",
        "Vehicle Model": "ଯାନ ମଡେଲ୍",
        "Vehicle Placeholder": "ଆପଣଙ୍କର ଯାନ ମଡେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ",
        "Type/Class": "ଦୁଇ ଚକିର ପ୍ରକାର/ଶ୍ରେଣୀ",
        "Type/Class Placeholder": "ତୁମର ପ୍ରକାର/ଦୁଇ ଚକିଆ ଶ୍ରେଣୀର ପ୍ରବେଶ କର",
        "Manufacture Year": "ଉତ୍ପାଦନ ବର୍ଷ",
        "MY Placeholder": "ତୁମର ଉତ୍ପାଦନ ବର୍ଷ ପ୍ରବେଶ କର",
        "Vehicle Price": "ଯାନର ମୂଲ୍ୟ (ଏକ୍ସ-ସୋ ମୂଲ୍ୟ)(ଟଙ୍କା)",
        "Price Placeholder": "ଯାନର ମୂଲ୍ୟ ପ୍ରବେଶ କର",
        "Insurance Cost": "ବୀମା ମୂଲ୍ୟ ଯୋଡନ୍ତୁ (ଟଙ୍କା)",
        "Insurance Placeholder": "ଆପଣଙ୍କର ଯୋଗ ବୀମା ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ",
        "Registration Cost": "ପଞ୍ଜୀକରଣ ମୂଲ୍ୟ ଯୋଡନ୍ତୁ (ଟଙ୍କା)",
        "Registration Placeholder": "ଆପଣଙ୍କର ଯୋଗ ପଞ୍ଜୀକରଣ ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ",
        "Less Margin": "କମ୍ ମାର୍ଜିନ୍ ଟଙ୍କା (ଟଙ୍କା)",
        "LMM Placeholder": "କମ୍ ମାର୍ଜିନ୍ ଟଙ୍କା ପ୍ରବେଶ କରନ୍ତୁ",
        "Less Dealer": "କମ୍ ଡିଲର/ଉତ୍ପାଦକ ରିହାତି (ଟଙ୍କା)",
        "Less Dealer Placeholder": "ଆପଣଙ୍କର କମ୍ ଡିଲର/ଉତ୍ପାଦକ ରିହାତି ପ୍ରବେଶ କର",
        "Accessories Cost": "ଆକ୍ସେସୋରିଜ୍ ମୂଲ୍ୟ ଯୋଡନ୍ତୁ (Rs ରେ)",
        "Accessories Cost Placeholder":
          "ଆପଣଙ୍କର ଆକ୍ସେସୋରିଜ୍ ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ",
        "Loan Amount": "ଆବଶ୍ୟକ ଋଣ ପରିମାଣ (Rs ରେ)",
        "Loan Amount Placeholder": "ଆବଶ୍ୟକ ପରିମାଣର ଋଣ ପ୍ରବେଶ କର",
        "Name&Address": "ଶୋ’ରୁମ୍ / ଡିଲର / ବିକ୍ରେତାଙ୍କ ନାମ ଏବଂ ଠିକଣା",
        "Name&Address Placeholder":
          "ଶୋରୁମ୍ / ଡିଲର / ବିକ୍ରେତାଙ୍କ ନାମ ଏବଂ ଠିକଣା ପ୍ରବେଶ କରନ୍ତୁ",
        TIN: "ଶୋ’ରୁମ୍ / ଡିଲର / ବିକ୍ରେତାଙ୍କ TIN",
        "TIN Placeholder": "ଶୋ’ରୁମ୍ / ଡିଲର / ବିକ୍ରେତାଙ୍କର TIN ପ୍ରବେଶ କର",
        "Quotation no": "କୋଟେସନ୍ ନମ୍ବର",
        "Quotation Placeholder": "ଆପଣଙ୍କର କୋଟେସନ୍ ନଂ ପ୍ରବେଶ କରନ୍ତୁ",
        "Quotation Date": "ଉଦ୍ଧୃତ ତାରିଖ",
        "Quotation Date Placeholder": "ତାରିଖ ଚୟନ କରନ୍ତୁ",
        "Proposed Repayment": "ପ୍ରସ୍ତାବିତ ପରିଶୋଧ ସମୟ",
        "Proposed Repayment Placeholder":
          "ଆପଣଙ୍କର ପ୍ରସ୍ତାବିତ ମୋଡ୍ ଅଫ୍ ସିଲେକ୍ଟ କରନ୍ତୁ",
        "Monthly Installment": "ପ୍ରସ୍ତାବିତ ସମାନ ମାସିକ କିସ୍ତି (ଟଙ୍କା)",
        "Monthly Installment Placeholder":
          "ଆପଣଙ୍କର ପ୍ରସ୍ତାବିତ ସମାନ ମାସିକ କିସ୍ତି ପ୍ରବେଶ କର",
        "Repayment Mode": "ପରିଶୋଧର ପ୍ରସ୍ତାବିତ ମୋଡ୍",
        "Repayment Mode Placeholder":
          "ଆପଣଙ୍କର ପ୍ରସ୍ତାବିତ ମୋଡ୍ ଅଫ୍ ସିଲେକ୍ଟ କରନ୍ତୁ",
      },
      SupportingDocs: {
        Heading1: "ପରିଚୟ ପ୍ରମାଣ",
        Heading2: "ଠିକଣା / ବାସିନ୍ଦା ପ୍ରମାଣ",
        Heading3: "ଅତିରିକ୍ତ ଡକ୍ୟୁମେଣ୍ଟ୍",
        Aadhaar: "ଆଧାର",
        PAN: "ପାନ୍",
        Passport: "ପାସପୋର୍ଟ",
        VoterId: "ଭୋଟର ID",
        ElectricityBill: "ବିଦ୍ୟୁତ୍ ବିଲ୍",
        Telephone: "ଟେଲିଫୋନ୍",
        PropertyTax: "ସମ୍ପତ୍ତି କର",
        AnyOtherDocument:
          "ଯେକଣସି ଅନ୍ୟ ଡକ୍ୟୁମେଣ୍ଟ୍ (ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ) (pdf / docx)",
        OtherDocumentLabel: "ଅନ୍ୟ କଣସି ଡକ୍ୟୁମେଣ୍ଟ୍ ଅପଲୋଡ୍ କରନ୍ତୁ",
        QuotationDetailsLabel:
          "ଉଦ୍ଧୃତି ବିବରଣୀ (ଡିଲର / ବିକ୍ରେତାଙ୍କ ଠାରୁ) (pdf / docx)",
        RecentPhotoLabel: "ସାମ୍ପ୍ରତିକ ଫଟୋ (ଦୁଇଟି କପି) (jpg / jpeg / png)",
        PropertyTaxLabel: "ସମ୍ପତ୍ତି ଟ୍ୟାକ୍ସ ଅପଲୋଡ୍ କରନ୍ତୁ",
        AadhaarLabel: "ଆଧାର ଅପଲୋଡ୍ କରନ୍ତୁ",
        PANLabel: "ପାନ୍ ଅପଲୋଡ୍ କରନ୍ତୁ",
        TelephoneLabel: "ଟେଲିଫୋନ୍ ବିଲ୍ ଅପଲୋଡ୍ କରନ୍ତୁ",
        ElectricityBillLabel: "ବିଦ୍ୟୁତ୍ ବିଲ୍ ଅପଲୋଡ୍ କରନ୍ତୁ",
        PassportLabel: "ପାସପୋର୍ଟ ଅପଲୋଡ୍ କରନ୍ତୁ",
        VoterIdLabel: "ଭୋଟର ID ଅପଲୋଡ୍ କରନ୍ତୁ",
      },
      Declaration: {
        SignatureUploadLabel: "ଦସ୍ତଖତ ଅପଲୋଡ୍ କରନ୍ତୁ(jpg/png/jpeg)",
        Declaration1:
          "ମୁଁ ଏହା ଘୋଷଣା କରେ ଯେ ଏହି ପ୍ରୟୋଗରେ ଦିଆଯାଇଥିବା ସୂଚନା ସତ୍ୟ ଏବଂ ମୋ ଜ୍ଞାନ ଏବଂ ବିଶ୍ୱାସର ସଠିକ୍ ଅଟେ।",
        Declaration2: "ମୁଁ ଚୁକ୍ତିନାମାକୁ ସହମତ ଅଟେ।",
        Declaration3: "ମୁଁ ବୁଜିପାରୁଛି ଯେ କଣସି ମିଥ୍ୟା ତଥ୍ୟ ମୋର ଅଯୋଗ୍ୟତା ହୋଇପାରେ",
        Declaration4: "ପ୍ରଦାନ କରାଯାଇଥିବା ସୂଚନାର ଯାଞ୍ଚକୁ ମୁଁ ଅନୁମତି ଦିଏ",
        Declaration5: "ମୁଁ ମୋର ଆବେଦନ ସମ୍ବନ୍ଧରେ ଯୋଗାଯୋଗ ଗ୍ରହଣ କରିବାକୁ ରାଜି ଅଟେ",
        Date: "ତାରିଖ",
        Place: "ସ୍ଥାନ",
      },
      Preview: {
        SignatureUploadLabel: "ଦସ୍ତଖତ ଅପଲୋଡ୍ କରନ୍ତୁ(jpg/png/jpeg)",
        Declaration1:
          "ମୁଁ ଏହା ଘୋଷଣା କରେ ଯେ ଏହି ପ୍ରୟୋଗରେ ଦିଆଯାଇଥିବା ସୂଚନା ସତ୍ୟ ଏବଂ ମୋ ଜ୍ଞାନ ଏବଂ ବିଶ୍ୱାସର ସଠିକ୍ ଅଟେ।",
        Declaration2: "ମୁଁ ଚୁକ୍ତିନାମାକୁ ସହମତ ଅଟେ।",
        Declaration3: "ମୁଁ ବୁଜିପାରୁଛି ଯେ କଣସି ମିଥ୍ୟା ତଥ୍ୟ ମୋର ଅଯୋଗ୍ୟତା ହୋଇପାରେ",
        Declaration4: "ପ୍ରଦାନ କରାଯାଇଥିବା ସୂଚନାର ଯାଞ୍ଚକୁ ମୁଁ ଅନୁମତି ଦିଏ",
        Declaration5: "ମୁଁ ମୋର ଆବେଦନ ସମ୍ବନ୍ଧରେ ଯୋଗାଯୋଗ ଗ୍ରହଣ କରିବାକୁ ରାଜି ଅଟେ",
        Date: "ତାରିଖ",
        Place: "ସ୍ଥାନ",
        Declaration: "ଘୋଷଣା",
        Yes: "ହଁ",
        No: "ନା",
        Preview: "ପୂର୍ବାବଲୋକନ",

        Heading1: "ପରିଚୟ ପ୍ରମାଣ",
        Heading2: "ଠିକଣା / ବାସିନ୍ଦା ପ୍ରମାଣ",
        Heading3: "ଅତିରିକ୍ତ ଡକ୍ୟୁମେଣ୍ଟ୍",
        Aadhaar: "ଆଧାର",
        PAN: "ପାନ୍",
        Passport: "ପାସପୋର୍ଟ",
        VoterId: "ଭୋଟର ID",
        ElectricityBill: "ବିଦ୍ୟୁତ୍ ବିଲ୍",
        Telephone: "ଟେଲିଫୋନ୍",
        PropertyTax: "ସମ୍ପତ୍ତି କର",
        AnyOtherDocument:
          "ଯେକଣସି ଅନ୍ୟ ଡକ୍ୟୁମେଣ୍ଟ୍ (ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ) (pdf / docx)",
        OtherDocumentLabel: "ଅନ୍ୟ କଣସି ଡକ୍ୟୁମେଣ୍ଟ୍ ଅପଲୋଡ୍ କରନ୍ତୁ",
        QuotationDetailsLabel:
          "ଉଦ୍ଧୃତି ବିବରଣୀ (ଡିଲର / ବିକ୍ରେତାଙ୍କ ଠାରୁ) (pdf / docx)",
        RecentPhotoLabel: "ସାମ୍ପ୍ରତିକ ଫଟୋ (ଦୁଇଟି କପି) (jpg / jpeg / png)",
        PropertyTaxLabel: "ସମ୍ପତ୍ତି ଟ୍ୟାକ୍ସ ଅପଲୋଡ୍ କରନ୍ତୁ",
        AadhaarLabel: "ଆଧାର ଅପଲୋଡ୍ କରନ୍ତୁ",
        PANLabel: "ପାନ୍ ଅପଲୋଡ୍ କରନ୍ତୁ",
        TelephoneLabel: "ଟେଲିଫୋନ୍ ବିଲ୍ ଅପଲୋଡ୍ କରନ୍ତୁ",
        ElectricityBillLabel: "ବିଦ୍ୟୁତ୍ ବିଲ୍ ଅପଲୋଡ୍ କରନ୍ତୁ",
        PassportLabel: "ପାସପୋର୍ଟ ଅପଲୋଡ୍ କରନ୍ତୁ",
        VoterIdLabel: "ଭୋଟର ID ଅପଲୋଡ୍ କରନ୍ତୁ",
        "Supporting Documents": "ସମର୍ଥନ ଡକ୍ୟୁମେଣ୍ଟ୍",

        Manufacturer: "ଉତ୍ପାଦକ ନାମ",
        "Vehicle Model": "ଯାନ ମଡେଲ୍",
        "Type/Class": "ପ୍ରକାର/ଶ୍ରେଣୀ",
        "Manufacture Year": "ଉତ୍ପାଦନ ବର୍ଷ",
        "Vehicle Price": "ଏକ୍ସ-ଶୋ’ରୁମ୍ ମୂଲ୍ୟ",
        "Insurance Cost": "ବୀମା ମୂଲ୍ୟ",
        "Registration Cost": "ପଞ୍ଜୀକରଣ ମୂଲ୍ୟ",
        "Less Margin": "କମ୍ ମାର୍ଜିନ୍ ଟଙ୍କା",
        "Less Dealer": "କମ୍ ଡିଲର/ଉତ୍ପାଦକ ରିହାତି",
        "Accessories Cost": "ଆକ୍ସେସୋରିଜ୍ ମୂଲ୍ୟ",
        "Loan Amount": "ଆବଶ୍ୟକ ଋଣ ପରିମାଣ",
        "Name&Address": "ଶୋ’ରୁମ୍ ନାମ ଏବଂ ଠିକଣା",
        TIN: "TIN ନମ୍ବର",
        "Quotation no": "କୋଟେସନ୍ ନମ୍ବର",
        "Quotation Date": "ଉଦ୍ଧୃତ ତାରିଖ",
        "Proposed Repayment": "ପ୍ରସ୍ତାବିତ ପରିଶୋଧ ସମୟ",
        "Monthly Installment": "ପ୍ରସ୍ତାବିତ ସମାନ ମାସିକ କିସ୍ତି",
        "Repayment Mode": "ପରିଶୋଧର ପ୍ରସ୍ତାବିତ ମୋଡ୍",
        "Vehicle Information": "ଯାନ ସୂଚନା",

        Heading1: "ଆବେଦନକାରୀଙ୍କ ପ୍ରାଥମିକ ଖାତା ବିବରଣୀ",
        "Account Number": "ଆକାଉଣ୍ଟ୍ ନମ୍ବର",
        "IFSC Code": "IFSC କୋଡ୍",
        "Bank Name": "ବ୍ୟାଙ୍କ ନାମ",
        "Branch Name": "ଶାଖା ନାମ",
        "Customer ID": "ଗ୍ରାହକ ID",
        "Age of Bank": "ବ୍ୟାଙ୍କ ସହିତ ବ୍ୟାଙ୍କିଙ୍ଗ୍ ସମ୍ପର୍କର ବୟସ",
        Heading2: "ବିଦ୍ୟମାନ ଋଣ ବିବରଣୀ",
        "Loan Type": "ଋଣ ପ୍ରକାର",
        "Loan Amount": "ଋଣ ପରିମାଣ",
        EMI: "ଇଏମଆଇ",
        "Outstanding Loan": "ଉଲ୍ଲେଖନୀୟ ଋଣ ରାଶି",
        Heading3: "ଆୟ ବିବରଣୀ",
        "Income Source": "ଦରମା ଠାରୁ ଆୟ",
        "Income From": "ଆୟ (ଜୀବିକା / ଅନ୍ୟାନ୍ୟ ସମ୍ବଳ)",
        "Total Income": "ମୋଟ ଆୟ",
        "Loan Details": "ଋଣ ବିବରଣୀ",
        "Other Income": "ଅନ୍ୟାନ୍ୟ ଆୟ",

        "Educational Qualification": "ଶିକ୍ଷାଗତ ଯୋଗ୍ୟତା",
        "Individual Type": "ବ୍ୟକ୍ତିଗତ ପ୍ରକାର",
        "Qualification Details": "ଯୋଗ୍ୟତା ବିବରଣୀ",
        "Membership Details": "ସଦସ୍ୟତା ବିବରଣୀ",
        "SHG ID": "ଏସଏଚଜି ପରିଚୟ",
        "Name of GPLF": "GPLF ର ନାମ",
        "Name of BLF": "BLF ର ନାମ",
        "Name of DLF": "DLF ର ନାମ",
        no_of_pd: "ବର୍ତ୍ତମାନର ପଦବୀରେ ମାସ ସଂଖ୍ୟା",

        "First Name": "ପ୍ରଥମ ନାମ",
        "Last Name": "ଶେଷ ନାମ",
        "Middle Name": "ମଧ୍ୟମ ନାମ",
        DOB: "ଜନ୍ମ ତାରିଖ",
        Gender: "ଲିଙ୍ଗ",
        "Fathers Name": "ପିତାଙ୍କ ନାମ",
        "Husbands Name": "ସ୍ୱାମୀଙ୍କ ନାମ",
        Age: "ବୟସ",
        "YearsMonths Total": "ବର୍ଷ ଏବଂ ମାସ ସମୁଦାୟ",
        "Aadhar Number": "ଆଧାର ସଂଖ୍ୟା",
        "Pan Number": "ପାନ୍ ନଂ",
        Email: "ଇମେଲ୍",
        VoterId: "ଭୋଟର ପରିଚୟ ସଂଖ୍ୟା",
        DrivingLicenceNo: "ଡ୍ରାଇଭିଂ ଲାଇସେନ୍ସ ନଂ",
        "Profile Photo": "ପ୍ରୋଫାଇଲ୍ ଫଟୋ (upto 300kb)(jpg/png/jpeg)",
        "Mobile No": "ମୋବାଇଲ୍ ନମ୍ବର",
        AadhaarName: "ଆଧାର ଅନୁଯାୟୀ ଆବେଦନକାରୀଙ୍କ ନାମ",

        "Current Address": "ବର୍ତ୍ତମାନର ଠିକଣା",
        "Permanent Address": "ସ୍ଥାୟୀ ଠିକଣା",
        "HousePlot No": "ଘର/ପ୍ଲଟ୍ ନଂ",
        VillageTown: "ଗାଁ/ଟାଉନ୍",
        PostOffice: "ଡାକ ଘର",
        Panchayat: "ପଞ୍ଚାୟତ",
        Block: "ବ୍ଲକ",
        City: "ସହର",
        District: "ଜିଲ୍ଲା",
        "Pin Code": "ପିନ୍ କୋଡ୍",
        State: "ରାଜ୍ୟ",
        Nationality: "ଜାତୀୟତା",
        SameAddress: "ସ୍ଥାୟୀ ଠିକଣା ପରି ସମାନ",
        "Personal Information": "ବ୍ୟକ୍ତିଗତ ସୂଚନା",
        ApplicationNumber: "ଆବେଦନ ସଂଖ୍ୟା",
        "Address Information": "ଠିକଣା ସୂଚନା",
      },
      TrackApplication: {
        ApplicationStatusTracking: "ଆବେଦନ ସ୍ଥିତି ଟ୍ରାକିଂ",
        ApplicationNumber: "ଆବେଦନ ସଂଖ୍ୟା",
        "Application Submitted": "ଆବେଦନ ଉପସ୍ଥାପିତ",
        BPCVerified: "BPC ଯାଞ୍ଚ କରାଯାଇଛି",
        CDPOVerified: "CDPO ଯାଞ୍ଚ ହୋଇଛି",
        BDOApproved: "BDO ଅନୁମୋଦିତ",
        ControllingBankApproved: "ନିୟନ୍ତ୍ରଣ ବ୍ୟାଙ୍କ ଅନୁମୋଦିତ",
        "Loan Approved": "ଋଣ ଅନୁମୋଦିତ",
      },
      feedback: {
        firstName: "ପ୍ରଥମ ନାମ",
        lastName: "ଶେଷ ନାମ",
        email: "ଇମେଲ୍",
        phone: "ଫୋନ୍ ନମ୍ବର",
        subject: "ବିଷୟ",
        feedback: "ମତାମତ",
        firstNameRequired: "First Name is required",
        lastNameRequired: "Last Name is required",
        emailRequired: "Email is required",
        phoneRequired: "Phone No. is required",
        subjectRequired: "Subject is required",
        feedbackRequired: "Feedback is required",
      },
    },
  },
};
const lan = localStorage.getItem("language");
i18n.use(initReactI18next).init({
  resources,
  lng: lan || "or",
  fallbackLng: lan || "or",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false, // Disable Suspense for now to control loading behavior
  },
});

export default i18n;
