import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import InputFile from "../../common/InputFile";
import { useTranslation } from "react-i18next";

const UploadBackLogData = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  const onFileChange = (selectedOption:any) => setFormData((prevstate:any) => ({
    ...prevstate,
    exlFile: selectedOption?.target?.files[0] || "",
  }));  


  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <form>
            <Row>
              <Col md={8}>
                <InputFile
                  type="file"
                  name="exlFile"
                  label={t("UploadFile")}
                  placeholder="Upload your file (.xlsx / CSV only)"
                  register={{
                    ...register("exlFile", {
                      required: "Please upload a (xlsx / csv) file",
                    }),
                  }}
                  errors={errors}
                  disabled={false}
                  required={true}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e: any) => onFileChange(e)}
                />
              </Col>

              <Col md={4} style={{ float: "right" }}>
                <Button
                  className="btn background-saffron"
                  type="submit"
                  style={{ width: "max-content", position: 'relative', top: '1.5rem' }}
                >
                  {t("Upload")}
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
};
export default UploadBackLogData;
