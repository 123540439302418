import React from "react";
import "./PreviewCard.css";
import { toTitleCase } from "../../utils/constants";
interface PreviewCardProps {
  title: string;
  value: string;
}

const PreviewCard: React.FC<PreviewCardProps> = ({ title, value }) => {
  return (
    <div className="previewContainer">
      <span className="title">{toTitleCase(title)}</span>
      <span className="value">{value ? value : "N/A"}</span>
    </div>
  );
};

export default PreviewCard;
