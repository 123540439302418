import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputText from "../../../common/InputText";

import InputMultiSelect from "../../../common/InputMultiSelect";
import { ToastContainer, toast } from "react-toastify";
import { CONSTANT_REGEX_SAMPLE } from "../../../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { postData, fileUpload } from "../../../../services/http.service";
import AddressInfo from "./AddressInfo";
import { useTranslation } from "react-i18next";
import Collapsible from "../../../common/Collapsible";
// import { useLanguageContext } from "../../../../LanguageContext";
import { userRoleName } from "../../../../utils/constants";

const PersonalInfo = ({ formData, setFormData, nextStep, userRole }) => {
  const addressData = React.useMemo(() => {
    if (
      formData.addressDetails &&
      Object.keys(formData?.addressDetails).length > 0
    ) {
      return formData.addressDetails;
    } else {
      return {
        currentHousePlotNumber: "",
        currentVillageTown: "",
        currentPostoffice: "",
        currentPanchayat: "",
        currentBlock: "",
        currentCity: "",
        currentDistrict: "",
        currentState: "Odisha",
        currentPinCode: "",
        nationality: "",
        permanentHousePlotNumber: "",
        permanentVillageTown: "",
        permanentPostoffice: "",
        permanentPanchayat: "",
        permanentBlock: "",
        permanentCity: "",
        permanentDistrict: "",
        permanentState: "Odisha",
        permanentPinCode: "",
        isSameAsPermanentAddress: false,
      };
    }
  }, [formData]);
  const applicationStatus =
    formData?.applicationBasicDetails?.applicationStatus;
  const [dataChange, setDataChange] = useState(false);
  let editable =
    userRole === userRoleName &&
    (applicationStatus === "Saved as Draft" || applicationStatus === undefined);

  const [addressDetails, setAddressDetails] = useState(addressData);
  let navigate = useNavigate();
  const { t } = useTranslation();

  
  const initialFormData = React.useMemo(() => {
    if (
      formData?.applicationBasicDetails &&
      Object.keys(formData?.applicationBasicDetails).length > 0
    ) {
      return formData.applicationBasicDetails;
    } else {
      return {
        applicantNameAsPerAadhar: "",
        fatherName: "",
        husbandName: "",
        dob: "",
        yearsMonthTotal: "",
        gender: "",
        aadharNumber: "",
        mobileNumber: "",
        voterIdNumber: "",
        panNumber: "",
        emailId: "",
        drivingLicenceNumber: "",
        firstName: "",
        lastName: "",
        middleName: "",
      };
    }
  }, [formData]);
  const [personalInfo, setPersonalInfo] = useState(initialFormData);
  useEffect(() => {
    if (formData?.applicationBasicDetails) {
      setPersonalInfo(formData.applicationBasicDetails);
    }
    if (formData?.addressDetails) {
      setAddressDetails(formData.addressDetails);
    }
 
    const localValues = JSON.parse(localStorage.getItem("UserDetails"));
    let Aadhar_number = localValues.aadharNumber;
    let NameAsPerAadhar = localValues.nameAsPerAadhar;
    if(!personalInfo.applicantNameAsPerAadhar){
      setPersonalInfo((prevstate) => ({
        ...prevstate,
        aadharNumber: Aadhar_number,
        applicantNameAsPerAadhar: NameAsPerAadhar,
      }));
    }
   
  }, [formData,personalInfo?.applicantNameAsPerAadhar]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: { ...personalInfo, ...addressDetails },
  });

  useEffect(() => {
    reset({ ...personalInfo, ...addressDetails });
  }, [personalInfo, addressDetails, reset]);

  const genderTypes = [
    { value: "female", label: "Female" },
    { value: "others", label: "Others" }
  ];

  useEffect(() => {
    let addressInfoChange =
      JSON.stringify(addressDetails) ===
      JSON.stringify(formData?.addressDetails);
    let personalInfoChange =
      JSON.stringify(personalInfo) ===
      JSON.stringify(formData?.applicationBasicDetails);
    if (!addressInfoChange || !personalInfoChange) {
      setDataChange(true);
    } else {
      setDataChange(false);
    }
  }, [
    addressDetails,
    personalInfo,
    formData?.addressDetails,
    formData?.applicationBasicDetails,
  ]);
  const handleGenderTypeChange = (selectedOption) => {
    const genderType = selectedOption.value;
    setPersonalInfo((prevstate) => ({ ...prevstate, gender: genderType }));
  };
  const validateFile = (profilePhotoId) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 300 * 1024; // 300kb
    if (personalInfo?.profilePhotoId) {
      return true;
    }

    if (!validTypes.includes(profilePhotoId[0].type)) {
      toast.error("Profile Photo should be in JPG, JPEG, or PNG format", {
        position: "top-right",
      });
      return "Profile Photo should be in JPG, JPEG, or PNG format";
    }

    if (profilePhotoId[0].size > maxSize) {
      toast.error("Profile Photo size should not exceed 300kb", {
        position: "top-right",
      });
      return "Profile Photo size should not exceed 300kb";
    }

    return true;
  };

  const onSubmit = async (data) => {
    const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
    const transformedData = {
      userInfo: userInfo,
      uuid: formData?.uuid || null,
      applicationNumber: formData?.applicationNumber || null,
      applicationBasicDetails: {
        ...personalInfo,
        isFinalSubmit: false,
      },
      addressDetails: { ...addressDetails },
      isUpdate: false,
    };
    try {
      const response = await postData(
        process.env.REACT_APP_CREATE_APPLICATION_SERVICE,
        transformedData
      );
      if (
        response.data &&
        response.data.status === "Successful" &&
        response.data.applicationDetails
      ) {
        setFormData((prevstate) => ({
          ...prevstate,
          uuid: response.data.uuid,
          applicationNumber: response.data.applicationNumber,
          applicationBasicDetails:
            response.data.applicationDetails.applicationBasicDetails[0],
          addressDetails: response.data.applicationDetails.addressDetails[0],
        }));
        toast.success("Personal Details Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        setTimeout(() => {
          nextStep();
        }, 2000);
      } else {
        toast.error("Failed to save data", {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error("Failed to save data", {
        position: "top-right",
      });
    }
  };

  const handleFormDetails = (event) => {
    setPersonalInfo((prevstate) => ({
      ...prevstate,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDate = (e) => {
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      return `${month}-${day}-${year}`;
    };

    const birthDate = new Date(e?.target?.value);
    let dobDate = formatDate(birthDate);
    const today = new Date();
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    const totalMonths = calculatedAge * 12 + m;
    // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //   calculatedAge--;
    // }
    // const totalMonths = calculatedAge * 12 + m;
    // if (calculatedAge < 18 || calculatedAge > 60) {
    //   toast.error("Age should be between 18 and 60", {
    //     position: "top-right",
    //   });
    // } else {
    //   setPersonalInfo((prevstate) => ({
    //     ...prevstate,
    //     age: calculatedAge,
    //     yearsMonthTotal: totalMonths,
    //     dob: dobDate,
    //   }));
    // }
    setPersonalInfo((prevstate) => ({
      ...prevstate,
      age: calculatedAge,
      yearsMonthTotal: totalMonths,
      dob: e?.target?.value,
    }));
  };
  const handleNext = () => {
    nextStep();
  };

  return (
    <div className="PersonalInfo">
      <ToastContainer />
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <form>
            <Collapsible title={t("Basic Details")}>
              <Row>
                <Col md={4}>
                  <InputText
                    key={personalInfo.applicantNameAsPerAadhar}
                    type="text"
                    name="nameAsPerAadhaar"
                    label={t("PersonalInfo.AadhaarName")}
                    placeholder={t("PersonalInfo.AadhaarName")}
                    register={{
                      ...register("applicantNameAsPerAadhar", {
                        required: "Please enter your  Name",
                      }),
                    }}
                    required={true}
                    disabled={personalInfo.applicantNameAsPerAadhar}
                    errors={errors}
                    onChange={handleFormDetails}
                    value={personalInfo.applicantNameAsPerAadhar}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    key={personalInfo.applicantNameAsPerAadhar}
                    type="text"
                    name="firstName"
                    required={true}
                    label={t("PersonalInfo.First Name")}
                    placeholder={t("PersonalInfo.First Name")}
                    register={{
                      ...register("firstName", {
                        required: "Please enter your firstName",
                      }),
                    }}
                    errors={errors}
                    onChange={handleFormDetails}
                    value={personalInfo.firstName}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    key={personalInfo.applicantNameAsPerAadhar}
                    type="text"
                    name="lastName"
                    required={true}
                    label={t("PersonalInfo.Last Name")}
                    placeholder={t("PersonalInfo.Last Name")}
                    register={{
                      ...register("lastName", {
                        required: "Please enter your lastName",
                      }),
                    }}
                    errors={errors}
                    onChange={handleFormDetails}
                    value={personalInfo.lastName}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    key={personalInfo.applicantNameAsPerAadhar}
                    type="text"
                    name="middleName"
                    label={t("PersonalInfo.Middle Name")}
                    placeholder={t("PersonalInfo.Middle Name")}
                    register={{
                      ...register("middleName"
                      //   , {
                      //   required: "Please enter your middleName",
                      //  }
                    ),
                    }}
                    errors={errors}
                    onChange={handleFormDetails}
                    value={personalInfo.middleName}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="fatherName"
                    label={t("PersonalInfo.Fathers Name")}
                    placeholder={t("PersonalInfo.Fathers Name")}
                    register={{
                      ...register("fatherName")
                    }}
                    errors={errors}
                    onChange={handleFormDetails}
                    value={personalInfo.fatherName}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="husbandName"
                    label={t("PersonalInfo.Husbands Name")}
                    placeholder={t("PersonalInfo.Husbands Name")}
                    register={{
                      ...register("husbandName"),
                    }}
                    errors={errors}
                    onChange={handleFormDetails}
                    value={personalInfo.husbandName}
                    disabled={!editable}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <InputText
                    key={"dob"}
                    type="date"
                    name="dob"
                    label={t("PersonalInfo.DOB")}
                    placeholder="Select date"
                    required={true}
                    register={{
                      ...register("dob", {
                        // valueAsDate:true,
                        formatDate: "MM/dd/yyyy",
                        required: "Please select the date",
                      }),
                    }}
                    onChange={handleDate}
                    errors={errors}
                    disabled={!editable}
                    // value={convertDateFormat(personalInfo.dob)}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="age"
                    label={t("PersonalInfo.Age")}
                    placeholder={t("PersonalInfo.Age")}
                    register={{
                      ...register("age", {
                        // required: "Please enter Age",
                        validate: {
                          between18and60: (value) =>
                            (value >= 18 && value <= 60) ||
                            "Age should be between 18 and 60",
                        },
                      }),
                    }}
                    errors={errors}
                    value={personalInfo.age}
                    disabled
                    required={true}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="yearsMonthTotal"
                    label={t("PersonalInfo.YearsMonths Total")}
                    placeholder="Enter your Years & Months Total"
                    register={{
                      ...register("yearsMonthTotal", {
                        required: "Please enter your Years & Months Total",
                      }),
                    }}
                    errors={errors}
                    required={true}
                    value={personalInfo.yearsMonthTotal}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <InputMultiSelect
                    key={personalInfo.gender}
                    isMulti={false}
                    name="gender"
                    label={t("PersonalInfo.Gender")}
                    placeholder="Select"
                    register={control}
                    options={genderTypes}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    onChange={handleGenderTypeChange}
                    defaultValue={genderTypes.find(
                      (g) => g.value === personalInfo.gender
                    )}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="aadharNumber"
                    label={t("PersonalInfo.Aadhar Number")}
                    placeholder="Enter Your Aadhaar No"
                    value={personalInfo.aadharNumber}
                    disabled
                    register={{
                      ...register("aadharNumber", {
                        required: "Please enter your Aadhaar No",
                        minLength: {
                          value: 12,
                          message:
                            "Aadhaar Number should not less than 12 digits.",
                        },
                        maxLength: {
                          value: 12,
                          message:
                            "Aadhaar Number should not more than 12 digits.",
                        },
                        // pattern: {
                        //   value: CONSTANT_REGEX_SAMPLE.NUMERIC,
                        //   message: "Aadhaar Number should be numeric.",
                        // },
                      }),
                    }}
                    required={true}
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="mobileNumber"
                    label={t("PersonalInfo.Mobile No")}
                    placeholder="Enter your Mobile No"
                    required={true}
                    register={{
                      ...register("mobileNumber", {
                        required: "Please enter your Mobile No",
                        minLength: {
                          value: 10,
                          message:
                            "Mobile Number should not less than 10 digits.",
                        },
                        maxLength: {
                          value: 10,
                          message:
                            "Mobile Number should not more than 10 digits.",
                        },
                        pattern: {
                          value: CONSTANT_REGEX_SAMPLE.NUMERIC,
                          message: "Mobile Number should be numeric.",
                        },
                      }),
                    }}
                    onChange={handleFormDetails}
                    value={personalInfo.mobileNumber}
                    errors={errors}
                    disabled={!editable}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="voterIdNumber"
                    label={t("PersonalInfo.VoterId")}
                    placeholder="Enter your Voter Id No"
                    register={{
                      ...register("voterIdNumber", {
                        // required: "Please enter your Voter Id No",
                      }),
                    }}
                    errors={errors}
                    onChange={handleFormDetails}
                    value={personalInfo.voterIdNumber}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="panNumber"
                    label={t("PersonalInfo.Pan Number")}
                    placeholder="Enter Your PAN No"
                    required={true}
                    register={{
                      ...register("panNumber", {
                        required: "Please enter your PAN No",
                        minLength: {
                          value: 10,
                          message: "PAN Number should not less than 10 digits.",
                        },
                        maxLength: {
                          value: 10,
                          message: "PAN Number should not more than 10 digits.",
                        },
                        pattern: {
                          value: CONSTANT_REGEX_SAMPLE.PAN,
                          message: "Invalid PAN Number.",
                        },
                      }),
                    }}
                    errors={errors}
                    onChange={handleFormDetails}
                    value={personalInfo.panNumber}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="emailId"
                    label={t("PersonalInfo.Email")}
                    placeholder="Enter your Email"
                    register={{
                      ...register("emailId", {
                        // pattern: {
                        //   value: CONSTANT_REGEX_SAMPLE.EMAIL,
                        //   message: "Invalid Email.",
                        // },
                      }),
                    }}
                    errors={errors}
                    onChange={handleFormDetails}
                    value={personalInfo.emailId}
                    disabled={!editable}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="drivingLicenceNumber"
                    label={t("PersonalInfo.DrivingLicenceNo")}
                    placeholder="Enter your Driving Licence No"
                    register={{
                      ...register("drivingLicenceNumber", {
                        validate: {
                          // pattern: (value) =>
                          //   !value ||
                          //   CONSTANT_REGEX_SAMPLE.DL.test(value) ||
                          //   "Invalid Driving License.",
                          minLength: (value) =>
                            !value ||
                            value.length >= 13 ||
                            "Driving Licence No should not be less than 13 digits.",
                          maxLength: (value) =>
                            !value ||
                            value.length <= 13 ||
                            "Driving Licence No should not be more than 13 digits.",
                        },
                      }),
                    }}
                    errors={errors}
                    onChange={handleFormDetails}
                    value={personalInfo.drivingLicenceNumber}
                    disabled={!editable}
                  />
                </Col>
              </Row>
            </Collapsible>
          </form>
        </div>
      </div>
      <AddressInfo
        formData={formData}
        setFormData={setFormData}
        addressDetails={addressDetails}
        setAddressDetails={setAddressDetails}
        userRole={userRole}
        control={control}
        register={register}
        errors={errors}
      />
      <div className="application-btn">
        <div className="d-flex justify-content-between">
          <Button
            variant="secondary"
            onClick={() => navigate("/mission-shakti/home")}
            style={{
              width: "max-content",
            }}
            className="cancelBtn"
          >
            {t("Previous")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={
              editable
                ? dataChange
                  ? handleSubmit(onSubmit)
                  : handleNext
                : handleNext
            }
            className="primaryBtn"
            style={{ width: "max-content" }}
          >
            {editable
              ? dataChange
                ? t("Save & Continue")
                : t("Next")
              : t("Next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
