import React, { useEffect, useState, useContext } from "react";
import PersonalInfo from "./LoanForm/PersonalInfo";
import LoanDetails from "./LoanForm/LoanDetails";
import Preview from "./LoanForm/Preview";
// import { Container, Button, Row, Col } from 'react-bootstrap';
import "./MssyForm.css";
import VehicleInfo from "./LoanForm/VehicleInfo";
import SupportingDocs from "./LoanForm/SupportingDocs";
import Declaration from "./LoanForm/Declaration";
import MembershipDetails from "./LoanForm/MembershipDetails";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { postData } from "../../../services/http.service";
import { ApplicationContext } from "../../ApplicationContext";
import { APP_MESSAGES } from "../../../utils/constants";
import { toast } from "react-toastify";
import { Card } from "react-bootstrap";
import { URLS_ENDPOINT } from "../../../utils/urls.const";
import { timestampToDateFormat } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const formDataDetails: any = {};
const MssyForm = () => {
  const { t } = useTranslation();
  const { updateApplicationContext } = useContext(ApplicationContext);
  const { application, userInfo } = useContext(ApplicationContext);
  const userRole = localStorage.getItem("role");
  const [step, setStep] = useState<number>(
    application?.applicationStatus === "Pending with BPC" &&
      userRole === "FL/CSS"
      ? 7
      : 1
  );
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [formData, setFormData] = useState(formDataDetails);
  const navigate = useNavigate();
  const location = new URLSearchParams(useLocation().search);

  const params = location.get("id");
  const [trackingInfo, setTrackingInfo] = useState<any>([]);

  useEffect(() => {
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, [navigate]);

  useEffect(() => {
    const dataFetch = async () => {
      if (params) {
        try {
          const response: any = await postData(
            process.env.REACT_APP_APPLICATION_SEARCH,
            {
              uuids: [params],
            }
          );
          if (response?.data && response?.data[0]) {
            if (
              response?.data[0] &&
              response?.data[0]?.applicationBasicDetails[0]
            ) {
              updateApplicationContext({
                id: response?.data[0]?.applicationBasicDetails[0]?.uuid,
                applicationNumber:
                  response?.data[0]?.applicationBasicDetails[0]
                    ?.applicationNumber,
                    applicationStatus:response?.data[0]?.applicationBasicDetails[0]
                    ?.applicationStatus
              });
            }

            const result = response?.data[0];
            let newone: any = {};
            Object.keys(result).forEach((key) => {
              if (Array.isArray(result[key]) && result[key].length > 0) {
                newone[key] = result[key][0];
              }
            });
            if (
              newone &&
              newone?.vehicleProposalDetails &&
              newone?.vehicleProposalDetails?.quotationDate
            ) {
              newone.vehicleProposalDetails.quotationDate =
                timestampToDateFormat(
                  newone?.vehicleProposalDetails?.quotationDate
                );
            }
            setFormData({
              ...newone,
              applicationNumber:
                result?.applicationBasicDetails[0]?.applicationNumber,
              uuid: result?.applicationBasicDetails[0]?.uuid,
              vehicleProposalDetailsAudit: [
                ...result?.vehicleProposalDetailsAudit,
              ],
            });

            if (result?.applicationBasicDetails[0]?.applicationNumber) {
              try {
                const URL =
                  process.env.REACT_APP_MIS_APP_SERVICE +
                  URLS_ENDPOINT.APP_SERVICES.TRACK_APP_STAT;
                const request = {
                  userInfo: userInfo,
                  trackingCriteria: {
                    applicationNumber:
                      result?.applicationBasicDetails[0]?.applicationNumber,
                  },
                };
                const response = await postData(URL, request);
                if (response?.data?.applicationStages?.length > 0) {
                  setTrackingInfo([...response?.data?.applicationStages]);
                }
              } catch (error) {
                console.error("Error fetching Tracking data:", error);
              }
            }
          }
        } catch (error) {
          toast.error(APP_MESSAGES.CMN_ERR, {
            position: "top-right",
          });
        }
      }
    };
    dataFetch();
  }, [params, userInfo, step]);

  useEffect(() => {
    localStorage.setItem("completedSteps", JSON.stringify(completedSteps));
  }, [completedSteps]);

  const nextStep = () => {
    setCompletedSteps((prev) => [...new Set([...prev, step])]);
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const stepTitles = [
    { title: t("Basic&AddressDetails") },
    { title: t("Membership&Qualifications") },
    { title: t("BankIncomeDetails") },
    { title: t("TwoWheelerProposed") },
    { title: t("SupportingDocuments") },
    { title: t("Declarationn") },
    { title: t("PreviewApplication") },
  ];

  return (
    <Card style={{ width: "100%" }}>
      <Card.Body>
        <div className="mssy-form-container">
          {formData?.applicationNumber && (
            <>
              <div className="profile-header-container">
                <h6 className="profile-header">
                  Application No.: {formData?.applicationNumber}
                </h6>
              </div>
              <hr style={{ border: "1px solid #ccc", margin: "1rem 0" }} />
            </>
          )}
          <div className="step-indicator">
            {stepTitles.map((title, index) => (
              <div
                key={index}
                className={`step-item ${step === index + 1 ? "active" : ""}`}
              >
                <div
                  className={`step-number ${
                    completedSteps.includes(index + 1) ? "completed" : ""
                  }`}
                >
                  {completedSteps.includes(index + 1) ? "" : index + 1}
                </div>
                <div className="step-title">{title.title}</div>
              </div>
            ))}
            {/* <Stepper steps={stepTitles} /> */}
          </div>
          {step === 1 && (
            <PersonalInfo
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              userRole={userRole}
            />
          )}
          {step === 2 && (
            <MembershipDetails
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              prevStep={prevStep}
              userRole={userRole}
            />
          )}
          {step === 3 && (
            <LoanDetails
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              prevStep={prevStep}
              userRole={userRole}
            />
          )}
          {step === 4 && (
            <VehicleInfo
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              prevStep={prevStep}
              userRole={userRole}
            />
          )}
          {step === 5 && (
            <SupportingDocs
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              prevStep={prevStep}
              userRole={userRole}
            />
          )}
          {step === 6 && (
            <Declaration
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              prevStep={prevStep}
              userRole={userRole}
            />
          )}
          {step === 7 && (
            <Preview
              formData={formData}
              prevStep={prevStep}
              setFormData={setFormData}
              setCompletedSteps={setCompletedSteps}
              userRole={userRole}
              trackingInfo={trackingInfo}
              uuid={params}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default MssyForm;
