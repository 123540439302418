import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/images/logo-odisha.png';
import Scooter from '../../assets/images/scooter.png'
import { useTranslation } from 'react-i18next';
import { useLanguageContext } from '../../LanguageContext'; 


const PublicLogoHeader = () => {
    const { t } = useTranslation();
    const { changeLanguage } = useLanguageContext();
    return (
        <Navbar bg="light" expand="lg">
            <Container fluid>
                {/* Logo with text on the left */}
                <Navbar.Brand href="#home" className='col-5'>
                    <div className="d-flex align-items-center col-6">
                        <img
                            src={logo} // Replace with your logo image URL
                            width="70"
                            height="70"
                            className="d-inline-block align-top"
                            alt="Mission Shakti"
                        />
                        <div className="ml-3">
                            <div>
                                <span className="logotext" style={{ fontSize: '1.1rem' }}>{t('missionShaktiScooterYojana')}</span>
                            </div>
                            <div>
                                <span className="logotext1" style={{ fontSize: '1rem' }}>{t('governmentOfOdisha')}</span>
                            </div>
                        </div>
                    </div>
                </Navbar.Brand>
                {/* Middle section with scooter icon and text */}
                <div className="d-flex justify-content-center align-items-center flex-grow-1">
                    <div style={{ background: 'linear-gradient(135deg, #2193b0 0%, #6dd5ed 100%)', padding: '10px', textAlign:'center',width:'min-content' }}>
                    <img
                            src={Scooter} // Replace with your logo image URL
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="Scooter"
                            style={{ filter: 'invert(1)' }}
                        />
                        <span style={{ color: 'white', marginLeft: '5px' }}>{t('checkSchemeEligibility')}</span>
                    </div>
                </div>
                {/* Right side text with register button */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Navbar.Text>
                        <span className="mr-2" style={{ fontWeight: '500' }}>{t('applyScooterLoan')}</span>&nbsp;
                        <a href="/mission-shakti/register" className="btn btn-success" style={{ color: 'white' }}>{t('register')}</a>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default PublicLogoHeader;
