import jsPDF from "jspdf";

export function generateVerificationCertificatePDF(formData: any) {
  const pdf = new jsPDF("p", "pt", "a4");
  const pdfWidth = 595; // A4 width in points
  const pdfHeight = 842; // A4 height in points

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  const canvasWidth = 792; // A4 dimensions in pixels
  const canvasHeight = 1123;
  const margin = 30;
  const rightMargin = 25;
  const contentWidth = canvasWidth - margin - rightMargin;
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  // Set up the font and alignment
  ctx.font = "12px Arial";
  ctx.textAlign = "left";
  ctx.textBaseline = "top";
  ctx.lineWidth = 1;

  // Define styles
  const headerStyle = "bold 20px Arial";
  const subHeaderStyle = "bold 16px Arial";
  const textStyle = "16px Arial";
  const indent = 54;
  const lineHeight = 30;
  const paragraphPadding = 50;

  // Clear the canvas
  ctx.clearRect(0, 0, canvasWidth, canvasHeight);

  // Draw the background color for the title section
  ctx.fillStyle = "#8ef3a6";
  ctx.fillRect(margin - 10, 50, canvasWidth - 30, 80);

  // Draw the title text
  ctx.fillStyle = "#000";
  ctx.font = headerStyle;
  ctx.fillText(
    "Block/Project Level Verification-cum-Recommendation Certificate from",
    50,
    60
  );
  ctx.fillText("Department of Mission Shakti", 220, 90);

  // Draw the content
  ctx.font = textStyle;
  const content = [
    `I/We hereby certify that ${formData?.applicationBasicDetails?.applicantNameAsPerAadhar} W/o or D/o ${formData?.applicationBasicDetails?.fatherName} presently residing in village/town ${formData?.addressDetails?.permanentVillageTown} is working as a ${formData?.membershipQualifications?.educationalQualification} since the year ${convertMonthsToYears(formData?.membershipQualifications?.designationDuration)} under Department of Mission Shakti.`,
    `Further, it is to certify that the statements/particulars in this application and the documents submitted (as per list attached) are true, complete and correct to the best of my knowledge and belief.`,
    `Considering the request for two-wheeler of the applicant, I/We recommend a loan amount of Rs. ${formData?.vehicleProposalDetails?.amountOfLoanRequired} (Rupees in favor of Mrs. ${formData?.applicationBasicDetails?.applicantNameAsPerAadhar} towards purchase of Scooter (${formData?.vehicleProposalDetails?.vehicleModel}) ${formData?.vehicleProposalDetails?.vehicleModel} under Mission Shakti Scooter Yojana - "Interest Free Loan for purchase of Scooter by Mission Shakti Federation Leaders and Community Support Staff."`,
  ];

  let yOffset = 120;
  let contentHeight = 0;

  // Calculate content height
  for (const paragraph of content) {
    const height = getParagraphHeight(
      ctx,
      paragraph,
      contentWidth,
      lineHeight,
      indent
    );
    contentHeight += height + paragraphPadding;
  }

  // Add the signature section height
  contentHeight += 300;

  // Calculate scale if content exceeds the canvas height
  const scale =
    contentHeight > canvasHeight - 40 ? (canvasHeight - 40) / contentHeight : 1;

  // Draw the content with scaling applied
  yOffset = 250;
  for (const paragraph of content) {
    drawParagraph(
      ctx,
      paragraph,
      margin,
      yOffset,
      contentWidth,
      lineHeight,
      indent,
      scale,
      rightMargin
    );
    yOffset +=
      (getParagraphHeight(ctx, paragraph, contentWidth, lineHeight, indent) +
        paragraphPadding) *
      scale;
  }

  // Draw the signature section with scaling applied
  ctx.font = textStyle;
  ctx.fillText("Signature", margin, yOffset + 140 * scale);
  ctx.font = subHeaderStyle;
  ctx.fillText(formData?.applicationBasicDetails?.applicantNameAsPerAadhar, margin, yOffset + 170 * scale);
  ctx.fillText(formData?.addressDetails?.permanentBlock, margin, yOffset + 200 * scale);
  ctx.fillText(formData?.addressDetails?.permanentDistrict, margin, yOffset + 230 * scale);
  ctx.fillText("Seal & Signature", 20*margin, yOffset + 140 * scale);

  // Convert canvas to image and add to PDF
  const imgData = canvas.toDataURL("image/png");
  const imgWidth = canvas.width;
  const imgHeight = canvas.height;

  const scaledWidth =
    imgWidth * Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  const scaledHeight =
    imgHeight * Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

  pdf.addImage(imgData, "PNG", 0, 0, scaledWidth, scaledHeight);
  pdf.save("RecommendationLetter.pdf");
}
function convertMonthsToYears(months:number) {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;  
  return `${years}-${remainingMonths} months` ;
}
const getParagraphHeight = (
  ctx: CanvasRenderingContext2D,
  text: string,
  maxWidth: number,
  lineHeight: number,
  indent: number
) => {
  const words = text.split(" ");
  let line = "";
  let height = lineHeight;
  let firstLine = true;

  for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + " ";
    const metrics = ctx.measureText(testLine);
    const testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      height += lineHeight;
      line = words[n] + " ";
      firstLine = false;
    } else {
      line = testLine;
    }
  }
  return height + (firstLine ? 0 : lineHeight);
};

const drawParagraph = (
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number,
  indent: number,
  scale: number,
  rightMargin: number
) => {
  const words = text.split(" ");
  let line = "";
  let firstLine = true;
  const effectiveWidth = maxWidth - rightMargin;

  for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + " ";
    const metrics = ctx.measureText(testLine);
    const testWidth = metrics.width;
    if (testWidth > effectiveWidth && n > 0) {
      if (firstLine) {
        ctx.fillText(line, x + indent * scale, y * scale);
        firstLine = false;
      } else {
        ctx.fillText(line, x, y * scale);
      }
      line = words[n] + " ";
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  if (firstLine) {
    ctx.fillText(line, x + indent * scale, y * scale);
  } else {
    ctx.fillText(line, x, y * scale);
  }
};
