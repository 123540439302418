import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputText from "../../../common/InputText";
import InputMultiSelect from "../../../common/InputMultiSelect";
import { postData } from "../../../../services/http.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Collapsible from "../../../common/Collapsible";
import { APP_MESSAGES, userRoleName } from "../../../../utils/constants";

const LoanDetails = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  userRole,
}) => {
  const applicationStatus =
    formData?.applicationBasicDetails?.applicationStatus;
  let editable =
    userRole === userRoleName &&
    (applicationStatus === "Saved as Draft" || applicationStatus === undefined);
  const [dataChange, setDataChange] = useState(false);
  const { t } = useTranslation();
  const [banks, setBanks] = useState([]);
  const [branches, setBranches] = useState([]);
  const [bankDetails, setBankDetails] = useState(
    { ...formData.bankIncomeDetails } || {
      accountNumber: "",
      bankIfscCode: "",
      bankName: "",
      branchAddress: "",
      branchName: "",
      branchMicr: "",
      customerId: "",
      ageOfBanking: "",
      existingLoanDetailsBank: "",
      existingLoanDetailsLoanType: "",
      existingLoanDetailsLoanAmount: "",
      existingLoanDetailsEmiAmount: "",
      outstandingLoanAmount: "",
      incomeFromRemuneration: "",
      incomeFromOtherSource: "",
      totalIncome: "",
    }
  );

  useEffect(() => {
    if (
      JSON.stringify(bankDetails) ===
      JSON.stringify(formData?.bankIncomeDetails)
    ) {
      setDataChange(false);
    } else {
      setDataChange(true);
    }
  }, [formData?.bankIncomeDetails, bankDetails]);
  useEffect(() => {
    if (formData?.bankIncomeDetails) {
      setBankDetails(formData?.bankIncomeDetails);
    }
  }, [formData]);
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
    async function loadBanks() {
      try {
        const response = await postData(
          process.env.REACT_APP_BANKS_DROPDOWN_SERVICE,
          {
            userInfo: userInfo,
            bankFilterDetails: {
              bankCode: "",
              branchCode: "",
            },
          }
        );
        // const response = await fetchBankList();
        const bankList = response.data.masterBankDetails || []; // Ensure banks array is extracted from the response
        let newone = [];
        for (const key in bankList) {
          newone.push({
            value: bankList[key]?.code,
            label: bankList[key]?.bankName,
          });
        }
        loadBranch(newone);
        setBanks(newone);
      } catch (error) {
        console.error("Error fetching bank list:", error);
      }
    }
    loadBanks();
    async function loadBranch(bankList = []) {
      try {
        const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
        const response = await postData(
          process.env.REACT_APP_BANKS_DROPDOWN_SERVICE,
          {
            userInfo: userInfo,
            bankFilterDetails: {
              bankCode:
                bankList?.length > 0
                  ? bankList.find((e) => e.label === bankDetails?.bankName)
                      ?.value
                  : "",
              branchCode: "",
            },
          }
        );
        // const response = await fetchBankList();
        const branchListList = response.data.branchDetails || []; // Ensure banks array is extracted from the response
        let newone = [];
        for (const key in branchListList) {
          newone.push({
            value: branchListList[key]?.branchID,
            label: branchListList[key]?.branchName,
          });
        }
        setBranches(newone);
      } catch (error) {
        toast.error(APP_MESSAGES.API_ERR, {
          position: "top-right",
        });
      }
    }
  }, [setFormData]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: bankDetails,
  });

  useEffect(() => {
    if (bankDetails) {
      reset(bankDetails);
    }
  }, [bankDetails, reset]);
  const handleIfscChange = async (e) => {
    const ifsc_code = e.target.value;
    setBankDetails((prevstate) => ({
      ...prevstate,
      bankIfscCode: ifsc_code,
    }));
    try {
      const response = await postData(
        `${process.env.REACT_APP_BANKSIFSC_SERVICE}?ifsc=${ifsc_code}`
      );
      // const ifscDetails = await fetchBankDetails(ifsc_code);
      if (response.data) {
        const data = response.data;
        setBankDetails((prevstate) => ({
          ...prevstate,
          bankName: data.bank,
          branchName: data.branch,
          branchAddress: data.address,
        }));
      }
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };
  const handleBankChange = (selectedOption) => {
    const bName = selectedOption.label;
    setBankDetails((prevstate) => ({
      ...prevstate,
      existingLoanDetailsBank: bName,
    }));
  };

  const handleLoanTypeChange = (selectedOption) => {
    const loanType = selectedOption.label;
    setBankDetails((prevstate) => ({
      ...prevstate,
      existingLoanDetailsLoanType: loanType,
    }));
  };
  const onSubmit = async (data) => {
    setFormData((prevstate) => ({
      ...prevstate,
      bankIncomeDetails: { ...bankDetails },
    }));
    const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
    const transformedData = {
      userInfo: userInfo,
      uuid: formData.uuid,
      applicationNumber: formData.applicationNumber,
      applicationBasicDetails: { ...formData.applicationBasicDetails },
      addressDetails: { ...formData.addressDetails },
      membershipQualifications: { ...formData.membershipQualifications },
      bankIncomeDetails: {
        ...bankDetails,
        auditDetails: {
          createdBy: userInfo.uuid,
          lastModifiedBy: userInfo.uuid,
          createdTime: new Date().getTime(),
          lastModifiedTime: new Date().getTime(),
        },
        isUpdate: false,
      },
    };

    try {
      const response = await postData(
        process.env.REACT_APP_CREATE_APPLICATION_SERVICE,
        transformedData
      );

      if (
        response.data &&
        response.data.status === "Successful" &&
        response.data.applicationDetails
      ) {
        setFormData((prevstate) => ({
          ...prevstate,
          uuid: response.data.uuid,
          applicationNumber: response.data.applicationNumber,
          bankIncomeDetails:
            response.data.applicationDetails.bankIncomeDetails[0],
        }));
        toast.success("Bank Details Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        setTimeout(() => {
          nextStep();
        }, 2000);
      } else {
        toast.error("Failed to save data", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data", {
        position: "top-right",
      });
    }
  };

  const loanTypes = [
    { value: "personal_loan", label: "Personal Loan" },
    { value: "education_loan", label: "Education Loan" },
    { value: "home_loan", label: "Home Loan" },
    { value: "car_loan", label: "Car Loan" },
    { value: "bike_loan", label: "Bike Loan" },
    { value: "business_loan", label: "Business Loan" },
    { value: "gold_loan", label: "Gold Loan" },
  ];
  useEffect(() => {
    reset(formData);
  }, [formData, reset]);
  useEffect(() => {
    const uuid = localStorage.getItem("UUID");
    if (uuid) {
      setValue("userId", uuid);
    }
  }, [setValue]);

  const handleChange = async (event) => {
    if (event.target.name === "bankIfscCode")
      try {
        const response = await postData(
          `${process.env.REACT_APP_BANKSIFSC_SERVICE}?ifsc=${event.target.value}`
        );
        if (bankDetails) {
          const data = response.data;
          setBankDetails((prevstate) => ({
            ...prevstate,
            bankName: data.bank,
            branchName: data.branch,
            branchAddress: data.address,
            bankIfscCode: event.target.value,
          }));
        }
      } catch (error) {
        console.error("Error fetching bank details:", error);
      }
    else {
    }
    setBankDetails((prevstate) => ({
      ...prevstate,
      [event.target.name]:
        event.target.type === "number"
          ? Number(event.target.value)
          : event.target.value,
    }));
  };

  const handleNext = () => {
    nextStep();
  };
  const handlePrimaryBankChange = async (e) => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
      const response = await postData(
        process.env.REACT_APP_BANKS_DROPDOWN_SERVICE,
        {
          userInfo: userInfo,
          bankFilterDetails: {
            bankCode: e?.value,
            branchCode: "",
          },
        }
      );
      // const response = await fetchBankList();
      const branchListList = response.data.branchDetails || []; // Ensure banks array is extracted from the response
      let newone = [];
      for (const key in branchListList) {
        newone.push({
          value: branchListList[key]?.branchID,
          label: branchListList[key]?.branchName,
        });
      }
      setBranches(newone);
      setBankDetails((prevstate) => ({
        ...prevstate,
        bankName: e?.label,
        branchName: "",
        bankIfscCode: "",
      }));
    } catch (error) {
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    }
  };
  const handlePrimaryBranchChange = async (e) => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
      const response = await postData(
        process.env.REACT_APP_BANKS_DROPDOWN_SERVICE,
        {
          userInfo: userInfo,
          bankFilterDetails: {
            bankCode: banks.find((b) => b.label === bankDetails.bankName)
              ?.value,
            branchCode: e?.value,
          },
        }
      );
      // const response = await fetchBankList();
      const ifscDetails = response.data.ifscDetails?.[0]?.ifscCode || []; // Ensure banks array is extracted from the response

      setBankDetails((prevstate) => ({
        ...prevstate,
        branchName: e?.label,
        bankIfscCode: ifscDetails,
      }));
    } catch (error) {
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    }
  };
  return (
    <div className="LoanDetails">
      <ToastContainer />
      {/* Applicant's Primary Account Details Card */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card mb-4 tiles_Size">
          <div className="card-body">
            <Collapsible title={t("LoanDetails.Heading1")}>
              <Row>
                <Col md={4}>
                  <InputText
                    type="number"
                    name="accountNumber"
                    label={t("LoanDetails.Account Number")}
                    placeholder={t("LoanDetails.Account Placeholder")}
                    register={register("accountNumber", {
                      required: "Please enter your Account Number",
                    })}
                    errors={errors}
                    required={true}
                    onChange={handleChange}
                    value={bankDetails.accountNumber}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputMultiSelect
                    key={"bankName"}
                    isMulti={false}
                    name="bankName"
                    label={t("LoanDetails.Bank Name")}
                    placeholder="Select your Bank Name"
                    register={control}
                    options={banks}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    onChange={handlePrimaryBankChange}
                    defaultValue={
                      bankDetails.bankName
                        ? banks.find((b) => b.label === bankDetails.bankName)
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>

                <Col md={4}>
                  <InputMultiSelect
                    key={bankDetails.branchName}
                    isMulti={false}
                    name="branchName"
                    label={t("LoanDetails.Branch Name")}
                    placeholder="Select your Branch"
                    register={control}
                    options={branches}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    disabled={!editable}
                    onChange={handlePrimaryBranchChange}
                    defaultValue={
                      bankDetails.branchName
                        ? branches.find(
                            (b) => b.label === bankDetails.branchName
                          )
                        : null
                    }
                  />
                </Col>

                <Col md={4}>
                  <InputText
                    type="text"
                    name="bankIfscCode"
                    required={true}
                    label={t("LoanDetails.IFSC Code")}
                    placeholder={t("LoanDetails.IFSC Placeholder")}
                    register={register("bankIfscCode", {
                      required: "Please enter your IFSC Code",
                    })}
                    errors={errors}
                    value={bankDetails.bankIfscCode}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="customerId"
                    label={t("LoanDetails.Customer ID")}
                    required={true}
                    placeholder={t("LoanDetails.ID Placeholder")}
                    register={register("customerId", {
                      required: "Please enter your Customer ID",
                    })}
                    errors={errors}
                    onChange={handleChange}
                    value={bankDetails.customerId}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    name="ageOfBanking"
                    label={t("LoanDetails.Age of Bank")}
                    placeholder={t("LoanDetails.BankAge Placeholder")}
                    register={register("ageOfBanking")}
                    errors={errors}
                    onChange={handleChange}
                    value={bankDetails.ageOfBanking}
                    disabled={!editable}
                  />
                </Col>
              </Row>
            </Collapsible>
          </div>
        </div>
        <div className="card mb-4 tiles_Size">
          <div className="card-body">
            <Collapsible title={t("LoanDetails.Heading2")}>
              <Row>
                <Col md={4}>
                  <InputMultiSelect
                    isMulti={false}
                    name="existingLoanDetailsBank"
                    label={t("LoanDetails.Bank Name")}
                    placeholder="Select your Bank Name"
                    register={control}
                    options={banks}
                    // rules={{
                    //   required: true,
                    //   message: "This field is required",
                    // }}
                    errors={errors}
                    onChange={handleBankChange}
                    value={
                      bankDetails.existingLoanDetailsBank
                        ? banks.find(
                            (b) =>
                              b.label === bankDetails.existingLoanDetailsBank
                          )
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputMultiSelect
                    isMulti={false}
                    name="existingLoanDetailsLoanType"
                    label={t("LoanDetails.Loan Type")}
                    placeholder={t("LoanDetails.LoanType Placeholder")}
                    register={control}
                    options={loanTypes}
                    // rules={{
                    //   required: true,
                    //   message: "This field is required",
                    // }}
                    errors={errors}
                    onChange={handleLoanTypeChange}
                    value={
                      bankDetails.existingLoanDetailsLoanType
                        ? {
                            value: bankDetails.existingLoanDetailsLoanType,
                            label: bankDetails.existingLoanDetailsLoanType,
                          }
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    name="existingLoanDetailsLoanAmount"
                    label={t("LoanDetails.Loan Amount")}
                    placeholder={t("LoanDetails.Loan Amount Placeholder")}
                    register={register("existingLoanDetailsLoanAmount", {
                      required: "Please enter your Loan Amount(in Rs)",
                    })}
                    errors={errors}
                    onChange={handleChange}
                    value={bankDetails.existingLoanDetailsLoanAmount}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    name="existingLoanDetailsEmiAmount"
                    label={t("LoanDetails.EMI")}
                    placeholder={t("LoanDetails.EMI Placeholder")}
                    register={register("existingLoanDetailsEmiAmount", {
                      required: "Please enter your EMI(in Rs)",
                    })}
                    errors={errors}
                    onChange={handleChange}
                    value={bankDetails.existingLoanDetailsEmiAmount}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    name="outstandingLoanAmount"
                    label={t("LoanDetails.Outstanding Loan")}
                    placeholder={t("LoanDetails.Outstanding Loan Placeholder")}
                    register={register("outstandingLoanAmount", {
                      required:
                        "Please enter your Outstanding Loan Amount(in Rs)",
                    })}
                    errors={errors}
                    onChange={handleChange}
                    value={bankDetails.outstandingLoanAmount}
                    disabled={!editable}
                  />
                </Col>
              </Row>
            </Collapsible>
          </div>
        </div>
        <div className="card mb-4 tiles_Size">
          <div className="card-body">
            <Collapsible title={t("LoanDetails.Heading3")}>
              <Row>
                <Col md={4}>
                  <InputText
                    type="number"
                    name="incomeFromRemuneration"
                    label={t("LoanDetails.Income Source")}
                    placeholder={t("LoanDetails.Income Source Placeholder")}
                    register={register("incomeFromRemuneration", {
                      required:
                        "Please enter your Income From Remuneration/Setting Fees/Incentive(in Rs)",
                    })}
                    onChange={handleChange}
                    required={true}
                    errors={errors}
                    value={bankDetails.incomeFromRemuneration}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    name="incomeFromOtherSource"
                    label={t("LoanDetails.Income From")}
                    placeholder={t("LoanDetails.Income From Placeholder")}
                    register={register("incomeFromOtherSource", {
                      required:
                        "Please enter your Income From (Livelihood/other resources)If Any (in Rs)",
                    })}
                    errors={errors}
                    required={true}
                    onChange={handleChange}
                    value={bankDetails.incomeFromOtherSource}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    name="totalIncome"
                    label={t("LoanDetails.Total Income")}
                    placeholder={t("LoanDetails.Total Income Placeholder")}
                    register={{
                      ...register("totalIncome", {
                        required: "Please enter your Total Income (in Rs)",
                      }),
                    }}
                    errors={errors}
                    onChange={handleChange}
                    required={true}
                    value={bankDetails.totalIncome}
                    disabled={!editable}
                  />
                </Col>
              </Row>
            </Collapsible>
          </div>
        </div>
      </form>
      {/* Existing Loan Details and Income Details Card */}

      {/* Navigation Buttons Card */}
      <div className="application-btn">
        <div className="d-flex justify-content-between">
          <Button
            variant="secondary"
            onClick={prevStep}
            style={{
              width: "max-content",
            }}
            className="cancelBtn"
          >
            {t("Previous")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={
              editable
                ? dataChange
                  ? handleSubmit(onSubmit)
                  : handleNext
                : handleNext
            }
            className="primaryBtn"
            style={{ width: "max-content" }} // Apply orange color
          >
            {editable
              ? dataChange
                ? t("Save & Continue")
                : t("Next")
              : t("Next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoanDetails;
