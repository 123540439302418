import React, { useEffect, useState } from "react";
import { Carousel, Container, Navbar } from "react-bootstrap";
import MeghaPhone from "../../assets/images/megaphone.png";
// import SocialMediaIcon from "../../assets/images/social-media.png";
import PhotoGallery from "../../assets/images/photoGallery.png";
import CarousalImage1 from "../../assets/images/scooterImage1.jpg";
import CarousalImage2 from "../../assets/images/scooterImage2.jpg";
import CarousalImage3 from "../../assets/images/scooterImage3.jpg";
import { useTranslation } from "react-i18next";
import { postData } from "../../services/http.service";

const NewsFeed = () => {
  const { t } = useTranslation();
    const [newsList, setNewslist] = useState<any>();
//   const newsList: any = [
//     {
//       id: 1,
//       date: "01",
//       month: "Aug",
//       year: "2024",
//       isNew: true,
//       text: "Live broadcast of the launching of Subhadra by Hon’ble Prime Minister on 17 Sep 2024",
//     },
//     {
//       id: 2,
//       date: "01",
//       month: "Aug",
//       year: "2024",
//       isNew: false,
//       text: "Padayatra of Subhadra by Mission Shakti members at Block level",
//     },
//     {
//       id: 3,
//       date: "01",
//       month: "Aug",
//       year: "2024",
//       text: "Mission Shakti Bazaar launches benty vanity bags",
//     },
//     // {id: 4, date: "01", month: "Aug", year: "2024", text: "Office Order - Resume of Tender process for empanelment of Catering Service ProviderNew"},
//     // {id: 5, date: "01", month: "Aug", year: "2024", text: "Office Order - Resume of Tender process for empanelment of Catering Service ProviderNew"}
//   ];
  const feedbackList: any = [
    {
      id: 1,
      text: "“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua”",
      name: "Bimla Devi",
      place: "Raipur, Odisha",
      profileImg: CarousalImage1,
    },
    {
      id: 2,
      text: "“Set the direction of flex items in a flex container with direction utilities. In most cases you can omit the horizontal class here as the browser default is row. However, you may encounter situations where you needed to explicitly set this value (like responsive layouts).”",
      name: "Rama Das",
      place: "Angul, Odisha",
      profileImg: CarousalImage2,
    },
    {
      id: 3,
      text: "“Use justify-content utilities on flexbox containers to change the alignment of flex items on the main axis (the x-axis to start, y-axis if flex-direction: column). Choose from start (browser default), end, center, between, or around.”",
      name: "Sabitri Devi",
      place: "Cuttack, Odisha",
      profileImg: CarousalImage3,
    },
  ];
  useEffect(() => {
    const newsFetch = async () => {
      try {
        const response = await postData(
          "http://164.52.212.74:8083/mis-common-master-service/_news/_search",
          {
            criteria: {
              uuid: "",
            },
          }
        );
        if (response?.data) {
          console.log(response?.data?.newsUpdates, "updates");
            setNewslist(response?.data?.newsUpdates);
        }
      } catch (err) {
        console.log(err);
      }
    };
    newsFetch();
  }, []);
  interface DateItemProps {
    date: string;
  }

  const DateItem: React.FC<DateItemProps> = ({ date }) => {
console.log(date,"date")
    const parsedDate = new Date(date);
    const year = parsedDate.getFullYear();
    const month = parsedDate.toLocaleString("default", { month: "long" }); 
    const day = parsedDate.getDate();
console.log(year,day,month,"check")
    return (
      <div style={{ display: "flex", alignItems: "center",flexDirection:'column',justifyContent:'center' }}>
        <div
          style={{
            background: "#FFD66D",
            width: 40,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "5px",
          }}
        >
          <span style={{ fontWeight: 600 }}>{day}</span>
          <div style={{ fontWeight: 500 }}>{month.substring(0,3)}</div>
        </div>
        <div style={{ width: 40, fontWeight: 500 }}>
          {year}
        </div>
      </div>
    );
  };

  return (
    <>
      <div style={{ backgroundColor: "#FF640214" }}>
        <div className="container" style={{ paddingBottom: 57 }}>
          <div className="row">
            <div className="col-sm-6">
              <div className="newsfeed-cls">
                <img
                  className="newsfeed-icon-cls"
                  src={MeghaPhone}
                  alt="Megha phone"
                />
                <span className="newsfeed-text-cls">{t("Table.News")}</span>
              </div>
              <div
                style={{
                  background: "white",
                  padding: "20px",
                  maxHeight: "20rem",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <ul style={{ padding: 0, fontSize: 14 }}>
                  {newsList &&
                    newsList.map((news: any) => (
                        
                      <li
                        className="row"
                        style={{
                          padding: 5,
                          borderBottom: "1px solid #6F6F761A",
                        }}
                      >
                        {console.log(news,"news")}
                        <div
                          className="col-sm-2"
                          style={{ textAlign: "center" }}
                        >
                            
                          {/* <div style={{ background: "#FFD66D", width: 40 }}>
                            <span style={{ fontWeight: 600 }}>
                              {news?.date}
                            </span>
                            <div style={{ fontWeight: 500 }}>{news?.month}</div>
                          </div>
                          <div style={{ width: 40, fontWeight: 500 }}>
                            {news?.year}
                          </div> */}
                          
                          <DateItem date={news.publishedAt} />
                        </div>
                        <div className="col-sm-8">
                          <p
                            style={{
                              color: "#000000",
                              fontWeight: 500,
                              fontSize: "18px",
                            }}
                          >
                            {news.content}
                          </p>
                        </div>
                        {news?.isNew && (
                          <div className="col-sm-2">
                            <div className="new-btn-cls">New</div>
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="newsfeed-cls">
                <img
                  className="newsfeed-icon-cls"
                  src={PhotoGallery}
                  alt="Gallery"
                />
                <span className="newsfeed-text-cls">{t("Photo Gallery")}</span>
              </div>
              <div
                style={{
                  background: "white",
                  padding: "16px",
                  height: "20rem",
                }}
              >
                <div className="feedback-carousel-main">
                  <Carousel slide={false} controls={true} className="">
                    {feedbackList &&
                      feedbackList.map((feedback: any) => (
                        <Carousel.Item style={{ height: "245px" }}>
                          {/* <div> */}
                          {feedback.profileImg && (
                            <img
                              src={feedback.profileImg}
                              alt="Profile Pic"
                              className="carousal-pic"
                            />
                          )}
                          {/* </div> */}
                        </Carousel.Item>
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsFeed;
