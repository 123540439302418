import { toast } from 'react-toastify';
// import { getUrl, getUserDataOnRefresh, getUserPrimaryDesignation } from '../services/modulesServices/userManagement.api';
import { redirect } from 'react-router';
import axios from 'axios';
// import { download, download_logo, file_preview, file_upload, jpgFormat, logo, pdfFormat, trash } from '../utils/Logo';
import _ from 'lodash';

export const saveToStorage = (key: string, data: any) => {
    const existingData = localStorage.getItem(key);
    if (!existingData) localStorage.setItem(key, JSON.stringify(data));
};

export const getUserData = async () => {
    try {
        const  data:any = {}
        // await getUserDataOnRefresh();

        if (data) {
            try {
                // if (data.type === 'EMPLOYEE') {
                //     const res = data && (await getUserPrimaryDesignation({ department: data?.departmentCode, designation: data?.designation }, data.type));
                //     const primaryAssignment = { department: res?.Department?.[0], designation: res?.Designation?.[0] };
                //     saveToStorage('currentUserAssignment', primaryAssignment);
                // } else {
                //     const res = data && (await getUserPrimaryDesignation({ designation: data?.designation }, data.type));
                //     const primaryAssignment = { designation: res?.roles?.[0] };
                //     saveToStorage('currentUserAssignment', primaryAssignment);
                // }

                return data;
            } catch (error) {
                console.error('Error fetching user designation:', error);
            }
        }
    } catch (e: any) {
        const msg = e.response?.data?.Errors[0].code === 'BadRequest' && 'Token Expired';
        toast(msg, {
            position: "top-right",
            className: 'foo-bar',
            type: 'error'
        });
        localStorage.clear();
        window.location.href = '/mission-shakti/';
    }
};

export const isAuthenticated = () => {
    const isAuth = localStorage.getItem('token');
    return isAuth;
};

export const getNavigateTo = (userType: string) => {
    switch (userType) {
        case 'EMPLOYEE':
            return '/mission-shakti/dashboard';
        case 'ENTERPRISE':
            return '/mission-shakti/enterprisedashboard';
        case 'EXTERNAL_EMPLOYEE':
            return '/mission-shakti/architectdashboard';
        default:
            return '/mission-shakti/';
    }
};

export function convertToHierarchy(data: any): any[] {
    const result: any[] = [];

    const pathMap = new Map<string, any>();

    function createObject(path: string, displayName: string, url: string, navigationURL: string): any {
        const pathArray = path.split('.');
        let currentLevel: any[] = result;

        pathArray.forEach((key, index) => {
            const existingObject = pathMap.get(key);

            if (existingObject) {
                currentLevel = existingObject.children;
            } else {
                const newObj: any = {
                    id: key,
                    name: key,
                    displayName: index === pathArray.length - 1 ? displayName : key,
                    url,
                    navigationURL,
                    path: path,
                    children: []
                };

                pathMap.set(key, newObj);
                currentLevel.push(newObj);
                currentLevel = newObj.children;
            }
        });

        return currentLevel[0];
    }

    data.menus.forEach((menu:any) => {
        createObject(menu.path, menu.displayName, menu.url, menu.navigationURL);
    });

    return result;
}

export function convertToHierarchyCard(cards: any[]): any[] {
    const result: any[] = [];
    const pathMap = new Map<string, any>();

    cards.forEach(card => {
        const { id, name, displayName, path, ...rest } = card;
        const pathArray = path.split('.');
        let currentLevel: any[] = result;

        pathArray.forEach((key: any, index: number) => {
            const existingObject = pathMap.get(key);

            if (existingObject) {
                currentLevel = existingObject.children;
            } else {
                const newObj: any = {
                    id: key,
                    name: key,
                    displayName: index === pathArray.length - 1 ? displayName : key,
                    children: [],
                    path,
                    ...rest
                };

                pathMap.set(key, newObj);
                currentLevel.push(newObj);
                currentLevel = newObj.children;
            }
        });
    });

    return result;
}

export const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.slice(0, maxLength) + '...';
};


export const extractFileUrl = (url: string) => {
    const validUrl = url.split('https://').filter(Boolean);
    if (validUrl.length > 1) {
        return 'https://' + validUrl[0].slice(0, -1);
    } else {
        return 'https://' + validUrl[0];
    }
};

// export const formatedDocumentDetails = async (id: string, multi: boolean = false) => {
//     if (id) {
//         try {
//             const res = await axios.get(getUrl() + '/filestore/v1/files/url', { params: { tenantId: 'ga', fileStoreIds: id } });
//             const ids = res.data.fileStoreIds.map((item: any, index: number) => ({
//                 documentName: res?.data?.fileStoreIds[index].tag,
//                 documentSize: res?.data?.fileStoreIds[index].fileSize,
//                 referenceId: res?.data?.fileStoreIds[index].id,
//                 documentUrl: extractFileUrl(res.data.fileStoreIds[index].url),
//                 uploadedDate: res?.data?.fileStoreIds[index].uploadedDate
//             }));
//             if (multi) return ids;
//             else return ids[0];
//         } catch (e) {
//             toast('Failed to fetch the document, Please try again after some time', {
//                 position: "top-right",
//                 className: 'foo-bar',
//                 type: 'error'
//             });
//             return null;
//         }
//     }
// };

export const getFileIcon = (documentName: string): string => {
    const fileExtension: string = documentName ? (documentName.split('.').pop()?.toLowerCase() as string) : 'png';
    return iconMap[fileExtension] || iconMap.default;
};

export const iconMap: any = {
    // pdf: pdfFormat,
    doc: 'doc-icon.png',
    docx: 'doc-icon.png',
    txt: 'txt-icon.png',
    // jpg: jpgFormat,
    // jpeg: jpgFormat,
    // png: jpgFormat,
    default: 'default-icon.png'
};

export const setDataFromPrefix = (propertiesToSet: { [key: string]: string }, prefix: string, values: any): any => {
    return Object.entries(propertiesToSet).reduce((acc, [key, value]) => {
        const propertyName = (prefix ?? '') + key;
        _.set(acc, propertyName, value);
        return acc;
    }, values);
};

export const timestampToDateFormat = (timestamp: any) => {
  let dateStr = "";
  if (timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");
    dateStr = `${year}-${month}-${day}`;
  }
  return dateStr;
};
