// import React from 'react';
// import { Card, Row, Col } from 'react-bootstrap';
// // import { FaClipboardCheck, FaShippingFast, FaCheckCircle, FaUserCheck, FaMoneyCheckAlt } from 'react-icons/fa'; // Importing icons
// // import { FaClipboardCheck, FaUserShield, FaCheckSquare, FaGavel, FaUniversity, FaThumbsUp } from 'react-icons/fa';
// import { FaCheckCircle, FaIdBadge, FaClipboardCheck, FaThumbsUp, FaBuilding, FaShieldAlt, FaFileSignature, FaHandshake, FaCheckDouble } from 'react-icons/fa';
// import './TrackDetails.css';

// const ApplicationTracking = () => {
//     const statusHistory = [
//         { status: 'Application Submitted', date: '2024-08-01', message: '', username: 'Smruti Dhal', icon: <FaClipboardCheck /> },
//         { status: 'BPC Verified', date: '2024-08-05', message: 'User Verified', username: 'BA/BA/241BPC', icon: <FaIdBadge /> }, // Updated Icon
//         { status: 'CDPO Verified', date: '2024-08-10', message: 'Documents Verified', username: 'BA/BA/241CDPO', icon: <FaFileSignature /> }, // Updated Icon
//         { status: 'BDO Approved', date: '2024-08-14', message: 'Application Approved', username: 'BA/BA/241BDO', icon: <FaCheckDouble /> }, // Updated Icon
//         { status: 'Controlling Bank Approved', date: '2024-08-20', message: 'Loan Approved', username: 'SBI', icon: <FaBuilding /> }, // Updated Icon
//         { status: 'Loan Approved', date: '2024-08-30', message: 'Loan Sanctioned', username: 'SBI/14665', icon: <FaCheckCircle /> }, // Updated Icon
//       ];

//   return (
//     <Card className="mt-3">
//       <Card.Header style={{ backgroundColor: 'white' }} className="mb-2">
//         Application Status Tracking
//       </Card.Header>
//       <Card.Body>
//         <Row className="d-flex justify-content-center">
//           <Col md={8} className="position-relative">
//             {statusHistory.map((item, index) => (
//               <div key={index} className="status-tracking-step mb-4">
//                 {/* Icon */}
//                 <div className="status-tracking-icon">
//                   {item.icon}
//                 </div>

//                 {/* Vertical Line */}
//                 {index < statusHistory.length - 1 && (
//                   <div className="status-tracking-line" />
//                 )}

//                 {/* Status Details */}
//                 <div className="status-tracking-details">
//                   <strong style={{ color: index === statusHistory.length - 1 ? '#28a745' : '#000' }}>{item.status}</strong>
//                   <div>{item.date}</div>
//                   <div>{item.username}</div>
//                   <div>{item.message}</div>
//                 </div>
//               </div>
//             ))}
//           </Col>
//         </Row>
//       </Card.Body>
//     </Card>
//   );
// };

// export default ApplicationTracking;

import { Card, Row, Col } from "react-bootstrap";

import {
  FaCheckCircle,
  FaIdBadge,
  FaClipboardCheck,
  FaExclamation,
  FaThumbsUp,
  FaBuilding,
  FaFileSignature,
  FaCheckDouble,
  FaTimes,
  FaArrowRight,
  FaArrowLeft,
} from "react-icons/fa";
import "./TrackDetails.css";
import { useTranslation } from "react-i18next";
import Collapsible from "../../../common/Collapsible";

const ApplicationTracking = ({ applicationHistorydata, appNo }) => {
  const { t } = useTranslation();
  const getStatusIcon = (status) => {
    const statusIconMap = {
      Approved: <FaCheckCircle color="white" title="Approved" />,
      Rejected: <FaTimes color="white" title="Rejected" />,
      "Sent back": <FaArrowLeft color="white" title="Sent back" />,
      "Pullback and Rejected": (
        <FaExclamation
          color="white"
          title="Pullback and Rejected"
          size="14px"
        />
      ),
      "Pending with BPC": (
        <FaExclamation color="white" title="Pending with BPC" size="14px" />
      ),
      "Approved by BPC": (
        <FaIdBadge color="white" title="Pending with BPC" size="14px" />
      ),
      "Pending with CDPO": (
        <FaExclamation color="white" title="Pending with CDPO" size="14px" />
      ),
      "Approved by CDPO": (
        <FaFileSignature color="white" title="Pending with CDPO" size="14px" />
      ),
      "Pending with BDO": (
        <FaExclamation color="white" title="Pending with BDO" size="14px" />
      ),
      "Approved by BDO": (
        <FaCheckCircle color="white" title="Approved By BDO" size="14px" />
      ),
      "Pending with Bank": (
        <FaExclamation color="white" title="Pending with Bank" size="14px" />
      ),
      "Approved by Bank": (
        <FaBuilding color="white" title="Pending with Bank" size="14px" />
      ),
      "Loan Approved": (
        <FaCheckCircle color="white" title="Pending with Bank" size="14px" />
      ),
    };

    return (
      statusIconMap[status] || (
        <FaExclamation color="gray" title="Unknown Status" />
      )
    );
  };

  return (
    <div className="card mb-4 tiles_Size" style={{ width: "100%" }}>
      <>
        <div className="card-body">
          <Collapsible title={t("TrackApplication.ApplicationStatusTracking")}>
            <Row className="d-flex justify-content-center">
              <Col md={12} className="status-tracking-container">
                {applicationHistorydata?.map((item, index) => (
                  <div key={index} className="status-tracking-step mb-4">
                    {/* Icon */}
                    <div className="status-tracking-icon">
                      {getStatusIcon(item.applicationStatus)}
                    </div>

                    {/* Vertical Line */}
                    {index < applicationHistorydata?.length - 1 && (
                      <div className="status-tracking-line" />
                    )}

                    {/* Status Details */}
                    <div className="status-tracking-details">
                      <strong
                        style={{
                          color:
                            index === applicationHistorydata?.length - 1 &&
                            item?.applicationStatus
                              ?.toLowerCase()
                              ?.includes("loan approved")
                              ? "#28a745"
                              : "#000",
                        }}
                      >
                        {item?.applicationStatus}
                      </strong>
                      <div>
                        {" "}
                        <label>Submitted Date : </label>{" "}
                        {item?.submittedDate || "--"}
                      </div>
                      <div>
                        {" "}
                        <label>Submitted By : </label>{" "}
                        {item?.submittedByName || "--"}
                        {item?.submittedBy ? (
                          <strong> ({item?.submittedBy})</strong>
                        ) : (
                          " "
                        )}
                      </div>
                      <div>
                        {" "}
                        <label>Submitted To : </label>{" "}
                        {item?.submittedToName || "--"}
                        {item?.submittedTo ? (
                          <strong> ({item?.submittedTo})</strong>
                        ) : (
                          " "
                        )}
                      </div>
                      <div>
                        {" "}
                        <label>Comments : </label> {item?.comments || "NA"}
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </Collapsible>
        </div>
      </>
    </div>
  );
};

export default ApplicationTracking;
