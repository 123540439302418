import React, { useState } from "react";
import "./AdharOtp.css";
import { useForm } from "react-hook-form";
import InputText from "../common/InputText";
import { Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomCaptcha from "../common/CustomCaptcha";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const VerifyAdharOtp = () => {
  let navigate = useNavigate();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      otp: ""
    }
  });

  const onSubmit = (data: any) => {
    if (!isCaptchaValid) {
      toast.error('Please complete the CAPTCHA.', {
        position: "top-right"
      });
      return;
    }
    console.log("onSubmit===", data);
    localStorage.setItem('token', JSON.stringify("t_123"));
    navigate('/mission-shakti/home');
  }

  return (
    <>
      <div className="adhar d-flex justify-content-center align-items-center">
        <div className="card">
          <div className="card-body">
            <h2 className="text-center mb-4">Verify OTP</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputText type="text" name="otp" label="OTP" placeholder="Enter your OTP" register={{
                ...register("otp", {
                  required: "Please enter your OTP"
                })
              }} errors={errors}
              />
              <CustomCaptcha onCaptchaValid={setIsCaptchaValid} />
              <Row id="btn">

                <Col className="text-left">
                  <Button variant="secondary" onClick={() => navigate('/mission-shakti/')}>Cancel</Button>
                </Col>
                <Col className="text-right">
                  <Button type="submit" variant="primary">Submit</Button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default VerifyAdharOtp;
