import React, { useState } from "react";
import "./MobileOtp.css";
import { useForm } from "react-hook-form";
import InputText from "../common/InputText";
import { Button, Row, Col } from "react-bootstrap";
import { useNavigate , useLocation } from "react-router-dom";
import CustomCaptcha from "../common/CustomCaptcha";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { validateOTP } from "../../services/http.service";
import PublicDepartments from "./PublicDepartments";
import MegaMenu from "./MegaMenu";
import PublicHeader from "./PublicHeader_old";
import PublicFooter from "./PublicFooter";
import PublicLogoHeader from "./PublicLogoHeader";

const VerifyAdharOtp = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const { phoneNumber, userId } = location.state || {};
  // let navigate = useNavigate();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      otpCode: ""
    }
  });

  const onSubmit = async (data: any) => {
    if (!isCaptchaValid) {
        toast.error('Please complete the CAPTCHA.', {
            position: "top-right"
        });
        return;
    }
    const payload = {
      otpCode: data.otpCode,
      phoneNumber: phoneNumber,
      userId: userId,
      requestType: "RESET_PASSWORD"
    };

    try {
        const response = await validateOTP('/_validate', payload);
        console.log(response);
        if (response.data && response.data.status === "success") {
            toast.success(response.data.message, {
                position: "top-right"
            });
            setTimeout(() => {
                navigate('/mission-shakti/reset-password', { state: { phoneNumber, userId } });
            }, 3000);

        } else {
            toast.error('OTP Sent failed. Please check your details.', {
                position: "top-right"
            });
        }
    } catch (error) {
        toast.error('An error occurred. Please try again later.', {
            position: "top-right"
        });
        console.error("Password reset error:", error);
    }
};

  return (
    <>
     <PublicHeader></PublicHeader>
     <PublicLogoHeader></PublicLogoHeader>
     <MegaMenu></MegaMenu>
      <div className="adhar d-flex justify-content-center align-items-center">
        <div className="card">
          <div className="card-body">
            <h2 className="text-center mb-4">Verify OTP</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputText type="text" name="otpCode" label="OTP" placeholder="Enter your OTP" register={{
                ...register("otpCode", {
                  required: "Please enter your OTP"
                })
              }} errors={errors}
              />
              <CustomCaptcha onCaptchaValid={setIsCaptchaValid} />
              <Row id="btn">
                <Col className="text-left">
                  <Button variant="secondary" onClick={() => navigate('/mission-shakti/')}>Cancel</Button>
                </Col>
                <Col className="text-right">
                  <Button type="submit" variant="primary">Submit</Button>
                </Col>

              </Row>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <PublicDepartments></PublicDepartments>
      <PublicFooter></PublicFooter>
    </>
  )
}

export default VerifyAdharOtp;
