import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './MenuBar.css'; // Import custom CSS for additional styling
import { useTranslation } from 'react-i18next';
// import { useLanguageContext } from '../../LanguageContext'; 

const MegaMenu = () => {
    const { t } = useTranslation();
    // const { changeLanguage } = useLanguageContext();
    return (
        <Navbar expand="lg" className="menu-bar">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto">
                    <Nav.Link href="#home">{t('Home')}</Nav.Link>
                    <NavDropdown title={t('About Us')} id="about-us-dropdown">
                        <NavDropdown.Item href="#action/3.1">{t('Overview')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">{t('Mission & Vission')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.1">{t('Community Structure')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">{t('Organizational Structure')}</NavDropdown.Item>
                        <NavDropdown title={t('WhosWho')} id="whos-who-dropdown">
                            <NavDropdown.Item href="#action/3.1">{t('Administration')}</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">{t('State Project Monitoring Unit')}</NavDropdown.Item>
                        </NavDropdown>
                    </NavDropdown>
                    <NavDropdown title={t('Notification')} id="notification-dropdown">
                        <NavDropdown.Item href="#action/4.1">{t('Rules & Guidelines')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/4.2">{t('Letters & Circular')}</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title={t('Publication')} id="publication-dropdown">
                        <NavDropdown.Item href="#action/5.1">{t('Report')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/5.2">{t('Magazines')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/5.1">{t('Newsletters')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/5.2">{t('Posters')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/5.1">{t('Leaflets')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/5.2">{t('Books')}</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title={t('Programme')} id="programme-dropdown">
                        <NavDropdown.Item href="#action/6.1">{t('Institution Building')}</NavDropdown.Item>
                        <NavDropdown title={t('Partnership')} id="partnership-dropdown">
                            <NavDropdown.Item href="#action/6.2">{t('ORMAS')}</NavDropdown.Item>
                            <NavDropdown.Item href="#action/6.3">{t('RSETI')}</NavDropdown.Item>
                            <NavDropdown.Item href="#action/6.4">{t('UNFPA')}</NavDropdown.Item>
                            <NavDropdown.Item href="#action/6.4">{t('UNICEF')}</NavDropdown.Item>
                            <NavDropdown.Item href="#action/6.4">{t('UNCDF')}</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown.Item href="#action/6.2">{t('Capacity Building')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Financial Inclusion')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Mission Shakti Loan – State Interest Subvention')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Livelihoods, Skill Development & Market Linkages')}</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title={t('Convergence')} id="convergence-dropdown">
                        <NavDropdown.Item href="#action/6.1">{t('Food Supplies & Consumer Welfare Department')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Department of Energy')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('School & Mass Education Department')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Department of W & CD and Mission Shakti')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Directorate of Fisheries')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Directorate of Animal Husbandry & Veterinary Services')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Directorate of Horticulture')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Directorate of Extension Education (OUAT)')}</NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Link href="#convergence">Convergence</Nav.Link> */}
                    <Nav.Link href="#district-pages">{t('District Pages')}</Nav.Link>
                    <Nav.Link href="#rti">{t('RTI')}</Nav.Link>
                    <NavDropdown title={t('Media Gallery')} id="media-gallery-dropdown">
                        <NavDropdown.Item href="#action/7.1">{t('Photo Gallery')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/7.2">{t('Video Gallery')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/7.2">{t('Audio Gallery')}</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="#contact-us">{t('Contact Us')}</Nav.Link>
                    <Nav.Link href="#important-links">{t('Important Links')}</Nav.Link>
                    <NavDropdown title={t('More')} id="more-dropdown">
                        <NavDropdown.Item href="#action/8.1">{t('Plan Progress')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/8.2">{t('Data & Statistics')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/8.1">{t('Best Practice')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/8.2">{t('Achievements')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/8.1">{t('Infrastructure')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/8.2">{t('Messages')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/8.1">{t('FAQs')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/8.2">{t('Success Stories')}</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default MegaMenu;
