// import LoginPage from '../features/loginPage/LoginPage.component';
// import MainPage from '../features/mainPage/MainPage.wrapper';
// import TechnicalClearanceFormPage from '../features/technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.component';
// import EmployeeCreationPage from '../features/modules/UserManagement/EmployeeModule/EmployeeCreationPage';
// import EnterpriseCreationPage from '../features/modules/UserManagement/EnterpriseModule/EnterpriseCreationPage';
// import UserManagementPage from '../features/modules/UserManagement/UserManagementPage';
import Home from '../components/private/home/Home';
import Login from '../components/public/Login';
import MainPage from '../components/public/MainPage.wrapper';
import RedirectUrlScreen from './RedirectUrlScreen';
import Register from '../components/public/Register';
import AdharVerify from '../components/public/VerifyAdharOtp'
import MobileOtpVerify from '../components/public/MobileOtpVerify';
import MssyForm from '../components/private/mssyloan/MssyForm';
import Preview from '../components/private/mssyloan/LoanForm/Preview';
import BlockOfficialHome from '../components/private/block-official/home/BlockOfiicialHome';
import CDPOHome from '../components/private/cdpo/home/CDPOHome';
import ApplicationsList from '../components/private/block-official/ApplicationsList';
import BDOHome from '../components/private/bdo/home/BDOHome';
import Applications from '../components/private/bdo/Applications';
import CDPOApplications from '../components/private/cdpo/CDPOApplications';
import ViewProfile from '../components/private/user-profile/ViewProfile';
import EditProfile from '../components/private/user-profile/EditProfile';
import ChangePassword from '../components/private/user-profile/ChangePassword';
import ForgotPassword from '../components/public/ForgotPassword';
import ResetPassword from '../components/public/ResetPassword';
import LoanBankHome from '../components/private/loan-bank/home/LoanBankHome';
import ControllingBankHome from '../components/private/head-bank/home/LoanBankHome';
import ApplicationTracking from '../components/private/mssyloan/LoanForm/ApplicationTracking';
import VerificationCertificate from '../components/public/VerificationCertificate';
import ShgDetails from '../components/private/masters/ShgDetails';
import UserManagement from '../components/private/masters/userManagement';
import InterestSubvention from '../components/private/interest-subvention/InterestSubvention';
import InterestSubventionReports from '../components/private/interest-subvention/interest-subvention-reports';
import Overview from '../components/public/Overview';
import MissionVissionPage from '../components/public/MissionVissionPage';
import ComunityStructurePage from '../components/public/ComunityStructurePage';
import OrganizationStructurePage from '../components/public/OrganizationStructurePage';
import ProgremeeOverviewPage from '../components/public/ProgremeeOverviewPage';
import ViewFeedBacks from '../components/private/masters/view-feedbacks';
import NewsDetails from '../components/private/masters/NewsDetails';
import UploadBackLogData from '../components/private/masters/UploadBackLogData';

// import OtpValidate from '../features/loginPage/OtpValidationPage.component';
// import ArchitectCreationPage from '../features/modules/UserManagement/ArchitectModule/ArchitectCreationPage';
// // import BpaHomePage from "../features/modules/UserManagement/BpaHomePage";
// import ScrutinyFormPage from '../features/scrutinyFlowScreen/ScrutinyFormPage.component';
// import ArchitectDashboard from '../features/modules/UserManagement/ArchitectModule/ArchitectDashboard';
// import EnterpriseDashboard from '../features/modules/UserManagement/EnterpriseModule/EnterpriseDashboard';
// import AboutUs from '../features/commonPages/AboutUs';
// import ResetPassword from '../features/loginPage/ResetPasswordPage.component';
// import FaqPage from '../features/commonPages/FAQPage';
// import ContactUsPage from '../features/commonPages/ContactUs';
// import TenderPage from '../features/commonPages/TenderPage';
// import UnderConstruction from '../features/commonPages/UnderConstruction';
// import WhoIsWho from '../features/commonPages/WhoIsWhoPage';
// import Disclaimer from '../features/commonPages/Disclaimer';
// import { PrivacyPolicy } from '../features/commonPages/PrivacyPolicy';
// import { TermsandConditionPage } from '../features/commonPages/TermsCondition';
// import PlotDetails from '../features/commonPages/PlotDetails';
// import SearchAdvertisement from '../features/modules/EstateManagement/ApplyForPlot/SearchAdvertisement';
// import ApplicationSearchPage from '../features/modules/UserManagement/EmployeeModule/ApplicationSearchPage';
// import Dashboard from '../features/modules/UserManagement/EmployeeModule/Dashboard';
// import OrderCompletionFormPage from '../features/orderCompletionScreen/OrderCompletionFormPage.component';
// import TechnicalSkipPayment from '../features/technicalFlowScreen/technicalClearanceForm/technicalSkipPayment/TechnicalSkipPayment.wrapper';
// // import { ConstructionDashboard } from "../components/ConstructionLicense/ConstructionlicenseDashboard.component";
// // import { ConstructionLicensePayment } from "../components/ConstructionLicense/PaymentStatus.component";
// import ScrutinySharedPlans from '../features/scrutinyFlowScreen/scrutinyPopUp/ScrutinyDashboard';
// import EstateListPage from '../features/modules/EstateManagement/EstateRegistry/EstateListPage';
// import NotificationPage from '../components/NotificationComponent/NotificationPage';
// import EmployeeSearch from '../features/modules/UserManagement/EmployeeModule/EmployeeSearch';
// import { EmployeeDetailsPage } from '../features/modules/UserManagement/EmployeeModule/EmployeeDetailsPage';
// import ConstructionLicenseMainPage from '../features/constructionLicense/ConstructionlicenseMainPage.component';
// import NOC from '../features/modules/EstateManagement/NOC/NOC_CreationPage';
// import ProfileComponent from '../components/Profile section/ProfileComponent';
// import LeaseDeed from '../features/leaseDeed/LeaseDeed';
// import { Payment } from '../features/modules/UserManagement/EmployeeModule/Payment';
// import FieldInspectionMainPage from '../features/orderCompletionScreen/fieldInspection/FieldInspectionMainPage.component';
// import HolidayList from '../features/commonPages/HolidayListPage';
// import { PaymentStatus } from '../features/modules/UserManagement/EmployeeModule/PaymentStatus';
// import { Noting } from '../components/recent/Noting/Noting';
// import MigrationForm from '../features/modules/Migration/MigrationForm';

export const routes = [
    //home,dashboard,applications
    { path: '/mission-shakti/home', component: Home, name: 'Home' },
    { path: '/mission-shakti/mmsy_loan_form', component: MssyForm, name: 'Application Form' },
    { path: '/mission-shakti/applications', component: Applications, name: 'Beneficiary Applications' },
    // { path: '/mission-shakti/preview', component: Preview },
    // { path: '/mission-shakti/block-official/home', component: BlockOfficialHome, name: 'Block Official Home' },
    // { path: '/mission-shakti/block-official/applications', component: ApplicationsList, name: 'Beneficiary Applications' },
    // { path: '/mission-shakti/cdpo/home', component: CDPOHome, name: 'CDPO Home' },
    // { path: '/mission-shakti/cdpo/applications', component: CDPOApplications, name: 'Beneficiary Applications' },
    // { path: '/mission-shakti/bdo/home', component: BDOHome, name: 'BDO Home' },
    // { path: '/mission-shakti/bdo/applications', component: Applications, name: 'Beneficiary Applications' },
    { path: '/mission-shakti/user-profile/ViewProfile/:uuid', component: ViewProfile, name: 'Profile Details' },
    { path: '/mission-shakti/user-profile/EditProfile/:uuid', component: EditProfile, name: 'Update Profile' },
    { path: '/mission-shakti/user-profile/ChangePassword', component: ChangePassword, name: 'Change Password' },
    { path: '/mission-shakti/loan-bank/home', component: LoanBankHome, name: 'Loan Bank Home' },
    { path: '/mission-shakti/head-bank/home', component: ControllingBankHome, name: 'Controlling Bank Home' },
    { path: '/mission-shakti/track-my-application', component: ApplicationTracking, name: 'Track My Application' },
    { path: '/mission-shakti/masters/shgdetails', component: ShgDetails, name: 'SHG Details' },
    { path: '/mission-shakti/masters/user-management', component: UserManagement, name: 'User Management' },
    { path: '/mission-shakti/interestsubvention/home', component: InterestSubvention,name: 'Interest Subvention' },
    { path: '/mission-shakti/interestsubvention/reports', component: InterestSubventionReports,name: 'Interest Subvention Reports' },
    { path: '/mission-shakti/others/view-feebacks', component: ViewFeedBacks,name: 'View Feedback' },
    { path: '/mission-shakti/masters/news&update', component: NewsDetails, name: 'News & Updates' },
    { path: '/mission-shakti/masters/uploadBackLogData', component: UploadBackLogData, name: 'UploadBackLogData' },


    // { path: '/mission-shakti/dashboard', component: Dashboard },
    // { path: '/mission-shakti/usermanagement', component: UserManagementPage },
    // { path: '/mission-shakti/usermanagement/employeecreation', component: EmployeeCreationPage },
    // { path: '/mission-shakti/usermanagement/searchapplication', component: ApplicationSearchPage },
    // { path: '/mission-shakti/emp/empPreview', component: EmployeeCreationPage },
    // { path: '/mission-shakti/obpas/technicalClearance', component: TechnicalClearanceFormPage, exact: true },
    // { path: '/mission-shakti/login', component: LoginPage },
    // { path: '/mission-shakti/validate', component: OtpValidate },
    // { path: '/mission-shakti/usermanagement/enterprisecreation', component: EnterpriseCreationPage },
    // { path: '/mission-shakti/usermanagement/architectCreation', component: ArchitectCreationPage },
    // { path: '/mission-shakti/obpas/scrutinyTool', component: ScrutinyFormPage },
    // { path: '/mission-shakti/bpa/scrutinyFlowScreen', component: ScrutinyFormPage },
    // { path: '/mission-shakti/architectdashboard', component: ArchitectDashboard },
    // { path: '/mission-shakti/scrutinysharedplans', component: ScrutinySharedPlans },
    // { path: '/mission-shakti/enterprisedashboard', component: EnterpriseDashboard },
    // { path: '/mission-shakti/estate/estateRegistry', component: EstateListPage },
    // { path: '/mission-shakti/estate/apply-for-plot', component: SearchAdvertisement },
    // { path: "/mission-shakti/estate/applyforplot/:id", component: ApplyforPlot },
    // { path: '/mission-shakti/obpas/constructionLicense', component: ConstructionLicenseMainPage },
    // { path: '/mission-shakti/noc', component: NOC },
    // { path: '/mission-shakti/obpas/occupancyCertificate', component: OrderCompletionFormPage, exact: true },
    // { path: '/mission-shakti/obpas/fieldInspectionPage', component: FieldInspectionMainPage },
    // { path: '/mission-shakti/obpas/paymentSkip', component: TechnicalSkipPayment, exact: true },
    // { path: '/mission-shakti/viewNotification', component: NotificationPage, exact: true },
    // { path: '/mission-shakti/empsearch', component: EmployeeSearch },
    // { path: '/mission-shakti/empdetails', component: EmployeeDetailsPage },
    // { path: '/mission-shakti/profile', component: ProfileComponent },
    // { path: '/mission-shakti/apply-deed', component: LeaseDeed },
    // { path: '/mission-shakti/emp-profile', component: ProfileComponent },
    // { path: '/mission-shakti/estate/applyfortransfer', component: ApplyForTransfer },
    // { path: '/mission-shakti/estate/applyforsptransfer', component: ApplyForSpTransfer },
    // { path: '/mission-shakti/estate/createadvertisement', component: CreateAdvertisement },
    // { path: '/mission-shakti/estate/applyforplot', component: ApplyforPlot },

    // { path: '/mission-shakti/estate/mortgage', component: CreationMortgage },
    { path: '*', component: RedirectUrlScreen },
];

export const publicRoutes = [
    // { path: '/mission-shakti/migration/migrationform', component: MigrationForm },
    { path: '/mission-shakti', component: MainPage },
    { path: '/mission-shakti/overview', component: Overview, name: 'Overview' },
    { path: '/mission-shakti/mission-vision', component: MissionVissionPage, name: 'Mission and vision' },
    { path: '/mission-shakti/comunity-structure', component: ComunityStructurePage, name: 'Comunity Structure' },
    { path: '/mission-shakti/organization-structure', component: OrganizationStructurePage, name: 'Organization Structure' },
    { path: '/mission-shakti/progremee-overview', component: ProgremeeOverviewPage, name: 'Progremee Overview' },
    { path: '/mission-shakti/login', component: Login },
    { path: '/mission-shakti/register', component: Register },
    { path: '/mission-shakti/adharotpverify', component: AdharVerify },
    { path: '/mission-shakti/mobileotpverify', component: MobileOtpVerify },
    { path: '/mission-shakti/forgot-password', component: ForgotPassword },
    { path: '/mission-shakti/reset-password', component: ResetPassword },
    // { path: '/mission-shakti/validate', component: OtpValidate },
    // { path: '/mission-shakti/resetpassword', component: ResetPassword },
    // { path: '/mission-shakti/usermanagement/enterprisecreation', component: EnterpriseCreationPage },
    // { path: '/mission-shakti/eu/euPreview', component: EnterpriseCreationPage },
    // { path: '/mission-shakti/aboutus', component: AboutUs },
    // { path: '/mission-shakti/faq', component: FaqPage },
    // { path: '/mission-shakti/contactus', component: ContactUsPage },
    // { path: '/mission-shakti/tender', component: TenderPage },
    // { path: '/mission-shakti/underconstruction', component: UnderConstruction },
    // { path: '/mission-shakti/whoweare', component: WhoIsWho },
    // { path: '/mission-shakti/disclaimer', component: Disclaimer },
    // { path: '/mission-shakti/privacypolicy', component: PrivacyPolicy },
    // { path: '/mission-shakti/terms&conditions', component: TermsandConditionPage },
    // { path: '/mission-shakti/plotdetails', component: PlotDetails },
    // { path: '/mission-shakti/dummy-payment', component: Payment },
    // { path: '/mission-shakti/holiday-calender', component: HolidayList },
    // { path: '/mission-shakti/dummy-payment-status', component: PaymentStatus },
    // { path: '/mission-shakti/workflow', component: Noting },
    // { path: '/mission-shakti/migration/migrationform', component: MigrationForm }
    { path: '*', component: RedirectUrlScreen },
    { path: '/mission-shakti/verificationCertificate', component: VerificationCertificate },
  
    
];
