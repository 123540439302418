import React, { useEffect, useState } from "react";
import ActionTable from "../../common/ActionTable";
import { t } from "i18next";
import { postData } from "../../../services/http.service";
import { URLS_ENDPOINT } from "../../../utils/urls.const";

const ViewFeedBacks: React.FC = () => {
  const [rowData, setRowData] = useState<any[]>([]);

  const TABLE_COLUMNS: any = [
    {
      colName: "Sl#",
      fieldName: "slNo",
      clsName: "column-text-start",
    },
    {
      colName: "Subject",
      fieldName: "subject",
      clsName: "column-text-start",
    },
    {
      colName: "Feedback Messsage",
      fieldName: "feedback",
      clsName: "column-text-start",
    },
    {
      colName: "Sent By",
      fieldName: "name",
      clsName: "column-text-start",
    },
    {
      colName: "Sent On",
      fieldName: "createdDate",
      clsName: "column-text-start",
    },
    {
      colName: "Email",
      fieldName: "email",
      clsName: "column-text-start",
    },
    {
      colName: "Phone",
      fieldName: "phone",
      clsName: "column-text-start",
    },
  ];

  useEffect(() => {
    async function getFeedbackList() {
      try {
        const response = await postData(
          process.env.REACT_APP_MIS_APP_SERVICE +
            URLS_ENDPOINT.APP_SERVICES.GET_FEEDBACK_LIST,
          {
            feedback: {
              uuid: "",
            },
          }
        );
        let res: any[] = response?.data?.feedbackList || []; // Ensure banks array is extracted from the response
        if (res?.length > 0) {
          res = res.map((item: any, index: number) => {
            return {
              ...item,
              slNo: index + 1,
              name: item?.firstName + " " + item?.lastName,
            };
          });
        }
        setRowData(res);
      } catch (error) {
        console.error("Error fetching bank list:", error);
      }
    }

    getFeedbackList();
  }, []);

  return (
    <div className="container mt-4">
      <>
        <ActionTable
          tableWidth={"125%"}
          columns={TABLE_COLUMNS}
          rowData={rowData}
          tableHeader={t("Table.FeedbackList")}
          hideExportBtn={false}
          searchBarPlaceholder={t("Table.SEARCH_BY")}
        />
      </>
    </div>
  );
};

export default ViewFeedBacks;
