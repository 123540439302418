import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LogoOdisha from "../../assets/images/Odisha-sasan.png";
import LoginLogo from "../../assets/logos/login.png";
import "../private/PrivateHeader.css";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../LanguageContext";
import { Link } from 'react-router-dom';
import SOP from "../../assets/files/MS_SCOOTER_YOJANA_SOP.pdf";

const PublicHeader = () => {
  const { t } = useTranslation();
  const { changeLanguage } = useLanguageContext();
  return (
    <>
      <Navbar expand="lg" bg="white" className="private-navbar">
        <Container>
          <img src={LogoOdisha} alt ="logo" className="me-3" style={{ height: "80px" }} />
          <Navbar.Brand
            href="/mission-shakti"
            className="lh-sm-2"
            style={{ fontFamily: "serif" }}
          >
            <span className="fw-bold">{t("PrivateHeader.Mission Shakti")}</span>
            <div className="fw-bold">{t("PrivateHeader.Scooter Yojana")}</div>
            <div style={{ fontSize: "15px" }}>
              {t("PrivateHeader.Goverment of Odisha")}
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link as={Link} to="/mission-shakti">{t("Home")}</Nav.Link>
              <NavDropdown title={t("About Us")} id="about-us-dropdown">
                <NavDropdown.Item as={Link} to="/mission-shakti/overview">
                  {t("Overview")}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/mission-shakti/mission-vision">
                  {t("Mission & Vission")}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/mission-shakti/comunity-structure">
                  {t("Community Structure")}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/mission-shakti/organization-structure">
                  {t("Organizational Structure")}
                </NavDropdown.Item>
                <NavDropdown.Item href="javascript:void(0)">
                  {t("WhosWho")}
                </NavDropdown.Item>

                {/* <NavDropdown title={t('WhosWho')} id="whos-who-dropdown">
                            <NavDropdown.Item href="javascript:void(0)">{t('Administration')}</NavDropdown.Item>
                            <NavDropdown.Item href="javascript:void(0)">{t('State Project Monitoring Unit')}</NavDropdown.Item>
                        </NavDropdown> */}
              </NavDropdown>
              <NavDropdown title={t("Notification")} id="notification-dropdown">
                {/* <NavDropdown.Item href="javascript:void(0)">{t('Rules & Guidelines')}</NavDropdown.Item> */}
                <NavDropdown.Item href={SOP} target="_blank" download>
                  {t("Letters & Circular")}
                </NavDropdown.Item>
              </NavDropdown>
              {/* <NavDropdown title={t('Publication')} id="publication-dropdown">
                        <NavDropdown.Item href="javascript:void(0)">{t('Report')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Magazines')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Newsletters')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Posters')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Leaflets')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Books')}</NavDropdown.Item>
                    </NavDropdown> */}
              <NavDropdown title={t("Programme")} id="programme-dropdown">
                <NavDropdown.Item as={Link} to="/mission-shakti/progremee-overview">
                  {t("Programme Overview")}
                </NavDropdown.Item>
              </NavDropdown>
              {/* <NavDropdown title={t('Convergence')} id="convergence-dropdown">
                        <NavDropdown.Item href="javascript:void(0)">{t('Food Supplies & Consumer Welfare Department')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Department of Energy')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('School & Mass Education Department')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Department of W & CD and Mission Shakti')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Directorate of Fisheries')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Directorate of Animal Husbandry & Veterinary Services')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Directorate of Horticulture')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/6.2">{t('Directorate of Extension Education (OUAT)')}</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="#district-pages">{t('District Pages')}</Nav.Link>
                    <Nav.Link href="#rti">{t('RTI')}</Nav.Link>
                    <NavDropdown title={t('Media Gallery')} id="media-gallery-dropdown">
                        <NavDropdown.Item href="#action/7.1">{t('Photo Gallery')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/7.2">{t('Video Gallery')}</NavDropdown.Item>
                        <NavDropdown.Item href="#action/7.2">{t('Audio Gallery')}</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="#contact-us">{t('Contact Us')}</Nav.Link>
                    <Nav.Link href="#important-links">{t('Important Links')}</Nav.Link> */}
              {/* <NavDropdown title={t('More')} id="more-dropdown">
                        <NavDropdown.Item href="javascript:void(0)">{t('Plan Progress')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Data & Statistics')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Best Practice')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Achievements')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Infrastructure')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Messages')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('FAQs')}</NavDropdown.Item>
                        <NavDropdown.Item href="javascript:void(0)">{t('Success Stories')}</NavDropdown.Item>
                    </NavDropdown> */}
            </Nav>
            <Nav className="ml-auto">
              <Nav.Link
                as={Link} to="/mission-shakti/login"
                style={{ fontWeight: 600, paddingRight: "5px" }}
              >
                <img
                  alt="Login"
                  src={LoginLogo}
                  style={{ height: "23px", marginRight: "6px" }}
                />
                {t("Login")}
              </Nav.Link>
              <Nav.Link
                style={{
                  fontWeight: 600,
                  paddingLeft: 0,
                  paddingRight: 0,
                  fontSize: "17px",
                }}
              >
                <span>/</span>
              </Nav.Link>
              {/*  */}
              <Nav.Link
                as={Link} to="/mission-shakti/register"
                style={{
                  fontWeight: 600,
                  paddingLeft: "5px",
                  color: "#ff6402",
                }}
              >
                {t("register")}
              </Nav.Link>
              <NavDropdown title={t("Language")} id="language-nav-dropdown">
                <NavDropdown.Item
                  onClick={() => changeLanguage("en")}
                  className="small-text"
                >
                  English
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => changeLanguage("or")}
                  className="small-text"
                >
                  ଓଡ଼ିଆ
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default PublicHeader;
