
import React, { useEffect, useState } from 'react';
import { Form } from "react-bootstrap";

const InputCheck = (props:any) => {
    return (
        <>
        <Form.Group className="mb-3" controlId={props?.name}>
            <Form.Label>{props?.label}</Form.Label>
            {props?.options && props?.options.map((e:any)=>{
                return (
                    <Form.Check
                    type="checkbox"
                    label={e?.label}
                    value={e?.value}
                    id={e?.value}
                    name={e?.value}
                    key={e?.value}
                    {...props?.register}
                    // {...register("gender", {
                    // required: "Please select your gender"
                    // })}
                />
                )
                
            })}
            
            {props?.errors[props?.name] && <p className="errorMsg">This field is required</p>}
        </Form.Group>
            {/* <Form.Group className="mb-3" controlId="gender">
                <Form.Label>Select Gender</Form.Label>
                <Form.Check
                    type="radio"
                    label="Male"
                    value="male"
                    {...register("gender", {
                    required: "Please select your gender"
                    })}
                />
                <Form.Check
                    type="radio"
                    label="Female"
                    value="female"
                    {...register("gender")}
                />
                {errors.gender && <p className="errorMsg">{errors.gender.message}</p>}
            </Form.Group> */}
        </>
    )
}

export default InputCheck;