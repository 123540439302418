import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import InputText from "../../common/InputText";
import { t } from "i18next";
import InputMultiSelect from "../../common/InputMultiSelect";
import { postData } from "../../../services/http.service";
import { APP_MESSAGES, CONSTANT_REGEX_SAMPLE } from "../../../utils/constants";
import InputCheck from "../../common/InputCheck";
import { URLS_ENDPOINT } from "../../../utils/urls.const";

interface inputProps {
    shgFormData: any;
    onFormSubmit: (params: any) => any;
    action:any
}

const ShgDetailsForm = ({ shgFormData = {}, onFormSubmit, action }: inputProps) => {

    const masterBaseURL: any = process.env.REACT_APP_MASTER_SERVICE;
    const masterServiceEndPoint:any = URLS_ENDPOINT?.MASTER_SERVICES
    console.log("====",masterBaseURL+masterServiceEndPoint)

    const [formData, setFormData] = useState(shgFormData);
    const [districts, setDistricts] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [panchayats, setPanchayats] = useState([]);
    const [villages, setVillages] = useState([]);
    const [postOffices, setPostOffices] = useState({
        p_postoffices: [],
        c_postoffices: [],
    });
    const [checkOptions, setCheckedOptions]:any=useState([{
        label: "Active",
        value: true
    }]);

    useEffect(() => {
        
        async function loadDistricts() {
            const response:any = postData(masterBaseURL+masterServiceEndPoint?.ADDRESS_DROPDOWN);

            response.then((res:any)=>{
                console.log("=-==",res)
                const { state, districts } = res?.data?.addressDetails;
                setDistricts(districts);
                loadBlocks(districts);
            }).catch((err:any)=> {
                toast.error(err?.message, {
                    position: "top-right",
                });
            })
        }

        async function loadBlocks(districts:any) {
            if (formData.districtName) {
            const districtId:any = districts.find((d:any) => {
                return d.districtName === formData.districtName;
            });
            
            if (districtId) {   
                try {
                    const blockRes:any = await postData(`${masterBaseURL+masterServiceEndPoint?.ADDRESS_DROPDOWN}?districtId=${districtId.uuid}`);
                    const blocks = blockRes?.data?.addressDetails?.blocks || [];
                    setBlocks(blocks);
                    loadPanchayats(blocks)
                }  catch (error) {
                    toast.error(APP_MESSAGES.API_ERR, {
                      position: "top-right",
                    });
                }          
                
            }
            }
        }
        async function loadPanchayats(blocks:any) {
            if (formData.blockName) {
            const blockId:any = blocks.find((d:any) => {
                return d.blockName === formData.blockName;
            });
            if (blockId) {
                try {
                    const panchayatRes:any = await postData(`${masterBaseURL+masterServiceEndPoint?.ADDRESS_DROPDOWN}?blockId=${blockId?.uuid}`);
                    const panchayats = panchayatRes?.data?.addressDetails?.panchayats || [];
                    setPanchayats(panchayats);
                    loadVillages(panchayats)
                } catch (error) {
                    toast.error(APP_MESSAGES.API_ERR, {
                      position: "top-right",
                    });
                }    
                
            }
            }
        }
        async function loadVillages(panchayats:any) {
            if (formData.panchayatName) {
            const panchayat:any = panchayats.find((d:any) => {
                return d.panchayatName === formData.panchayatName;
            });
            if (panchayat) {
                try {
                    const villageRes:any = await postData(`${masterBaseURL+masterServiceEndPoint?.ADDRESS_DROPDOWN}?panchayatId=${panchayat?.uuid}`);
                    const villages = villageRes?.data?.addressDetails?.villages || [];
                    setVillages(villages);
                } catch (error) {
                    toast.error(APP_MESSAGES.API_ERR, {
                      position: "top-right",
                    });
                }
                
            }
            }
        }
        loadDistricts();
        
    }, []);
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
        setValue,
        getValues,
        reset,
    } = useForm({
    //     mode: "onTouched",
    // reValidateMode: "onSubmit",
        defaultValues: formData,
    });
    const handleFormDetails = (event: any) => {
        
    };
    const handleDistrictChange= async(e:any)=> {
        const districtId = e.value;
        try {
            const blockRes:any = await postData(`${masterBaseURL+masterServiceEndPoint?.ADDRESS_DROPDOWN}?districtId=${districtId}`);
            const blocks = blockRes?.data?.addressDetails?.blocks || [];
            setBlocks(blocks);
            setValue('districtName', e.label);
            setValue('blockName', "");
            setValue('panchayatName', "");
            setValue('villageName', "");
            setFormData((prevstate:any)=> ({...prevstate, districtName: e.label}));
            setFormData((prevstate:any)=> ({...prevstate, blockName: ""}));
            setFormData((prevstate:any)=> ({...prevstate, panchayatName: ""}));
            setFormData((prevstate:any)=> ({...prevstate, villageName: ""}));
        } catch (error) {
            toast.error(APP_MESSAGES.API_ERR, {
              position: "top-right",
            });
        }
        
    }
    const handleBlockChange= async(e:any)=> {
        const blockId = e.value;
        try {
            const panchayatRes:any = await postData(`${masterBaseURL+masterServiceEndPoint?.ADDRESS_DROPDOWN}?blockId=${blockId}`);
            const panchayats = panchayatRes?.data?.addressDetails?.panchayats || [];
            setPanchayats(panchayats);
            setValue('blockName', e.label);
            setValue('panchayatName', "", {shouldDirty: true});
            setValue('villageName', "");
            setFormData((prevstate:any)=> ({...prevstate, blockName: e.label}));
            setFormData((prevstate:any)=> ({...prevstate, panchayatName: ""}));
            setFormData((prevstate:any)=> ({...prevstate, villageName: ""}));
        } catch (error) {
            toast.error(APP_MESSAGES.API_ERR, {
              position: "top-right",
            });
        }
        
    }
    const handlePanchayatChange = async(e:any)=> {
        const panchayatId = e.value;
        try {
            const villageRes:any = await postData(`${masterBaseURL+masterServiceEndPoint?.ADDRESS_DROPDOWN}?panchayatId=${panchayatId}`);
            const villages = villageRes?.data?.addressDetails?.villages || [];
            setVillages(villages);
            setValue('panchayatName', e.label);
            setValue('villageName', "");
            setFormData((prevstate:any)=> ({...prevstate, panchayatName: e.label}));
            setFormData((prevstate:any)=> ({...prevstate, villageName: ""}));
        } catch (error) {
            toast.error(APP_MESSAGES.API_ERR, {
              position: "top-right",
            });
        }
        
    }
    const handleSHGVillageChange = async(e:any)=>{
        setFormData((prevstate:any)=> ({...prevstate, villageName: e.label}));
        setTimeout(() => {
            setValue('villageName', e.label);
        }, 100);
    }
    const onChangeCheckBox = (e:any)=>{
        console.log("onChangeCheckBox==",e)
    }

    return (
        <>
            <div className="">
                <ToastContainer />
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onFormSubmit)}>
                            <Row>
                                <Col md={6}>
                                    <InputText
                                        key={formData?.shgId}
                                        type="text"
                                        name="shgId"
                                        label={t("Table.SHG ID")}
                                        placeholder={t("Enter SHG ID")}
                                        register={{
                                            ...register("shgId", {
                                                required: "Please enter your  SHG ID",
                                            }),
                                        }}
                                        disabled={false}
                                        errors={errors}
                                        onChange={handleFormDetails}
                                        // value={formData?.shgId}
                                        readOnly={false}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputText
                                        key={formData?.shgName}
                                        type="text"
                                        name="shgName"
                                        label={t("Table.SHG Name")}
                                        placeholder={t("Enter SHG Name")}
                                        register={{
                                            ...register("shgName", {
                                                required: "Please enter your  SHG Name",
                                            }),
                                        }}
                                        disabled={false}
                                        errors={errors}
                                        onChange={handleFormDetails}
                                        // value={formData?.shgName}
                                        readOnly={false}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputText
                                        key={formData?.mobileNumber}
                                        type="text"
                                        name="mobileNumber"
                                        label={t("Table.Mobile No.")}
                                        placeholder={t("Enter Mobile No.")}
                                        register={{
                                            ...register("mobileNumber", {
                                                required: "Please enter your  Mobile No.",
                                            }),
                                        }}
                                        disabled={false}
                                        errors={errors}
                                        onChange={handleFormDetails}
                                        // value={formData?.mobileNumber}
                                        readOnly={false}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputText
                                        key={formData?.aadharNumber}
                                        type="text"
                                        name="aadharNumber"
                                        label={t("Table.Aadhaar No.")}
                                        placeholder={t("Enter Aadhaar No.")}
                                        register={{
                                            ...register("aadharNumber", {
                                                required: "Please enter your  Aadhaar No.",
                                                minLength: {
                                                    value: 12,
                                                    message:
                                                      "Aadhaar Number should not less than 12 digits.",
                                                  },
                                                  maxLength: {
                                                    value: 12,
                                                    message:
                                                      "Aadhaar Number should not more than 12 digits.",
                                                  },
                                                  pattern: {
                                                    value: CONSTANT_REGEX_SAMPLE.NUMERIC,
                                                    message: "Aadhaar Number should be numeric.",
                                                  },
                                            }),
                                        }}
                                        disabled={false}
                                        errors={errors}
                                        onChange={handleFormDetails}
                                        // value={formData?.aadharNumber}
                                        readOnly={false}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputText
                                        key={formData?.beneficiaryName}
                                        type="text"
                                        name="beneficiaryName"
                                        label={t("Table.Beneficiary Name")}
                                        placeholder={t("Enter Beneficiary Name")}
                                        register={{
                                            ...register("beneficiaryName", {
                                                required: "Please enter your  Beneficiary Name",
                                            }),
                                        }}
                                        disabled={false}
                                        errors={errors}
                                        onChange={handleFormDetails}
                                        // value={formData?.beneficiaryName}
                                        readOnly={false}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputText
                                        key={formData?.icdsProjectName}
                                        type="text"
                                        name="icdsProjectName"
                                        label={t("Table.ICDS Project Name")}
                                        placeholder={t("Enter ICDS Project Name")}
                                        register={{
                                            ...register("icdsProjectName", {
                                                required: "Please enter your  ICDS Project Name",
                                            }),
                                        }}
                                        disabled={false}
                                        errors={errors}
                                        onChange={handleFormDetails}
                                        // value={formData?.icdsProjectName}
                                        readOnly={false}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputText
                                        key={formData?.designation}
                                        type="text"
                                        name="designation"
                                        label={t("Designation")}
                                        placeholder={t("Enter Designation")}
                                        register={{
                                            ...register("designation", {
                                                required: "Please enter your  designation",
                                            }),
                                        }}
                                        disabled={false}
                                        errors={errors}
                                        onChange={handleFormDetails}
                                        // value={formData?.designation}
                                        readOnly={false}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputText
                                        key={formData?.address}
                                        type="text"
                                        name="address"
                                        label={t("Table.Address")}
                                        placeholder={t("Enter Address")}
                                        register={{
                                            ...register("address", {
                                                required: "Please enter your  address",
                                            }),
                                        }}
                                        disabled={false}
                                        errors={errors}
                                        onChange={handleFormDetails}
                                        // value={formData?.address}
                                        readOnly={false}
                                        required={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputMultiSelect
                                    key={formData.districtName}
                                    isMulti={false}
                                    name="districtName"
                                    label={t("AddressInfo.District")}
                                    placeholder="Select your district"
                                    register={control}
                                    // options={district}
                                    options={districts?.map((d:any) => {
                                        return { value: d.uuid, label: d.districtName };
                                    })}
                                    rules={{
                                        required: true,
                                        message: "This field is required",
                                    }}
                                    errors={errors}
                                    onChange={handleDistrictChange}
                                    defaultValue={
                                        formData.districtName
                                        ? {
                                            value: formData.districtName,
                                            label: formData.districtName,
                                            }
                                        : null
                                    }
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputMultiSelect
                                    key={formData.blockName}
                                    isMulti={false}
                                    name="blockName"
                                    label={t("AddressInfo.Block")}
                                    placeholder="Select your block"
                                    register={control}
                                    // options={district}
                                    options={blocks?.map((b:any) => {
                                        return { value: b.uuid, label: b.blockName };
                                      })}
                                    rules={{
                                        required: true,
                                        message: "This field is required",
                                    }}
                                    errors={errors}
                                    onChange={handleBlockChange}
                                    defaultValue={
                                        formData.blockName
                                        ? {
                                            value: formData.blockName,
                                            label: formData.blockName,
                                            }
                                        : null
                                    }
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputMultiSelect
                                    key={formData.panchayatName}
                                    isMulti={false}
                                    name="panchayatName"
                                    label={t("AddressInfo.Panchayat")}
                                    placeholder="Select your panchayat"
                                    register={control}
                                    // options={district}
                                    options={panchayats?.map((p:any) => {
                                        return { value: p.uuid, label: p.panchayatName };
                                    })}
                                    rules={{
                                        required: true,
                                        message: "This field is required",
                                    }}
                                    errors={errors}
                                    onChange={handlePanchayatChange}
                                    defaultValue={
                                        formData.panchayatName
                                        ? {
                                            value: formData.panchayatName,
                                            label: formData.panchayatName,
                                            }
                                        : null
                                    }
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputMultiSelect
                                    key={formData.villageName}
                                    isMulti={false}
                                    name="villageName"
                                    label={t("AddressInfo.VillageTown")}
                                    placeholder="Select your village/town"
                                    register={control}
                                    options={villages?.map((v:any) => {
                                        return { value: v.uuid, label: v.villageName };
                                      })}
                                    rules={""}
                                    errors={errors}
                                    onChange={handleSHGVillageChange}
                                    defaultValue={
                                        formData.villageName
                                        ? {
                                            value: formData.villageName,
                                            label: formData.villageName,
                                            }
                                        : null
                                    }
                                    />
                                </Col>
                                <Col md={6}>
                                {/* <input type='checkbox' key={Math.random()} checked={formData.isEligible} name='row-check' onChange={event => onRowCheck(event, rowId, o)} /> */}

                                <InputCheck type="checkbox" name="isEligible" label="" register={{...register("isEligible", {
                                    onChange: onChangeCheckBox
                                    })}}  errors={errors} options={checkOptions}
                                    
                                />
                                </Col>
                                
                            </Row>
                            <div style={{float: "right"}}>
                            <Button
                                className="btn background-saffron"
                                type="submit"
                                style={{ width: "max-content" }}
                            >
                                {t("Submit")}
                            </Button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}
export default ShgDetailsForm