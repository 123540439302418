import React, { useState } from "react";
import "./Login.css";
import { useForm } from "react-hook-form";
import InputText from "../common/InputText";
import { Button, Row, Col } from "react-bootstrap";
// import { CONSTANT_REGEX_SAMPLE } from "../../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CustomCaptcha from "../common/CustomCaptcha";
import { resetPassword } from "../../services/http.service"; // Import the API service
import PublicDepartments from "./PublicDepartments";
import MegaMenu from "./MegaMenu";
import PublicHeader from "./PublicHeader_old";
import PublicFooter from "./PublicFooter";
import PublicLogoHeader from "./PublicLogoHeader";

const ResetPassword = () => {
    let location = useLocation();
    const { phoneNumber, userId } = location.state || {};
    let navigate = useNavigate();
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    // const [captchaResponse, setCaptchaResponse] = useState("");
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            newPassword: ""
        }
    });

    const onSubmit = async (data: any) => {
        if (!isCaptchaValid) {
            toast.error('Please complete the CAPTCHA.', {
                position: "top-right"
            });
            return;
        }
        const payload = {
            phoneNumber: phoneNumber,
            userId: userId,
            newPassword: data.newPassword
        };

        try {
            const response = await resetPassword('/_user/_resetPassword', payload); // Update the URL to match your API endpoint
            console.log(response); //
            if (response) {
                toast.success('Pssword Reset Successfully', {
                    position: "top-right"
                });
                setTimeout(() => {
                    navigate('/mission-shakti/login');
                }, 3000);

            } else {
                toast.error('OTP Sent failed. Please check your details.', {
                    position: "top-right"
                });
            }
        } catch (error) {
            toast.error('An error occurred. Please try again later.', {
                position: "top-right"
            });
            console.error("Password reset error:", error);
        }
    };

    return (
        <>
            <PublicHeader></PublicHeader>
            <PublicLogoHeader></PublicLogoHeader>
            <MegaMenu></MegaMenu>
            <div className="login">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4 mt-2">Reset Password</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <InputText type="password" name="newPassword" label="New Password" placeholder="Enter your New Password" register={{
                                ...register("newPassword", {
                                    required: "Please enter your password",
                                    minLength: {
                                        value: 6,
                                        message: "Password should be at-least 6 characters."
                                    },
                                })
                            }} errors={errors} />

                            <CustomCaptcha onCaptchaValid={setIsCaptchaValid} />
                            <Row id="btn" className="mb-3">
                                <Col className="text-left">
                                    <Button variant="secondary" onClick={() => navigate('/mission-shakti/')}>Cancel</Button>
                                </Col>
                                <Col className="text-right">
                                    <Button type="submit" variant="primary">Submit</Button>
                                </Col>
                            </Row>

                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <PublicDepartments></PublicDepartments>
            <PublicFooter></PublicFooter>
        </>
    );
};

export default ResetPassword;
