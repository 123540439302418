import React, { useEffect, useState } from "react";
import { Button, Row, Col, Table } from "react-bootstrap";
import './InterestSubvention.css';
import ActionTable from "../../common/ActionTable";
import { t } from "i18next";
import { postData } from "../../../services/http.service";
import { URLS_ENDPOINT } from "../../../utils/urls.const";
import InputMultiSelect from "../../common/InputMultiSelect";
import { APP_MESSAGES, MONTH_LIST } from "../../../utils/constants";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { loanDetails } from "../mssyloan/PreviewConstants";

const defFormData: any = {
  financialYear: "",
  month: "",
  bankId: "",
};

const InterestSubventionReports: React.FC = () => {
    const [rowData, setRowData] = useState<any[]>([]);
    const [bankList, setBankList] = useState<any[]>([]);
    const [finYearList, setFinYearList] = useState<any[]>([]);
    const [formData, setFormData] = useState(defFormData);

    const [tableData, setTableData] = useState<any>();

        const {
          register,
          handleSubmit,
          control,           
          formState: { errors },
      } = useForm({
          defaultValues: defFormData
      });

    const monthList :any[] = [...MONTH_LIST];
    const TABLE_COLUMNS: any = [
      {
        colName: "Interest Amount",
        fieldName: "intAmt",
        clsName: "column-text-start",
        isSortable : false
       
      },
      {
        colName: "Outstanding Amount",
        fieldName: "outStandingAmt",
        clsName: "column-text-start",
        isSortable : false
       
      },
      {
        colName: "OverDue Amount",
        fieldName: "overdueAmt",
        clsName: "column-text-start",
        isSortable : false
       
      },
      {
        colName: "NPA Count",
        fieldName: "npaCount",
        clsName: "column-text-start",
        isSortable : false
      }
    ];

    const handleYearSelect = (selectedOption:any) => setFormData((prevstate:any) => ({
        ...prevstate,
        financialYear: selectedOption,
      }));
    const handleOnMonthChange = (selectedOption:any) => setFormData((prevstate:any) => ({
        ...prevstate,
        month: selectedOption,
      }));

    const handleBankSelect = (selectedOption:any) => setFormData((prevstate:any) => ({
        ...prevstate,
        bankId: selectedOption,
      }));


        // Handle search button click
    const onSubmitForm = async () => {
      try {
        const fd = new FormData();
        fd.append("financialYear", formData?.financialYear?.label);
        fd.append("month", formData?.month?.value);
        fd.append("bankId", formData?.bankId?.value);
        const URL =
          process.env.REACT_APP_MIS_SUBVENTION_SERVICE +
          URLS_ENDPOINT.SUBVENTION.IS_SEARCH;
          const response:any = await axios.post(URL, fd);
          console.log(response);
          if (response?.data?.report) {
            const rData = {...response?.data?.report};
            // let loanDetails:any[] = [];
            // let row1 = {
            //   intAmt : "Bank Interest = "+rData.bankInterest,
            //   outStandingAmt : "Bank Amount = "+rData.bankOutstanding,
            //   overdueAmt : "Bank Overdue = "+rData.bankOverdue,
            //   npaCount : "Bank Data = "+rData.npaCount,
            // }

            // let row2 = {
            //   intAmt :  "Application = "+rData.applicationInterest,
            //   outStandingAmt : "Application =  "+rData.applicationOutstanding,
            //   overdueAmt : "Application =  "+rData.applicationOverdue,
            //   npaCount : "Application = "+rData.notNpaCount,
            // }

            // let row3 = {
            //   intAmt : "Difference =  "+(rData.bankInterest - rData.applicationInterest),
            //   outStandingAmt : "Difference =  "+(rData.bankOutstanding - rData.applicationOutstanding),
            //   overdueAmt : "Difference =  "+(rData.bankOverdue - rData.applicationOverdue),
            //   npaCount : "Difference =  "+(rData.npaCount - rData.notNpaCount),
            // }
            setTableData(rData);
            // loanDetails.push(row1, row2, row3);
            setRowData(loanDetails);
         }else{
            toast.info(APP_MESSAGES.NO_DATA_MSG, {
                position: "top-right",
             });
         }
      } catch (error) {
        toast.error(APP_MESSAGES.API_ERR, {
          position: "top-right",
        });
        console.error("Error fetching bank list:", error);
      }
    };

    useEffect(() => {
      async function getBanksList() {
        try {
          const response = await postData(
            process.env.REACT_APP_BANKS_DROPDOWN_SERVICE,
            {
              bankFilterDetails: {
                bankCode: "",
                branchCode: "",
              },
            }
          );
          const bankList: any[] = response?.data?.masterBankDetails || []; // Ensure banks array is extracted from the response
          if (bankList.length > 0) {
            let tempBankList: any[] = [];
            for (const key in bankList) {
              tempBankList.push({
                value: bankList[key].code,
                label: bankList[key].bankName,
              });
            }
            setBankList(tempBankList);
          }
        } catch (error) {
          console.error("Error fetching bank list:", error);
        }
      }

      async function getFinYearList() {
        try {
          let finURL  = process.env.REACT_APP_MASTER_SERVICE+URLS_ENDPOINT.MASTER_SERVICES.FINYEAR_DROPDOWN;
          const response = await postData(finURL);
          if (response?.data?.financialYears?.length > 0) {
            let tempList: any[] = [];
            for (const key in response?.data?.financialYears) {
              tempList.push({
                value: response?.data?.financialYears[key].uuid,
                label: response?.data?.financialYears[key].financialYear,
              });
            }
            setFinYearList(tempList);
          }
        } catch (error) {
          console.error("Error fetching bank list:", error);
        }
      }
      
      getBanksList();
      getFinYearList();
    }, []);

    return (
      <div className="container mt-4">
        <ToastContainer />
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={3}>
                  <InputMultiSelect
                    key="Financial Year"
                    isMulti={false}
                    id="financialYear"
                    name="financialYear"
                    label={t("FinancialYear")}
                    placeholder="Select Financial Year"
                    register={control}
                    options={finYearList}
                    rules={{
                      required: true,
                      message: "Financial Year is required",
                    }}
                    errors={errors}
                    onChange={handleYearSelect}
                    defaultValue={
                      formData?.financialYear
                        ? {
                            ...formData?.financialYear,
                          }
                        : null
                    }
                  />
                </Col>

                <Col md={3}>
                  <InputMultiSelect
                    key="Month"
                    isMulti={false}
                    id="month"
                    name="month"
                    label={t("Month")}
                    placeholder="Select Month"
                    register={control}
                    options={monthList}
                    rules={{
                      required: true,
                      message: "Month is required",
                    }}
                    errors={errors}
                    onChange={handleOnMonthChange}
                    defaultValue={
                      formData?.month
                        ? {
                            ...formData?.month,
                          }
                        : null
                    }
                  />
                </Col>

                <Col md={5}>
                  <InputMultiSelect
                    key="Bank"
                    isMulti={false}
                    id="bankId"
                    name="bankId"
                    label={t("Bank")}
                    placeholder="Select Bank"
                    register={control}
                    options={bankList}
                    rules={{
                      required: true,
                      message: "Bank is required",
                    }}
                    errors={errors}
                    onChange={handleBankSelect}
                    defaultValue={
                      formData?.bankId
                        ? {
                            ...formData?.bankId,
                          }
                        : null
                    }
                  />
                </Col>

                <Col md={1}>
                  <Button
                    className="btn background-saffron"
                    type="submit"
                    style={{ marginTop: "1rem" }}
                  >
                    {t("Table.Search")}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>

        {rowData?.length > 0 && (
          <>
            {/* <div className="w-full">
              <ActionTable
                tableWidth={"100%"}
                columns={TABLE_COLUMNS}
                rowData={rowData}
                tableHeader={t("Table.SUBVENTION_TABLE_HEADER")}
                hideExportBtn={false}
                searchBarPlaceholder={t("Table.SEARCH_BY")}
              />
            </div> */}
            <br />
            <div className="w-full table-responsive">
              <Table bordered hover>
                <thead style={{ backgroundColor: "#007bff", color: "white" }}>
                  <tr>
                    {TABLE_COLUMNS?.map((item: any, index: number) => (
                      <th rowSpan={2} style={{ textAlign: "center" }} key={index}>
                        {item?.colName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr key={1}>
                    <td> 
                      {"Bank Interest = " + tableData.bankInterest}
                      <br />
                      {"App. Interest = " + tableData.applicationInterest}
                    </td>

                    <td>
                      {"Bank Amount = " + tableData.bankOutstanding}
                      <br />
                      {"App. Amount = " + tableData.applicationOutstanding}
                    </td>

                    <td >
                      {"Bank Overdue = " + tableData.bankOverdue}
                      <br />
                      {"App. Overdue = " + tableData.applicationOverdue}
                    </td>

                    <td>
                      {"Bank Data = " + tableData.npaCount}
                      <br />
                      {"App. Data = " + tableData.notNpaCount}
                    </td>
                  </tr>

                  <tr className="lastRow" key={2}>
                    <td>
                      Difference ={" "}
                      {tableData.bankInterest - tableData.applicationInterest}
                    </td>

                    <td>
                      Difference ={" "}
                      {tableData.bankOutstanding -
                        tableData.applicationOutstanding}
                    </td>

                    <td>
                      Difference ={" "}
                      {tableData.bankOverdue - tableData.applicationOverdue}
                    </td>

                    <td>
                      Difference = {tableData.npaCount - tableData.notNpaCount}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        )}
      </div>
    );
};

export default InterestSubventionReports;
