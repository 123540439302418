import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import InputText from "../../common/InputText";
import { t } from "i18next";
import InputCheck from "../../common/InputCheck";
import InputTextArea from "../../common/InputTextArea";

interface inputProps {
  NewsFormData: any;
  onFormSubmit: (params: any) => any;
  action: any;
}

const NewsAdd = ({ NewsFormData = {}, onFormSubmit, action }: inputProps) => {
  const [formData, setFormData] = useState(NewsFormData);

  const [checkOptions, setCheckedOptions]: any = useState([
    {
      label: "Active",
      value: true,
    },
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });
  const handleFormDetails = (event: any) => {};

  const onChangeCheckBox = (e: any) => {
    console.log("onChangeCheckBox==", e);
  };

  const handleDate = (e: any) => {
    setFormData((prevstate: any) => ({
      ...prevstate,
      publishedAt: e?.target?.value,
    }));
  };

  return (
    <>
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row>
              <Col md={12}>
                <InputTextArea
                  type="text"
                  name="content"
                  label={t("Table.newsDetails")}
                  placeholder="Enter News Details"
                  register={{
                    ...register("content", {
                      required: "Please enter News Content",
                    }),
                  }}
                  onChange={handleFormDetails}
                  errors={errors}
                  required={true}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <InputText
                  key={"publishedAt"}
                  type="date"
                  name="publishedAt"
                  label={t("Table.pubDate")}
                  placeholder="Select Publishing date"
                  register={{
                    ...register("publishedAt", {
                      required: "Please select the date",
                    }),
                  }}
                  onChange={handleDate}
                  errors={errors}
                  disabled={false}
                  required={true}
                />
              </Col>
            </Row>

            <Col md={6}>
              <InputCheck
                type="checkbox"
                name="isActive"
                label=""
                register={{
                  ...register("isActive", {
                    onChange: onChangeCheckBox,
                  }),
                }}
                errors={errors}
                options={checkOptions}
              />
            </Col>

            <div style={{ float: "right" }}>
              <Button
                className="btn background-saffron"
                type="submit"
                style={{ width: "max-content" }}
              >
                {t("Submit")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default NewsAdd;
