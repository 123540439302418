// App.tsx

import React, { useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';

import { publicRoutes, routes } from './routes/routeConfig';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.min.css';
import { useDispatch } from 'react-redux';
import { getUserData, isAuthenticated } from './utils/utils';
import { setUser } from './redux/authSlice';
import PrivateHeader from './components/private/PrivateHeader'; // Update import to use PrivateHeader
import DynamicHeader from './components/private/DynamicHeader';
// import '../src/i18n';
import { LanguageProvider } from './LanguageContext';
import { ApplicationContext,ApplicationContextProvider } from './components/ApplicationContext';
import { postData } from './services/http.service';
import { APP_MESSAGES } from './utils/constants';
import { URLS_ENDPOINT } from './utils/urls.const';

function App() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoggedIn = isAuthenticated();
  useEffect(()=>{
    const dataFetch = async () => {
      try {
        let finURL  = process.env.REACT_APP_MASTER_SERVICE+URLS_ENDPOINT.MASTER_SERVICES.UTILS_VISITOR_VISIT;
        const response: any = await postData(
          finURL,
          {
           
          }
        );

        if (response?.data && response?.data[0]) {
        }
      } catch (error) {
        // toast.error(APP_MESSAGES.CMN_ERR, {
        //   position: "top-right",
        // });
      }
    };
    dataFetch();
  },[])

  useEffect(() => {
    isLoggedIn && fetchUserData();
    // if (isLoggedIn) {
    //   const role = localStorage.getItem('role');
    //   switch (role) {
    //     case 'CSS':
    //       navigate('/mission-shakti/home');
    //       break;
    //     case 'BPC':
    //       navigate('/mission-shakti/block-official/home');
    //       break;
    //     case 'BDO':
    //       navigate('/mission-shakti/bdo/home');
    //       break;
    //     case 'CDPO':
    //       navigate('/mission-shakti/cdpo/home');
    //       break;
    //     case 'Loan bank':
    //       navigate('/mission-shakti/loan-bank/home');
    //       break;
    //     case 'Head bank':
    //       navigate('/mission-shakti/head-bank/home');
    //       break;
    //     case 'ms Department':
    //       navigate('/mission-shakti/ms-department/home');
    //       break;
    //     default:
    //       navigate('/mission-shakti/login');
    //       break;
    //   }
    // }
  }, [isLoggedIn]);

  // useEffect(() => {
  //   isLoggedIn && fetchUserData();
  //   if (isLoggedIn) {
  //     navigate("/mission-shakti/home")
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    const publicPaths = publicRoutes.map((item: any) => item.path).includes(location.pathname);
    const privatePaths = routes.map((item: any) => item.path).includes(location.pathname);
    window.scrollTo(0, 0);

    if (location.pathname === '/') {
      navigate('/mission-shakti');
    }
    if (!isLoggedIn && privatePaths) {
      navigate('/mission-shakti/login');
    }

    if (isLoggedIn && publicPaths) {

      const role = localStorage.getItem('role');
      navigate('/mission-shakti/home');
    }
  }, [location, isLoggedIn]);

  const fetchUserData = async () => {
    const userData = await getUserData();
    dispatch(setUser(userData));
  };

  return (
    <LanguageProvider>
      <ApplicationContextProvider>
      <div className='wrapper'>

        <ToastContainer autoClose={2000} style={{ color: 'black' }} />

        {
          !isLoggedIn && (
            <div>
              <Routes>
                {publicRoutes.map((route: any) => (
                  <Route key={route.path} path={route.path} element={<route.component />} />
                ))}
              </Routes>
            </div>
          )
        }
        {
          isLoggedIn && (
            <div style={{ height: "Auto" }}>
              <PrivateHeader /> {/* Render PrivateHeader for logged-in users */}
              <div className='container private-container pt-6'>
                <DynamicHeader isLoggedIn={isLoggedIn} />
                <Routes>
                  {routes.map((route: any) => <Route key={route.path} path={route.path} element={<route.component />} />)}
                </Routes>
              </div>
            </div>
          )
        }
      </div>
      </ApplicationContextProvider>
    </LanguageProvider>
  );
}

export default App;
