// import React, { useEffect, useState } from 'react';
// import { FloatingLabel, Form } from 'react-bootstrap';
// import { Controller } from 'react-hook-form';
// import Select from 'react-select';

// const InputMultiSelect = (props: any) => {

//     return (
//         <>
//             <Form.Group className="mb-3" controlId={props?.name}>
//                 <Form.Label>{props?.label}</Form.Label>
//                 <Controller
//                     name={props?.name}
//                     control={props?.register}
//                     rules={props?.rules}
//                     render={({ field }) => (
//                         <Select {...field} isMulti={props?.isMulti} options={props?.options}
//                             onChange={(e) => { props?.onChange(e); field.onChange(e) }}
//                         />
//                     )}
//                 />
//                 {props?.errors[props?.name] && <p className="errorMsg">This field is required</p>}
//             </Form.Group>
//         </>
//     )

// }
// export default InputMultiSelect

import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
// import Select from "react-select";
import "./InputMultiSelect.css";
import Select from "react-select";



const InputMultiSelect = (props: any) => {
  return (
    <FloatingLabel
      className="mb-3 parentDiv"
      controlId={props?.name}
      label={
        <>
          {props?.label}
          {props?.rules?.required && <span className="text-danger ms-1">*</span>}
        </>
      }
    >
      <Controller
        name={props?.name}
        control={props?.register}
        rules={props?.rules}
        render={({ field }) => (
          <Select
            {...field}
            isMulti={props?.isMulti}
            options={props?.options}
            onChange={(e) => {
              props?.onChange(e);
              field.onChange(e);
            }}
            value={props?.value}
            defaultValue={props?.defaultValue}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: "none",
                boxShadow: "none",
                borderRadius: 0,
                borderBottom: "1px solid black",
                minHeight: "57px",
                "&:hover": {
                  border: "none",
                  boxShadow: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid black",
                },
              }),
            }}
            className="select"
            isDisabled={props?.disabled}
            // isDisabled={userRole === "CSS" ? false : true}
          />
        )}
      />
      {props?.errors[props?.name] && (
        <p className="errorMsg">This field is required</p>
      )}
    </FloatingLabel>
  );
};

export default InputMultiSelect;
