import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  scales,
  RadialLinearScale,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar, Doughnut, PolarArea } from "react-chartjs-2";
import InputMultiSelect from "../../common/InputMultiSelect";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Card, Col, Row, Button, Alert, Spinner } from "react-bootstrap";
import {
  APP_MESSAGES,
  FIN_YEAR_MONTHS,
  MONTH_LIST,
} from "../../../utils/constants";
import { postData } from "../../../services/http.service";
import { URLS_ENDPOINT } from "../../../utils/urls.const";
import { Colors } from "chart.js";
import { ToastContainer, toast } from "react-toastify";
import { assert } from "console";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  RadialLinearScale
);

export const barChartOptions: any = {
  indexAxis: "x",
  responsive: true,
  maintainAspectRatio: false,
  scaleShowValues: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Status of fund disbursement by Mission Shakti Department",
    },
  },
};

export const doughnutChartOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scaleShowValues: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Status of Scooter Loans",
    },
  },

  type: "doughnut",
  data: [],
};

const polarChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Performance assessment of Banks  ",
    },
  },
};
const chartsDataStruct: any = {
  statusofScooterLoans: [
    {
      titleName: "No. of Loans applied",
      field: "numberOfLoansApplied",
      count: 0,
    },
    {
      titleName: "No. of Loans in process",
      count: 0,
      field: "numberOfLoansProcessed",
    },
    {
      titleName: "No. of Loans approved",
      count: 0,
      field: "numberOfLoansApproved",
    },
    {
      titleName: "No. of Loans Rejected",
      count: 0,
      field: "numberOfLoandsRejected",
    },
  ],

  statusofFundDisBurse: [
    {
      titleName: "Total fund Approved",
      count: 150,
    },
    {
      titleName: "Total Fund Processed",
      count: 30,
    },
    {
      titleName: "Total Fund Released / Disbursed",
      count: 50,
    },
    {
      titleName: "Total Fund Utilized",
      count: 20,
    },
    {
      titleName: "Total Fund Balance with Mission Shakti Department",
      count: 50,
    },
  ],
};

const defFilterFormData: any = {
  district: "",
  financialYear: "",
  month: "",
  pbank: "",
  pdistrict: "",
  pfinancialYear: "",
  pmonth: "",
};

const assessmentBanksDef: any = {
  district: "",
  financialYear: "",
  month: "",
  bank: "",
};
const AdminAnalyticsView = () => {
  const [filterFormData, setfilterFormData] = useState(defFilterFormData);
  const [assessmentData, setAssessmentData] = useState({
    ...assessmentBanksDef,
  });
  const [finYearList, setFinYearList] = useState<any[]>([]);
  const [barChartData, setBarChartData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [polarChartGraph, setPolarChartGraph] = useState<any>();

  const [bankList, setBankList] = useState<any[]>([]);
  const [doughNutChartlabels, setDoughNutChartLabels] = useState([]);
  const [doughNutChartData, setDoughNutChartData] = useState([]);
  const [scooterLoanStatusReports, setScooterLoanStatusReports] =
    useState<any>();

  const masterBaseURL: any = process.env.REACT_APP_MASTER_SERVICE;
  const masterServiceEndPoint: any = URLS_ENDPOINT?.MASTER_SERVICES;
  const monthList: any[] = [...MONTH_LIST];
  const [districts, setDistricts] = useState([]);
  const [yearList, setYearList] = useState([]);
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: filterFormData,
  });

  useEffect(() => {
    // if (chartsDataStruct) {
    // if (chartsDataStruct?.districtsWiseCounts?.length > 0) {
    //   let barLabels: any = [];
    //   let barDataList: any = [];
    //   chartsDataStruct?.districtsWiseCounts?.forEach((e: any) => {
    //     barLabels.push(e.districtName);
    //     barDataList.push(e.approvedCount);
    //   });
    //   setBarChartLabels(barLabels);
    //   setBarChartData(barDataList);
    // }

    //   if (chartsDataStruct?.statusofFundDisBurse?.length > 0) {
    //     let labels: any = [];
    //     let dataList: any = [];
    //     chartsDataStruct?.statusofFundDisBurse?.forEach((e: any) => {
    //       labels.push(e.titleName);
    //       dataList.push(e.count);
    //     });
    //     setBarChartLabels(labels);
    //     setBarChartData(dataList);
    //   }
    // }

    let today: any = new Date();
    let currentYear: any = today.getFullYear();
    let years: any = [
      {
        label: currentYear,
        value: currentYear,
      },
    ];
    for (let i = 1; i < 11; i++) {
      let obj: any = {
        label: currentYear - i,
        value: currentYear - i,
      };
      years.push(obj);
    }
    setYearList(years);

    async function loadDistricts() {
      const response: any = postData(
        masterBaseURL + masterServiceEndPoint?.ADDRESS_DROPDOWN
      );

      response
        .then((res: any) => {
          const { state, districts } = res?.data?.addressDetails;
          setDistricts(districts);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    async function getFinYearList() {
      try {
        let finURL =
          process.env.REACT_APP_MASTER_SERVICE +
          URLS_ENDPOINT.MASTER_SERVICES.FINYEAR_DROPDOWN;
        const response = await postData(finURL);
        if (response?.data?.financialYears?.length > 0) {
          let tempList: any[] = [];
          for (const key in response?.data?.financialYears) {
            tempList.push({
              value: response?.data?.financialYears[key].uuid,
              label: response?.data?.financialYears[key].financialYear,
            });
          }
          setFinYearList(tempList);
        }
      } catch (error) {
        console.error("Error fetching bank list:", error);
      }
    }

    async function getBanksList() {
      try {
        const response = await postData(
          process.env.REACT_APP_BANKS_DROPDOWN_SERVICE,
          {
            bankFilterDetails: {
              bankCode: "",
              branchCode: "",
            },
          }
        );
        const bankList: any[] = response?.data?.masterBankDetails || []; // Ensure banks array is extracted from the response
        if (bankList.length > 0) {
          let tempBankList: any[] = [];
          for (const key in bankList) {
            tempBankList.push({
              value: bankList[key].code,
              label: bankList[key].bankName,
            });
          }
          setBankList(tempBankList);
        }
      } catch (error) {
        console.error("Error fetching bank list:", error);
      }
    }

    getFinYearList();
    loadDistricts();
    getBanksList();
    fetchDoNutAndBarChartData();
    fetchPolarChartData();
  }, [assessmentData]);

  // Function to fetch ALL apllications list for ADMIN
  const fetchDoNutAndBarChartData = async (
    fYear?: string,
    month?: number,
    district?: string
  ) => {
    try {
      const token = localStorage.getItem("token");
      const userInfo = JSON.parse(localStorage.getItem("UserDetails") || "{}");

      if (token && userInfo) {
        setLoading(true);
        const response: any = await postData(
          process.env.REACT_APP_APPLICATIONS_SERVICE +
            URLS_ENDPOINT.TRENDS.LOAN_STATUS_REPORT,
          {
            userInfo: {},
            analyticsChartCriteria: {
              financialYear: fYear || "2024-25",
              month: month || null,
              districtName: district || "",
            },
          }
        );

        if (response?.data) {
          const doughtNutApiRes = response?.data?.scooterLoanStatusReports
            ? { ...response?.data?.scooterLoanStatusReports }
            : null;
          if (doughtNutApiRes) {
            if (chartsDataStruct?.statusofScooterLoans?.length > 0) {
              let labels: any = [];
              let dataList: any = [];
              chartsDataStruct?.statusofScooterLoans?.forEach((e: any) => {
                labels.push(e.titleName);
                dataList.push(doughtNutApiRes[e.field]);
              });
              setDoughNutChartLabels(labels);
              setDoughNutChartData(dataList);
              setScooterLoanStatusReports(doughtNutApiRes);
            } else {
              setDoughNutChartLabels([]);
              setDoughNutChartData([]);
              setScooterLoanStatusReports(null);
            }
          } else {
            setDoughNutChartLabels([]);
            setDoughNutChartData([]);
            setScooterLoanStatusReports(null);
          }
        } else {
          toast.info("No Data available for the selected filters", {
            position: "top-right",
          });
        }

        const barChartRes: any = await postData(
          process.env.REACT_APP_APPLICATIONS_SERVICE +
            URLS_ENDPOINT.TRENDS.LOAN_FUNDS_REPORT,
          {
            userInfo: {},
            analyticsChartCriteria: {
              financialYear: fYear || "2024-25",
              month: month || null,
              districtName: district || "",
            },
          }
        );

        if (barChartRes?.data) {
          const barChartApiRes = barChartRes?.data
            ?.fundDisbursmentAnalyticsReports
            ? [...barChartRes?.data?.fundDisbursmentAnalyticsReports]
            : [];
          const cdata: any = processLoanAnalyticsData(barChartApiRes);
          setBarChartData(cdata);
        } else {
          toast.info("No Funds Data available for the selected filters", {
            position: "top-right",
          });
        }
      } else {
        console.error("User not authenticated");
        toast.error("User not authenticated", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const processLoanAnalyticsData = (analytics: any[]) => {
    const labels = [...FIN_YEAR_MONTHS];

    const monthlyData = {
      totalFundApproved: Array(12).fill(0),
      totalFundProcessed: Array(12).fill(0),
      totalFundReleasedDisbursed: Array(12).fill(0),
      totalFundUtilised: Array(12).fill(0),
      totalFundBalance: Array(12).fill(0),
    };

    analytics.forEach((report) => {
      // const monthIndex = (report.month + 2) % 12; // Month is 1-based in the API response
      const monthIndex = labels.indexOf(report.monthName.toUpperCase());
      monthlyData.totalFundApproved[monthIndex] = report.totalFundApproved || 0;
      monthlyData.totalFundProcessed[monthIndex] =
        report.totalFundProcessed || 0;
      monthlyData.totalFundReleasedDisbursed[monthIndex] =
        report.totalFundReleasedDisbursed || 0;
      monthlyData.totalFundUtilised[monthIndex] = report.totalFundUtilised || 0;
      monthlyData.totalFundBalance[monthIndex] = report.totalFundBalance || 0;
    });

    if (analytics) {
      return {
        labels,
        datasets: [
          {
            label: "Total Fund Approved",
            data: monthlyData?.totalFundApproved || [],
            borderColor: "#32CD32",
            backgroundColor: "#32CD32",
          },
          {
            label: "Total Fund Processed",
            data: monthlyData?.totalFundProcessed || [],
            borderColor: "#FFB1C1",
            backgroundColor: "#FFB1C1",
          },
          {
            label: "Total fund Released / Disbursed",
            data: monthlyData?.totalFundReleasedDisbursed || [],
            borderColor: "#FFD700",
            backgroundColor: "#FFD700",
          },
          {
            label: "Total fund Utilized",
            data: monthlyData?.totalFundUtilised || [],
            borderColor: "#9BD0F5",
            backgroundColor: "#9BD0F5",
          },
          {
            label: "Total Fund balance with Mission Shakti Department",
            data: monthlyData?.totalFundBalance || [],
            borderColor: "#FF4500",
            backgroundColor: "#FF4500",
          },
        ],
      };
    } else {
      return {
        labels,
        datasets: [],
      };
    }
  };

  doughnutChartOptions.data =
    doughNutChartData.length > 0 ? [...doughNutChartData] : [];

  const DNutChartFinalData = {
    labels: doughNutChartlabels?.length > 0 ? [...doughNutChartlabels] : [],
    datasets: [
      {
        data: doughNutChartData?.length > 0 ? [...doughNutChartData] : [],
        backgroundColor: ["#ff6402", "#36A2EB", "#82e0aa", "#ec7063"],
        hoverBackgroundColor: ["#ff6402", "#36A2EB", "#82e0aa", "#ec7063"],
      },
    ],
    text: scooterLoanStatusReports?.totalApplications
      ? "Total: " + scooterLoanStatusReports?.totalApplications
      : "",
  };

  const handleOnYearChange = (e: any) => {
    console.log("handleOnYearChange==", e);
    setfilterFormData((prevstate: any) => ({
      ...prevstate,
      financialYear: e,
    }));
  };

  const handleOnMonthChange = (e: any) => {
    console.log("handleOnMonthChange==", e);
    setfilterFormData((prevstate: any) => ({
      ...prevstate,
      month: e,
    }));
  };

  const handleOnDistChange = (e: any) => {
    setfilterFormData((prevstate: any) => ({
      ...prevstate,
      district: e,
    }));
  };

  const handleAssesmentData = (e: any, type: string) => {
    console.log(e, "e");
    setAssessmentData((prevstate: any) => ({
      ...prevstate,
      [type]: e.label,
    }));
  };
  const handleFilterReset = async (e: any) => {
    reset();
    setValue("district", "");
    setValue("financialYear", "");
    setValue("month", "");
    setfilterFormData(defFilterFormData);
  };

  const onSubmitFilterForm = async (e: any) => {
    try {
      if (e && Object.keys(e).length > 0) {
        console.log(e);
        const year = e?.financialYear?.label;
        const mon = e?.month?.value;
        if (mon && !year) {
          toast.warning("Please select Year to continue...", {
            position: "top-right",
          });
          return;
        }

        fetchDoNutAndBarChartData(year, mon, e?.district?.label);
      }
    } catch (error) {
      console.error("Error onSubmit Filter Form :", error);
    }
  };
  const fetchPolarChartData = async (
    bank?: string,
    month?: number,
    district?: string,
    financialYear?: string
  ) => {
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("UserDetails") || "{}");
    if (token && userInfo) {
      try {
        const response: any = await postData(
          process.env.REACT_APP_APPLICATIONS_SERVICE +
            "/report/performanceAssessmentOfBanksReports",
          {
            analyticsChartCriteria: {
              financialYear: financialYear || "2024-25",
              month: month || null,
              districtName: district || "",
              bankName: bank || "",
            },
          }
        );
        if (response?.data?.performanceAssesmentReports) {
          let dataArray = [];
          console.log(response?.data?.performanceAssesmentReports, "vf");
          const values = response?.data?.performanceAssesmentReports[0];
          if (values) {
            dataArray.push(
              values?.numberOfLoansProcessed,
              values?.numberOfLoansTransit,
              values?.numberOfLoansRejected,
              values?.averageLoanProcessingTime,
              values?.standardReportingTimeToDepartment,
              values?.numberOfNPA,
              values?.numberOfLoanDefaulters
            );
          }

          const graphData = {
            labels: [
              "Loans Processed",
              "Loans in Transit",
              "Loans Rejected",
              "Average Processing Time",
              "Standard Reporting Time",
              "Non-Performing Assets (NPAs)",
              "Loan Defaulters",
            ],
            datasets: [
              {
                label: "Loan Statistics",
                data: dataArray,
                backgroundColor: [
                  "rgba(255, 99, 132, 0.6)",
                  "rgba(54, 162, 235, 0.6)",
                  "rgba(255, 206, 86, 0.6)",
                  "rgba(75, 192, 192, 0.6)",
                  "rgba(153, 102, 255, 0.6)",
                  "rgba(255, 159, 64, 0.6)",
                  "rgba(255, 206, 86, 0.6)",
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                  "rgba(255, 159, 64, 1)",
                  "rgba(255, 206, 86, 1)",
                ],
                borderWidth: 1,
              },
            ],
          };
          console.log(graphData, "gdata");
          setPolarChartGraph(graphData);
        }
      } catch (err) {
        console.error("Failed to fetch data:", err);
        toast.error("Data Not Found", {
          position: "top-right",
        });
      }
    }
  };
  const polarGraphData = {
    labels: [
      "Loans Processed",
      "Loans in Transit",
      "Loans Rejected",
      "Average Processing Time",
      "Standard Reporting Time",
      "Non-Performing Assets (NPAs)",
      "Loan Defaulters",
    ],
    datasets: [
      {
        label: "Loan Statistics",
        data: [50, 30, 20, 15, 10, 9, 5], // Example values for each parameter
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(255, 206, 86, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const handlePolarFilter = async (e: any) => {
    e.preventDefault();
    fetchPolarChartData(
      assessmentData?.bank,
      assessmentData?.month,
      assessmentData?.district,
      assessmentData?.financialYear
    );
  };
  const resetPolarFilters = () => {
    setAssessmentData({ ...assessmentBanksDef });
  };
  return (
    <>
      <div className="portal-analytic-main">
        {/* <div
          style={{ padding: "0.5rem" }}
          className="analytic-header"
        >
          Analytic View
        </div> */}
        <ToastContainer />
        <div>
          <div className=" tab-content" id="myTabContent">
            <Card>
              <form
                style={{ padding: "1rem", margin: 0 }}
                onSubmit={handleSubmit(onSubmitFilterForm)}
              >
                <Row>
                  <Col md={3}>
                    <InputMultiSelect
                      key={filterFormData.district}
                      isMulti={false}
                      name="district"
                      label={t("AddressInfo.District")}
                      placeholder="Select your district"
                      register={control}
                      options={districts?.map((d: any) => {
                        return {
                          value: d.uuid,
                          label: d.districtName,
                        };
                      })}
                      errors={errors}
                      onChange={handleOnDistChange}
                      defaultValue={
                        filterFormData?.district
                          ? {
                              ...filterFormData?.district,
                            }
                          : null
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <InputMultiSelect
                      key={filterFormData.financialYear}
                      isMulti={false}
                      id="financialYear"
                      name="financialYear"
                      label={t("FinancialYear")}
                      register={control}
                      options={finYearList}
                      errors={errors}
                      onChange={handleOnYearChange}
                      defaultValue={
                        filterFormData?.financialYear
                          ? {
                              ...filterFormData?.financialYear,
                            }
                          : null
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <InputMultiSelect
                      key={filterFormData.month}
                      isMulti={false}
                      name="month"
                      label={t("Month")}
                      placeholder="Select Month"
                      register={control}
                      options={monthList}
                      errors={errors}
                      onChange={handleOnMonthChange}
                      defaultValue={
                        filterFormData?.month
                          ? {
                              ...filterFormData?.month,
                            }
                          : null
                      }
                    />
                  </Col>

                  <Col md={3} style={{ textAlign: "center" }}>
                    <Button
                      className="btn background-saffron"
                      type="submit"
                      style={{ marginTop: "1rem", width: "5rem" }}
                    >
                      {t("Filter")}
                    </Button>

                    <Button
                      className="btn btn-reset"
                      type="reset"
                      onClick={handleFilterReset}
                      style={{ marginTop: "1rem", marginLeft: "1rem" }}
                    >
                      {t("Reset")}
                    </Button>
                  </Col>
                </Row>
              </form>

              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <Row>
                  <Col
                    md={5}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {doughNutChartData.length === 0 ? (
                      <Alert variant="warning" className="text-center">
                        <h5>No Data Available</h5>
                        <p>
                          The current dataset does not contain any values to
                          display. Please check the using diffrent filters.
                        </p>
                      </Alert>
                    ) : (
                      <Doughnut
                        options={doughnutChartOptions}
                        data={DNutChartFinalData}
                        className="protal-chart"
                        width={"500px"}
                        height={"500px"}
                      />
                    )}
                  </Col>

                  <Col md={7}>
                    {loading ? (
                      <Spinner
                        className="text-center"
                        animation="border"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : barChartData?.datasets?.length === 0 ||
                      barChartData === undefined ? (
                      <Alert variant="warning" className="text-center">
                        <h5>No Data Available</h5>
                        <p>
                          The current dataset does not contain any meaningful
                          values for display. Please check the data source for
                          updates.
                        </p>
                      </Alert>
                    ) : (
                      <Bar
                        options={barChartOptions}
                        data={barChartData}
                        className="protal-chart"
                        width={"500px"}
                        height={"500px"}
                      />
                    )}
                  </Col>
                </Row>
              </div>

              {/* ------------- Polar Chart--------- */}
              <>
                <Row style={{ padding: "1rem", margin: 0 }}>
                  <form>
                    <Row>
                      <Col md={4}>
                        <InputMultiSelect
                          key={assessmentData.bank}
                          isMulti={false}
                          name="bank"
                          label={t("Bank")}
                          placeholder="Select Bank"
                          register={control}
                          options={bankList}
                          errors={errors}
                          onChange={(e: any) => handleAssesmentData(e, "bank")}
                          defaultValue={
                            assessmentData?.bank
                              ? {
                                  value: assessmentData?.bank,
                                  label: assessmentData?.bank,
                                }
                              : null
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <InputMultiSelect
                          key={assessmentData.district}
                          isMulti={false}
                          name="district"
                          label={t("AddressInfo.District")}
                          placeholder="Select your district"
                          register={control}
                          options={districts?.map((d: any) => {
                            return {
                              value: d.uuid,
                              label: d.districtName,
                            };
                          })}
                          errors={errors}
                          onChange={(e: any) =>
                            handleAssesmentData(e, "district")
                          }
                          defaultValue={
                            assessmentData?.district
                              ? {
                                  value: assessmentData?.district,
                                  label: assessmentData?.district,
                                }
                              : null
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <InputMultiSelect
                          key={assessmentData.financialYear}
                          isMulti={false}
                          name="financialYear"
                          label={t("FinancialYear")}
                          placeholder="Select Financial Year"
                          register={control}
                          options={finYearList}
                          errors={errors}
                          onChange={(e: any) =>
                            handleAssesmentData(e, "financialYear")
                          }
                          defaultValue={
                            assessmentData.financialYear
                              ? {
                                  value: assessmentData.financialYear,
                                  label: assessmentData.financialYear,
                                }
                              : null
                          }
                        />
                      </Col>

                      <Col md={3}>
                        <InputMultiSelect
                          key={assessmentData.month}
                          isMulti={false}
                          name="month"
                          label={t("Month")}
                          placeholder="Select Month"
                          register={control}
                          options={monthList}
                          errors={errors}
                          onChange={(e: any) => handleAssesmentData(e, "month")}
                          defaultValue={
                            assessmentData.month
                              ? {
                                  value: assessmentData.month,
                                  label: assessmentData.month,
                                }
                              : null
                          }
                        />
                      </Col>
                    </Row>

                    <Col md={3} style={{ float: "right", textAlign: "right" }}>
                      <Button
                        className="btn background-saffron"
                        type="submit"
                        onClick={handlePolarFilter}
                        style={{ marginTop: "0.5rem", width: "5rem" }}
                      >
                        {t("Filter")}
                      </Button>

                      <Button
                        className="btn btn-reset"
                        type="reset"
                        style={{ marginTop: "0.5rem", marginLeft: "1rem" }}
                        onClick={resetPolarFilters}
                      >
                        {t("Reset")}
                      </Button>
                    </Col>
                  </form>
                </Row>
              </>
              <Row className="justify-content-center">
                <Col md={6}>
                  <PolarArea
                    data={polarChartGraph || polarGraphData}
                    options={polarChartOptions}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAnalyticsView;
