import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import InputMultiSelect from "../common/InputMultiSelect";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toTitleCase } from "../../utils/constants";
import { postData } from "../../services/http.service";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options: any = {
  indexAxis: "y",
  responsive: true,
  maintainAspectRatio: false,
  scaleShowValues: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "District-wise scooters rolled out",
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        padding: 10,
        autoSkip: false,
        fontSize: 10,
      },
    },
    y: {
      stacked: true,
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        padding: 10,
        autoSkip: false,
        fontSize: 10,
      },
      grid: {
        display: false,
      },
    },
  },
};

const response: any = {
  districtsWiseCounts: [
    {
      districtName: "ANGUL",
      approvedCount: 10,
    },
    {
      districtName: "Angul",
      approvedCount: 20,
    },
    {
      districtName: "BALESHWAR",
      approvedCount: 0,
    },
    {
      districtName: "BARGARH",
      approvedCount: 30,
    },
    {
      districtName: "BHADRAK",
      approvedCount: 40,
    },
    {
      districtName: "BOLANGIR",
      approvedCount: 22,
    },
    {
      districtName: "BOUDH",
      approvedCount: 456,
    },
    {
      districtName: "Balasore",
      approvedCount: 234,
    },
    {
      districtName: "CUTTACK",
      approvedCount: 544,
    },
    {
      districtName: "DEOGARH",
      approvedCount: 230,
    },
    {
      districtName: "DHENKANAL",
      approvedCount: 500,
    },
    {
      districtName: "GAJAPATI",
      approvedCount: 200,
    },
    {
      districtName: "GANJAM",
      approvedCount: 2000,
    },
    {
      districtName: "JAGATSINGHAPUR",
      approvedCount: 1000,
    },
    {
      districtName: "JAJPUR",
      approvedCount: 6000,
    },
    {
      districtName: "JHARSUGUDA",
      approvedCount: 3780,
    },
    {
      districtName: "Jajpur",
      approvedCount: 133,
    },
    {
      districtName: "KALAHANDI",
      approvedCount: 4890,
    },
    {
      districtName: "KANDHAMAL",
      approvedCount: 388,
    },
    {
      districtName: "KENDRAPARA",
      approvedCount: 877,
    },
    {
      districtName: "KENDUJHAR",
      approvedCount: 8765,
    },
    {
      districtName: "KHORDHA",
      approvedCount: 4565,
    },
    {
      districtName: "KORAPUT",
      approvedCount: 377,
    },
    {
      districtName: "MALKANGIRI",
      approvedCount: 599,
    },
    {
      districtName: "MAYURBHANJ",
      approvedCount: 4987,
    },
    {
      districtName: "NABARANGAPUR",
      approvedCount: 1809,
    },
    {
      districtName: "NAYAGARH",
      approvedCount: 5898,
    },
    {
      districtName: "NUAPADA",
      approvedCount: 989,
    },
    {
      districtName: "PURI",
      approvedCount: 1000,
    },
    {
      districtName: "RAYAGADA",
      approvedCount: 3000,
    },
    {
      districtName: "SAMBALPUR",
      approvedCount: 5900,
    },
    {
      districtName: "SUBARNAPUR",
      approvedCount: 40,
    },
    {
      districtName: "SUNDARGARH",
      approvedCount: 20,
    },
  ],
  totalApprovedCounts: 1,
  yearWiseCounts: [
    {
      month: "January",
      count: 50,
    },
    {
      month: "February",
      count: 100,
    },
    {
      month: "March",
      count: 30,
    },
    {
      month: "April",
      count: 45,
    },
    {
      month: "May",
      count: 86,
    },
    {
      month: "June",
      count: 23,
    },
    {
      month: "July",
      count: 66,
    },
    {
      month: "August",
      count: 10,
    },
    {
      month: "September",
      count: 85,
    },
    {
      month: "October",
      count: 95,
    },
    {
      month: "November",
      count: 100,
    },
    {
      month: "December",
      count: 30,
    },
  ],
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June','July', 'August', 'September', 'October', 'November', 'December'];

const PortalAnalyticView = () => {
  const [labels, setBarChartLabels] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [lineChartLabels, setLineChartLabels] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  // const yearList:any = [{value: 2023, label: 2023},{value: 2024, label: 2024}];
  const [yearList, setYearList] = useState([]);

  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    let today: any = new Date();
    let currentYear: any = today.getFullYear();
    let years: any = [
      {
        label: currentYear,
        value: currentYear,
      },
    ];
    for (let i = 1; i < 11; i++) {
      let obj: any = {
        label: currentYear - i,
        value: currentYear - i,
      };
      years.push(obj);
    }
    setYearList(years);
    const fetchData = async () => {
      const response = await postData(
        "http://164.52.212.74:8087/mis-application-service/_application/getApprovedApplicationCounts",
        {
          year: "2024",
        }
      );
      if (response && response.data) {
        let barLabels: any = [];
        let barDataList: any = [];
        response?.data?.districtsWiseCounts.map((e: any) => {
          barLabels.push(toTitleCase(e.districtName));
          barDataList.push(e.approvedCount);
        });
        setBarChartLabels(barLabels);
        setBarChartData(barDataList);
        setBarChartData(response?.data?.districtsWiseCounts);
        let lineLabels: any = [],
          lineDataList: any = [];
        response?.data?.yearWiseCounts.map((e: any) => {
          lineLabels.push(e.month);
          lineDataList.push(e.count);
        });
        setLineChartLabels(lineLabels);
        setLineChartData(lineDataList);
      }
    };
    fetchData();
  }, []);
  const BarChartData = {
    labels,
    datasets: [
      {
        axis: "y",
        label: "Total Scooters Rolled out",
        data: barChartData,
        fill: true,
        backgroundColor: "#ff6402",
        borderWidth: 1,
      },
    ],
  };
  const lineChart: any = {
    labels: lineChartLabels,
    datasets: [
      {
        label: "Month-wise Scooter Rolled Out",
        data: lineChartData,
        fill: true,
        borderColor: "#FFCD00",
        tension: 0.1,
      },
    ],
  };
  console.log(barChartData, "chartdata");
  const handleDateChange = async (e: any) => {
    if (e.value) {
      try {
        const response = await postData(
          process.env.REACT_APP_MIS_APPLICATION_COUNT,
          {
            year: e.value,
          }
        );
        if (response && response.data) {
          let barLabels: any = [];
          let barDataList: any = [];
          response?.data?.districtsWiseCounts.map((e: any) => {
            barLabels.push(toTitleCase(e.districtName));
            barDataList.push(e.approvedCount);
          });
          setBarChartLabels(barLabels);
          setBarChartData(barDataList);
          setBarChartData(response?.data?.districtsWiseCounts);
          let lineLabels: any = [],
            lineDataList: any = [];
          response?.data?.yearWiseCounts.map((e: any) => {
            lineLabels.push(e.month);
            lineDataList.push(e.count);
          });
          setLineChartLabels(lineLabels);
          setLineChartData(lineDataList);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  return (
    <>
      <div
        style={{ marginTop: "30px", marginBottom: "20px" }}
        className="container"
      >
        <div className="portal-analytic-main">
          <div>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row">
                  <div style={{}} className="col-sm-6">
                    <Bar
                      options={options}
                      data={BarChartData}
                      className="protal-chart"
                      width={"400px"}
                      height={"600px"}
                    />
                  </div>
                  <div style={{ height: "400px" }} className="col-sm-6">
                    <InputMultiSelect
                      key={"year"}
                      isMulti={false}
                      name="year"
                      label={t("year")}
                      register={control}
                      options={yearList}
                      // rules={{
                      //   required: true,
                      //   message: "This field is required",
                      // }}
                      errors={errors}
                      onChange={handleDateChange}
                    />
                    <Line
                      data={lineChart}
                      className="protal-chart"
                      height={"200px"}
                      options={{ maintainAspectRatio: false }}
                    />
                    <div className="happy-count">
                      <div id="emoji" className="emoji">
                        {t("HAPPYWOMEN")} : {response?.totalApprovedCounts}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                ...Profile
              </div>
              <div
                className="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                ...contact
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalAnalyticView;
