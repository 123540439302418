import React, { useEffect, useState } from "react";
import ActionTable from "../../../common/ActionTable";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_COLUMN_DATA } from "../../home/HomeConst";
import { getApplications } from '../../../../services/http.service';
// import NewTable from "../../common/NewTable";

const LoanBankHome = () => {
    const columns = NOTIFICATION_COLUMN_DATA;
    const [rowData, setRowData] = useState([
        // {
        //     module: "abc",
        //     id: "1",
        //     tokenid:"123",
        //     bebeficiary: "mmm",
        //     panchayat:"test",
        //     createdDate: "5/31/2024",
        //     status: "active",
        // },
        // {
        //     module: "def",
        //     id: "2",
        //     tokenid:"123",
        //     bebeficiary: "mmm",
        //     panchayat:"test",
        //     createdDate: "5/31/2024",
        //     status: "active"
        // },
        // {
        //     module: "ghi",
        //     id: "3",
        //     tokenid:"123",
        //     bebeficiary: "mmm",
        //     panchayat:"test",
        //     createdDate: "5/31/2024",
        //     status: "active"
        // },
        
    ]);
    const navigate = useNavigate();
    const [selectedIds, setSelectedIds] = useState([]);

    const handleNotificationRowClick = async (rowData: any) => {
        try {
            // const { data } = await POST(`/idc-notification-service/usernotification/details/_markasread?userNotificationIds=${rowData.id}`, { RequestInfo: requestInfo() });
            // navigate(rowData.notificationDetails.navUrl);
            // dispatch(setNotificationCount(data.unreadCount));
        } catch (error) {
            navigate('/mission-shakti/');
        }
    };
    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const userInfo = JSON.parse(localStorage.getItem('UserDetails') || '{}');

            if (token && userInfo) {
                const dashboardApplications = await getApplications(token, {
                    uuid: userInfo.uuid,
                    rolesCode: userInfo.roles[0].roleName,
                });

                const formattedData = dashboardApplications.map((item: any) => {
                    console.log('API Item:', item); // Log the entire item

                    const district = item.district || '';
                    const block = item.block || '';
                    const panchayat = item.panchayat || '';

                    const jurisdiction = [district, block, panchayat].filter(part => part).join('/');
                    console.log('Jurisdiction:', jurisdiction);

                    return {
                        ...item,
                        // submittedDate: formatDate(item.submittedDate),
                        // createdDate: formatDate(item.createdDate),
                        jurisdiction: jurisdiction
                    };
                });

                setRowData(formattedData);


            } else {
                console.error('User not authenticated');
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);


    const onCheckNotification = (selected: any) => {
        setSelectedIds(selected);
    };
    return (
        <>
            <div className="w-full p-4">
                {/* This is Home */}
                <div>
                <ActionTable
                    columns={columns}
                    rowData={rowData}
                    additionalMethod={handleNotificationRowClick}
                    // checkType='multiCheck'
                    onCheckHandler={onCheckNotification}
                    searchBarPlaceholder='SEARCH_BY'
                    />
                </div>
               
               
            </div>
        </>
    )
}
export default LoanBankHome;