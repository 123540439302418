import PublicFooter from './PublicFooter';
import PublicHeader from './PublicHeader';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import OrganizationalStructureImg from "../../assets/images/organizationalStructure.png";
import { useTranslation } from 'react-i18next';

const OrganizationStructurePage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };
    return (
        <>
            <PublicHeader />
            <div style={{paddingTop: "110px",paddingBottom: "20px"}}> 
                <div className='container' >
                    <h2><IoArrowBack onClick={handleBackClick}/> {t('Organizational Structure')} </h2>
                    <img src={OrganizationalStructureImg} alt="Organizational Structure" />
                </div>
            </div>
            <PublicFooter />
        </>
        
    );
};

export default OrganizationStructurePage;
