const getUserRole = () => {
  return localStorage.getItem("role");
};

const getNotificationColumnData = () => {
  const userRole = getUserRole();
  let actions = [];

  switch (userRole) {
    case "CONTROLLING_BANK":
      actions = ["send back to user", "forward"];
      break;
    case "BRANCH_BANK":
      actions = ["send back to user", "forward"];
      break;
    case "BPC":
      actions = ["view", "send back to user", "forward"];
      break;
    case "CDPO":
      actions = ["view", "forward", "reject", "letter"];
      break;
    case "BDO":
      actions = ["upload", "download","pullback"];
      break;
    case "CSS":
      actions = ["view"];
      break;
    default:
      actions = ["view"];
  }
  return [
    // {
    //     colName: "",
    //     fieldName: "checkbox",
    //     clsName: "col-sm-1 text-start row-select"
    // },
    {
      colName: "Token ID",
      fieldName: "applicationNumber",
      clsName: "col-sm-3 column-text-start",
      isClickable: true
    },
    {
      colName: "Date of Application",
      fieldName: "submittedDate",
      clsName: "col-sm-2 column-text-start"
    },
    {
      colName: "Name of Beneficiary",
      fieldName: "nameOfApplicant",
      clsName: "col-sm-2 column-text-start",
      isClickable: false,
    },
    // {
    //   colName: "Panchayat/Jurisdiction",
    //   fieldName: "jurisdiction",
    //   clsName: "col-sm-2 column-text-start",
    //   isClickable: false,
    // },
    {
      colName: "Status",
      fieldName: "applicationStatus",
      clsName: "col-sm-4 column-text-start",
      isClickable: false,
    },
    {
      colName: "Pending Since",
      fieldName: "daysPendingSince",
      clsName: "col-sm-2 column-text-start",
      isClickable: false,
    },
    {
      colName: "Action",
      fieldName: "action",
      actions: actions,
      clsName: "col-sm-2 column-text-start actions-column",
    },
  ];
};
export const NOTIFICATION_COLUMN_DATA = getNotificationColumnData();
