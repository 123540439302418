import { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputText from "../../../common/InputText";
import InputMultiSelect from "../../../common/InputMultiSelect";
import { postData } from "../../../../services/http.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Collapsible from "../../../common/Collapsible";
import { membershipDetails } from "../PreviewConstants";
import { userRoleName } from "../../../../utils/constants";

const MembershipDetails = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  userRole,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
  const shgId = userInfo?.shgDetails?.shgId;
  const applicationStatus =
    formData?.applicationBasicDetails?.applicationStatus;
  let editable =
    userRole === userRoleName &&
    (applicationStatus === "Saved as Draft" || applicationStatus === undefined);
  const { t } = useTranslation();
  const [dataChange, setDataChange] = useState(false);
  const initialFormData = formData?.membershipQualifications || {
    educationalQualification: "",
    individualType: "",
    shgId: shgId,
    nameBlf: "",
    nameGplf: "",
    nameDlf: "",
    designationDuration: "",
  };
  const [membership, setMembership] = useState(initialFormData);
  useEffect(() => {
    if (formData?.membershipQualifications) {
      setMembership(formData?.membershipQualifications);
    }
  }, [formData]);
  const [educationalQualification, setEducationalQualifications] = useState([]);
  const [individualTypes, setIndividualType] = useState([]);
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: membership,
  });
  useEffect(() => {
    if (membership) {
      reset(membership);
    }
  }, [membership, reset]);

  const blf = [
    { value: "BLF A", label: "BLF A" },
    { value: "BLF B", label: "BLF B" },
    { value: "BLF C", label: "BLF C" },
    { value: "BLF D", label: "BLF D" },
  ];
  const dlf = [
    { value: "DLF A", label: "DLF A" },
    { value: "DLF B", label: "DLF B" },
    { value: "DLF C", label: "DLF C" },
  ];
  const gplf = [
    { value: "GPLF A", label: "GPLF A" },
    { value: "GPLF B", label: "GPLF B" },
    { value: "GPLF C", label: "GPLF C" },
  ];

  const handleDropdownChange = (name, value) => {
    setMembership((prevstate) => ({ ...prevstate, [name]: value }));
  };

  const onSubmit = async (data) => {
    const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
    const transformedData = {
      userInfo: userInfo,
      uuid: formData.uuid,
      applicationNumber: formData.applicationNumber,
      applicationBasicDetails: { ...formData.applicationBasicDetails },
      addressDetails: { ...formData.addressDetails },
      membershipQualifications: {
        ...membership,
        auditDetails: {
          createdBy: userInfo.uuid,
          lastModifiedBy: userInfo.uuid,
          createdTime: new Date().getTime(),
          lastModifiedTime: new Date().getTime(),
        },
        isUpdate: false,
      },
    };

    try {
      const response = await postData(
        process.env.REACT_APP_CREATE_APPLICATION_SERVICE,
        transformedData
      );
      if (
        response.data &&
        response.data.status === "Successful" &&
        response.data.applicationDetails
      ) {
        setFormData((prevstate) => ({
          ...prevstate,
          uuid: response.data.uuid,
          applicationNumber: response.data.applicationNumber,
          membershipQualifications:
            response.data.applicationDetails.membershipQualifications[0],
        }));
        toast.success("Member Details Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        setTimeout(() => {
          nextStep();
        }, 2000);
      } else {
        toast.error("Failed to save data", {
          position: "top-right",
        });
        nextStep();
      }
    } catch (error) {
      toast.error("Failed to save data", {
        position: "top-right",
      });
      nextStep();
    }
  };

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await postData(
          process.env.REACT_APP_COMMONDROPDOWN_SERVICE
        );
        if (response) {
          const educationalQualInfo =
            response?.data?.educationalQualification || [];
          const individualTypeINfo = response?.data.individualType || [];
          setEducationalQualifications(educationalQualInfo);
          setIndividualType(individualTypeINfo);
        }
      } catch (error) {
        toast.error("Error in fetching data..", {
          position: "top-right",
        });
      }
    };
    dataFetch();
  }, []);
  const handleChange = (event) => {
    setMembership((prevstate) => ({
      ...prevstate,
      [event.target.name]:
        event.target.name === "designationDuration"
          ? Number(event.target.value)
          : event.target.value,
    }));
  };

  useEffect(() => {
    if (
      JSON.stringify(membership) ===
      JSON.stringify(formData?.membershipQualifications)
    ) {
      setDataChange(false);
    } else {
      setDataChange(true);
    }
  }, [membership, formData?.membershipQualifications]);
  const handleNext = () => {
    nextStep();
  };
  return (
    <div className="PersonalInfo">
      <ToastContainer />
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("MemberShip.Qualification Details")}>
            <form>
              <Row>
                <Col md={4}>
                  <InputMultiSelect
                    key={membership.educationalQualification}
                    isMulti={false}
                    name="educationalQualification"
                    label={t("MemberShip.Educational Qualification")}
                    placeholder="Select your Educational Qualification"
                    register={control}
                    options={educationalQualification.map((e) => {
                      return { value: e.value, label: e.name };
                    })}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    onChange={(selectedOption) =>
                      handleDropdownChange(
                        "educationalQualification",
                        selectedOption.value
                      )
                    }
                    defaultValue={
                      membership.educationalQualification
                        ? {
                            value: membership.educationalQualification,
                            label: membership.educationalQualification,
                          }
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputMultiSelect
                    key={membership.individualType}
                    isMulti={false}
                    name="individualType"
                    label={t("MemberShip.Individual Type")}
                    placeholder="Select your Individual Type"
                    register={control}
                    options={individualTypes.map((i) => {
                      return {
                        value: i.name,
                        label: i.name,
                      };
                    })}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    onChange={(selectedOption) =>
                      handleDropdownChange(
                        "individualType",
                        selectedOption.value
                      )
                    }
                    defaultValue={
                      membership.individualType
                        ? {
                            value: membership.individualType,
                            label: membership.individualType,
                          }
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>
              </Row>
            </form>
          </Collapsible>
        </div>
      </div>

      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("MemberShip.Membership Details")}>
            <form>
              <Row>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="shgId"
                    label={t("MemberShip.SHG ID")}
                    placeholder="Enter Your SHG ID"
                    required={true}
                    register={{
                      ...register("shgId", {
                        required: "Please enter your SHG ID",
                      }),
                    }}
                    errors={errors}
                    onChange={handleChange}
                    value={membership.shgId}
                    disabled={membership.shgId || !editable}

                  />
                </Col>
                <Col md={4}>
                  <InputMultiSelect
                    key={membership.nameGplf}
                    isMulti={false}
                    name="nameGplf"
                    label={t("MemberShip.Name of GPLF")}
                    placeholder="Select your Name of GPLF"
                    register={control}
                    options={gplf}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    onChange={(selectedOption) =>
                      handleDropdownChange("nameGplf", selectedOption.value)
                    }
                    defaultValue={
                      membership.nameGplf
                        ? {
                            value: membership.nameGplf,
                            label: membership.nameGplf,
                          }
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputMultiSelect
                    key={membership.nameBlf}
                    isMulti={false}
                    name="nameBlf"
                    label={t("MemberShip.Name of BLF")}
                    placeholder="Select your Name of BLF"
                    register={control}
                    options={blf}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    onChange={(selectedOption) =>
                      handleDropdownChange("nameBlf", selectedOption.value)
                    }
                    defaultValue={
                      membership.nameBlf
                        ? {
                            value: membership.nameBlf,
                            label: membership.nameBlf,
                          }
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputMultiSelect
                    key={membership.nameDlf}
                    isMulti={false}
                    name="nameDlf"
                    label={t("MemberShip.Name of DLF")}
                    placeholder="Select your Name of DLF"
                    register={control}
                    options={dlf}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    onChange={(selectedOption) =>
                      handleDropdownChange("nameDlf", selectedOption.value)
                    }
                    defaultValue={
                      membership.nameDlf
                        ? {
                            value: membership.nameDlf,
                            label: membership.nameDlf,
                          }
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    name="designationDuration"
                    label={t("MemberShip.no_of_pd")}
                    placeholder="Enter your No of Months in Present Designation"
                    register={{
                      ...register("designationDuration", {
                        required:
                          "Please enter your No of Months in Present Designation",
                      }),
                    }}
                    errors={errors}
                    value={membership.designationDuration}
                    onChange={handleChange}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
              </Row>
            </form>
          </Collapsible>
        </div>
      </div>

      <div className="application-btn">
        <div className="d-flex justify-content-between">
          <Button
            variant="secondary"
            onClick={prevStep}
            style={{
              width: "max-content",
            }}
            className="cancelBtn"
          >
            {t("Previous")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={
              editable ? (dataChange ? handleSubmit(onSubmit) : handleNext) : handleNext
            }
            className="primaryBtn"
            style={{ width: "max-content" }} // Apply orange color
          >
            {editable
              ? dataChange
                ? t("Save & Continue")
                : t("Next")
              : t("Next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MembershipDetails;
