import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { postData } from "../../../services/http.service";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLanguageContext } from "../../../LanguageContext";
import ActionTable from "../../common/ActionTable";

import { toast } from "react-toastify";
import ActionModal from "../../common/ActionModal";
import NewsAdd from "./NewsAdd";

const formData: any = {
  isActive: true,
  uuid: "",
  content: "",
  publishedAt: "",
  isNew: false,
  createdBy: "",
  lastModifiedBy: "",
  createdDate: "",
  lastModifiedDate: "",
};

const NewsDetails = () => {
  const { t } = useTranslation();
  const { changeLanguage } = useLanguageContext();
  let navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [NewsFormData, setNewsFormData] = useState(formData);
  const [modalTitle, setModalTitle] = useState(t("AddNews"));

  const NEWS_COLUMNS: any = [
    {
      colName: "Sl#",
      fieldName: "slNo",
      clsName: "column-text-start",
    },
    {
      colName: "newsDetails",
      fieldName: "content",
      clsName: "column-text-start",
    },
    {
      colName: "pubDate",
      fieldName: "publishedAt",
      clsName: "column-text-start",
    },
    {
      colName: "Status",
      fieldName: "status",
      clsName: "column-text-start",
    },
    {
      colName: "Action",
      fieldName: "action",
      actions: "edit",
      clsName: "column-text-start actions-column",
    },
  ];

  useEffect(() => {
    getNewsDetails();
  }, []);

  const getNewsDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      const userInfo = JSON.parse(localStorage.getItem("UserDetails") || "{}");

      if (token && userInfo) {
        const newsApiRes: any = await postData(process.env.REACT_APP_MIS_NEWS, {
          userInfo: userInfo,
          criteria: {
            uuid: "",
          },
        });

        if (newsApiRes && newsApiRes?.data?.newsUpdates) {
          const formattedData = newsApiRes?.data?.newsUpdates.map(
            (item: any, index: number) => {
              return {
                ...item,
                slNo: index + 1,
                id: item.uuid,
                status: item?.isActive ? "Active" : "In-Active",
              };
            }
          );
          setRowData(formattedData);
        } else {
          console.warn("News Updates not found in the response:", NewsDetails);
          setRowData([]);
        }

        console.log("News Details", NewsDetails);
      } else {
        toast.error("User information not found!", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      toast.error("Something went wrong!", {
        position: "top-right",
      });
      setRowData([]);
    }
  };

  const handleRowClick = (e: any) => {};

  const handleBtnClick = (e: any) => {
    setModalTitle(t("AddNews"));
    setNewsFormData(formData);
    setModal(true);
  };

  const handleEditRow = (row: any) => {
    setModalTitle(t("EditNews"));
    setNewsFormData({
      ...row,
    });
    setModal(true);
  };

  const Toggle = () => {
    setNewsFormData(formData);
    setModal(!modal);
  };

  const handleFormSubmit = async (data: any) => {
    try {
      const token = localStorage.getItem("token");
      const userInfo = JSON.parse(localStorage.getItem("UserDetails") || "{}");

      if (token && userInfo) {
        let payload;

        if (data.id) {
          //update
          payload = {
            userInfo: userInfo,
            newsFeed: {
              ...data,
            },
          };
        } else {
          //create
          payload = {
            userInfo: userInfo,
            newsFeed: {
              content: data.content,
              publishedAt: data.publishedAt,
              isActive: data.isActive,
            },
          };
        }

        const response: any = await postData(
          process.env.REACT_APP_MIS_NEWS_CREATE,
          payload
        );

        if (response.status === 200) {
          toast.success(response.data.message, { position: "top-right" });
          getNewsDetails();
          Toggle();
        } else {
          toast.error(response.data.message || "Failed to add/edit news", {
            position: "top-right",
          });
        }
      } else {
        toast.error("User not authenticated", { position: "top-right" });
      }
    } catch (error) {
      console.error("Failed to add/edit news:", error);
      toast.error("Something went wrong!", { position: "top-right" });
    }
  };

  return (
    <>
      <div className="w-full">
        <div>
          <ActionTable
            tableHeader={t("Table.News & Updates")}
            tableWidth={"100%"}
            columns={NEWS_COLUMNS}
            rowData={rowData}
            additionalMethod={handleRowClick}
            showCustomBtn={true}
            btnName={t("Add")}
            handleBtnClick={handleBtnClick}
            handleEditRow={handleEditRow}
            hideExportBtn={true}
            searchBarPlaceholder={t("Table.SEARCH_BY")}
          />
        </div>

        <ActionModal
          show={modal}
          close={Toggle}
          handleModalSubmit={handleFormSubmit}
          title={modalTitle}
          action={modalAction}
          isFooterHide={true}
          size={"lg"}
        >
          <NewsAdd
            NewsFormData={NewsFormData}
            onFormSubmit={handleFormSubmit}
            action={modalAction}
          />
        </ActionModal>
      </div>
    </>
  );
};

export default NewsDetails;
