import React, { useEffect, useRef, useState } from 'react';
import DownArrow from '../../../assets/logos/down_arrow.svg';
import ExportMenu from './ExportMenu';
import _ from 'lodash';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useLocation } from 'react-router-dom';

const GenericExportDropdown = (props: any) => {
    const { disabled, size, variant, ClassName, type, columns, rowData } = props;
    const [isDropdown, setIsDropdown] = useState(false);
    const toggleDropdown = () => setIsDropdown(!isDropdown);
    const { pathname } = useLocation();
    const exportRef = useRef<any>(null);
    // In this object we have to add more key and values, for different titles.
    const pathObject: any = {
        '/goa-idc/empsearch': 'Employee_Search',
        '/goa-idc/tender': 'Tender_Report',
        '/goa-idc/viewNotification': 'Notifications',
        '/goa-idc/estate/apply-for-plot': 'Search_Advertisement',
        '/goa-idc/estate/estateRegistry': 'E-state_registry',
        '/goa-idc/architectdashboard': 'Architect_Dashboard',
        '/goa-idc/usermanagement/searchapplication': 'Search_Application',
        '/goa-idc/dashboard': 'Dashboard',
        '/goa-idc/enterprisedashboard': 'Enterprise_Dashboard',
        '/goa-idc/scrutinysharedplans': 'Scrutiny_Shared_Plans'
    };

    const fileName = pathObject[pathname] || 'save_report';

    const processData = () => {
        let data: any = [];
        rowData?.map((oneRow: any) => {
            let allValues: any = [];
            let obj: any = {};
            columns.map((oneCol: any) => {
                let value = `${_.get(oneRow, oneCol.fieldName)} ${oneCol.fieldName2 ? _.get(oneRow, oneCol.fieldName2) : ''}`;
                allValues.push(value);
                obj[oneCol.colName] = value;
            });
            data.push(obj);
        });

        return data;
    };

    const onCSVExport = () => {
        toggleDropdown();
        let headers: any = [];
        columns?.map((oneCol: any) => {
            headers.push(oneCol.colName);
        });

        let data = rowData?.map((oneRow: any) => {
            let allValues: any = [];
            columns.map((oneCol: any) => {
                let value = `${_.get(oneRow, oneCol.fieldName)} ${oneCol.fieldName2 ? _.get(oneRow, oneCol.fieldName2) : ''}`;
                allValues.push(value);
            });
            return allValues?.join(',');
        });

        let csvString = `${headers.join(',')}\n${data.join('\n')}`;

        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `file.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const onExcelExport = () => {
        toggleDropdown();
        let data = processData();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const exportToCSV = (apiData: any) => {
            const ws = XLSX.utils.json_to_sheet(apiData);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        };

        exportToCSV(data);
    };

    const onExportPdf = () => {
        toggleDropdown();
        const data = processData();
        const generatePdfFromData = (dataArray: any) => {
            const doc = new jsPDF();
            const columns = Object.keys(dataArray[0]);
            const rows = dataArray.map((obj: any) => Object.values(obj));
            // @ts-ignore
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save(`${fileName}.pdf`);
        };

        generatePdfFromData(data);
    };

    useEffect(() => {
        const handleOutsideClick = (e: any) => {
            if (exportRef.current && !exportRef.current.contains(e.target)) {
                setIsDropdown(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div ref={exportRef} id='export_button' className={`col-sm-2 my-2 dropdown`} style={{width:"12%"}}>
            <button
                onClick={toggleDropdown}
                type={type ? type : 'button'}
                disabled={disabled}
                className={`dropdown-toggle ${ClassName} ${size === 'sm' ? 'Button_small' : 'Button_medium'} Export_button background-saffron`}
                style={{ backgroundColor: `${variant === 'primary' && '#45852E'}`, color: `${variant === 'primary' && '#fff'}` }}
                id="dropdownExportButton" data-bs-toggle="dropdown" aria-expanded="false"
            >
                <span className='font-14'>Export As</span> 
                {/* <span className='Export_button_saperator' /> <i className='fa fa fa-angle-down'></i> */}
                {/* <img alt='' className='Export_button_downarrow' src={DownArrow} /> */}
            </button>
            { <ExportMenu onCSVExport={onCSVExport} onExcelExport={onExcelExport} onExportPdf={onExportPdf} />}
        </div>
    );
};

export default GenericExportDropdown;
