import React, { useState } from "react";
import "./Login.css";
import { useForm } from "react-hook-form";
import InputText from "../common/InputText";
import { Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CustomCaptcha from "../common/CustomCaptcha";
import { forgotPassword } from "../../services/http.service";

import PublicDepartments from "./PublicDepartments";
import MegaMenu from "./MegaMenu";
import PublicHeader from "./PublicHeader_old";
import PublicFooter from "./PublicFooter";
import PublicLogoHeader from "./PublicLogoHeader";
import { useTranslation } from 'react-i18next';
// import { useLanguageContext } from '../../LanguageContext';

const ForgotPassword = () => {
    const { t } = useTranslation();
    // const { changeLanguage } = useLanguageContext();
    let navigate = useNavigate();
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            phoneNumber: '',
            userId: '',
            requestType: "RESET_PASSWORD"
        }
    });

    const onSubmit = async (data) => {
        if (!isCaptchaValid) {
            toast.error('Please complete the CAPTCHA.', {
                position: "top-right"
            });
            return;
        }
        const payload = {
            phoneNumber: data.phoneNumber,
            userId: data.phoneNumber,
            requestType: "RESET_PASSWORD"
        };

        try {
            const response = await forgotPassword('/_generate', payload);
            console.log(response);
            if (response.data && response.data.status === "success") {
                toast.success(response.data.message, {
                    position: "top-right"
                });
                setTimeout(() => {
                    navigate('/mission-shakti/mobileotpverify', { state: { phoneNumber: data.phoneNumber, userId: data.phoneNumber } });
                }, 3000);

            } else {
                toast.error('OTP Sent failed. Please check your details.', {
                    position: "top-right"
                });
            }
        } catch (error) {
            toast.error('An error occurred. Please try again later.', {
                position: "top-right"
            });
            console.error("Password reset error:", error);
        }
    };

    return (
        <>
            <PublicHeader></PublicHeader>
            <PublicLogoHeader></PublicLogoHeader>
            <MegaMenu></MegaMenu>
            <div className="login">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4 mt-2">{t('Forgot.Forgot Password')}</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <InputText type="text" name="phoneNumber" label={t('Forgot.MobileNo')} placeholder="Enter your Mobile Number" register={{
                                ...register("phoneNumber", {
                                    required: "Please enter your Mobile Number"
                                })
                            }} errors={errors}
                            />
                            <CustomCaptcha onCaptchaValid={setIsCaptchaValid} />
                            <Row id="btn" className="mb-3">
                                <Col className="text-left">
                                    <Button className="button-auto-width" variant="secondary" onClick={() => navigate('/mission-shakti/')}>{t('Forgot.Cancel')}</Button>
                                </Col>
                                <Col className="text-right">
                                    <Button className="button-auto-width" type="submit" variant="primary">{t('Forgot.Submit')}</Button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <PublicDepartments></PublicDepartments>
            <PublicFooter></PublicFooter>
        </>
    );
};

export default ForgotPassword;
