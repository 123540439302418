import React from "react";
import "./RedirectPage.css"
export default function RedirectUrlScreen() {

    return (
        <div className="wrapperPage">
            <h1>Page Not Found</h1>
        </div>
    )

}

