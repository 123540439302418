import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const InputFile = (props: any) => {
  return (
    <>
      <Form.Group controlId={props?.name} className="mb-3">
        <Form.Label>
          <>
            {props?.label}
            {props?.required && <span className="text-danger ms-1">*</span>}
          </>
        </Form.Label>
        <Form.Control
          type="file"
          {...props?.register}
          onChange={props?.onChange}
          multiple
          accept={props?.accept}
          // style={{ pointerEvents: userRole === "CSS" ? "" : "none" }}
          disabled={props?.disabled}
        />
        {/* {props?.errors[props?.name] && (
          <p className="errorMsg">This field is required</p>
        )} */}

        {props?.errors[props?.name] && (
          <p className="errorMsg">
            {props?.errors[props?.name]?.message || "Please select a file"}
          </p>
        )}
      </Form.Group>
    </>
  );
};
export default InputFile;
