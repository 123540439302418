import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { postData } from "../../../services/http.service";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLanguageContext } from "../../../LanguageContext";
import ActionTable from "../../common/ActionTable";
import { URLS_ENDPOINT } from "../../../utils/urls.const";
import { toast } from "react-toastify";
import ActionModal from "../../common/ActionModal";
import { APP_MESSAGES } from "../../../utils/constants";
import UserManageForm from "./userManageForm";
import { USER_REGIS_MASTER } from "./userManagConst";
const formData: any = {
  salutation: "",
  nameAsPerAadhar: "",
  userName: "",
  firstName: "",
  middleName: "",
  lastName: "",
  mobileNumber: "",
  aadharNumber: "",
  password: "",
  plainPassword: "",
  active: true,
  isBlocked: false,
  blockedAt: "",
  alternateMobileNumber: "",
  emailId: "",
  gender: "",
  panNumber: "",
  voterIdNumber: "",
  drivingLicense: "",
  address: "",
  gurdianName: "",
  gurdianRelation: "",
  type: "",
  dob: "",
  ageOnDate: "",
  bloodGroup: "",
  photo: "",
  identificationMark: "",
  nationality: "",
  educationQualification: "",
  individualTypeDesignation: "",
  panAttachmentUrl: "",
  passwordExpiryDate: "",
  rolesCode: "",
  districtName: "",
  blockName: "",
  panchayatName: "",
  projectCode: "",
  projectName: "",
  ifscCode: "",
  bankName: "",
  bankType: "",
};

const UserManagement = () => {
  const { t } = useTranslation();
  const { changeLanguage } = useLanguageContext();
  let navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [userFormData, setuserFormData] = useState(formData);
  const [modalTitle, setModalTitle] = useState(t("AddUser"));

  const userBaseURL: any = process.env.REACT_APP_USER_SERVICE;
  const userServiceEndPoint: any = URLS_ENDPOINT?.USER_SERVICE;

  useEffect(() => {
    setuserFormData([]);
    getUserDetails();
  }, []);
  const columns = USER_REGIS_MASTER.USER_INFO_COLUMNS;
  const getUserDetails = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {

        const userDetails = await postData(
          `${process.env.REACT_APP_USER_SERVICE}`+URLS_ENDPOINT.USER_SERVICE.SEARCH_USER
        );

        if (userDetails?.status == 200 &&
          userDetails?.data?.enterpriseUserResponse?.length > 0
        ) {
          const formattedData = userDetails?.data?.enterpriseUserResponse.map(
            (item: any, index:number) => {
              return {
                ...item?.enterpriseUser,
                 ...item?.enterpriseUserProjectDetails,
                id: item?.enterpriseUser?.uuid,
                roleName : item?.enterpriseUser?.roles[0]?.roleName,
                slNo: index+1
              };
            }
          );
          setRowData(formattedData);
        }
      } else {
        toast.error("User not authenticated", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      toast.error("Something went wrong!", {
        position: "top-right",
      });
    }
  };

  const handleRowClick = (e: any) => {};
  const handleBtnClick = (e: any) => {
    setModal(!modal);
  };
  const handleModalSubmit = (e: any) => {
    // setModalAction(e)
    // handleFormSubmit(userFormData)
  };
  const handleEditRow = (e: any) => {
    setModalTitle(t("EditUser"));
    setuserFormData(e);
    console.log(e);
    console.log(userFormData);
    setModal(!modal);
  };
  const Toggle = () => {
    setuserFormData(formData);
    setModal(!modal);
  };
  const handleFormSubmit = async (e: any) => {
    // setuserFormData(e)
    if (e && Object.keys(e).length > 0) {
      try {
        const token = localStorage.getItem("token");
        const userInfo = JSON.parse(
          localStorage.getItem("UserDetails") || "{}"
        );

        const enterpriseUser:any  = {
          salutation: parseDDValues(e?.salutation, true),
          nameAsPerAadhar: e?.nameAsPerAadhar,
          userName: e?.userName,
          firstName: e?.firstName,
          middleName: e?.middleName,
          lastName: e?.lastName,
          mobileNumber: e?.mobileNumber,
          aadharNumber: e?.aadharNumber,
          password: null,
          plainPassword: null,
          active: e?.active,
          isBlocked: false,
          // blockedAt: 0,
          alternateMobileNumber: e?.alternateMobileNumber,
          emailId: e?.emailId || "",
          gender:  parseDDValues(e?.gender),
          panNumber:  e?.panNumber,
          voterIdNumber:  e?.voterIdNumber,
          drivingLicense:  e?.drivingLicense,
          address:  e?.address,
          gurdianName:  e?.gurdianName,
          gurdianRelation:  e?.gurdianRelation,
          type: null,
          dob: e?.dob,
          // ageOnDate: 0,
          bloodGroup: e?.bloodGroup,
          photo: null,
          identificationMark: e?.identificationMark,
          nationality: "Indian",
          educationQualification: null,
          individualTypeDesignation: null,
          panAttachmentUrl: null,
          passwordExpiryDate: null,
          rolesCode: parseDDValues(e?.rolesCode, true)
        };
        const enterpriseUserProjectDetails:any = {
          districtName:  e?.districtName|| "",
          blockName: e?.blockName || "",
          panchayatName:  parseDDValues(e?.panchayatName?.label),
          projectCode:  e?.projectCode || "",
          projectName: parseDDValues(e?.projectName),
          ifscCode: parseDDValues(e?.ifscCode, true),
          bankName: parseDDValues(e?.bankName),
          bankType: "",
        };

        if(e?.userId){
          enterpriseUserProjectDetails.userId = e?.userId;
          enterpriseUserProjectDetails.uuid = e?.uuid;
          enterpriseUser.uuid = e?.userId;
        }

        if (token && userInfo) {
          const userResp: any = await postData(
            userBaseURL + userServiceEndPoint?.REGISTER_APP_USER,
            {
              enterpriseUser: enterpriseUser,
              enterpriseUserProjectDetails : enterpriseUserProjectDetails
            }
          );

          console.log(userResp);
          if (userResp?.data?.status === '200') {

            toast.success(userResp?.data?.message, {
              position: "top-right",
            });

            setTimeout(()=>{
              getUserDetails();
              Toggle();
            });
          } else {
            toast.error(APP_MESSAGES.CMN_ERR, {
              position: "top-right",
            });
          }
        } else {
          toast.error(APP_MESSAGES.USER_ERR, {
            position: "top-right",
          });
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        toast.error(APP_MESSAGES.API_ERR, {
          position: "top-right",
        });
      }
    }
  };

  const parseDDValues = (data:any, takeValue?:boolean) => {
    let result = "";
    if(data){
        if(typeof(data) == 'string'){
          result = data;
        }else if(typeof(data) == 'object'){
          result = takeValue ? data?.value : data?.label;
        }
    }
    return result;
  };

  return (
    <>
      <div className="w-full">
        {/* This is Home */}
        <div>
          <ActionTable
            tableHeader={t("UserList")}
            columns={columns}
            rowData={rowData}
            additionalMethod={handleRowClick}
            showCustomBtn={true}
            btnName={t("Add")}
            handleBtnClick={handleBtnClick}
            handleEditRow={handleEditRow}
            hideExportBtn={true}
            searchBarPlaceholder={t("Table.SEARCH_BY")}
          />
        </div>

        <ActionModal
          show={modal}
          close={Toggle}
          handleModalSubmit={handleModalSubmit}
          title={modalTitle}
          action={modalAction}
          isFooterHide={true}
          size={"lg"}
        >
          <UserManageForm
            userFormData={userFormData}
            onFormSubmit={handleFormSubmit}
            action={modalAction}
          />
        </ActionModal>
      </div>
    </>
  );
};

export default UserManagement;
