import { useState, useEffect, ChangeEvent, useContext } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "./ApplicationsList.css"; // Import custom CSS for additional styling
import ActionTable from "../../common/ActionTable";
import { postData } from "../../../services/http.service";
import { toast } from "react-toastify";
import { NOTIFICATION_COLUMN_DATA } from "../../private/home/HomeConst";
import { generateVerificationCertificatePDF } from "../../public/recommendationLetter";
import ActionModal from "../../common/ActionModal";
import { fileUpload } from "../../../services/http.service";
import { APP_MESSAGES, userRoleName } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { ApplicationContext } from "../../ApplicationContext";

const ApplicationsList = () => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");
  const [rowData, setRowData] = useState<any[]>([]);
  const [uploadLetterModal, setUploadLetterModal] = useState(false);
  const [pullbackModal, setPullbackModal] = useState(false);
  const [comments, setComments] = useState("");
  const [signedLetter, setSignedLetter] = useState<File | null>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null); // Track the currently selected row for modal
  const [modalHeader, setModalHeader] = useState("");
  const { updateApplicationContext } = useContext(ApplicationContext);
  const uploadRecommendationLetter = (row: any) => {
    setSelectedRow(row);
    setUploadLetterModal(true);
    setModalHeader("Upload Recommendation Letter");
  };

  const handleActionSubmit = async () => {
    if (selectedRow && signedLetter) {
      // Handle the file upload here
      const formData = new FormData();
      formData.append("file", signedLetter);

      try {
        const uploadLetter = await fileUpload(formData);
        if (uploadLetter && uploadLetter.data.files[0].fileStoreId) {
          let fileStoreId = uploadLetter.data.files[0].fileStoreId;
          try {
            const response = await postData(
              `${process.env.REACT_APP_LETTER_DOWNLOAD}`,
              {
                basicDetails: {
                  uuid: selectedRow.id,
                  recommendationLetter: fileStoreId,
                },
              }
            );
            if (response.data.message === "Successful") {
              const payload = {
                applicationNumber: selectedRow.applicationNumber,
                comments: comments,
                action: "FORWARD",
              };
              try {
                const workflowResponse = await postData(
                  process.env.REACT_APP_WORKFLOW_APPLICATION_SERVICE,
                  payload
                );
                if (workflowResponse) {
                  toast.success("Uploaded the letter successfully", {
                    position: "top-right",
                  });
                }
              } catch (err) {}
            }
          } catch (err) {
            console.error(err);
            toast.error(APP_MESSAGES.UPLOAD_LETTER_ERR, {
              position: "top-right",
            });
          }
        }

        setUploadLetterModal(false);
      } catch (err) {
        console.error(err);
        toast.error(APP_MESSAGES.UPLOAD_LETTER_ERR, {
          position: "top-right",
        });
      }
    } else {
      toast.error(APP_MESSAGES.FILE_UPLOAD_ERR, {
        position: "top-right",
      });
    }
  };

  const downloadRecommendationLetter = async (row: any) => {
    try {
      const response = await postData(
        `${process.env.REACT_APP_APPLICATION_SEARCH}`,
        {
          uuids: [row?.id],
        }
      );
      if (response.status === 200 && response.data[0]) {
        let data = response.data[0];
        let newone: any = {};
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key]) && data[key].length > 0) {
            newone[key] = data[key][0];
          }
        });
        generateVerificationCertificatePDF(newone);
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to Download the Letter", {
        position: "top-right",
      });
    }
  };

  const pullBack = async (row: any) => {
    setPullbackModal(true);
    setModalHeader("Pullback the Application");
    setSelectedRow(row);
  };
  useEffect(() => {
    const fetchApplications = async () => {
      const token = localStorage.getItem("token");
      const userInfo = JSON.parse(localStorage.getItem("UserDetails") || "{}");

      if (token && userInfo) {
        let response;
        try {
          if (userRole === "BRANCH_BANK") {
            response = await postData(
              `${process.env.REACT_APP_APPLICATIONS_SERVICE}/getBankApplications`
            );
          } else {
            response = await postData(
              `${process.env.REACT_APP_APPLICATIONS_SERVICE}/getApplications`,
              { filterCriteria: {} }
            );
          }

          if (response?.status === 200) {
            setRowData(response.data.dashboardApplications);
          }
        } catch (err) {
          toast.error(APP_MESSAGES.API_ERR, {
            position: "top-right",
          });
        }
      }
    };

    fetchApplications();
  }, []);

  const additionalMethod = (rowData: any) => {
    if (userRole === "BRANCH_BANK") {
      return;
    } else {
      updateApplicationContext({
        id: rowData.id,
        applicationNumber: rowData.applicationNumber,
      });
      navigate(`/mission-shakti/mmsy_loan_form?id=${rowData.id}`, { state: 7 });
    }
  };

  const actionToggle = () => {
    setUploadLetterModal(false);
    setPullbackModal(false);
    setComments("");
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      setSignedLetter(fileInput.files[0]);
    } else {
      setSignedLetter(null);
    }
  };
  const handlePullback = async () => {
    const payload = {
      applicationNumber: selectedRow?.applicationNumber,
      comments: comments,
    };

    try {
      const pullback = await postData(
        `${process.env.REACT_APP_MIS_PULLBACK}`,
        payload
      );
      if (pullback && pullback.status === 200) {
        toast.success(pullback?.data?.message, {
          position: "top-right",
        });
        setPullbackModal(false);
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to pullback", {
        position: "top-right",
      });
      setPullbackModal(false);
    }
  };
  return (
    <div className="AddressInfo container mt-3">
      <div className="card container mt-3">
        <div className="card-body">
          <div className="mt-3">
            <Col md={12} className="main-content mt-2">
              <Row className="mt-4">
                <Col md={12} className="mt-2">
                  <ActionTable
                    columns={NOTIFICATION_COLUMN_DATA}
                    rowData={rowData}
                    additionalMethod={additionalMethod}
                    onViewRow={uploadRecommendationLetter}
                    download={downloadRecommendationLetter}
                    tableHeader={"Applications"}
                    pullBack={pullBack}
                  />
                  <ActionModal
                    show={uploadLetterModal || pullbackModal}
                    close={actionToggle}
                    handleModalSubmit={
                      modalHeader === "Upload Recommendation Letter"
                        ? handleActionSubmit
                        : handlePullback
                    }
                    title={modalHeader}
                    key={modalHeader}
                  >
                    <Form>
                      <Form.Group controlId="comments">
                        <Form.Label>Comments</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter comments here"
                          value={comments}
                          onChange={(e) => {
                            setComments(e.target.value);
                          }}
                        />
                      </Form.Group>
                      {modalHeader === "Upload Recommendation Letter" && (
                        <Form.Group controlId="fileUpload" className="mt-3">
                          <Form.Label>Upload File</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleFileChange}
                          />
                        </Form.Group>
                      )}
                    </Form>
                  </ActionModal>
                </Col>
              </Row>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationsList;
