import React, { useEffect, useState } from "react";
// import { Button, Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
// import { useForm } from "react-hook-form";
import "../mssyloan/MssyForm.css";
// import { searchUserByUUID } from '../../../services/http.service';
import { useTranslation } from "react-i18next";
import "./ViewProfile.css";
import { postData } from "../../../services/http.service";
import { toast, ToastContainer } from "react-toastify";
import { APP_MESSAGES } from "../../../utils/constants";

import { Button, Container, Row, Col, Image } from "react-bootstrap";
import { FaTrash, FaEdit } from "react-icons/fa";
import PreviewCard from "../../common/PreviewCard";

const ViewProfile = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const { uuid } = useParams();
  const [profile, setProfile] = useState(null);
  formData = location.state?.formData || formData || {};

  const handleCancel = () => {
    navigate("/mission-shakti/home");
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await postData(
          `${process.env.REACT_APP_USER_SERVICE}/_user/_search?uuids=${uuid}`
        );
        // const users = await searchUserByUUID(uuid);
        if (response?.data?.users?.length > 0) {
          setProfile(response.data.users[0]);
        }
      } catch (error) {
        console.error("Failed to fetch profile", error);
        toast.error(APP_MESSAGES.API_ERR, {
          position: "top-right",
        });
      }
    };

    fetchProfile();
  }, [uuid]);

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ViewProfile">
      <ToastContainer />
      <div className="card mt-4">
        <div className="card-body">
          {/* Card 1: Personal Information */}
          {/* <div className="mb-4 m-3 mt-3"> */}

          {/* <h6 className="text-left m-3 mt-4">
              <strong>{t("ViewProfile.Heading")}</strong>
            </h6>

            <div className="mb-4" style={{ paddingLeft: "2rem" }}>
              <div className="profile-photo-container">
               
              </div>
            </div>
         */}

          <Container className="profile-container">
            <h6 className="profile-header">Profile Information</h6>
            <Button
              variant="custom"
              type="submit"
              className="primaryBtn"
              style={{ position: "absolute", right: "2rem" }}
              as={Link}
              to={`/mission-shakti/user-profile/EditProfile/${uuid}`}
            >
              <FaEdit /> {t("ViewProfile.Edit Profile")}
            </Button>
            <Row
              className="align-items-center mb-4"
              style={{ paddingLeft: "1rem" }}
            >
              <Col md={2}>
                {profile.photo ? (
                  <Image
                    src={profile?.photo}
                    roundedCircle
                    className="profile-photo"
                  />
                ) : (
                  <div className="placeholder-photo">
                    {t("ViewProfile.No Photo")}
                  </div>
                )}
              </Col>
              <Col md={8}>
                <div className="profile-links row">
                  <button className="changeBtn" href="#">
                    Change Profile Photo
                  </button>
                  {/* <p className="remove-photo">
                    <FaTrash /> Remove Profile Photo
                  </p> */}
                </div>
              </Col>
            </Row>
          </Container>

          <div
            className="view"
            style={{ paddingLeft: "1.5rem", paddingRight: "1rem" }}
          >
            <Row>
              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Name")}
                  value={profile?.nameAsPerAadhar || "--"}
                />
              </Col>

              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.UName")}
                  value={profile?.userName || "--"}
                />
              </Col>

              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Email")}
                  value={profile?.emailId || "--"}
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Gender")}
                  value={profile?.gender || "--"}
                />
              </Col>

              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.DOB")}
                  value={profile?.dob || "--"}
                />
              </Col>

              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Age")}
                  value={profile?.ageOnDate === 0 ? "--" : profile?.ageOnDate}
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Aadhar Number")}
                  value={profile?.aadharNumber || "--"}
                />
              </Col>

              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Mobile No")}
                  value={profile?.mobileNumber || "--"}
                />
              </Col>

              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.VoterId")}
                  value={profile?.voterIdNumber || "--"}
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Pan Number")}
                  value={profile?.panNumber || "--"}
                />
              </Col>

              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.DrivingLicenceNo")}
                  value={profile?.drivingLicense || "--"}
                />
              </Col>

              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Nationality")}
                  value={profile?.nationality || "--"}
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Educational Qualification")}
                  value={profile?.educationQualification || "--"}
                />
              </Col>

              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Address")}
                  value={profile?.gurdianName || "--"}
                />
              </Col>

              <Col md={4}>
                <PreviewCard
                  title={t("ViewProfile.Guardian Name")}
                  value={profile?.nationality || "--"}
                />
              </Col>
            </Row>
          </div>
          
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                onClick={handleCancel}
                style={{
                  width: "max-content",
                }}
                className="cancelBtn"
              >
                {t("EditProfile.Cancel")}
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="primaryBtn"
                as={Link}
                to={`/mission-shakti/user-profile/EditProfile/${uuid}`}
              >
                <FaEdit /> {t("ViewProfile.Edit Profile")}
              </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
