import { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fileUpload, postData } from "../../../../services/http.service";
import { useTranslation } from "react-i18next";
import Collapsible from "../../../common/Collapsible";
import { FaInfoCircle } from "react-icons/fa";
import FileUpload from "../../../common/FileUpload";
import { userRoleName } from "../../../../utils/constants";
const SupportingDocs = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  userRole,
}) => {
  const applicationStatus =
    formData?.applicationBasicDetails?.applicationStatus;
  let editable =
    userRole === userRoleName &&
    (applicationStatus === "Saved as Draft" || applicationStatus === undefined);
  const initialFormData = formData?.supportingDocuments || {
    aadhaarFile: "",
    panFile: "",
    passportFile: "",
    voterFile: "",
    addressAadhaarFile: "",
    addressElectricityfile: "",
    addressPassportFile: "",
    addressVoterFile: "",
    addressTelephonefile: "",
    addressPropertyTaxfile: "",
    file1: "",
    file2: "",
    file3: "",
    anyOtherDocument: false,
    otherDoc: "",
  };
  const [supportingDocs, setSupportingDocs] = useState({});
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (formData?.supportingDocuments?.documents) {
      let documents = formData?.supportingDocuments?.documents;
      let internalState = {};
      for (let item of documents) {
        internalState[item.docSource] = item;
      }
      if (internalState) {
        setSupportingDocs(internalState);
      }
    }
  }, [formData?.supportingDocuments]);
  const {
    register,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: initialFormData,
  });

  const [isOther, setIsOther] = useState(
    formData?.supportingDocuments?.isOther || false
  );

  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const maxFileSize = 500 * 1024; // 500 KB in bytes

  const handleAnyOtherDocumentChange = (e) => {
    const { checked } = e.target;
    setIsOther(checked);
  };
  let newUpdateFileIds = {};
  let transformedData = {};
  const onSubmit = async (data) => {
    const uploadFiles = async () => {
      const documentFiles = Object.values(supportingDocs);
      const updatedFiles = await Promise.all(
        documentFiles.map(async (fileObj) => {
          const formData = new FormData();
          if (typeof fileObj?.docId === "object") {
            formData.append("file", fileObj?.docId);
            try {
              const response = await fileUpload(formData);
              // Store the response ID in the file object
              return {
                ...fileObj,
                docId: response.data.files[0].fileStoreId,
              };
            } catch (error) {
              console.error("Error uploading file:", error);
              return {
                ...fileObj,
                id: null,
              };
            }
          } else {
            return { ...fileObj };
          }
        })
      );

      updatedFiles.forEach((item) => {
        newUpdateFileIds[item?.docSource] = item;
      });
      const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
      transformedData = {
        userInfo: userInfo,
        uuid: formData?.uuid,
        applicationNumber: formData?.applicationNumber,
        applicationBasicDetails: { ...formData.applicationBasicDetails },
        addressDetails: { ...formData.addressDetails },
        membershipQualifications: { ...formData.membershipQualifications },
        bankIncomeDetails: {
          ...formData.bankIncomeDetails,
        },
        vehicleProposalDetails: {
          ...formData.vehicleProposalDetails,
        },
        supportingDocuments: {
          documents: [...updatedFiles],
          identityProofName: supportingDocs?.identityProofName?.docType,
          identityDoc: newUpdateFileIds?.identityProofName?.docId || null,
          addressProofName: supportingDocs?.addressProofName?.docType,
          addressDoc: newUpdateFileIds?.addressProofName?.docId || null,
          firstRecentPhoto: newUpdateFileIds?.firstRecentPhoto?.docId || null,
          quotationDetailsDoc:
            newUpdateFileIds?.quotationDetailsDoc?.docId || null,
          ...(supportingDocs.hasOwnProperty("secondRecentPhoto")
            ? {
                secondRecentPhoto:
                  newUpdateFileIds?.secondRecentPhoto?.docId || null,
              }
            : {}),
          isOther: isOther,
          ...(isOther
            ? { otherDoc: newUpdateFileIds?.otherDoc?.docId || null }
            : {}),
          auditDetails: {
            createdBy: userInfo.uuid,
            lastModifiedBy: userInfo.uuid,
            createdTime: new Date().getTime(),
            lastModifiedTime: new Date().getTime(),
          },
          isUpdate: false,
        },
      };
      console.log(transformedData, "tsdata");
    };

    await uploadFiles();

    setError("");

    try {
      const response = await postData(
        process.env.REACT_APP_CREATE_APPLICATION_SERVICE,
        transformedData
      );
      if (response.data && response.data.status === "Successful") {
        setFormData((prevstate) => ({
          ...prevstate,
          supportingDocuments:
            response.data?.applicationDetails?.supportingDocuments[0],
        }));
        toast.success("Documents Details Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        setTimeout(() => {
          nextStep();
        }, 2000);
      } else {
        toast.error("Failed to save data", {
          position: "top-right",
        });
      }
    } catch (err) {
      toast.error("Failed to save data", {
        position: "top-right",
      });
    }
  };
  const handleFileChange = async (e, fieldName, type) => {
    const file = e.target.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        toast.error("Invalid file format. Please upload a valid file.", {
          position: "top-right",
        });
        setError(fieldName, {
          type: "manual",
          message: "Only jpg, jpeg, png, pdf, doc, docx accepted.",
        });
        return;
      } else if (file.size > maxFileSize) {
        toast.error("File size exceeds 500KB. Please upload a smaller file.", {
          position: "top-right",
        });
        setError(fieldName, {
          type: "manual",
          message: "File size exceeds 500KB. Please upload a smaller file.",
        });
        return;
      } else {
        setSupportingDocs((prevstate) => ({
          ...prevstate,
          [type]: { docType: fieldName, docId: file, docSource: type },
        }));

        clearErrors(fieldName);
      }
    }
  };

  const fileValidation = (file) => {
    if (file[0]) {
      if (!allowedFileTypes.includes(file[0].type)) {
        return "Only jpg, jpeg, png, pdf, doc, docx accepted.";
      } else if (file[0].size > maxFileSize) {
        return "File size exceeds 500KB. Please upload a smaller file.";
      }
    }
    return true;
  };

  const handlePhotos = (e) => {
    setSupportingDocs((prevstate) => ({
      ...prevstate,
      firstRecentPhoto: {
        docType: "Photo",
        docId: e.target.files[0],
        docSource: "firstRecentPhoto",
      },
    }));
    if (e.target.files[1]) {
      setSupportingDocs((prevstate) => ({
        ...prevstate,
        secondRecentPhoto: {
          docType: "Photo",
          docId: e.target.files[1],
          docSource: "secondRecentPhoto",
        },
      }));
    }
  };
  const identityProofs = ["Aadhaar", "Pan", "Passport", "VoterId"];
  const addressProofs = [
    "Aadhaar",
    "Pan",
    "Passport",
    "VoterId",
    "Telephone",
    "PropertyTax",
  ];
  const additionalProofs = ["firstRecentPhoto", "quotationDetailsDoc"];
  const handleChange = (e) => {
    setSupportingDocs((prevstate) => ({
      ...prevstate,
      [e.target.name]: {
        docType: e.target.value,
        docId: null,
        docSource: e.target.name,
      },
    }));
  };
  const handleNext = () => {
    nextStep();
  };
  return (
    <div className="PersonalInfo">
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("SupportingDocs.Heading1")}>
            <form className="m-3">
              <Row className="d-flex flex-wrap">
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-wrap gap-5">
                    {identityProofs.map((value, i) => {
                      return (
                        <div key={i} className="d-flex flex-wrap gap-3">
                          <label
                            style={{
                              fontWeight:
                                supportingDocs.identityProofName?.docType ===
                                value
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            {value}
                          </label>
                          <input
                            type="radio"
                            value={value}
                            checked={
                              supportingDocs.identityProofName?.docType ===
                              value
                            }
                            name="identityProofName"
                            onChange={handleChange}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {supportingDocs?.identityProofName?.docType && (
                    <>
                      <div className="d-flex flex-wrap gap-2 align-items-center mt-2">
                        <FaInfoCircle />
                        <strong>
                          {t("Note")}:{" "}
                          <span>{t("Attachment Upto 2Mb Format")}</span>
                        </strong>
                      </div>
                      <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
                        <FileUpload
                          accept="image/*"
                          name={`${supportingDocs.identityProofName?.docSource}`}
                          label={`${supportingDocs.identityProofName?.docType}`}
                          placeholder="Upload your Profile Photo"
                          // register={{
                          //   ...register("profilePhotoId", {
                          //     required: "Please upload your Profile Photo",
                          //     validate: {
                          //       validateFile,
                          //     },
                          //   }),
                          // }}
                          errors={errors}
                          value={supportingDocs?.identityProofName?.docId}
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              supportingDocs.identityProofName?.docType,
                              supportingDocs.identityProofName?.docSource
                            )
                          }
                          disabled={!editable}
                          key={supportingDocs.identityProofName?.docType}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Row>
              <Row></Row>
            </form>
          </Collapsible>
        </div>
      </div>

      {/* Card 2: Address Proof */}
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("SupportingDocs.Heading2")}>
            <form className="m-3">
              <Row className="d-flex flex-wrap">
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-wrap gap-5">
                    {addressProofs.map((value, i) => {
                      return (
                        <div key={i} className="d-flex flex-wrap gap-3">
                          <label
                            style={{
                              fontWeight:
                                supportingDocs.addressProofName?.docType ===
                                value
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            {value}
                          </label>
                          <input
                            type="radio"
                            value={value}
                            checked={
                              supportingDocs.addressProofName?.docType === value
                            }
                            name="addressProofName"
                            onChange={handleChange}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {supportingDocs?.addressProofName?.docType && (
                    <>
                      <div className="d-flex flex-wrap gap-2 align-items-center mt-2">
                        <FaInfoCircle />
                        <strong>
                          {t("Note")}:{" "}
                          <span>{t("Attachment Upto 2Mb Format")}</span>
                        </strong>
                      </div>
                      <div className="d-flex flex-wrap gap-2 align-items-center mt-4">
                        <FileUpload
                          accept="image/*"
                          name={`${supportingDocs.addressProofName?.docType}`}
                          label={`${supportingDocs.addressProofName?.docType}`}
                          placeholder="Upload your Profile Photo"
                          // register={{
                          //   ...register("profilePhotoId", {
                          //     required: "Please upload your Profile Photo",
                          //     validate: {
                          //       validateFile,
                          //     },
                          //   }),
                          // }}
                          errors={errors}
                          value={supportingDocs?.addressProofName?.docId}
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              supportingDocs.addressProofName?.docType,
                              supportingDocs.addressProofName?.docSource
                            )
                          }
                          disabled={!editable}
                          key={supportingDocs.addressProofName?.docType}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Row>
            </form>
          </Collapsible>
        </div>
      </div>
      {console.log(supportingDocs, "docs")}
      {/* Card 3: Additional Documents */}
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("SupportingDocs.Heading3")}>
            <form className="m-3">
              <Row>
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-column flex-wrap gap-5">
                    {additionalProofs.map((value, i) => {
                      return (
                        <div
                          key={i}
                          className="d-flex flex-wrap gap-3 flex-column"
                        >
                          <label
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            {value}
                          </label>

                          <>
                            <div className="d-flex flex-wrap gap-2 align-items-center mt-2">
                              <FaInfoCircle />
                              <strong>
                                {t("Note")}:{" "}
                                <span>{t("Attachment Upto 500kb Format")}</span>
                              </strong>
                            </div>
                            <div className="d-flex flex-wrap gap-2 align-items-center mt-4">
                              <FileUpload
                                type="file"
                                accept="image/*"
                                name={value}
                                label={value}
                                placeholder="Upload your file"
                                register={{
                                  ...register("file1", {
                                    required: "Please upload your file",
                                  }),
                                }}
                                errors={errors}
                                onChange={
                                  value === "firstRecentPhoto"
                                    ? handlePhotos
                                    : (e) =>
                                        handleFileChange(
                                          e,
                                          "quotationDetailsDoc",
                                          "quotationDetailsDoc"
                                        )
                                }
                                disabled={!editable}
                                key={supportingDocs.addressProofName?.docType}
                                value={supportingDocs?.[value]?.docId}
                              />
                            </div>
                          </>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Row>
              <Col md={5} className="mt-2 d-grid mb-3">
                <Form.Check
                  type="checkbox"
                  label={t("SupportingDocs.AnyOtherDocument")}
                  name="otherDoc"
                  onChange={handleAnyOtherDocumentChange}
                  defaultChecked={isOther}
                />
                {/* Conditional Rendering of Upload Field */}
                {isOther && (
                  <Col md={12} className="mt-4">
                    {/* <InputFile
                      type="file"
                      name="otherDoc"
                      label={t("SupportingDocs.OtherDocumentLabel")}
                      placeholder="Upload your file"
                      // register={{
                      //   ...register("anyOtherDocumentFile", {
                      //     required: "Please upload your file",
                      //     validate: fileValidation,
                      //   }),
                      // }}
                      errors={errors}
                      onChange={(e) =>
                        handleFileChange(e, "Document", "otherDoc")
                      }
                      disabled={!editable}
                    /> */}
                    <FileUpload
                      type="file"
                      accept="image/*"
                      name="otherDoc"
                      label={t("SupportingDocs.OtherDocumentLabel")}
                      placeholder="Upload your file"
                      // register={{
                      //   ...register("file1", {
                      //     required: "Please upload your file",
                      //   }),
                      // }}
                      errors={errors}
                      onChange={(e) => {
                        handleFileChange(e, "Document", "otherDoc");
                      }}
                      disabled={!editable}
                      key={supportingDocs.addressProofName?.docType}
                      value={supportingDocs?.otherDoc?.docId}
                    />
                  </Col>
                )}
              </Col>
            </form>
          </Collapsible>
        </div>
      </div>

      {/* Card 4: Buttons */}
      <div className="application-btn">
        <div className="d-flex justify-content-between">
          <Button
            variant="secondary"
            onClick={prevStep}
            style={{
              width: "max-content",
            }}
            className="cancelBtn"
          >
            {t("Previous")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={editable ? onSubmit : handleNext}
            className="primaryBtn"
            style={{ width: "max-content" }}
          >
            {editable ? t("Save & Continue") : t("Next")}
          </Button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SupportingDocs;
