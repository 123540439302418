import React, { useContext, useState, useEffect } from "react";
import { Button, Row, Col, Form, Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import "../MssyForm.css";
import { postData } from "../../../../services/http.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import ActionModal from "../../../common/ActionModal";
import { ApplicationContext } from "../../../ApplicationContext";
import Modal from "react-bootstrap/Modal";
import VerificationCertificate from "../../../public/VerificationCertificate";
import { generateVerificationCertificatePDF } from "../../../public/recommendationLetter";
import { FaDownload } from "react-icons/fa";
import { fileUpload } from "../../../../services/http.service";

import { useTranslation } from "react-i18next";
import Collapsible from "../../../common/Collapsible";
import {
  personalInfo,
  addressDetails,
  membershipDetails,
  loanDetails,
  vehicleInformation,
} from "../PreviewConstants";
import PreviewCard from "../../../common/PreviewCard";
import { URLS_ENDPOINT } from "../../../../utils/urls.const";
import { APP_MESSAGES, userRoleName } from "../../../../utils/constants";
import ApplicationTracking from "./ApplicationTracking";
import { strToCamelCase } from "../../../../utils/constants";

const Preview = ({
  formData,
  prevStep,
  setFormData,
  setCompletedSteps,
  userRole,
  uuid,
  trackingInfo,
}) => {
  const applicationStatus =
    formData?.applicationBasicDetails?.applicationStatus;
  let editable =
    userRole === userRoleName &&
    (applicationStatus === "Saved as Draft" || applicationStatus === undefined);
  const [letterModalShow, setLetterModalShow] = React.useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const { application } = useContext(ApplicationContext);
  formData = location.state?.formData || formData || {};
  const [downloaded, setDownloaded] = useState([]);
  const role = localStorage.getItem("role");
  const { t } = useTranslation();
  const [trackingDetails, setTrackingDetails] = useState(trackingInfo || []);
  const [signedLetter, setSignedLetter] = useState(null);
  useEffect(() => {
    const fetchTrackingData = async () => {
      try {
        const URL =
          process.env.REACT_APP_MIS_APP_SERVICE +
          URLS_ENDPOINT.APP_SERVICES.TRACK_APP_STAT;
        const request = {
          userInfo: JSON.parse(localStorage.getItem("UserDetails")),
          trackingCriteria: {
            applicationNumber:
              formData?.applicationBasicDetails?.applicationNumber,
          },
        };
        const response = await postData(URL, request);
        if (response?.data?.applicationStages?.length > 0) {
          setTrackingDetails([...response?.data?.applicationStages]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(APP_MESSAGES.API_ERR + ".. for Tracking Application...", {
          position: "top-right",
        });
      }
    };

    if (trackingDetails?.length === 0) {
      fetchTrackingData();
    }
  }, [formData?.applicationNumber]);

  const onSubmit = async () => {
    const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
    const transformedData = {
      userInfo: userInfo,
      uuid: formData.uuid,
      applicationNumber: formData.applicationNumber,
      applicationBasicDetails: {
        ...formData.applicationBasicDetails,
        isFinalSubmit: true,
      },
      addressDetails: { ...formData.addressDetails },
      membershipQualifications: { ...formData.membershipQualifications },
      vehicleProposalDetails: { ...formData.vehicleProposalDetails },
      declarationDetails: { ...formData.declarationDetails },
      bankIncomeDetails: { ...formData.bankIncomeDetails },
      supportingDocuments: { ...formData.supportingDocuments },
    };
    try {
      const response = await postData(
        process.env.REACT_APP_CREATE_APPLICATION_SERVICE,
        transformedData
      );
      if (response.data && response.data.status === "Successful") {
        toast.success("Application Submitted Successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        setCompletedSteps([]);
        setTimeout(() => {
          navigate("/mission-shakti/home");
        }, 3000);
      } else {
        toast.error("Failed to save data", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data", {
        position: "top-right",
      });
    }
  };

  const downloadDoc = async (id, filename) => {
    const a = document.createElement("a");

    a.href = `${process.env.REACT_APP_FILE_DOWNLOAD}=${id}`;

    a.download = filename;

    document.body.appendChild(a);

    a.click();
    setDownloaded((prevstate) => [...prevstate, id]);
    document.body.removeChild(a);
  };
  const [reason, setReason] = useState("");
  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const Toggle = () => {
    setModal(!modal);
    setReason("");
  };
  const handleModalSubmit = async (e, action) => {
    const userInfo = JSON.parse(localStorage.getItem("UserDetails") || "{}");
    let payload;
    if (applicationStatus === "Approved by BDO" && signedLetter) {
      try {
        const response = await postData(
          `${process.env.REACT_APP_LETTER_DOWNLOAD}`,
          {
            basicDetails: {
              uuid: application?.uuid,
              recommendationLetter: signedLetter,
            },
          }
        );
        if (response.data.message === "Successful") {
          toast.success("uploaded letter Successfully", {
            position: "top-right",
          });
        }
      } catch (err) {
        console.error(err);
        toast.error(APP_MESSAGES.UPLOAD_LETTER_ERR, {
          position: "top-right",
        });
      }
    }
    payload = {
      userInfo: userInfo,
      applicationNumber: application.applicationNumber,
      comments: reason,
      action: action,
    };

    try {
      if (applicationStatus === "Pending with BDO") {
        generateVerificationCertificatePDF(formData);
      }
      const data = await postData(
        process.env.REACT_APP_WORKFLOW_APPLICATION_SERVICE,
        payload
      );
      if (data.data && data.data.status === "Successful") {
        toast.success(data.data?.message, {
          position: "top-right",
        });
        setModal(false);
        Toggle();
        if (action === "Pending with BDO") {
          generateVerificationCertificatePDF(formData);
        }
        setTimeout(() => {
          navigate("/mission-shakti/home");
        }, 3000);
      }
    } catch (err) {
      toast.error("Something went wrong", {
        position: "top-right",
      });
    }
  };
  const handleSentBackToCitizen = (e) => {
    const userInfo = JSON.parse(localStorage.getItem("UserDetails") || "{}");
    const userRole = userInfo.roles[0].roleName;
    let action, title;
    switch (userRole) {
      case "CDPO":
        action = "SEND_BACK_TO_CITIZEN";
        title = "Send Back to Citizen";
        break;
      case "BDO":
        action = "SEND_BACK_TO_CITIZEN";
        title = "Send Back to Citizen";
        break;
      case "BPC":
        action = "SEND_BACK_TO_CITIZEN";
        title = "Send Back to Citizen";
        break;
      default:
        action = "UNKNOWN_ACTION";
        title = "Unknown Action";
    }
    setModalAction(action);
    setModalTitle(title);
    setModal(!modal);
  };
  const handleForward = (e) => {
    const userInfo = JSON.parse(localStorage.getItem("UserDetails") || "{}");
    const userRole = userInfo.roles[0].roleName;
    let action, title;
    switch (userRole) {
      case "CDPO":
        action = "RECOMMEND";
        title = "Recommend to BDO";
        break;
      case "BPC":
        action = "FORWARD";
        title = "Forward to CDPO";
        break;
      default:
        action = "UNKNOWN_ACTION";
        title = "Unknown Action";
    }
    setModalAction(action);
    setModalTitle(title);
    setModal(!modal);
  };
  const handleApprove = (e) => {
    const userInfo = JSON.parse(localStorage.getItem("UserDetails") || "{}");
    const userRole = userInfo.roles[0].roleName;
    let action, title;
    switch (userRole) {
      case "BDO":
        action =
          applicationStatus === "Pending with BDO"
            ? "APPROVED_BY_BDO"
            : "FORWARD";
        title =
          applicationStatus === "Pending with BDO"
            ? "Approve by BDO"
            : "Forward to Branch Bank ";
        break;
      default:
        action = "UNKNOWN_ACTION";
        title = "Unknown Action";
    }
    setModalAction(action);
    setModalTitle(title);
    setModal(!modal);
  };

  const handleBranchBank = (e, type) => {
    setModalAction(type);
    if (type === "APPROVED") {
      setModalTitle("Approve");
    } else if (type === "FORWARD") {
      setModalAction("Forward to controlling bank");
    } else if (type === "REJECTED") {
      setModalAction("Reject");
    }
    setModal(!modal);
  };
  const handleReject = (e) => {
    setModalAction("REJECTED");
    setModalTitle("Reject");
    setModal(!modal);
  };
  function timestampToDateFormat(timestamp) {
    if (timestamp) {
      const date = new Date(timestamp);

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  }
  const ModalS = (props) => {
    const handleDownload = () => {
      generateVerificationCertificatePDF(formData);
      setLetterModalShow(false);
    };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modalLetter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Recommendation Letter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "hidden", overflowY: "scroll" }}>
          <div id="letter">
            <VerificationCertificate formData={formData}/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor:"#FF6A00",borderColor:'transparent'}} onClick={handleDownload}>Download</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const extractValues = (data, keys) => {
    return keys?.reduce((result, key) => {
      if (data && key in data) {
        result[key] = data[key];
      }
      return result;
    }, {});
  };
  const handleRecommendationLetter = async (e) => {
    const fileInput = e.target;
    if (fileInput.files[0]) {
      const formData = new FormData();
      formData.append("file", fileInput.files[0]);
      try {
        const uploadLetter = await fileUpload(formData);
        if (uploadLetter && uploadLetter.data.files[0].fileStoreId) {
          let fileStoreId = uploadLetter.data.files[0].fileStoreId;
          setSignedLetter(fileStoreId);
        }
      } catch (err) {
        toast.error("Failed to upload the letter", {
          position: "top-right",
        });
      }
    }
  };
  const showSubmitButton = () => {
    if (applicationStatus === "Approved by BDO" && signedLetter === null) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="Preview">
      <ToastContainer />
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("Preview.Personal Information")}>
            <Container className="g-10">
              <Row>
                {Object.entries(
                  extractValues(formData?.applicationBasicDetails, personalInfo)
                )?.map(([key, value]) => (
                  <Col key={key} xs={12} sm={6} md={4} lg={3}>
                    <PreviewCard title={key} value={value} />
                  </Col>
                ))}
              </Row>
            </Container>
          </Collapsible>
        </div>
      </div>

      {/* Card 2: Address Information */}
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("Preview.Address Information")}>
            <Container className="g-10">
              <Row>
                {Object.entries(
                  extractValues(formData?.addressDetails, addressDetails)
                ).map(([key, value]) => (
                  <Col key={key} xs={12} sm={6} md={4} lg={3}>
                    <PreviewCard title={key} value={value} />
                  </Col>
                ))}
              </Row>
            </Container>
          </Collapsible>
        </div>
      </div>

      {/* Card 3: Membership Details */}
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("Preview.Membership Details")}>
            <Container className="g-10">
              <Row>
                {Object.entries(
                  extractValues(
                    formData?.membershipQualifications,
                    membershipDetails
                  )
                ).map(([key, value]) => (
                  <Col key={key} xs={12} sm={6} md={4} lg={3}>
                    <PreviewCard title={key} value={value} />
                  </Col>
                ))}
              </Row>
            </Container>
          </Collapsible>
        </div>
      </div>

      {/* Card 4: Loan Details */}
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("Preview.Loan Details")}>
            <Container className="g-10">
              <Row>
                {Object.entries(
                  extractValues(formData?.bankIncomeDetails, loanDetails)
                ).map(([key, value]) => (
                  <Col key={key} xs={12} sm={6} md={4} lg={3}>
                    <PreviewCard title={key} value={value} />
                  </Col>
                ))}
              </Row>
            </Container>
          </Collapsible>
        </div>
      </div>

      {/* Card 5: Vehicle Information */}
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("Preview.Vehicle Information")}>
            <Container className="g-10">
              <Row>
                {Object.entries(
                  extractValues(
                    formData?.vehicleProposalDetails,
                    vehicleInformation
                  )
                ).map(([key, value]) => (
                  <Col key={key} xs={12} sm={6} md={4} lg={3}>
                    <PreviewCard title={key} value={value} />
                  </Col>
                ))}
              </Row>
            </Container>
          </Collapsible>
        </div>
      </div>

      {/* Card 6: Supporting Documents */}
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("Preview.Supporting Documents")}>
            {formData?.supportingDocuments?.documents.map((document, index) => {
              return (
                <div style={{ margin: "1rem", width: "fit-content" }}>
                  <h4>{document?.docSource}</h4>
                  <div className="d-flex gap-3 align-items-center justify-content-between">
                    <p
                      style={{
                        cursor: "pointer",
                        textDecoration: downloaded.includes(document.docId)
                          ? "underline"
                          : "none",
                        color: downloaded.includes(document.docId)
                          ? "grey"
                          : "#000",
                        marginBottom: "0",
                      }}
                    >
                      {document?.docType}
                    </p>
                    <FaDownload
                      style={{ color: "black", cursor: "pointer" }}
                      onClick={() => {
                        downloadDoc(document.docId, document.docId);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Collapsible>
        </div>
      </div>

      {/* Card 7: Declaration */}
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title={t("Preview.Declaration")}>
            <div className="Declaration">
              <div className="info-row">
                <span className="info-label">
                  {t("Preview.Declaration1")}:{" "}
                </span>
                <span className="info-value">
                  {formData?.declarationDetails?.isDeclareInfo
                    ? t("Preview.Yes")
                    : t("Preview.No")}
                </span>
              </div>
              <div className="info-row">
                <span className="info-label">
                  {" "}
                  {t("Preview.Declaration2")}:{" "}
                </span>
                <span className="info-value">
                  {" "}
                  {formData?.declarationDetails?.isTermCondition
                    ? t("Preview.Yes")
                    : t("Preview.No")}
                </span>
              </div>
              <div className="info-row">
                <span className="info-label">
                  {" "}
                  {t("Preview.Declaration3")}:{" "}
                </span>
                <span className="info-value">
                  {" "}
                  {formData?.declarationDetails?.isDisqualification
                    ? t("Preview.Yes")
                    : t("Preview.No")}
                </span>
              </div>
              <div className="info-row">
                <span className="info-label">
                  {" "}
                  {t("Preview.Declaration4")}:{" "}
                </span>
                <span className="info-value">
                  {" "}
                  {formData?.declarationDetails?.isAuthorize
                    ? t("Preview.Yes")
                    : t("Preview.No")}
                </span>
              </div>
              <div className="info-row">
                <span className="info-label">
                  {" "}
                  {t("Preview.Declaration5")}:{" "}
                </span>
                <span className="info-value">
                  {" "}
                  {formData?.declarationDetails?.isCommunication
                    ? t("Preview.Yes")
                    : t("Preview.No")}
                </span>
              </div>
              <div className="info-row">
                <span className="info-label">{t("Preview.Date")} : </span>
                <span className="info-value">
                  {" "}
                  {timestampToDateFormat(formData.declarationDetails?.date) ||
                    "N/A"}
                </span>
              </div>
              <div className="info-row">
                <span className="info-label"> {t("Preview.Place")}: </span>
                <span className="info-value">
                  {" "}
                  {formData?.declarationDetails?.place || "N/A"}
                </span>
              </div>
            </div>
          </Collapsible>
        </div>
      </div>

      {trackingDetails?.length > 0 && (
        <ApplicationTracking
          applicationHistorydata={trackingDetails}
          appNo={formData?.applicationNumber}
        />
      )}

      <div className="application-btn">
        <div className="d-flex justify-content-between">
          <Button
            className="cancelBtn mt-3"
            variant="secondary"
            onClick={prevStep}
          >
            {t("Previous")}
          </Button>
          {role && role === userRoleName && (
            <Button
              className="primaryBtn mt-3"
              style={{ width: "max-content" }}
              onClick={
                !editable
                  ? () => {
                      navigate("/mission-shakti/home");
                    }
                  : onSubmit
              }
            >
              {!editable ? "Go back to home" : t("Submit")}
            </Button>
          )}
          {role && role === "BPC" && (
            <div
              style={{
                display: "inline",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <Button
                className="primaryBtn mt-3"
                style={{ width: "max-content", marginRight: "10px" }}
                onClick={handleSentBackToCitizen}
              >
                {t("SendBack")}
              </Button>
              <Button
                className="primaryBtn mt-3"
                style={{ width: "max-content" }}
                onClick={handleForward}
              >
                {t("Forward")}
              </Button>
            </div>
          )}
          {role && role === "CDPO" && (
            <div
              style={{
                display: "inline",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <Button
                className="primaryBtn mt-3"
                style={{ width: "max-content" }}
                onClick={handleForward}
              >
                Recommend
              </Button>
            </div>
          )}
          {role === "BDO" &&
            (applicationStatus === "Pending with BDO" ||
            applicationStatus === "Approved by BDO" ? (
              <div
                style={{
                  display: "inline",
                  float: "right",
                  paddingRight: "10px",
                }}
              >
                <Button
                  className="primaryBtn mt-3"
                  style={{ width: "max-content", marginRight: "10px" }}
                  onClick={handleApprove}
                >
                  {applicationStatus === "Pending with BDO"
                    ? t("Approve")
                    : t("Forward")}
                </Button>
                <Button
                  className="cancelBtn mt-3"
                  variant="secondary"
                  onClick={handleReject}
                >
                  {t("Reject")}
                </Button>
                <Button
                  onClick={() => setLetterModalShow(true)}
                  className="primaryBtn mt-3"
                  style={{ width: "max-content" }}
                >
                  {t("PreviewLetter")}
                </Button>
                <ModalS
                  show={letterModalShow}
                  onHide={() => setLetterModalShow(false)}
                />
              </div>
            ) : (
              <Button
                className="primaryBtn mt-3"
                style={{ width: "max-content", marginRight: "10px" }}
                onClick={() => navigate("/mission-shakti/home")}
              >
                {t("GoBackToHome")}
              </Button>
            ))}

          {role && role === "BRANCH_BANK" && (
            <div
              style={{
                display: "inline",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <Button
                className="primaryBtn mt-3"
                style={{ width: "max-content", marginRight: "10px" }}
                onClick={(e) => {
                  handleBranchBank(e, "APPROVED");
                }}
              >
                {t("Approve")}
              </Button>
              <Button
                className="cancelBtn mt-3"
                variant="secondary"
                style={{background: "#28a745", color: "white"}}
                onClick={(e) => {
                  handleBranchBank(e, "FORWARD");
                }}
              >
                {t("ForwardtoControllingBank")}
              </Button>
              <Button
                className="cancelBtn mt-3"
                variant="secondary"
                // onClick={handleReject}
                onClick={(e) => {
                  handleBranchBank(e, "REJECTED");
                }}
              >
                {t("Reject")}
              </Button>
              <ModalS
                show={letterModalShow}
                onHide={() => setLetterModalShow(false)}
              />
            </div>
          )}
          {role && role === "CONTROLLING_BANK" && (
            <div
              style={{
                display: "inline",
                float: "right",
                paddingRight: "10px",
              }}
            >
              <Button
                className="primaryBtn mt-3"
                style={{ width: "max-content", marginRight: "10px" }}
                onClick={(e) => {
                  handleBranchBank(e, "APPROVED");
                }}
              >
                {"Approve"}
              </Button>
              <Button
                className="cancelBtn mt-3"
                variant="secondary"
                onClick={(e) => {
                  handleBranchBank(e, "REJECTED");
                }}
              >
                {"Reject"}
              </Button>
              {/* <ModalS show={modalShow} onHide={() => setModalShow(false)} /> */}
            </div>
          )}
        </div>
      </div>
      <ActionModal
        show={modal}
        close={Toggle}
        handleModalSubmit={handleModalSubmit}
        title={modalTitle}
        action={modalAction}
        showSubmit={showSubmitButton()}
      >
        <Form>
          <Form.Group controlId="reason">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Enter reason here"
            />
            {modalAction === "FORWARD" &&
              applicationStatus === "Approved by BDO" && (
                <Form.Group controlId="fileUpload" className="mt-3">
                  <Form.Label>Upload Recommendation Letter</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".pdf"
                    onChange={handleRecommendationLetter}
                  />
                </Form.Group>
              )}
          </Form.Group>
        </Form>
      </ActionModal>

      {/* Tracking */}
    </div>
  );
};

export default Preview;
