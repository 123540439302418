
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { postData } from "../../../services/http.service";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLanguageContext } from "../../../LanguageContext";
import ActionTable from "../../common/ActionTable";
import { SHGDETAILS_MASTER } from "./shgDetails.const";
import { URLS_ENDPOINT } from "../../../utils/urls.const";
import { toast } from "react-toastify";
import ActionModal from "../../common/ActionModal";
import ShgDetailsForm from "./ShgDetailsForm";
const formData:any = {
  "uuid": "",
  "districtName": "",
  "icdsProjectName": "",
  "blockName": "",
  "panchayatName": "",
  "villageName": "",
  "beneficiaryName": "",
  "address": "",
  "aadharNumber": "",
  "shgId": "",
  "shgName": "",
  "mobileNumber": "",
  "designation": "",
  "isEligible": true
}

const ShgDetails = () => {
  const { t } = useTranslation();
  const { changeLanguage } = useLanguageContext();
  let navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [shgFormData, setShgFormData] = useState(formData);
  const [modalTitle, setModalTitle] = useState(t("AddSHG"));

  

  const userBaseURL: any = process.env.REACT_APP_USER_SERVICE;
  const userServiceEndPoint:any = URLS_ENDPOINT?.USER_SERVICE
 
  useEffect(() => {
    getSHGDetails();
  }, []);
  const columns = SHGDETAILS_MASTER.SHGDETAILS_COLUMNS;
  const getSHGDetails = async()=> {
    try {
      const token = localStorage.getItem('token');
      const userInfo = JSON.parse(localStorage.getItem('UserDetails') || '{}');
      if (token && userInfo ) {
          const shgDetails:any = await postData(userBaseURL+userServiceEndPoint?.SHG_LIST, {
            userInfo: userInfo,
            shgDetailsFilterCriteria: {
                // "shgId": "SHG123",
                // "aadharNumber": "828183112136649"
            }

          });
          if(shgDetails && shgDetails?.data && shgDetails?.status==200 && shgDetails?.data?.shgDetails?.length>0) {
            const formattedData = shgDetails?.data?.shgDetails.map((item: any) => {

              return {
                  ...item,
                  id: item.uuid
              };
            });
            setRowData(formattedData);
          }
      } else {
        toast.error("User not authenticated", {
          position: "top-right",
        });
      }
  } catch (error) {
      console.error('Failed to fetch data:', error);
      toast.error("Something went wrong!", {
        position: "top-right",
      });
  }
  }

  const handleRowClick =(e:any)=>{

  }
  const handleBtnClick = (e:any)=> {
    setModal(!modal);
  }
  const handleModalSubmit = (e:any)=> {
    // setModalAction(e)
    // handleFormSubmit(shgFormData)
  }
  const handleEditRow = (e:any)=> {
    setModalTitle(t("EditSHG"))
    setShgFormData(e)
    setModal(!modal);
  }
  const Toggle = () => {
    setShgFormData(formData)
    setModal(!modal);
  };
  const handleFormSubmit = async(e:any)=>{
    // setShgFormData(e)
    if(e  && Object.keys(e).length > 0) {
      try {
        const token = localStorage.getItem('token');
        const userInfo = JSON.parse(localStorage.getItem('UserDetails') || '{}');
        if (token && userInfo ) {
            const shgDetails:any = await postData(userBaseURL+userServiceEndPoint?.SHG_CREATE, {
              userInfo: userInfo,
              shgDetails: e
            });
            if(shgDetails && shgDetails?.status===200) {
              toast.success(shgDetails?.data?.message, {
                position: "top-right",
              });
              getSHGDetails();
              Toggle();
            } else {
              toast.error("User not authenticated", {
                position: "top-right",
              });
            }
  
        } else {
          toast.error("User not authenticated", {
            position: "top-right",
          });
        }
    } catch (error) {
        console.error('Failed to fetch data:', error);
        toast.error("Something went wrong!", {
          position: "top-right",
        });
    }
    }
  }

  


  return (
    <>
        <div className="w-full">
            {/* This is Home */}
            <div>
                <ActionTable
                    tableHeader={t("Table.SHGList")}
                    columns={columns}
                    rowData={rowData}
                    additionalMethod={handleRowClick}
                    showCustomBtn={true}
                    btnName={t('Add')}
                    handleBtnClick={handleBtnClick}
                    handleEditRow={handleEditRow}
                    hideExportBtn={true}
                    // checkType='multiCheck'
                    // onCheckHandler={onCheckHandler}
                    searchBarPlaceholder={t('Table.SEARCH_BY')}
                />
            </div>

            <ActionModal
            show={modal}
            close={Toggle}
            handleModalSubmit={handleModalSubmit}
            title={modalTitle}
            action={modalAction}
            isFooterHide = {true}
            size={"lg"}
          >
            <ShgDetailsForm shgFormData={shgFormData} onFormSubmit={handleFormSubmit} action={modalAction} />
          </ActionModal>
        </div>
    </>
  );
};

export default ShgDetails;
