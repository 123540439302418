// src/components/CustomCaptcha.tsx
import React, { useState, useEffect } from 'react';
import '../../components/common/CustomCaptcha.css';

const CustomCaptcha = ({ onCaptchaValid }: { onCaptchaValid: (isValid: boolean) => void }) => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [userInput, setUserInput] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        generateCaptcha();
    }, []);

    const generateCaptcha = () => {
        const num1 = Math.floor(Math.random() * 10) + 1;
        const num2 = Math.floor(Math.random() * 10) + 1;
        setQuestion(`${num1} + ${num2} = ?`);
        setAnswer((num1 + num2).toString());
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
        if (e.target.value === answer) {
            setError('');
            onCaptchaValid(true);
        } else {
            setError('Incorrect answer. Please try again.');
            onCaptchaValid(false);
        }
    };

    return (
        <div className="custom-captcha">
            <label className="captcha-question">{question}</label>
            <input
                type="text"
                value={userInput}
                onChange={handleChange}
                placeholder='Enter the Captcha Value'
                required
                className={`captcha-input ${error ? 'is-invalid' : ''}`}
            />
            {error && <small className="text-danger captcha-error">{error}</small>}
        </div>
    );
};

export default CustomCaptcha;
