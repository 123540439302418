import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import InputText from "../common/InputText";
import { Button, Row, Col } from "react-bootstrap";
// import { CONSTANT_REGEX_SAMPLE } from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CustomCaptcha from "../common/CustomCaptcha";
import { userPost } from "../../services/http.service"; // Import the API service
import { useTranslation } from "react-i18next";
import InputTextArea from "../common/InputTextArea";
import { CONSTANT_REGEX_SAMPLE } from "../../utils/constants";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";


const WebsitePolicyPage = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
  
    return (
        <>
            <div className="website-policy-page">
                <ul>
                    <li>
                        <div className="ft-b">Copyright Policy </div>
                        <p>
                            Material featured on this Website may be reproduced free of charge after taking proper permission by sending a mail to us. However, the material has to be reproduced accurately and not to be used in a derogatory manner or in a misleading context. Wherever the material is being published or issued to others, the source must be prominently acknowledged. However, the permission to reproduce this material shall not extend to any material which is identified as being copyright of a third party. Authorization to reproduce such material must be obtained from the departments/copyright holders concerned. These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India.
                        </p>
                    </li>
                    <li>
                        <div className="ft-b">Hyperlinking Policy</div>
                        <p>
                            <b className="ft-14">Links to external websites/portals: </b>
                            <br />
                            At many places in this Website, you shall find links to other websites/portals. This links have been placed for your convenience. [Department] is not responsible for the contents and reliability of the linked websites and does not necessarily endorse the views expressed in them. Mere presence of the link or its listing on this website should not be assumed as endorsement of any kind. We can not guarantee that these links will work all the time and we have no control over the availability of linked pages.
                        </p>
                        <p>
                            <b className="ft-14">Links to our website by other websites: </b>
                            <br />
                            We do not object to you linking directly to the information that is hosted on this website and no prior permission is required for the same. However, we would like you to inform us about any links provided to this website so that you can be informed of any changes or updations therein. Also, we do not permit our pages to be loaded into frames on your site. The pages belonging to this website must load into a newly opened browser window of the User.
                        </p>
                    </li>
                    <li>
                        <div className="ft-b">Privacy Policy</div>
                        <p>
                            This website does not automatically capture any specific personal information from you, (like name, phone number or e-mail address), that allows us to identify you individually. Wherever the Website requests you to provide personal information, you will be informed for the particular purposes for which the information is gathered and adequate security measures will be taken to protect your personal information. We do not sell or share any personally identifiable information volunteered on the website site to any third party (public/private). Any information provided to this Website will be protected from loss, misuse, unauthorized access or disclosure, alteration, or destruction. We gather certain information about the User, such as Internet protocol (IP) addresses, domain name, browser type, operating system, the date and time of the visit and the pages visited. We make no attempt to link these addresses with the identity of individuals visiting our site unless an attempt to damage the site has been detected.
                        </p>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default WebsitePolicyPage