import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import { BiPlayCircle } from 'react-icons/bi';
import { BiFontSize, BiSitemap, BiText, BiPlus, BiMinus } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { useLanguageContext } from '../../LanguageContext'; 

const PublicHeaderOld = () => {
    const { t } = useTranslation();
    const { changeLanguage } = useLanguageContext();
    return (
        <>
            <div>
                <Navbar expand="lg" bg="dark" variant="dark">
                    <Container>
                        <div className="d-flex align-items-center">
                            {/* Add custom class "small-text" */}
                            <Navbar.Brand href="#home" className="small-text">{t('governmentOfOdisha')}</Navbar.Brand>
                            <hr className="vertical-line mx-3 my-0" />
                            {/* Add custom class "small-text" */}
                            <Navbar.Brand href="#home" className="small-text">{t('pradhanPrustha')}</Navbar.Brand>
                        </div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="align-items-center ms-auto">
                            <hr className="vertical-line mx-3 my-0" />
                                <Nav.Link href="/mission-shakti/login" className="small-text">{t('Login')}</Nav.Link>
                                <hr className="vertical-line mx-3 my-0" />
                                <Nav.Link href="/mission-shakti/register" className="small-text">{t('register')}</Nav.Link>
                                <hr className="vertical-line mx-3 my-0" />
                                <Nav.Link href="#search"><i className="bi bi-search"></i></Nav.Link>
                                <hr className="vertical-line mx-3 my-0" />
                                <Nav.Link href="#play"><i className="bi bi-play-fill"></i></Nav.Link>
                                <hr className="vertical-line mx-3 my-0" />
                                <NavDropdown title={<BiFontSize />} id="basic-nav-dropdown">
                                    <NavDropdown.Item><BiText /> <BiPlus /></NavDropdown.Item>
                                    <NavDropdown.Item><BiText /> <BiMinus /></NavDropdown.Item>
                                    <NavDropdown.Item><BiText /> <strong>B</strong></NavDropdown.Item>
                                </NavDropdown>
                                <hr className="vertical-line mx-3 my-0" />
                                {/* Site Map Icon */}
                                <Nav.Link href="#sitemap"><BiSitemap /></Nav.Link>
                                <hr className="vertical-line mx-3 my-0" />
                                <NavDropdown title={t('Language')} id="basic-nav-dropdown" className="small-text">
                                    <NavDropdown.Item onClick={() => changeLanguage('en')} className="small-text">English</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => changeLanguage('or')} className="small-text">ଓଡ଼ିଆ</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    );
};

export default PublicHeaderOld;
