import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputText from "../../../common/InputText";
import { fileUpload, postData } from "../../../../services/http.service";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Collapsible from "../../../common/Collapsible";
import FileUpload from "../../../common/FileUpload";
import { userRoleName } from "../../../../utils/constants";

const Declaration = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  userRole,
}) => {
  const [dataChange, setDataChange] = useState(false);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0"); // Day of the month

    return `${year}-${month}-${day}`;
  };
  const applicationStatus =
    formData?.applicationBasicDetails?.applicationStatus;
  let editable =
    userRole === userRoleName &&
    (applicationStatus === "Saved as Draft" || applicationStatus === undefined);
  const { t } = useTranslation();
  const initialFormData = formData?.declarationDetails
    ? {
        ...formData.declarationDetails,
        date: formData?.declarationDetails?.date
          ? timestampToDateFormat(formData?.declarationDetails?.date)
          : "",
      }
    : {
        signatureDoc: "",
        date: getTodayDate(),
        place: "",
        isDeclareInfo: true,
        isTermCondition: true,
        isDisqualification: true,
        isAuthorize: true,
        isCommunication: true,
      };
  const [declarationDetails, setDeclarationDetails] = useState(initialFormData);
  const {
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialFormData,
  });

  const onChangeFile = (e) => {
    setDeclarationDetails((prevstate) => ({
      ...prevstate,
      signatureDoc: e.target.files[0],
    }));
  };
  useEffect(() => {
    let declarationDetailsChnage =
      JSON.stringify(declarationDetails) ===
      JSON.stringify(formData?.declarationDetails);

    if (!declarationDetailsChnage) {
      setDataChange(true);
    } else {
      setDataChange(false);
    }
  }, [declarationDetails, formData?.declarationDetails]);
  const onSubmit = async (data) => {
    let signId = declarationDetails.signatureDoc;
    if (typeof signId === "object") {
      const formData = new FormData();
      formData.append("file", signId);
      const response = await fileUpload(formData);
      signId = response.data.files[0].fileStoreId;
    }
    const userInfo = JSON.parse(localStorage.getItem("UserDetails"));
    const transformedData = {
      userInfo: userInfo,
      uuid: formData.uuid,
      applicationNumber: formData.applicationNumber,
      applicationBasicDetails: { ...formData.applicationBasicDetails },
      addressDetails: { ...formData.addressDetails },
      membershipQualifications: { ...formData.membershipQualifications },
      bankIncomeDetails: {
        ...formData.bankIncomeDetails,
      },
      vehicleProposalDetails: {
        ...formData.vehicleProposalDetails,
      },
      supportingDocuments: {
        ...formData.supportingDocuments,
      },
      declarationDetails: {
        ...declarationDetails,
        signatureDoc: signId,
        date: dateToDateFormat(new Date()),
        auditDetails: {
          createdBy: userInfo.uuid,
          lastModifiedBy: userInfo.uuid,
          createdTime: new Date().getTime(),
          lastModifiedTime: new Date().getTime(),
        },
        isUpdate: false,
      },
    };
    try {
      const response = await postData(
        process.env.REACT_APP_CREATE_APPLICATION_SERVICE,
        transformedData
      );

      if (response.data && response.data.status === "Successful") {
        setFormData((prevstate) => ({
          ...prevstate,
          declarationDetails:
            response.data.applicationDetails.declarationDetails[0],
        }));
        toast.success("Declaration Details Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        setTimeout(() => {
          nextStep();
        }, 2000);
      } else {
        toast.error("Failed to save data", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data", {
        position: "top-right",
      });
    }
  };
  useEffect(() => {
    reset({ ...declarationDetails });
  }, [declarationDetails, reset]);

  const handleCheckBoxes = (e) => {
    const { checked } = e.target;
    setDeclarationDetails((prevstate) => ({
      ...prevstate,
      [e.target.name]: checked,
    }));
  };

  function timestampToDateFormat(timestamp) {
    if (timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  }
  function dateToDateFormat(date) {
    // Extract year, month, and day
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");

    // Format the date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
  }
  const handleNext = () => {
    nextStep();
  };
  return (
    <div className="Declaration">
      <ToastContainer />
      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title="Declaration">
            <form className="m-3">
              <Row>
                <Col md={4}>
                  <FileUpload
                    type="file"
                    name="otherDoc"
                    label={t("Declaration.SignatureUploadLabel")}
                    placeholder="Upload your Signature"
                    // register={{
                    //   ...register("file1", {
                    //     required: "Please upload your file",
                    //   }),
                    // }}
                    errors={errors}
                    onChange={(e) => {
                      onChangeFile(e);
                    }}
                    disabled={!editable}
                    key="signatureDoc"
                    value={declarationDetails?.signatureDoc}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={12}>
                  <Form.Check
                    type="checkbox"
                    id="isDeclareInfo"
                    label={t("Declaration.Declaration1")}
                    {...register("isDeclareInfo", {
                      required: "This checkbox is required",
                    })}
                    isInvalid={!!errors.isDeclareInfo}
                    onChange={handleCheckBoxes}
                    checked={declarationDetails?.isDeclareInfo}
                  />
                  {errors.isDeclareInfo && (
                    <p className="text-danger">
                      {errors.isDeclareInfo.message}
                    </p>
                  )}
                </Col>
                <Col md={12}>
                  <Form.Check
                    type="checkbox"
                    id="isTermCondition"
                    label={t("Declaration.Declaration2")}
                    {...register("isTermCondition", {
                      required: "This checkbox is required",
                    })}
                    isInvalid={!!errors.isTermCondition}
                    onChange={handleCheckBoxes}
                    checked={declarationDetails?.isTermCondition}
                  />
                  {errors.isTermCondition && (
                    <p className="text-danger">
                      {errors.isTermCondition.message}
                    </p>
                  )}
                </Col>
                <Col md={12}>
                  <Form.Check
                    type="checkbox"
                    id="isDisqualification"
                    label={t("Declaration.Declaration3")}
                    {...register("isDisqualification", {
                      required: "This checkbox is required",
                    })}
                    isInvalid={!!errors.isDisqualification}
                    onChange={handleCheckBoxes}
                    checked={declarationDetails?.isDisqualification}
                  />
                  {errors.isDisqualification && (
                    <p className="text-danger">
                      {errors.isDisqualification.message}
                    </p>
                  )}
                </Col>
                <Col md={12}>
                  <Form.Check
                    type="checkbox"
                    id="isAuthorize"
                    label={t("Declaration.Declaration4")}
                    {...register("isAuthorize", {
                      required: "This checkbox is required",
                    })}
                    isInvalid={!!errors.isAuthorize}
                    onChange={handleCheckBoxes}
                    checked={declarationDetails?.isAuthorize}
                  />
                  {errors.isAuthorize && (
                    <p className="text-danger">{errors.isAuthorize.message}</p>
                  )}
                </Col>
                <Col md={12}>
                  <Form.Check
                    type="checkbox"
                    id="isCommunication"
                    label={t("Declaration.Declaration5")}
                    {...register("isCommunication", {
                      required: "This checkbox is required",
                    })}
                    isInvalid={!!errors.isCommunication}
                    onChange={handleCheckBoxes}
                    checked={declarationDetails?.isCommunication}
                  />
                  {errors.isCommunication && (
                    <p className="text-danger">
                      {errors.isCommunication.message}
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <InputText
                    key={"date"}
                    type="date"
                    name="date"
                    label={t("Declaration.Date")}
                    placeholder="Select date"
                    register={{
                      ...register("date", {
                        formatDate: "mm/dd/yyyy",
                        required: "Please select the date",
                      }),
                    }}
                    errors={errors}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    key="place"
                    type="text"
                    name="place"
                    label={t("Declaration.Place")}
                    placeholder="Enter place"
                    register={{
                      ...register("place", {
                        required: "Please enter the place",
                      }),
                    }}
                    onChange={(e) => {
                      setDeclarationDetails((prevstate) => ({
                        ...prevstate,
                        place: e.target.value,
                      }));
                    }}
                    errors={errors}
                    value={declarationDetails?.place}
                    disabled={!editable}
                  />
                </Col>
              </Row>
            </form>
          </Collapsible>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="application-btn">
        <div className="d-flex justify-content-between">
          <Button
            variant="secondary"
            onClick={prevStep}
            style={{
              width: "max-content",
            }}
            className="cancelBtn"
          >
            {t("Previous")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={editable ? onSubmit : handleNext}
            className="primaryBtn"
            style={{ width: "max-content" }}
            disabled={
              !(
                declarationDetails?.isAuthorize &&
                declarationDetails?.isCommunication &&
                declarationDetails?.isDeclareInfo &&
                declarationDetails?.isDisqualification &&
                declarationDetails?.isTermCondition
              )
            }
          >
           {editable
              ? dataChange
                ? t("Save & Continue")
                : t("Next")
              : t("Next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Declaration;
