export const URLS_ENDPOINT = {
    USER_SERVICE: {
        SHG_CREATE: "/_shgDetails/create",
        SHG_LIST: "/_shgDetails/search",
        LOGOUT : "/_user/_logout",
        REGISTER_APP_USER : "/_user/_registerEnterpriseUser",
        SEARCH_USER : "/_user/_searchEnterpriseUser",
        News_SEARCH: '/_news/_search',
    },
    MASTER_SERVICES: {
        ADDRESS_DROPDOWN: "/_address/dropdown",
        FINYEAR_DROPDOWN : "/bank/getBankFinancialYears",
        UTILS_VISITOR_VISIT: "/_utils/_visit",
        UTILS_VISITOR_COUNT: "/_utils/_getTotalVisitorsCount",
        PROJECT_DROPDOWN : "/_utils/_getIcdsProjectDetails"
    },
    APP_SERVICES : {
        TRACK_APP_STAT : "/_tracking/_getApplicationTrackingDetails",
        SAVE_FEEDBACK : "/feedback/create",
        GET_FEEDBACK_LIST : "/feedback/getFeedbackList"
    },
    SUBVENTION :{
        SEARCH : "loan_details/search",
        SUBMIT : "loan_details/upload",
        IS_SEARCH : "mssy_reports/search"
    },
    TRENDS : {
        LOAN_STATUS_REPORT : '/report/scooterLoanStatusReports',
        LOAN_FUNDS_REPORT : '/report/fundDisbursementReports'
    }
};