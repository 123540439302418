import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import InputText from "../../common/InputText";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { UpdateProfile,  fileUpload, postData } from '../../../services/http.service';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import InputMultiSelect from "../../common/InputMultiSelect";
import InputFile from "../../common/InputFile";
import InputTextArea from '../../common/InputTextArea';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { APP_MESSAGES } from '../../../utils/constants';
const EditProfile = ({ formData, setFormData }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { uuid } = useParams();
    const [profile, setProfile] = useState(null);
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [initialGender, setInitialGender] = useState(null);
    formData = location.state?.formData || formData || {};
    // const [profile, setProfile] = useState(
    //     formData?.photo || null
    //   );

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_USER_SERVICE}/_user/_search?uuids=${uuid}`
                  );
                // const users = await searchUserByUUID(uuid);
                if (response.data.users && response.data.users.length > 0) {
                    setProfile(response.data.users[0]);
                    setInitialGender(response.data.users[0]?.gender);
                    reset(response.data.users[0]);
                }
            } catch (error) {
                console.error('Failed to fetch profile', error);
                toast.error('Failed to fetch profile');
            }
        };

        fetchProfile();
    }, [uuid]);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: profile || {},

    });
    const formatDateForInput = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return `${year}-${month}-${day}`;
    };

    const formatDateForSubmit = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleDate = (e) => {
        const birthDate = e.target.value;
        // You can add additional logic here if needed, such as calculating age
    }



    useEffect(() => {
        // if (profile) {
        //     reset(profile);
        // }
        if (formData?.photo) {
            setProfile(formData.photo);
          }
    }, [formData]);

    useEffect(() => {
        const uuid = localStorage.getItem('UUID');
        if (uuid) {
            setValue('userId', uuid);
        }
    }, [setValue]);

    const onSubmit = async (data) => {

        // let profileId = profile;
        // if (profileId !== null && typeof profile === "object") {
        //   const formData = new FormData();
        //   formData.append("file", profile);
        //   const profileUpload = await fileUpload(formData);
        //   profileId = profileUpload.data.files[0].fileStoreId;
        // }

        try {
            
    
            const payload = {
                uuid: profile.uuid,
                alternateMobileNumber: data.alternateMobileNumber,
                emailId: data.emailId,
                gender: data.gender?.value || initialGender || '',  
                panNumber: data.panNumber,
                gurdianName: data.gurdianName,
                gurdianRelation: data.gurdianRelation,
                dob: formatDateForSubmit(data.dob),
                bloodGroup: data.bloodGroup,
                address: data.address,
                photo: 'profileId' , // Assuming image is handled separately
            };
    
            console.log('Payload:', payload);
            try{
                const response = await postData(process.env.REACT_APP_UPDATE_PROFILE_SERVICE,payload)
                // const response = await UpdateProfile(payload);
    if(response.data) {
        toast.success("Application Updated successfully", {
            position: "top-right",
        });
    }
              
            }
            catch (error) {
                toast.error(APP_MESSAGES.API_ERR, {
                  position: "top-right",
                });
              }

        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error('Failed to update profile');
        }
    };




    const educational_qualification = [
        { value: "ba", label: "BA" },
        { value: "mca", label: "MCA" },
        { value: "bsc", label: "BSC" },
    ];
    const genderTypes = [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "others", label: "Others" },
    ];

    const handleCancel = () => {
        navigate(-1);
    };
    const handleDropdownChange = (name, value) => {
        setFormData((prevstate) => ({
            ...prevstate,
            membershipQualification: {
                ...prevstate.membershipQualification,
                [name]: value,
            },
        }));
    };
    const handleGenderTypeChange = (selectedOption) => {
        const genderType = selectedOption.value;
        if (setFormData) {
            setFormData(prevstate => ({
                ...prevstate,
                gender: genderType,
            }));
        }
    };
    const handleImageChange = (e) => {
        const photo = e.target.files; // Update to handle multiple files if needed
        if (photo && photo[0]) {
            const file = photo[0];
            const validationResult = validateFile(photo);
            if (validationResult === true) {
                setImage(file);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImagePreview(reader.result);
                };
                reader.readAsDataURL(file);
            }
        }
    };
    const validateFile = (photo) => {
        if (!photo || !photo[0]) {
            return true; // No file selected, validation passes
        }

        const file = photo[0];
        const validTypes = ["image/jpeg", "image/jpg", "image/png"];
        const maxSize = 300 * 1024; // 300kb

        if (!validTypes.includes(file.type)) {
            toast.error("Profile Photo should be in JPG, JPEG, or PNG format", {
                position: "top-right",
            });
            return "Profile Photo should be in JPG, JPEG, or PNG format";
        }

        if (file.size > maxSize) {
            toast.error("Profile Photo size should not exceed 300kb", {
                position: "top-right",
            });
            return "Profile Photo size should not exceed 300kb";
        }

        return true;
    };

    if (!profile) {
        return <div>Loading...</div>;
    }

    return (
      <div className="LoanDetails">
        <ToastContainer />
        <div className="card mb-4 shadow mt-3" style={{ padding: "1rem" }}>
          <div className="card-body">
            {/* <h6 className='text-left mb-3 m-3'><strong>{t('EditProfile.Heading')}</strong></h6> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="nameAsPerAadhar"
                    label={t("EditProfile.Name")}
                    placeholder="Enter Your First Name"
                    register={register("nameAsPerAadhar")}
                    errors={errors}
                    defaultValue={profile.nameAsPerAadhar || ""}
                    disabled
                  />
                </Col>
                {/* <Col md={4}>
                                <InputText
                                    type="text"
                                    name="firstName"
                                    label="First Name"
                                    placeholder="Enter Your First Name"
                                    register={register("firstName")}
                                    errors={errors}
                                    defaultValue={profile.firstName || ''}
                                />
                            </Col>
                            <Col md={4}>
                                <InputText
                                    type="text"
                                    name="middleName"
                                    label="Middle Name"
                                    placeholder="Enter Your Middle Name"
                                    register={register("middleName")}
                                    errors={errors}
                                    defaultValue={profile.middleName || ''}
                                />
                            </Col>
                            <Col md={4}>
                                <InputText
                                    type="text"
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="Enter Your Last Name"
                                    register={register("lastName")}
                                    errors={errors}
                                    defaultValue={profile.lastName || ''}
                                />
                            </Col> */}
                {/* <Col md={4}>
                                <InputText
                                    type="text"
                                    name="userName"
                                    label={t('EditProfile.UName')}
                                    placeholder="Enter Your User Name"
                                    register={register("userName")}
                                    errors={errors}
                                    defaultValue={profile.userName || ''}
                                    disabled
                                />
                            </Col> */}
                <Col md={4}>
                  <InputText
                    type="text"
                    name="aadharNumber"
                    label={t("EditProfile.Aadhaar Number")}
                    placeholder="Enter Your Aadhaar Number"
                    register={register("aadharNumber")}
                    errors={errors}
                    defaultValue={profile.aadharNumber || ""}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="mobileNumber"
                    label={t("EditProfile.Mobile No")}
                    placeholder="Enter Your Mobile Number"
                    register={register("mobileNumber")}
                    errors={errors}
                    defaultValue={profile.mobileNumber || ""}
                    disabled
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="alternateMobileNumber"
                    label={t("EditProfile.Alternate Number")}
                    placeholder="Enter Your Alternate Mobile Number"
                    register={register("alternateMobileNumber")}
                    errors={errors}
                    defaultValue={profile.alternateMobileNumber || ""}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="emailId"
                    label={t("EditProfile.Email")}
                    placeholder="Enter Your Email Address"
                    register={register("emailId")}
                    errors={errors}
                    defaultValue={profile.emailId || ""}
                  />
                </Col>
                <Col md={4}>
                  <InputMultiSelect
                    isMulti={false}
                    name="gender"
                    label={t("EditProfile.Gender")}
                    placeholder="Select your gender"
                    register={control}
                    options={genderTypes}
                    //   rules={{ required: true, message: "This field is required" }}
                    errors={errors}
                    onChange={handleGenderTypeChange}
                    defaultValue={genderTypes.find(
                      (option) => option.value === profile.gender
                    )}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="panNumber"
                    label={t("EditProfile.Pan Number")}
                    placeholder="Enter Your PAN Number"
                    register={register("panNumber")}
                    errors={errors}
                    defaultValue={profile.panNumber || ""}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="gurdianName"
                    label={t("EditProfile.Guardian Name")}
                    placeholder="Enter Your Gurdian Name"
                    register={register("gurdianName")}
                    errors={errors}
                    defaultValue={profile.gurdianName || ""}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="gurdianRelation"
                    label={t("EditProfile.Guardian Relation")}
                    placeholder="Enter Your Gurdian Relation"
                    register={register("gurdianRelation")}
                    errors={errors}
                    defaultValue={profile.gurdianRelation || ""}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="date"
                    name="dob"
                    label={t("EditProfile.DOB")}
                    placeholder="Select date"
                    register={{ ...register("dob") }}
                    errors={errors}
                    defaultValue={profile.dob || ""}
                    onChange={handleDate} //
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="bloodGroup"
                    label={t("EditProfile.Blood Group")}
                    placeholder="Enter Your Blod Group"
                    register={register("bloodGroup")}
                    errors={errors}
                    defaultValue={profile.bloodGroup || ""}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    name="nationality"
                    label={t("EditProfile.Nationality")}
                    placeholder="Enter Your Nationality"
                    register={register("nationality")}
                    errors={errors}
                    defaultValue={profile.nationality || ""}
                    disabled
                  />
                </Col>

                {/* <Col md={4}>
                                <InputMultiSelect
                                    isMulti={false}
                                    name="educationalQualification"
                                    label={t('EditProfile.Educational Qualification')}
                                    placeholder="Select your Educational Qualification"
                                    register={control}
                                    options={educational_qualification}
                                    //   rules={{ required: true, message: "This field is required" }}
                                    errors={errors}
                                    onChange={(selectedOption) =>
                                        handleDropdownChange(
                                            "educationalQualification",
                                            selectedOption.value
                                        )
                                    }
                                    defaultValue={educational_qualification.find(
                                        (option) =>
                                            option.value ===
                                            formData?.membershipQualification
                                                ?.educationalQualification
                                    )}
                                />
                            </Col> */}
                <Col md={4} className="mt-0">
                  <InputTextArea
                    type="text"
                    name="address"
                    label={t("EditProfile.Address")}
                    placeholder="Enter Your Address"
                    register={register("address")}
                    errors={errors}
                    defaultValue={profile.address || ""}
                  />
                </Col>
                <Col md={4} className="d-flex">
                  <InputFile
                    type="file"
                    name="photo"
                    accept="image/*"
                    label={t("EditProfile.Profile Photo")}
                    placeholder="Upload your Profile Photo"
                    register={{
                      ...register("photo", {
                        validate: {
                          validateFile,
                        },
                      }),
                    }}
                    errors={errors}
                    onChange={handleImageChange}
                  />

                  {imagePreview && (
                    <div style={{ marginTop: "10px" }}>
                      <img
                        src={imagePreview}
                        alt="Profile Preview"
                        style={{
                          width: "100px",
                          height: "80px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    onClick={handleCancel}
                    style={{
                      width: "max-content",
                    }}
                    className="cancelBtn"
                  >
                    {t("EditProfile.Cancel")}
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    className="primaryBtn"
                    style={{ width: "max-content" }}
                  >
                    {t("EditProfile.Update Profile")}
                  </Button>
                </div>
            </form>
          </div>
        </div>

        {/* <div className="card mb-4 shadow">
                <div className="card-body">
                    <Row className="mt-2 twobtn mb-2">
                        <Col className="text-left">
                            <Button variant="secondary" style={{ width: 'max-content', padding: '0.375rem 0.75rem', marginTop: '15px' }} onClick={handleCancel}>
                                {t('EditProfile.Cancel')}
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button variant="info" style={{ width: 'max-content', padding: '0.375rem 0.75rem', marginTop: '15px' }} type="button" onClick={handleSubmit(onSubmit)}>
                                {t('EditProfile.Update Profile')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div> */}
      </div>
    );
};

export default EditProfile;