import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import './InputTextArea.css';
const InputTextArea = (props: any) => {

    return (
      <>
        <FloatingLabel
          className="mb-3"
          controlId={props?.name}
          label={
            <>
              {props?.label}
              {props?.required && <span className="text-danger ms-1">*</span>}
            </>
          }
        >
          {/* <Form.Label>{props?.label}</Form.Label> */}
          <Form.Control
            as="textarea"
            rows={props?.row ? props?.row : 3}
            name={props?.name}
            maxlength={props?.maxlength}
            placeholder={props?.placeholder}
            {...props?.register}
          />
          {props?.errors[props?.name] && (
            <p className="errorMsg">{props?.errors[props?.name].message}</p>
          )}
        </FloatingLabel>
        {/* <div className="floating-label-container"> */}
        {/* <FloatingLabel className="mb-3" controlId={props?.name} 
                    label={props?.label}>
                    <Form.Control
                        as="textarea"
                        rows={props?.row ? props?.row : 3}
                        name={props?.name}
                        placeholder=" "
                        {...props?.register}
                        className={props?.errors[props?.name] ? 'error' : ''}
                    />
                    {/* <Form.Label>{props?.label}</Form.Label> */}
        {/* {props?.errors[props?.name] && <p className="errorMsg">{props?.errors[props?.name].message}</p>}
                </FloatingLabel> */}
        {/* </div> */}
      </>
    );

}
export default InputTextArea